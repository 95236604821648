import client from 'services/kardAPI';

const fetchIssuers = async () => {
  try {
    const issuer = await client.get(`/issuers/portal/issuers`);
    return issuer;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchIssuers;
