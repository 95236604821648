/* eslint-disable react/prop-types */
import React from 'react';
import dayjs from 'dayjs';

import arrow from 'assets/icons/arrows/arrow-order-by.svg';
import arrowGrey from 'assets/icons/arrows/arrow-sort-by.svg';

import classes from 'components/Forms/MerchantInfo/MerchantInfo.module.scss';
import Button, { Kind } from 'components/Common/Button';
import classNames from 'classnames';

const DATE_FORMAT = 'MM/DD/YYYY';

const getColumns = ({ isEditing, onOrderUp, onOrderDown, offersLength }) => [
  {
    Header: 'header',
    columns: [
      {
        accessor: 'arrow',
        Header: '',
        columnName: 'arrow',
        sortable: false,
        filterable: false,
        Cell: ({ row }) => (
          <>
            <Button
              kind={Kind.Minimal}
              onClick={() => onOrderUp(row.index)}
              disabled={!isEditing}
              className={classNames([
                classes.arrow,
                classes.left,
                {
                  [classes.hidden]: row.index === 0,
                },
              ])}
            >
              <img src={isEditing ? arrow : arrowGrey} alt="Order up" />
            </Button>
            <Button
              kind={Kind.Minimal}
              onClick={() => onOrderDown(row.index)}
              disabled={!isEditing}
              className={classNames([
                classes.arrow,
                {
                  [classes.hidden]: row.index === offersLength,
                },
              ])}
            >
              <img
                src={isEditing ? arrow : arrowGrey}
                alt="Order down"
                className={classes.arrowDown}
              />
            </Button>
          </>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        columnName: 'name',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Offer ID',
        accessor: '_id',
        columnName: '_id',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: false,
        filterable: false,
      },

      {
        Header: 'Type',
        accessor: 'offerType',
        columnName: 'offerType',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Commission Value',
        accessor: 'commissionValue',
        columnName: 'commissionValue',
        sortable: false,
        filterable: false,
        Cell: ({
          value,
          row: {
            original: { commissionType },
          },
        }) => {
          if (value) {
            return commissionType === 'FLAT' ? `$${value}` : `${value}%`;
          }
          return '-';
        },
      },
      {
        Header: 'Merchant Network',
        accessor: 'merchantNetwork',
        columnName: 'merchantNetwork',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        columnName: 'startDate',
        Cell: ({ value }) => {
          if (value) {
            return dayjs(value).format(DATE_FORMAT);
          }
          return '-';
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Expiration Date',
        accessor: 'expirationDate',
        columnName: 'expirationDate',
        Cell: ({ value }) => {
          if (value) {
            return dayjs(value).format(DATE_FORMAT);
          }
          return '-';
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Always Appears to Cardholder',
        accessor: 'displayAlways',
        columnName: 'displayAlways',
        Cell: ({ value }) => <>{value ? 'YES' : 'NO'}</>,
        sortable: false,
        filterable: false,
      },
    ],
  },
];

export default getColumns;
