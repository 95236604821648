import client from 'services/kardAPI';

const createAudience = async (body) => {
  try {
    await client.post(`/audience/portal/audience`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createAudience;
