import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment/moment';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import {
  clearAudienceData,
  clearAudiencesErrors,
  clearSelectedAudienceRule,
  createAudience,
  editAudience,
  isEditingAudience,
  saveAudienceInformation,
  saveSelectedAudienceRule,
} from 'state/actions/audiences';
import {
  selectCreateAudienceState,
  selectCurrentAudienceRule,
  selectEditAudienceState,
  selectNewAudienceState,
} from 'state/selectors/audiences';
import { audienceType, behaviorRangeValues } from 'utils/audiences/values';
import { fetchMerchantById } from 'state/actions/merchants';
import { selectFetchMerchantByIdState } from 'state/selectors/merchants';
import getAudienceRuleData from 'utils/audiences/getAudienceRuleData';
import Status from 'enums/status/status.enum';
import commonToast from 'components/Common/commonToast';
import audienceInformationSchema from './AudienceInformation.schema';
import getInputFields from './inputFields';

import classes from './AudienceInformation.module.scss';

const DEFAULT_TIME = Object.freeze({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});

const AudienceInformation = ({ audience, isCreating, onCancel, goBack }) => {
  const dispatch = useDispatch();

  const {
    error: creatingAudienceError,
    loading: creatingAudience,
    success: audienceCreatedSuccessfully,
  } = useSelector(selectCreateAudienceState, shallowEqual);

  const {
    error: editingAudienceError,
    loading: editingAudience,
    success: audienceEditedSuccessfuly,
  } = useSelector(selectEditAudienceState, shallowEqual);

  const newAudience = useSelector(selectNewAudienceState, shallowEqual);
  const { selectedRule, isEditing } = useSelector(
    selectCurrentAudienceRule,
    shallowEqual
  );

  const { merchant: fetchedMerchant, loading: fetchingMerchant } = useSelector(
    selectFetchMerchantByIdState,
    shallowEqual
  );

  useEffect(() => {
    if (audienceCreatedSuccessfully || audienceEditedSuccessfuly) {
      dispatch(clearAudienceData());
      dispatch(clearSelectedAudienceRule());
      dispatch(clearAudiencesErrors());
    }
  }, [audienceCreatedSuccessfully, audienceEditedSuccessfuly, dispatch]);

  useEffect(() => {
    if (audience?.merchantId && !isEditing) {
      dispatch(fetchMerchantById(audience.merchantId));
    }
  }, [audience, dispatch, isEditing]);

  const isDynamic = selectedRule?.refreshAudience ?? false;

  const onSubmitHandler = (values) => {
    if (isCreating) {
      const {
        behaviorValue,
        timescaleValue,
        merchant,
        behaviorRange,
        period,
        enrollNewUser,
      } = newAudience.rule;

      const transactionCount = {
        ...(behaviorRange === behaviorRangeValues.LessThanOrEqualTo && {
          lte: Number(behaviorValue),
        }),
        ...(behaviorRange === behaviorRangeValues.GreaterThanOrEqualTo && {
          gte: Number(behaviorValue),
        }),
      };

      const authorizationDateRange = {
        gte: moment()
          .subtract(timescaleValue, period)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toISOString(),
        lte: moment()
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toISOString(),
      };

      const transformedAudience = {
        ...values,
        enrollNewUser,
        transactionCount,
        authorizationDateRange,
        merchantId: merchant._id,
        type: isDynamic ? audienceType.Dynamic : audienceType.Static,
        ...(isDynamic && { interval: 24 }), // default value for now
      };

      dispatch(saveAudienceInformation(values));
      dispatch(createAudience(transformedAudience));
    }

    if (audience && isEditing) {
      const {
        behaviorValue,
        timescaleValue,
        merchant,
        behaviorRange,
        period,
        refreshAudience,
        enrollNewUser,
      } = selectedRule;

      const { description, name, status } = values;

      const { _id, status: currentStatus } = audience;

      const transactionCount = {
        ...(behaviorRange === behaviorRangeValues.LessThanOrEqualTo && {
          lte: Number(behaviorValue),
        }),
        ...(behaviorRange === behaviorRangeValues.GreaterThanOrEqualTo && {
          gte: Number(behaviorValue),
        }),
      };

      const authorizationDateRange = {
        gte: moment()
          .subtract(timescaleValue, period)
          .utcOffset(0)
          .set(DEFAULT_TIME)
          .toISOString(),
        lte: moment().utcOffset(0).set(DEFAULT_TIME).toISOString(),
      };

      const getAudienceType = () => {
        if (refreshAudience !== null && refreshAudience !== undefined) {
          return refreshAudience ? audienceType.Dynamic : audienceType.Static;
        }

        return audience.type;
      };

      const updatedAudience = {
        _id,
        ...(name && { name }),
        ...(description && { description }),
        ...(refreshAudience && { interval: 24 }),
        ...(Object.keys(transactionCount).length > 0 && { transactionCount }),
        ...(currentStatus === Status.DRAFT && {
          authorizationDateRange,
          type: getAudienceType(),
          merchantId: merchant._id,
          ...(!refreshAudience && {
            enrollNewUser: enrollNewUser ?? audience.enrollNewUser,
          }),
        }),
        ...(['ACTIVE', 'INACTIVE'].includes(status) && { status }),
      };

      dispatch(editAudience(_id, updatedAudience));
    }
  };

  // const rulesQuantity = useMemo(() => Object.keys(newAudience.rules).length, [
  //   newAudience.rules,
  // ]);

  // const audienceOffersOptions = getDropdownValues(audience?.offers);

  const title = isCreating
    ? 'New Audience - Audience Information'
    : 'Audience Information';

  const disabledEdit =
    fetchingMerchant ||
    Status[audience?.status] === Status['in progress'] ||
    Status[audience?.status] === Status.inactive;

  const inputFields = getInputFields({
    audience,
    isEditing,
    isCreating,
    newAudience,
    isDynamic,
    disabledEdit,
  });

  const onClickCancelHandler = () => {
    dispatch(clearAudienceData());
    dispatch(clearSelectedAudienceRule());
    onCancel();
  };

  const onEditHandler = () => {
    const audienceRule = getAudienceRuleData(audience, fetchedMerchant);

    dispatch(saveSelectedAudienceRule(audienceRule, 0));
    dispatch(isEditingAudience());
  };

  const ruleText = useMemo(() => {
    if (!isEditing && !isCreating) {
      const { behaviorValue, timescaleValue } = getAudienceRuleData(
        audience,
        fetchedMerchant
      );

      return `${behaviorValue ?? '-'} purchases in the Last ${
        timescaleValue ?? '-'
      } days in ${fetchedMerchant?.name || '-'}`;
    }

    const { behaviorValue, timescaleValue, period, merchant } =
      selectedRule || {};

    return `${behaviorValue} purchases in the Last ${timescaleValue} ${period} in ${merchant?.name}`;
  }, [audience, fetchedMerchant, isCreating, isEditing, selectedRule]);

  const onClickRuleHandler = () => {
    let rule = { ...selectedRule };

    if (!isEditing && !isCreating) {
      rule = getAudienceRuleData(audience, fetchedMerchant);
    }

    dispatch(
      saveSelectedAudienceRule(
        {
          ...rule,
          refreshAudience: isDynamic,
          ...(audience ? { enrollNewUser: audience.enrollNewUser } : {}),
        },
        0
      )
    );
    goBack();
  };

  const isLoading = creatingAudience || fetchingMerchant || editingAudience;

  const isSuccess = isEditing
    ? audienceEditedSuccessfuly
    : audienceCreatedSuccessfully;

  const isError = isEditing ? editingAudienceError : creatingAudienceError;

  return (
    <>
      {commonToast(
        isSuccess,
        isError,
        `Audience ${isEditing ? 'updated' : 'created'} successfully`,
        isError
      )}
      <Form
        onSubmit={onSubmitHandler}
        validationMode={ValidationMode.OnChange}
        className={classes.form}
        validationSchema={audienceInformationSchema}
      >
        <Heading size={HeadingSize.M}>{title}</Heading>
        <div>
          {inputFields.map(
            ({
              name,
              renderProps,
              show,
              component: Component,
              ...otherProps
            }) =>
              show && (
                <Fragment key={name}>
                  {renderProps ? (
                    <FormControl
                      name={name}
                      render={(props) => (
                        <Component {...renderProps} {...props} />
                      )}
                    />
                  ) : (
                    <Component name={name} {...otherProps} />
                  )}
                </Fragment>
              )
          )}
        </div>
        <div className={classes.rulesContainer}>
          <span className={classes.audienceRuleLabel}>Audience Rule</span>
          {/* <div>
            {Object.entries(newAudience.rules).map(
              ([ruleNumber, ruleData], index) => {
                const {
                  behaviorValue,
                  timescale,
                  timescaleValue,
                  period,
                  merchant,
                } = ruleData;

                return (
                  <div key={ruleNumber}>
                    <button
                      type="button"
                      onClick={null}
                      className={classes.audienceRule}
                    >
                      {`${behaviorValue} purchases in the ${timescale} ${timescaleValue} ${period} in ${merchant.name}`}
                    </button>
                    {index + 1 < rulesQuantity ? (
                      <span className={classes.ruleConnector}>
                        {newAudience.ruleCondition}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
            )}
            {rulesQuantity === 0 && (
              <span className={classes.noRules}>No rules were added yet</span>
            )}
          </div> */}
          <Button
            type={ButtonType.Default}
            onClick={onClickRuleHandler}
            className={classes.audienceRule}
            size={ButtonSize.L}
            loaderColor="Black"
            loading={fetchingMerchant}
          >
            {ruleText}
          </Button>
        </div>
        <div className={classes.actionButtons}>
          <div
            className={classNames({ [classes.buttonsContainer]: isCreating })}
          >
            {isCreating && (
              <button
                className={classes.goBackLink}
                onClick={onClickRuleHandler}
                type="button"
              >
                Go back to Rule Builder
              </button>
            )}
            <div>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                onClick={onClickCancelHandler}
                kind={ButtonKind.Secondary}
              >
                Cancel
              </Button>
              {!isEditing && !isCreating && (
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  className={classes.submitButton}
                  loading={fetchingMerchant}
                  disabled={disabledEdit}
                  onClick={onEditHandler}
                >
                  Edit
                </Button>
              )}
              {(isEditing || isCreating) && (
                <Button
                  type={ButtonType.Submit}
                  size={ButtonSize.S}
                  className={classes.submitButton}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {isCreating ? 'Create' : 'Save'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

AudienceInformation.propTypes = {
  audience: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
  goBack: PropTypes.func,
};

AudienceInformation.defaultProps = {
  audience: null,
  isCreating: false,
  onCancel: () => {},
  goBack: null,
};

export default AudienceInformation;
