// Source: https://github.com/greggles/mcc-codes

const MerchantCategoryCode = Object.freeze({
  '0742': {
    edited_description: 'Veterinary Services',
    combined_description: 'Veterinary Services',
    usda_description: 'Veterinary Services',
    irs_description: 'Veterinary Services',
    irs_reportable: 'Yes',
    id: 0,
  },
  '0763': {
    edited_description: 'Agricultural Co-operatives',
    combined_description: 'Agricultural Co-operatives',
    usda_description: 'Agricultural Co-operatives',
    irs_description: 'Agricultural Cooperative',
    irs_reportable: 'Yes',
    id: 1,
  },
  '0780': {
    edited_description: 'Horticultural Services, Landscaping Services',
    combined_description: 'Horticultural Services, Landscaping Services',
    usda_description: 'Horticultural Services',
    irs_description: 'Landscaping Services',
    irs_reportable: 'Yes',
    id: 2,
  },
  1520: {
    edited_description: 'General Contractors-Residential and Commercial',
    combined_description: 'General Contractors-Residential and Commercial',
    usda_description: 'General Contractors-Residential and Commercial',
    irs_description: 'General Contractors',
    irs_reportable: 'Yes',
    id: 3,
  },
  1711: {
    edited_description:
      'Air Conditioning Contractors \u2013 Sales and Installation, Heating Contractors \u2013 Sales, Service, Installation',
    combined_description:
      'Air Conditioning Contractors \u2013 Sales and Installation, Heating Contractors \u2013 Sales, Service, Installation',
    usda_description:
      'Air Conditioning Contractors \u2013 Sales and Installation',
    irs_description: 'Heating, Plumbing, A/C',
    irs_reportable: 'Yes',
    id: 4,
  },
  1731: {
    edited_description: 'Electrical Contractors',
    combined_description: 'Electrical Contractors',
    usda_description: 'Electrical Contractors',
    irs_description: 'Electrical Contractors',
    irs_reportable: 'Yes',
    id: 5,
  },
  1740: {
    edited_description:
      'Insulation \u2013 Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors',
    combined_description:
      'Insulation \u2013 Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors',
    usda_description: 'Insulation \u2013 Contractors',
    irs_description: 'Masonry, Stonework, and Plaster',
    irs_reportable: 'Yes',
    id: 6,
  },
  1750: {
    edited_description: 'Carpentry Contractors',
    combined_description: 'Carpentry Contractors',
    usda_description: 'Carpentry Contractors',
    irs_description: 'Carpentry Contractors',
    irs_reportable: 'Yes',
    id: 7,
  },
  1761: {
    edited_description:
      'Roofing \u2013 Contractors, Sheet Metal Work \u2013 Contractors, Siding \u2013 Contractors',
    combined_description:
      'Roofing \u2013 Contractors, Sheet Metal Work \u2013 Contractors, Siding \u2013 Contractors',
    usda_description: 'Roofing - Contractors',
    irs_description: 'Roofing/Siding, Sheet Metal',
    irs_reportable: 'Yes',
    id: 8,
  },
  1771: {
    edited_description: 'Contractors \u2013 Concrete Work',
    combined_description: 'Contractors \u2013 Concrete Work',
    usda_description: 'Contractors \u2013 Concrete Work',
    irs_description: 'Concrete Work Contractors',
    irs_reportable: 'Yes',
    id: 9,
  },
  1799: {
    edited_description:
      'Contractors \u2013 Special Trade, Not Elsewhere Classified',
    combined_description:
      'Contractors \u2013 Special Trade, Not Elsewhere Classified',
    usda_description:
      'Contractors \u2013 Special Trade, Not Elsewhere Classified',
    irs_description: 'Special Trade Contractors',
    irs_reportable: 'Yes',
    id: 10,
  },
  2741: {
    edited_description: 'Miscellaneous Publishing and Printing',
    combined_description: 'Miscellaneous Publishing and Printing',
    usda_description: 'Miscellaneous Publishing and Printing',
    irs_description: 'Miscellaneous Publishing and Printing',
    irs_reportable: 'Yes',
    id: 11,
  },
  2791: {
    edited_description: 'Typesetting, Plate Making, & Related Services',
    combined_description: 'Typesetting, Plate Making, & Related Services',
    usda_description: 'Typesetting, Plate Making, & Related Services',
    irs_description: 'Typesetting, Plate Making, and Related Services',
    irs_reportable: 'Yes',
    id: 12,
  },
  2842: {
    edited_description:
      'Specialty Cleaning, Polishing, and Sanitation Preparations',
    combined_description:
      'Specialty Cleaning, Polishing, and Sanitation Preparations',
    usda_description:
      'Specialty Cleaning, Polishing, and Sanitation Preparations',
    irs_description: 'Specialty Cleaning',
    irs_reportable: 'Yes',
    id: 13,
  },
  3000: {
    edited_description: 'UNITED AIRLINES',
    combined_description: 'UNITED AIRLINES',
    usda_description: 'UNITED AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 14,
  },
  3001: {
    edited_description: 'AMERICAN AIRLINES',
    combined_description: 'AMERICAN AIRLINES',
    usda_description: 'AMERICAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 15,
  },
  3002: {
    edited_description: 'PAN AMERICAN',
    combined_description: 'PAN AMERICAN',
    usda_description: 'PAN AMERICAN',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 16,
  },
  3003: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 17,
  },
  3004: {
    edited_description: 'TRANS WORLD AIRLINES',
    combined_description: 'TRANS WORLD AIRLINES',
    usda_description: 'TRANS WORLD AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 18,
  },
  3005: {
    edited_description: 'BRITISH AIRWAYS',
    combined_description: 'BRITISH AIRWAYS',
    usda_description: 'BRITISH AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 19,
  },
  3006: {
    edited_description: 'JAPAN AIRLINES',
    combined_description: 'JAPAN AIRLINES',
    usda_description: 'JAPAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 20,
  },
  3007: {
    edited_description: 'AIR FRANCE',
    combined_description: 'AIR FRANCE',
    usda_description: 'AIR FRANCE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 21,
  },
  3008: {
    edited_description: 'LUFTHANSA',
    combined_description: 'LUFTHANSA',
    usda_description: 'LUFTHANSA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 22,
  },
  3009: {
    edited_description: 'AIR CANADA',
    combined_description: 'AIR CANADA',
    usda_description: 'AIR CANADA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 23,
  },
  3010: {
    edited_description: 'KLM (ROYAL DUTCH AIRLINES)',
    combined_description: 'KLM (ROYAL DUTCH AIRLINES)',
    usda_description: 'KLM (ROYAL DUTCH AIRLINES)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 24,
  },
  3011: {
    edited_description: 'AEORFLOT',
    combined_description: 'AEORFLOT',
    usda_description: 'AEORFLOT',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 25,
  },
  3012: {
    edited_description: 'QANTAS',
    combined_description: 'QANTAS',
    usda_description: 'QANTAS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 26,
  },
  3013: {
    edited_description: 'ALITALIA',
    combined_description: 'ALITALIA',
    usda_description: 'ALITALIA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 27,
  },
  3014: {
    edited_description: 'SAUDIA ARABIAN AIRLINES',
    combined_description: 'SAUDIA ARABIAN AIRLINES',
    usda_description: 'SAUDIA ARABIAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 28,
  },
  3015: {
    edited_description: 'SWISSAIR',
    combined_description: 'SWISSAIR',
    usda_description: 'SWISSAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 29,
  },
  3016: {
    edited_description: 'SAS',
    combined_description: 'SAS',
    usda_description: 'SAS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 30,
  },
  3017: {
    edited_description: 'SOUTH AFRICAN AIRWAYS',
    combined_description: 'SOUTH AFRICAN AIRWAYS',
    usda_description: 'SOUTH AFRICAN AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 31,
  },
  3018: {
    edited_description: 'VARIG (BRAZIL)',
    combined_description: 'VARIG (BRAZIL)',
    usda_description: 'VARIG (BRAZIL)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 32,
  },
  3019: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 33,
  },
  3020: {
    edited_description: 'AIR-INDIA',
    combined_description: 'AIR-INDIA',
    usda_description: 'AIR-INDIA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 34,
  },
  3021: {
    edited_description: 'AIR ALGERIE',
    combined_description: 'AIR ALGERIE',
    usda_description: 'AIR ALGERIE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 35,
  },
  3022: {
    edited_description: 'PHILIPPINE AIRLINES',
    combined_description: 'PHILIPPINE AIRLINES',
    usda_description: 'PHILIPPINE AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 36,
  },
  3023: {
    edited_description: 'MEXICANA',
    combined_description: 'MEXICANA',
    usda_description: 'MEXICANA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 37,
  },
  3024: {
    edited_description: 'PAKISTAN INTERNATIONAL',
    combined_description: 'PAKISTAN INTERNATIONAL',
    usda_description: 'PAKISTAN INTERNATIONAL',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 38,
  },
  3025: {
    edited_description: 'AIR NEW ZEALAND',
    combined_description: 'AIR NEW ZEALAND',
    usda_description: 'AIR NEW ZEALAND',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 39,
  },
  3026: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 40,
  },
  3027: {
    edited_description: 'UTA/INTERAIR',
    combined_description: 'UTA/INTERAIR',
    usda_description: 'UTA/INTERAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 41,
  },
  3028: {
    edited_description: 'AIR MALTA',
    combined_description: 'AIR MALTA',
    usda_description: 'AIR MALTA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 42,
  },
  3029: {
    edited_description: 'SABENA',
    combined_description: 'SABENA',
    usda_description: 'SABENA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 43,
  },
  3030: {
    edited_description: 'AEROLINEAS ARGENTINAS',
    combined_description: 'AEROLINEAS ARGENTINAS',
    usda_description: 'AEROLINEAS ARGENTINAS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 44,
  },
  3031: {
    edited_description: 'OLYMPIC AIRWAYS',
    combined_description: 'OLYMPIC AIRWAYS',
    usda_description: 'OLYMPIC AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 45,
  },
  3032: {
    edited_description: 'EL AL',
    combined_description: 'EL AL',
    usda_description: 'EL AL',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 46,
  },
  3033: {
    edited_description: 'ANSETT AIRLINES',
    combined_description: 'ANSETT AIRLINES',
    usda_description: 'ANSETT AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 47,
  },
  3034: {
    edited_description: 'AUSTRAINLIAN AIRLINES',
    combined_description: 'AUSTRAINLIAN AIRLINES',
    usda_description: 'AUSTRAINLIAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 48,
  },
  3035: {
    edited_description: 'TAP (PORTUGAL)',
    combined_description: 'TAP (PORTUGAL)',
    usda_description: 'TAP (PORTUGAL)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 49,
  },
  3036: {
    edited_description: 'VASP (BRAZIL)',
    combined_description: 'VASP (BRAZIL)',
    usda_description: 'VASP (BRAZIL)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 50,
  },
  3037: {
    edited_description: 'EGYPTAIR',
    combined_description: 'EGYPTAIR',
    usda_description: 'EGYPTAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 51,
  },
  3038: {
    edited_description: 'KUWAIT AIRLINES',
    combined_description: 'KUWAIT AIRLINES',
    usda_description: 'KUWAIT AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 52,
  },
  3039: {
    edited_description: 'AVIANCA',
    combined_description: 'AVIANCA',
    usda_description: 'AVIANCA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 53,
  },
  3040: {
    edited_description: 'GULF AIR (BAHRAIN)',
    combined_description: 'GULF AIR (BAHRAIN)',
    usda_description: 'GULF AIR (BAHRAIN)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 54,
  },
  3041: {
    edited_description: 'BALKAN-BULGARIAN AIRLINES',
    combined_description: 'BALKAN-BULGARIAN AIRLINES',
    usda_description: 'BALKAN-BULGARIAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 55,
  },
  3042: {
    edited_description: 'FINNAIR',
    combined_description: 'FINNAIR',
    usda_description: 'FINNAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 56,
  },
  3043: {
    edited_description: 'AER LINGUS',
    combined_description: 'AER LINGUS',
    usda_description: 'AER LINGUS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 57,
  },
  3044: {
    edited_description: 'AIR LANKA',
    combined_description: 'AIR LANKA',
    usda_description: 'AIR LANKA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 58,
  },
  3045: {
    edited_description: 'NIGERIA AIRWAYS',
    combined_description: 'NIGERIA AIRWAYS',
    usda_description: 'NIGERIA AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 59,
  },
  3046: {
    edited_description: 'CRUZEIRO DO SUL (BRAZIJ)',
    combined_description: 'CRUZEIRO DO SUL (BRAZIJ)',
    usda_description: 'CRUZEIRO DO SUL (BRAZIJ)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 60,
  },
  3047: {
    edited_description: 'THY (TURKEY)',
    combined_description: 'THY (TURKEY)',
    usda_description: 'THY (TURKEY)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 61,
  },
  3048: {
    edited_description: 'ROYAL AIR MAROC',
    combined_description: 'ROYAL AIR MAROC',
    usda_description: 'ROYAL AIR MAROC',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 62,
  },
  3049: {
    edited_description: 'TUNIS AIR',
    combined_description: 'TUNIS AIR',
    usda_description: 'TUNIS AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 63,
  },
  3050: {
    edited_description: 'ICELANDAIR',
    combined_description: 'ICELANDAIR',
    usda_description: 'ICELANDAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 64,
  },
  3051: {
    edited_description: 'AUSTRIAN AIRLINES',
    combined_description: 'AUSTRIAN AIRLINES',
    usda_description: 'AUSTRIAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 65,
  },
  3052: {
    edited_description: 'LANCHILE',
    combined_description: 'LANCHILE',
    usda_description: 'LANCHILE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 66,
  },
  3053: {
    edited_description: 'AVIACO (SPAIN)',
    combined_description: 'AVIACO (SPAIN)',
    usda_description: 'AVIACO (SPAIN)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 67,
  },
  3054: {
    edited_description: 'LADECO (CHILE)',
    combined_description: 'LADECO (CHILE)',
    usda_description: 'LADECO (CHILE)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 68,
  },
  3055: {
    edited_description: 'LAB (BOLIVIA)',
    combined_description: 'LAB (BOLIVIA)',
    usda_description: 'LAB (BOLIVIA)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 69,
  },
  3056: {
    edited_description: 'QUEBECAIRE',
    combined_description: 'QUEBECAIRE',
    usda_description: 'QUEBECAIRE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 70,
  },
  3057: {
    edited_description: 'EASTWEST AIRLINES (AUSTRALIA)',
    combined_description: 'EASTWEST AIRLINES (AUSTRALIA)',
    usda_description: 'EASTWEST AIRLINES (AUSTRALIA)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 71,
  },
  3058: {
    edited_description: 'DELTA',
    combined_description: 'DELTA',
    usda_description: 'DELTA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 72,
  },
  3059: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 73,
  },
  3060: {
    edited_description: 'NORTHWEST',
    combined_description: 'NORTHWEST',
    usda_description: 'NORTHWEST',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 74,
  },
  3061: {
    edited_description: 'CONTINENTAL',
    combined_description: 'CONTINENTAL',
    usda_description: 'CONTINENTAL',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 75,
  },
  3062: {
    edited_description: 'WESTERN',
    combined_description: 'WESTERN',
    usda_description: 'WESTERN',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 76,
  },
  3063: {
    edited_description: 'US AIR',
    combined_description: 'US AIR',
    usda_description: 'US AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 77,
  },
  3064: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 78,
  },
  3065: {
    edited_description: 'AIRINTER',
    combined_description: 'AIRINTER',
    usda_description: 'AIRINTER',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 79,
  },
  3066: {
    edited_description: 'SOUTHWEST',
    combined_description: 'SOUTHWEST',
    usda_description: 'SOUTHWEST',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 80,
  },
  3067: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 81,
  },
  3068: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 82,
  },
  3069: {
    edited_description: 'SUN COUNTRY AIRLINES',
    combined_description: 'SUN COUNTRY AIRLINES',
    usda_description: 'SUN COUNTRY AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 83,
  },
  3070: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 84,
  },
  3071: {
    edited_description: 'AIR BRITISH COLUBIA',
    combined_description: 'AIR BRITISH COLUBIA',
    usda_description: 'AIR BRITISH COLUBIA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 85,
  },
  3072: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 86,
  },
  3073: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 87,
  },
  3074: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 88,
  },
  3075: {
    edited_description: 'SINGAPORE AIRLINES',
    combined_description: 'SINGAPORE AIRLINES',
    usda_description: 'SINGAPORE AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 89,
  },
  3076: {
    edited_description: 'AEROMEXICO',
    combined_description: 'AEROMEXICO',
    usda_description: 'AEROMEXICO',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 90,
  },
  3077: {
    edited_description: 'THAI AIRWAYS',
    combined_description: 'THAI AIRWAYS',
    usda_description: 'THAI AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 91,
  },
  3078: {
    edited_description: 'CHINA AIRLINES',
    combined_description: 'CHINA AIRLINES',
    usda_description: 'CHINA AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 92,
  },
  3079: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 93,
  },
  3080: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 94,
  },
  3081: {
    edited_description: 'NORDAIR',
    combined_description: 'NORDAIR',
    usda_description: 'NORDAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 95,
  },
  3082: {
    edited_description: 'KOREAN AIRLINES',
    combined_description: 'KOREAN AIRLINES',
    usda_description: 'KOREAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 96,
  },
  3083: {
    edited_description: 'AIR AFRIGUE',
    combined_description: 'AIR AFRIGUE',
    usda_description: 'AIR AFRIGUE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 97,
  },
  3084: {
    edited_description: 'EVA AIRLINES',
    combined_description: 'EVA AIRLINES',
    usda_description: 'EVA AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 98,
  },
  3085: {
    edited_description: 'MIDWEST EXPRESS AIRLINES, INC.',
    combined_description: 'MIDWEST EXPRESS AIRLINES, INC.',
    usda_description: 'MIDWEST EXPRESS AIRLINES, INC.',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 99,
  },
  3086: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 100,
  },
  3087: {
    edited_description: 'METRO AIRLINES',
    combined_description: 'METRO AIRLINES',
    usda_description: 'METRO AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 101,
  },
  3088: {
    edited_description: 'CROATIA AIRLINES',
    combined_description: 'CROATIA AIRLINES',
    usda_description: 'CROATIA AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 102,
  },
  3089: {
    edited_description: 'TRANSAERO',
    combined_description: 'TRANSAERO',
    usda_description: 'TRANSAERO',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 103,
  },
  3090: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 104,
  },
  3091: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 105,
  },
  3092: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 106,
  },
  3093: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 107,
  },
  3094: {
    edited_description: 'ZAMBIA AIRWAYS',
    combined_description: 'ZAMBIA AIRWAYS',
    usda_description: 'ZAMBIA AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 108,
  },
  3095: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 109,
  },
  3096: {
    edited_description: 'AIR ZIMBABWE',
    combined_description: 'AIR ZIMBABWE',
    usda_description: 'AIR ZIMBABWE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 110,
  },
  3097: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 111,
  },
  3098: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 112,
  },
  3099: {
    edited_description: 'CATHAY PACIFIC',
    combined_description: 'CATHAY PACIFIC',
    usda_description: 'CATHAY PACIFIC',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 113,
  },
  3100: {
    edited_description: 'MALAYSIAN AIRLINE SYSTEM',
    combined_description: 'MALAYSIAN AIRLINE SYSTEM',
    usda_description: 'MALAYSIAN AIRLINE SYSTEM',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 114,
  },
  3101: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 115,
  },
  3102: {
    edited_description: 'IBERIA',
    combined_description: 'IBERIA',
    usda_description: 'IBERIA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 116,
  },
  3103: {
    edited_description: 'GARUDA (INDONESIA)',
    combined_description: 'GARUDA (INDONESIA)',
    usda_description: 'GARUDA (INDONESIA)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 117,
  },
  3104: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 118,
  },
  3105: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 119,
  },
  3106: {
    edited_description: 'BRAATHENS S.A.F.E. (NORWAY)',
    combined_description: 'BRAATHENS S.A.F.E. (NORWAY)',
    usda_description: 'BRAATHENS S.A.F.E. (NORWAY)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 120,
  },
  3107: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 121,
  },
  3108: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 122,
  },
  3109: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 123,
  },
  3110: {
    edited_description: 'WINGS AIRWAYS',
    combined_description: 'WINGS AIRWAYS',
    usda_description: 'WINGS AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 124,
  },
  3111: {
    edited_description: 'BRITISH MIDLAND',
    combined_description: 'BRITISH MIDLAND',
    usda_description: 'BRITISH MIDLAND',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 125,
  },
  3112: {
    edited_description: 'WINDWARD ISLAND',
    combined_description: 'WINDWARD ISLAND',
    usda_description: 'WINDWARD ISLAND',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 126,
  },
  3113: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 127,
  },
  3114: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 128,
  },
  3115: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 129,
  },
  3116: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 130,
  },
  3117: {
    edited_description: 'VIASA',
    combined_description: 'VIASA',
    usda_description: 'VIASA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 131,
  },
  3118: {
    edited_description: 'VALLEY AIRLINES',
    combined_description: 'VALLEY AIRLINES',
    usda_description: 'VALLEY AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 132,
  },
  3119: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 133,
  },
  3120: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 134,
  },
  3121: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 135,
  },
  3122: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 136,
  },
  3123: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 137,
  },
  3124: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 138,
  },
  3125: {
    edited_description: 'TAN',
    combined_description: 'TAN',
    usda_description: 'TAN',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 139,
  },
  3126: {
    edited_description: 'TALAIR',
    combined_description: 'TALAIR',
    usda_description: 'TALAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 140,
  },
  3127: {
    edited_description: 'TACA INTERNATIONAL',
    combined_description: 'TACA INTERNATIONAL',
    usda_description: 'TACA INTERNATIONAL',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 141,
  },
  3128: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 142,
  },
  3129: {
    edited_description: 'SURINAM AIRWAYS',
    combined_description: 'SURINAM AIRWAYS',
    usda_description: 'SURINAM AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 143,
  },
  3130: {
    edited_description: 'SUN WORLD INTERNATIONAL',
    combined_description: 'SUN WORLD INTERNATIONAL',
    usda_description: 'SUN WORLD INTERNATIONAL',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 144,
  },
  3131: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 145,
  },
  3132: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 146,
  },
  3133: {
    edited_description: 'SUNBELT AIRLINES',
    combined_description: 'SUNBELT AIRLINES',
    usda_description: 'SUNBELT AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 147,
  },
  3134: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 148,
  },
  3135: {
    edited_description: 'SUDAN AIRWAYS',
    combined_description: 'SUDAN AIRWAYS',
    usda_description: 'SUDAN AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 149,
  },
  3136: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 150,
  },
  3137: {
    edited_description: 'SINGLETON',
    combined_description: 'SINGLETON',
    usda_description: 'SINGLETON',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 151,
  },
  3138: {
    edited_description: 'SIMMONS AIRLINES',
    combined_description: 'SIMMONS AIRLINES',
    usda_description: 'SIMMONS AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 152,
  },
  3139: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 153,
  },
  3140: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 154,
  },
  3141: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 155,
  },
  3142: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 156,
  },
  3143: {
    edited_description: 'SCENIC AIRLINES',
    combined_description: 'SCENIC AIRLINES',
    usda_description: 'SCENIC AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 157,
  },
  3144: {
    edited_description: 'VIRGIN ATLANTIC',
    combined_description: 'VIRGIN ATLANTIC',
    usda_description: 'VIRGIN ATLANTIC',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 158,
  },
  3145: {
    edited_description: 'SAN JUAN AIRLINES',
    combined_description: 'SAN JUAN AIRLINES',
    usda_description: 'SAN JUAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 159,
  },
  3146: {
    edited_description: 'LUXAIR',
    combined_description: 'LUXAIR',
    usda_description: 'LUXAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 160,
  },
  3147: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 161,
  },
  3148: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 162,
  },
  3149: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 163,
  },
  3150: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 164,
  },
  3151: {
    edited_description: 'AIR ZAIRE',
    combined_description: 'AIR ZAIRE',
    usda_description: 'AIR ZAIRE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 165,
  },
  3152: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 166,
  },
  3153: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 167,
  },
  3154: {
    edited_description: 'PRINCEVILLE',
    combined_description: 'PRINCEVILLE',
    usda_description: 'PRINCEVILLE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 168,
  },
  3155: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 169,
  },
  3156: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 170,
  },
  3157: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 171,
  },
  3158: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 172,
  },
  3159: {
    edited_description: 'PBA',
    combined_description: 'PBA',
    usda_description: 'PBA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 173,
  },
  3160: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 174,
  },
  3161: {
    edited_description: 'ALL NIPPON AIRWAYS',
    combined_description: 'ALL NIPPON AIRWAYS',
    usda_description: 'ALL NIPPON AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 175,
  },
  3162: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 176,
  },
  3163: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 177,
  },
  3164: {
    edited_description: 'NORONTAIR',
    combined_description: 'NORONTAIR',
    usda_description: 'NORONTAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 178,
  },
  3165: {
    edited_description: 'NEW YORK HELICOPTER',
    combined_description: 'NEW YORK HELICOPTER',
    usda_description: 'NEW YORK HELICOPTER',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 179,
  },
  3166: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 180,
  },
  3167: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 181,
  },
  3168: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 182,
  },
  3169: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 183,
  },
  3170: {
    edited_description: 'NOUNT COOK',
    combined_description: 'NOUNT COOK',
    usda_description: 'NOUNT COOK',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 184,
  },
  3171: {
    edited_description: 'CANADIAN AIRLINES INTERNATIONAL',
    combined_description: 'CANADIAN AIRLINES INTERNATIONAL',
    usda_description: 'CANADIAN AIRLINES INTERNATIONAL',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 185,
  },
  3172: {
    edited_description: 'NATIONAIR',
    combined_description: 'NATIONAIR',
    usda_description: 'NATIONAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 186,
  },
  3173: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 187,
  },
  3174: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 188,
  },
  3175: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 189,
  },
  3176: {
    edited_description: 'METROFLIGHT AIRLINES',
    combined_description: 'METROFLIGHT AIRLINES',
    usda_description: 'METROFLIGHT AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 190,
  },
  3177: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 191,
  },
  3178: {
    edited_description: 'MESA AIR',
    combined_description: 'MESA AIR',
    usda_description: 'MESA AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 192,
  },
  3179: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 193,
  },
  3180: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 194,
  },
  3181: {
    edited_description: 'MALEV',
    combined_description: 'MALEV',
    usda_description: 'MALEV',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 195,
  },
  3182: {
    edited_description: 'LOT (POLAND)',
    combined_description: 'LOT (POLAND)',
    usda_description: 'LOT (POLAND)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 196,
  },
  3183: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 197,
  },
  3184: {
    edited_description: 'LIAT',
    combined_description: 'LIAT',
    usda_description: 'LIAT',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 198,
  },
  3185: {
    edited_description: 'LAV (VENEZUELA)',
    combined_description: 'LAV (VENEZUELA)',
    usda_description: 'LAV (VENEZUELA)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 199,
  },
  3186: {
    edited_description: 'LAP (PARAGUAY)',
    combined_description: 'LAP (PARAGUAY)',
    usda_description: 'LAP (PARAGUAY)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 200,
  },
  3187: {
    edited_description: 'LACSA (COSTA RICA)',
    combined_description: 'LACSA (COSTA RICA)',
    usda_description: 'LACSA (COSTA RICA)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 201,
  },
  3188: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 202,
  },
  3189: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 203,
  },
  3190: {
    edited_description: 'JUGOSLAV AIR',
    combined_description: 'JUGOSLAV AIR',
    usda_description: 'JUGOSLAV AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 204,
  },
  3191: {
    edited_description: 'ISLAND AIRLINES',
    combined_description: 'ISLAND AIRLINES',
    usda_description: 'ISLAND AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 205,
  },
  3192: {
    edited_description: 'IRAN AIR',
    combined_description: 'IRAN AIR',
    usda_description: 'IRAN AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 206,
  },
  3193: {
    edited_description: 'INDIAN AIRLINES',
    combined_description: 'INDIAN AIRLINES',
    usda_description: 'INDIAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 207,
  },
  3194: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 208,
  },
  3195: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 209,
  },
  3196: {
    edited_description: 'HAWAIIAN AIR',
    combined_description: 'HAWAIIAN AIR',
    usda_description: 'HAWAIIAN AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 210,
  },
  3197: {
    edited_description: 'HAVASU AIRLINES',
    combined_description: 'HAVASU AIRLINES',
    usda_description: 'HAVASU AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 211,
  },
  3198: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 212,
  },
  3199: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 213,
  },
  3200: {
    edited_description: 'FUYANA AIRWAYS',
    combined_description: 'FUYANA AIRWAYS',
    usda_description: 'FUYANA AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 214,
  },
  3201: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 215,
  },
  3202: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 216,
  },
  3203: {
    edited_description: 'GOLDEN PACIFIC AIR',
    combined_description: 'GOLDEN PACIFIC AIR',
    usda_description: 'GOLDEN PACIFIC AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 217,
  },
  3204: {
    edited_description: 'FREEDOM AIR',
    combined_description: 'FREEDOM AIR',
    usda_description: 'FREEDOM AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 218,
  },
  3205: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 219,
  },
  3206: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 220,
  },
  3207: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 221,
  },
  3208: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 222,
  },
  3209: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 223,
  },
  3210: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 224,
  },
  3211: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 225,
  },
  3212: {
    edited_description: 'DOMINICANA',
    combined_description: 'DOMINICANA',
    usda_description: 'DOMINICANA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 226,
  },
  3213: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 227,
  },
  3214: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 228,
  },
  3215: {
    edited_description: 'DAN AIR SERVICES',
    combined_description: 'DAN AIR SERVICES',
    usda_description: 'DAN AIR SERVICES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 229,
  },
  3216: {
    edited_description: 'CUMBERLAND AIRLINES',
    combined_description: 'CUMBERLAND AIRLINES',
    usda_description: 'CUMBERLAND AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 230,
  },
  3217: {
    edited_description: 'CSA',
    combined_description: 'CSA',
    usda_description: 'CSA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 231,
  },
  3218: {
    edited_description: 'CROWN AIR',
    combined_description: 'CROWN AIR',
    usda_description: 'CROWN AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 232,
  },
  3219: {
    edited_description: 'COPA',
    combined_description: 'COPA',
    usda_description: 'COPA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 233,
  },
  3220: {
    edited_description: 'COMPANIA FAUCETT',
    combined_description: 'COMPANIA FAUCETT',
    usda_description: 'COMPANIA FAUCETT',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 234,
  },
  3221: {
    edited_description: 'TRANSPORTES AEROS MILITARES ECCUATORANOS',
    combined_description: 'TRANSPORTES AEROS MILITARES ECCUATORANOS',
    usda_description: 'TRANSPORTES AEROS MILITARES ECCUATORANOS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 235,
  },
  3222: {
    edited_description: 'COMMAND AIRWAYS',
    combined_description: 'COMMAND AIRWAYS',
    usda_description: 'COMMAND AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 236,
  },
  3223: {
    edited_description: 'COMAIR',
    combined_description: 'COMAIR',
    usda_description: 'COMAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 237,
  },
  3224: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 238,
  },
  3225: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 239,
  },
  3226: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 240,
  },
  3227: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 241,
  },
  3228: {
    edited_description: 'CAYMAN AIRWAYS',
    combined_description: 'CAYMAN AIRWAYS',
    usda_description: 'CAYMAN AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 242,
  },
  3229: {
    edited_description: 'SAETA SOCIAEDAD ECUATORIANOS DE TRANSPORTES AEREOS',
    combined_description: 'SAETA SOCIAEDAD ECUATORIANOS DE TRANSPORTES AEREOS',
    usda_description: 'SAETA SOCIAEDAD ECUATORIANOS DE TRANSPORTES AEREOS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 243,
  },
  3230: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 244,
  },
  3231: {
    edited_description: 'SASHA SERVICIO AERO DE HONDURAS',
    combined_description: 'SASHA SERVICIO AERO DE HONDURAS',
    usda_description: 'SASHA SERVICIO AERO DE HONDURAS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 245,
  },
  3232: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 246,
  },
  3233: {
    edited_description: 'CAPITOL AIR',
    combined_description: 'CAPITOL AIR',
    usda_description: 'CAPITOL AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 247,
  },
  3234: {
    edited_description: 'BWIA',
    combined_description: 'BWIA',
    usda_description: 'BWIA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 248,
  },
  3235: {
    edited_description: 'BROKWAY AIR',
    combined_description: 'BROKWAY AIR',
    usda_description: 'BROKWAY AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 249,
  },
  3236: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 250,
  },
  3237: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 251,
  },
  3238: {
    edited_description: 'BEMIDJI AIRLINES',
    combined_description: 'BEMIDJI AIRLINES',
    usda_description: 'BEMIDJI AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 252,
  },
  3239: {
    edited_description: 'BAR HARBOR AIRLINES',
    combined_description: 'BAR HARBOR AIRLINES',
    usda_description: 'BAR HARBOR AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 253,
  },
  3240: {
    edited_description: 'BAHAMASAIR',
    combined_description: 'BAHAMASAIR',
    usda_description: 'BAHAMASAIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 254,
  },
  3241: {
    edited_description: 'AVIATECA (GUATEMALA)',
    combined_description: 'AVIATECA (GUATEMALA)',
    usda_description: 'AVIATECA (GUATEMALA)',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 255,
  },
  3242: {
    edited_description: 'AVENSA',
    combined_description: 'AVENSA',
    usda_description: 'AVENSA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 256,
  },
  3243: {
    edited_description: 'AUSTRIAN AIR SERVICE',
    combined_description: 'AUSTRIAN AIR SERVICE',
    usda_description: 'AUSTRIAN AIR SERVICE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 257,
  },
  3244: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 258,
  },
  3245: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 259,
  },
  3246: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 260,
  },
  3247: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 261,
  },
  3248: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 262,
  },
  3249: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 263,
  },
  3250: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 264,
  },
  3251: {
    edited_description: 'ALOHA AIRLINES',
    combined_description: 'ALOHA AIRLINES',
    usda_description: 'ALOHA AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 265,
  },
  3252: {
    edited_description: 'ALM',
    combined_description: 'ALM',
    usda_description: 'ALM',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 266,
  },
  3253: {
    edited_description: 'AMERICA WEST',
    combined_description: 'AMERICA WEST',
    usda_description: 'AMERICA WEST',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 267,
  },
  3254: {
    edited_description: 'TRUMP AIRLINE',
    combined_description: 'TRUMP AIRLINE',
    usda_description: 'TRUMP AIRLINE',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 268,
  },
  3255: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 269,
  },
  3256: {
    edited_description: 'ALASKA AIRLINES',
    combined_description: 'ALASKA AIRLINES',
    usda_description: 'ALASKA AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 270,
  },
  3257: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 271,
  },
  3258: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 272,
  },
  3259: {
    edited_description: 'AMERICAN TRANS AIR',
    combined_description: 'AMERICAN TRANS AIR',
    usda_description: 'AMERICAN TRANS AIR',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 273,
  },
  3260: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 274,
  },
  3261: {
    edited_description: 'AIR CHINA',
    combined_description: 'AIR CHINA',
    usda_description: 'AIR CHINA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 275,
  },
  3262: {
    edited_description: 'RENO AIR, INC.',
    combined_description: 'RENO AIR, INC.',
    usda_description: 'RENO AIR, INC.',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 276,
  },
  3263: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 277,
  },
  3264: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 278,
  },
  3265: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 279,
  },
  3266: {
    edited_description: 'AIR SEYCHELLES',
    combined_description: 'AIR SEYCHELLES',
    usda_description: 'AIR SEYCHELLES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 280,
  },
  3267: {
    edited_description: 'AIR PANAMA',
    combined_description: 'AIR PANAMA',
    usda_description: 'AIR PANAMA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 281,
  },
  3268: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 282,
  },
  3269: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 283,
  },
  3270: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 284,
  },
  3271: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 285,
  },
  3272: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 286,
  },
  3273: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 287,
  },
  3274: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 288,
  },
  3275: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 289,
  },
  3276: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 290,
  },
  3277: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 291,
  },
  3278: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 292,
  },
  3279: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 293,
  },
  3280: {
    edited_description: 'AIR JAMAICA',
    combined_description: 'AIR JAMAICA',
    usda_description: 'AIR JAMAICA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 294,
  },
  3281: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 295,
  },
  3282: {
    edited_description: 'AIR DJIBOUTI',
    combined_description: 'AIR DJIBOUTI',
    usda_description: 'AIR DJIBOUTI',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 296,
  },
  3283: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 297,
  },
  3284: {
    edited_description: 'AERO VIRGIN ISLANDS',
    combined_description: 'AERO VIRGIN ISLANDS',
    usda_description: 'AERO VIRGIN ISLANDS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 298,
  },
  3285: {
    edited_description: 'AERO PERU',
    combined_description: 'AERO PERU',
    usda_description: 'AERO PERU',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 299,
  },
  3286: {
    edited_description: 'AEROLINEAS NICARAGUENSIS',
    combined_description: 'AEROLINEAS NICARAGUENSIS',
    usda_description: 'AEROLINEAS NICARAGUENSIS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 300,
  },
  3287: {
    edited_description: 'AERO COACH AVAIATION',
    combined_description: 'AERO COACH AVAIATION',
    usda_description: 'AERO COACH AVAIATION',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 301,
  },
  3288: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 302,
  },
  3289: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 303,
  },
  3290: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 304,
  },
  3291: {
    edited_description: 'ARIANA AFGHAN',
    combined_description: 'ARIANA AFGHAN',
    usda_description: 'ARIANA AFGHAN',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 305,
  },
  3292: {
    edited_description: 'CYPRUS AIRWAYS',
    combined_description: 'CYPRUS AIRWAYS',
    usda_description: 'CYPRUS AIRWAYS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 306,
  },
  3293: {
    edited_description: 'ECUATORIANA',
    combined_description: 'ECUATORIANA',
    usda_description: 'ECUATORIANA',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 307,
  },
  3294: {
    edited_description: 'ETHIOPIAN AIRLINES',
    combined_description: 'ETHIOPIAN AIRLINES',
    usda_description: 'ETHIOPIAN AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 308,
  },
  3295: {
    edited_description: 'KENYA AIRLINES',
    combined_description: 'KENYA AIRLINES',
    usda_description: 'KENYA AIRLINES',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 309,
  },
  3296: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 310,
  },
  3297: {
    edited_description: 'Airlines',
    combined_description: 'Airlines',
    usda_description: '',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 311,
  },
  3298: {
    edited_description: 'AIR MAURITIUS',
    combined_description: 'AIR MAURITIUS',
    usda_description: 'AIR MAURITIUS',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 312,
  },
  3299: {
    edited_description: 'WIDERO\u2019S FLYVESELSKAP',
    combined_description: 'WIDERO\u2019S FLYVESELSKAP',
    usda_description: 'WIDERO\u2019S FLYVESELSKAP',
    irs_description: 'Airlines',
    irs_reportable: 'Yes',
    id: 313,
  },
  3351: {
    edited_description: 'AFFILIATED AUTO RENTAL',
    combined_description: 'AFFILIATED AUTO RENTAL',
    usda_description: 'AFFILIATED AUTO RENTAL',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 314,
  },
  3352: {
    edited_description: 'AMERICAN INTL RENT-A-CAR',
    combined_description: 'AMERICAN INTL RENT-A-CAR',
    usda_description: 'AMERICAN INTL RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 315,
  },
  3353: {
    edited_description: 'BROOKS RENT-A-CAR',
    combined_description: 'BROOKS RENT-A-CAR',
    usda_description: 'BROOKS RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 316,
  },
  3354: {
    edited_description: 'ACTION AUTO RENTAL',
    combined_description: 'ACTION AUTO RENTAL',
    usda_description: 'ACTION AUTO RENTAL',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 317,
  },
  3355: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 318,
  },
  3356: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 319,
  },
  3357: {
    edited_description: 'HERTZ RENT-A-CAR',
    combined_description: 'HERTZ RENT-A-CAR',
    usda_description: 'HERTZ RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 320,
  },
  3358: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 321,
  },
  3359: {
    edited_description: 'PAYLESS CAR RENTAL',
    combined_description: 'PAYLESS CAR RENTAL',
    usda_description: 'PAYLESS CAR RENTAL',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 322,
  },
  3360: {
    edited_description: 'SNAPPY CAR RENTAL',
    combined_description: 'SNAPPY CAR RENTAL',
    usda_description: 'SNAPPY CAR RENTAL',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 323,
  },
  3361: {
    edited_description: 'AIRWAYS RENT-A-CAR',
    combined_description: 'AIRWAYS RENT-A-CAR',
    usda_description: 'AIRWAYS RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 324,
  },
  3362: {
    edited_description: 'ALTRA AUTO RENTAL',
    combined_description: 'ALTRA AUTO RENTAL',
    usda_description: 'ALTRA AUTO RENTAL',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 325,
  },
  3363: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 326,
  },
  3364: {
    edited_description: 'AGENCY RENT-A-CAR',
    combined_description: 'AGENCY RENT-A-CAR',
    usda_description: 'AGENCY RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 327,
  },
  3365: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 328,
  },
  3366: {
    edited_description: 'BUDGET RENT-A-CAR',
    combined_description: 'BUDGET RENT-A-CAR',
    usda_description: 'BUDGET RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 329,
  },
  3367: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 330,
  },
  3368: {
    edited_description: 'HOLIDAY RENT-A-WRECK',
    combined_description: 'HOLIDAY RENT-A-WRECK',
    usda_description: 'HOLIDAY RENT-A-WRECK',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 331,
  },
  3369: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 332,
  },
  3370: {
    edited_description: 'RENT-A-WRECK',
    combined_description: 'RENT-A-WRECK',
    usda_description: 'RENT-A-WRECK',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 333,
  },
  3371: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 334,
  },
  3372: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 335,
  },
  3373: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 336,
  },
  3374: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 337,
  },
  3375: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 338,
  },
  3376: {
    edited_description: 'AJAX RENT-A-CAR',
    combined_description: 'AJAX RENT-A-CAR',
    usda_description: 'AJAX RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 339,
  },
  3377: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 340,
  },
  3378: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 341,
  },
  3379: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 342,
  },
  3380: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 343,
  },
  3381: {
    edited_description: 'EUROP CAR',
    combined_description: 'EUROP CAR',
    usda_description: 'EUROP CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 344,
  },
  3382: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 345,
  },
  3383: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 346,
  },
  3384: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 347,
  },
  3385: {
    edited_description: 'TROPICAL RENT-A-CAR',
    combined_description: 'TROPICAL RENT-A-CAR',
    usda_description: 'TROPICAL RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 348,
  },
  3386: {
    edited_description: 'SHOWCASE RENTAL CARS',
    combined_description: 'SHOWCASE RENTAL CARS',
    usda_description: 'SHOWCASE RENTAL CARS',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 349,
  },
  3387: {
    edited_description: 'ALAMO RENT-A-CAR',
    combined_description: 'ALAMO RENT-A-CAR',
    usda_description: 'ALAMO RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 350,
  },
  3388: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 351,
  },
  3389: {
    edited_description: 'AVIS RENT-A-CAR',
    combined_description: 'AVIS RENT-A-CAR',
    usda_description: 'AVIS RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 352,
  },
  3390: {
    edited_description: 'DOLLAR RENT-A-CAR',
    combined_description: 'DOLLAR RENT-A-CAR',
    usda_description: 'DOLLAR RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 353,
  },
  3391: {
    edited_description: 'EUROPE BY CAR',
    combined_description: 'EUROPE BY CAR',
    usda_description: 'EUROPE BY CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 354,
  },
  3392: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 355,
  },
  3393: {
    edited_description: 'NATIONAL CAR RENTAL',
    combined_description: 'NATIONAL CAR RENTAL',
    usda_description: 'NATIONAL CAR RENTAL',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 356,
  },
  3394: {
    edited_description: 'KEMWELL GROUP RENT-A-CAR',
    combined_description: 'KEMWELL GROUP RENT-A-CAR',
    usda_description: 'KEMWELL GROUP RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 357,
  },
  3395: {
    edited_description: 'THRIFTY RENT-A-CAR',
    combined_description: 'THRIFTY RENT-A-CAR',
    usda_description: 'THRIFTY RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 358,
  },
  3396: {
    edited_description: 'TILDEN TENT-A-CAR',
    combined_description: 'TILDEN TENT-A-CAR',
    usda_description: 'TILDEN TENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 359,
  },
  3397: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 360,
  },
  3398: {
    edited_description: 'ECONO-CAR RENT-A-CAR',
    combined_description: 'ECONO-CAR RENT-A-CAR',
    usda_description: 'ECONO-CAR RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 361,
  },
  3399: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 362,
  },
  3400: {
    edited_description: 'AUTO HOST COST CAR RENTALS',
    combined_description: 'AUTO HOST COST CAR RENTALS',
    usda_description: 'AUTO HOST COST CAR RENTALS',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 363,
  },
  3401: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 364,
  },
  3402: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 365,
  },
  3403: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 366,
  },
  3404: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 367,
  },
  3405: {
    edited_description: 'ENTERPRISE RENT-A-CAR',
    combined_description: 'ENTERPRISE RENT-A-CAR',
    usda_description: 'ENTERPRISE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 368,
  },
  3406: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 369,
  },
  3407: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 370,
  },
  3408: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 371,
  },
  3409: {
    edited_description: 'GENERAL RENT-A-CAR',
    combined_description: 'GENERAL RENT-A-CAR',
    usda_description: 'GENERAL RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 372,
  },
  3410: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 373,
  },
  3411: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 374,
  },
  3412: {
    edited_description: 'A-1 RENT-A-CAR',
    combined_description: 'A-1 RENT-A-CAR',
    usda_description: 'A-1 RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 375,
  },
  3413: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 376,
  },
  3414: {
    edited_description: 'GODFREY NATL RENT-A-CAR',
    combined_description: 'GODFREY NATL RENT-A-CAR',
    usda_description: 'GODFREY NATL RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 377,
  },
  3415: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 378,
  },
  3416: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 379,
  },
  3417: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 380,
  },
  3418: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 381,
  },
  3419: {
    edited_description: 'ALPHA RENT-A-CAR',
    combined_description: 'ALPHA RENT-A-CAR',
    usda_description: 'ALPHA RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 382,
  },
  3420: {
    edited_description: 'ANSA INTL RENT-A-CAR',
    combined_description: 'ANSA INTL RENT-A-CAR',
    usda_description: 'ANSA INTL RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 383,
  },
  3421: {
    edited_description: 'ALLSTAE RENT-A-CAR',
    combined_description: 'ALLSTAE RENT-A-CAR',
    usda_description: 'ALLSTAE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 384,
  },
  3422: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 385,
  },
  3423: {
    edited_description: 'AVCAR RENT-A-CAR',
    combined_description: 'AVCAR RENT-A-CAR',
    usda_description: 'AVCAR RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 386,
  },
  3424: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 387,
  },
  3425: {
    edited_description: 'AUTOMATE RENT-A-CAR',
    combined_description: 'AUTOMATE RENT-A-CAR',
    usda_description: 'AUTOMATE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 388,
  },
  3426: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 389,
  },
  3427: {
    edited_description: 'AVON RENT-A-CAR',
    combined_description: 'AVON RENT-A-CAR',
    usda_description: 'AVON RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 390,
  },
  3428: {
    edited_description: 'CAREY RENT-A-CAR',
    combined_description: 'CAREY RENT-A-CAR',
    usda_description: 'CAREY RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 391,
  },
  3429: {
    edited_description: 'INSURANCE RENT-A-CAR',
    combined_description: 'INSURANCE RENT-A-CAR',
    usda_description: 'INSURANCE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 392,
  },
  3430: {
    edited_description: 'MAJOR RENT-A-CAR',
    combined_description: 'MAJOR RENT-A-CAR',
    usda_description: 'MAJOR RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 393,
  },
  3431: {
    edited_description: 'REPLACEMENT RENT-A-CAR',
    combined_description: 'REPLACEMENT RENT-A-CAR',
    usda_description: 'REPLACEMENT RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 394,
  },
  3432: {
    edited_description: 'RESERVE RENT-A-CAR',
    combined_description: 'RESERVE RENT-A-CAR',
    usda_description: 'RESERVE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 395,
  },
  3433: {
    edited_description: 'UGLY DUCKLING RENT-A-CAR',
    combined_description: 'UGLY DUCKLING RENT-A-CAR',
    usda_description: 'UGLY DUCKLING RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 396,
  },
  3434: {
    edited_description: 'USA RENT-A-CAR',
    combined_description: 'USA RENT-A-CAR',
    usda_description: 'USA RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 397,
  },
  3435: {
    edited_description: 'VALUE RENT-A-CAR',
    combined_description: 'VALUE RENT-A-CAR',
    usda_description: 'VALUE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 398,
  },
  3436: {
    edited_description: 'AUTOHANSA RENT-A-CAR',
    combined_description: 'AUTOHANSA RENT-A-CAR',
    usda_description: 'AUTOHANSA RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 399,
  },
  3437: {
    edited_description: 'CITE RENT-A-CAR',
    combined_description: 'CITE RENT-A-CAR',
    usda_description: 'CITE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 400,
  },
  3438: {
    edited_description: 'INTERENT RENT-A-CAR',
    combined_description: 'INTERENT RENT-A-CAR',
    usda_description: 'INTERENT RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 401,
  },
  3439: {
    edited_description: 'MILLEVILLE RENT-A-CAR',
    combined_description: 'MILLEVILLE RENT-A-CAR',
    usda_description: 'MILLEVILLE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 402,
  },
  3440: {
    edited_description: 'VIA ROUTE RENT-A-CAR',
    combined_description: 'VIA ROUTE RENT-A-CAR',
    usda_description: 'VIA ROUTE RENT-A-CAR',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 403,
  },
  3441: {
    edited_description: 'Car Rental',
    combined_description: 'Car Rental',
    usda_description: '',
    irs_description: 'Car Rental',
    irs_reportable: 'Yes',
    id: 404,
  },
  3501: {
    edited_description: 'HOLIDAY INNS, HOLIDAY INN EXPRESS',
    combined_description: 'HOLIDAY INNS, HOLIDAY INN EXPRESS',
    usda_description: 'HOLIDAY INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 405,
  },
  3502: {
    edited_description: 'BEST WESTERN HOTELS',
    combined_description: 'BEST WESTERN HOTELS',
    usda_description: 'BEST WESTERN HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 406,
  },
  3503: {
    edited_description: 'SHERATON HOTELS',
    combined_description: 'SHERATON HOTELS',
    usda_description: 'SHERATON HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 407,
  },
  3504: {
    edited_description: 'HILTON HOTELS',
    combined_description: 'HILTON HOTELS',
    usda_description: 'HILTON HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 408,
  },
  3505: {
    edited_description: 'FORTE HOTELS',
    combined_description: 'FORTE HOTELS',
    usda_description: 'FORTE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 409,
  },
  3506: {
    edited_description: 'GOLDEN TULIP HOTELS',
    combined_description: 'GOLDEN TULIP HOTELS',
    usda_description: 'GOLDEN TULIP HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 410,
  },
  3507: {
    edited_description: 'FRIENDSHIP INNS',
    combined_description: 'FRIENDSHIP INNS',
    usda_description: 'FRIENDSHIP INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 411,
  },
  3508: {
    edited_description: 'QUALITY INNS, QUALITY SUITES',
    combined_description: 'QUALITY INNS, QUALITY SUITES',
    usda_description: 'QUALITY INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 412,
  },
  3509: {
    edited_description: 'MARRIOTT HOTELS',
    combined_description: 'MARRIOTT HOTELS',
    usda_description: 'MARRIOTT HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 413,
  },
  3510: {
    edited_description: 'DAYS INN, DAYSTOP',
    combined_description: 'DAYS INN, DAYSTOP',
    usda_description: 'DAYS INN',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 414,
  },
  3511: {
    edited_description: 'ARABELLA HOTELS',
    combined_description: 'ARABELLA HOTELS',
    usda_description: 'ARABELLA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 415,
  },
  3512: {
    edited_description: 'INTER-CONTINENTAL HOTELS',
    combined_description: 'INTER-CONTINENTAL HOTELS',
    usda_description: 'INTER-CONTINENTAL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 416,
  },
  3513: {
    edited_description: 'WESTIN HOTELS',
    combined_description: 'WESTIN HOTELS',
    usda_description: 'WESTIN HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 417,
  },
  3514: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 418,
  },
  3515: {
    edited_description: 'RODEWAY INNS',
    combined_description: 'RODEWAY INNS',
    usda_description: 'RODEWAY INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 419,
  },
  3516: {
    edited_description: 'LA QUINTA MOTOR INNS',
    combined_description: 'LA QUINTA MOTOR INNS',
    usda_description: 'LA QUINTA MOTOR INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 420,
  },
  3517: {
    edited_description: 'AMERICANA HOTELS',
    combined_description: 'AMERICANA HOTELS',
    usda_description: 'AMERICANA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 421,
  },
  3518: {
    edited_description: 'SOL HOTELS',
    combined_description: 'SOL HOTELS',
    usda_description: 'SOL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 422,
  },
  3519: {
    edited_description: 'PULLMAN INTERNATIONAL HOTELS',
    combined_description: 'PULLMAN INTERNATIONAL HOTELS',
    usda_description: 'PULLMAN INTERNATIONAL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 423,
  },
  3520: {
    edited_description: 'MERIDIEN HOTELS',
    combined_description: 'MERIDIEN HOTELS',
    usda_description: 'MERIDIEN HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 424,
  },
  3521: {
    edited_description: 'CREST HOTELS (see FORTE HOTELS)',
    combined_description: 'CREST HOTELS (see FORTE HOTELS)',
    usda_description: 'CREST HOTELS (see FORTE HOTELS)',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 425,
  },
  3522: {
    edited_description: 'TOKYO HOTEL',
    combined_description: 'TOKYO HOTEL',
    usda_description: 'TOKYO HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 426,
  },
  3523: {
    edited_description: 'PENNSULA HOTEL',
    combined_description: 'PENNSULA HOTEL',
    usda_description: 'PENNSULA HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 427,
  },
  3524: {
    edited_description: 'WELCOMGROUP HOTELS',
    combined_description: 'WELCOMGROUP HOTELS',
    usda_description: 'WELCOMGROUP HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 428,
  },
  3525: {
    edited_description: 'DUNFEY HOTELS',
    combined_description: 'DUNFEY HOTELS',
    usda_description: 'DUNFEY HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 429,
  },
  3526: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 430,
  },
  3527: {
    edited_description: 'DOWNTOWNER-PASSPORT HOTEL',
    combined_description: 'DOWNTOWNER-PASSPORT HOTEL',
    usda_description: 'DOWNTOWNER-PASSPORT HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 431,
  },
  3528: {
    edited_description: 'RED LION HOTELS, RED LION INNS',
    combined_description: 'RED LION HOTELS, RED LION INNS',
    usda_description: 'RED LION HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 432,
  },
  3529: {
    edited_description: 'CP HOTELS',
    combined_description: 'CP HOTELS',
    usda_description: 'CP HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 433,
  },
  3530: {
    edited_description: 'RENAISSANCE HOTELS, STOUFFER HOTELS',
    combined_description: 'RENAISSANCE HOTELS, STOUFFER HOTELS',
    usda_description: 'RENAISSANCE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 434,
  },
  3531: {
    edited_description: 'ASTIR HOTELS',
    combined_description: 'ASTIR HOTELS',
    usda_description: 'ASTIR HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 435,
  },
  3532: {
    edited_description: 'SUN ROUTE HOTELS',
    combined_description: 'SUN ROUTE HOTELS',
    usda_description: 'SUN ROUTE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 436,
  },
  3533: {
    edited_description: 'HOTEL IBIS',
    combined_description: 'HOTEL IBIS',
    usda_description: 'HOTEL IBIS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 437,
  },
  3534: {
    edited_description: 'SOUTHERN PACIFIC HOTELS',
    combined_description: 'SOUTHERN PACIFIC HOTELS',
    usda_description: 'SOUTHERN PACIFIC HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 438,
  },
  3535: {
    edited_description: 'HILTON INTERNATIONAL',
    combined_description: 'HILTON INTERNATIONAL',
    usda_description: 'HILTON INTERNATIONAL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 439,
  },
  3536: {
    edited_description: 'AMFAC HOTELS',
    combined_description: 'AMFAC HOTELS',
    usda_description: 'AMFAC HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 440,
  },
  3537: {
    edited_description: 'ANA HOTEL',
    combined_description: 'ANA HOTEL',
    usda_description: 'ANA HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 441,
  },
  3538: {
    edited_description: 'CONCORDE HOTELS',
    combined_description: 'CONCORDE HOTELS',
    usda_description: 'CONCORDE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 442,
  },
  3539: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 443,
  },
  3540: {
    edited_description: 'IBEROTEL HOTELS',
    combined_description: 'IBEROTEL HOTELS',
    usda_description: 'IBEROTEL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 444,
  },
  3541: {
    edited_description: 'HOTEL OKURA',
    combined_description: 'HOTEL OKURA',
    usda_description: 'HOTEL OKURA',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 445,
  },
  3542: {
    edited_description: 'ROYAL HOTELS',
    combined_description: 'ROYAL HOTELS',
    usda_description: 'ROYAL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 446,
  },
  3543: {
    edited_description: 'FOUR SEASONS HOTELS',
    combined_description: 'FOUR SEASONS HOTELS',
    usda_description: 'FOUR SEASONS HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 447,
  },
  3544: {
    edited_description: 'CIGA HOTELS',
    combined_description: 'CIGA HOTELS',
    usda_description: 'CIGA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 448,
  },
  3545: {
    edited_description: 'SHANGRI-LA INTERNATIONAL',
    combined_description: 'SHANGRI-LA INTERNATIONAL',
    usda_description: 'SHANGRI-LA INTERNATIONAL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 449,
  },
  3546: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 450,
  },
  3547: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 451,
  },
  3548: {
    edited_description: 'HOTELES MELIA',
    combined_description: 'HOTELES MELIA',
    usda_description: 'HOTELES MELIA',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 452,
  },
  3549: {
    edited_description: 'AUBERGE DES GOVERNEURS',
    combined_description: 'AUBERGE DES GOVERNEURS',
    usda_description: 'AUBERGE DES GOVERNEURS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 453,
  },
  3550: {
    edited_description: 'REGAL 8 INNS',
    combined_description: 'REGAL 8 INNS',
    usda_description: 'REGAL 8 INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 454,
  },
  3551: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 455,
  },
  3552: {
    edited_description: 'COAST HOTELS',
    combined_description: 'COAST HOTELS',
    usda_description: 'COAST HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 456,
  },
  3553: {
    edited_description: 'PARK INNS INTERNATIONAL',
    combined_description: 'PARK INNS INTERNATIONAL',
    usda_description: 'PARK INNS INTERNATIONAL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 457,
  },
  3554: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 458,
  },
  3555: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 459,
  },
  3556: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 460,
  },
  3557: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 461,
  },
  3558: {
    edited_description: 'JOLLY HOTELS',
    combined_description: 'JOLLY HOTELS',
    usda_description: 'JOLLY HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 462,
  },
  3559: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 463,
  },
  3560: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 464,
  },
  3561: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 465,
  },
  3562: {
    edited_description: 'COMFORT INNS',
    combined_description: 'COMFORT INNS',
    usda_description: 'COMFORT INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 466,
  },
  3563: {
    edited_description: 'JOURNEY\u2019S END MOTLS',
    combined_description: 'JOURNEY\u2019S END MOTLS',
    usda_description: 'JOURNEY\u2019S END MOTLS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 467,
  },
  3564: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 468,
  },
  3565: {
    edited_description: 'RELAX INNS',
    combined_description: 'RELAX INNS',
    usda_description: 'RELAX INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 469,
  },
  3566: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 470,
  },
  3567: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 471,
  },
  3568: {
    edited_description: 'LADBROKE HOTELS',
    combined_description: 'LADBROKE HOTELS',
    usda_description: 'LADBROKE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 472,
  },
  3569: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 473,
  },
  3570: {
    edited_description: 'FORUM HOTELS',
    combined_description: 'FORUM HOTELS',
    usda_description: 'FORUM HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 474,
  },
  3571: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 475,
  },
  3572: {
    edited_description: 'MIYAKO HOTELS',
    combined_description: 'MIYAKO HOTELS',
    usda_description: 'MIYAKO HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 476,
  },
  3573: {
    edited_description: 'SANDMAN HOTELS',
    combined_description: 'SANDMAN HOTELS',
    usda_description: 'SANDMAN HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 477,
  },
  3574: {
    edited_description: 'VENTURE INNS',
    combined_description: 'VENTURE INNS',
    usda_description: 'VENTURE INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 478,
  },
  3575: {
    edited_description: 'VAGABOND HOTELS',
    combined_description: 'VAGABOND HOTELS',
    usda_description: 'VAGABOND HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 479,
  },
  3576: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 480,
  },
  3577: {
    edited_description: 'MANDARIN ORIENTAL HOTEL',
    combined_description: 'MANDARIN ORIENTAL HOTEL',
    usda_description: 'MANDARIN ORIENTAL HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 481,
  },
  3578: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 482,
  },
  3579: {
    edited_description: 'HOTEL MERCURE',
    combined_description: 'HOTEL MERCURE',
    usda_description: 'HOTEL MERCURE',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 483,
  },
  3580: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 484,
  },
  3581: {
    edited_description: 'DELTA HOTEL',
    combined_description: 'DELTA HOTEL',
    usda_description: 'DELTA HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 485,
  },
  3582: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 486,
  },
  3583: {
    edited_description: 'SAS HOTELS',
    combined_description: 'SAS HOTELS',
    usda_description: 'SAS HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 487,
  },
  3584: {
    edited_description: 'PRINCESS HOTELS INTERNATIONAL',
    combined_description: 'PRINCESS HOTELS INTERNATIONAL',
    usda_description: 'PRINCESS HOTELS INTERNATIONAL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 488,
  },
  3585: {
    edited_description: 'HUNGAR HOTELS',
    combined_description: 'HUNGAR HOTELS',
    usda_description: 'HUNGAR HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 489,
  },
  3586: {
    edited_description: 'SOKOS HOTELS',
    combined_description: 'SOKOS HOTELS',
    usda_description: 'SOKOS HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 490,
  },
  3587: {
    edited_description: 'DORAL HOTELS',
    combined_description: 'DORAL HOTELS',
    usda_description: 'DORAL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 491,
  },
  3588: {
    edited_description: 'HELMSLEY HOTELS',
    combined_description: 'HELMSLEY HOTELS',
    usda_description: 'HELMSLEY HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 492,
  },
  3589: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 493,
  },
  3590: {
    edited_description: 'FAIRMONT HOTELS',
    combined_description: 'FAIRMONT HOTELS',
    usda_description: 'FAIRMONT HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 494,
  },
  3591: {
    edited_description: 'SONESTA HOTELS',
    combined_description: 'SONESTA HOTELS',
    usda_description: 'SONESTA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 495,
  },
  3592: {
    edited_description: 'OMNI HOTELS',
    combined_description: 'OMNI HOTELS',
    usda_description: 'OMNI HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 496,
  },
  3593: {
    edited_description: 'CUNARD HOTELS',
    combined_description: 'CUNARD HOTELS',
    usda_description: 'CUNARD HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 497,
  },
  3594: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 498,
  },
  3595: {
    edited_description: 'HOSPITALITY INTERNATIONAL',
    combined_description: 'HOSPITALITY INTERNATIONAL',
    usda_description: 'HOSPITALITY INTERNATIONAL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 499,
  },
  3596: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 500,
  },
  3597: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 501,
  },
  3598: {
    edited_description: 'REGENT INTERNATIONAL HOTELS',
    combined_description: 'REGENT INTERNATIONAL HOTELS',
    usda_description: 'REGENT INTERNATIONAL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 502,
  },
  3599: {
    edited_description: 'PANNONIA HOTELS',
    combined_description: 'PANNONIA HOTELS',
    usda_description: 'PANNONIA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 503,
  },
  3600: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 504,
  },
  3601: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 505,
  },
  3602: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 506,
  },
  3603: {
    edited_description: 'NOAH\u2019S HOTELS',
    combined_description: 'NOAH\u2019S HOTELS',
    usda_description: 'NOAH\u2019S HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 507,
  },
  3604: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 508,
  },
  3605: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 509,
  },
  3606: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 510,
  },
  3607: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 511,
  },
  3608: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 512,
  },
  3609: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 513,
  },
  3610: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 514,
  },
  3611: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 515,
  },
  3612: {
    edited_description: 'MOVENPICK HOTELS',
    combined_description: 'MOVENPICK HOTELS',
    usda_description: 'MOVENPICK HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 516,
  },
  3613: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 517,
  },
  3614: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 518,
  },
  3615: {
    edited_description: 'TRAVELODGE',
    combined_description: 'TRAVELODGE',
    usda_description: 'TRAVELODGE',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 519,
  },
  3616: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 520,
  },
  3617: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 521,
  },
  3618: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 522,
  },
  3619: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 523,
  },
  3620: {
    edited_description: 'TELFORD INTERNATIONAL',
    combined_description: 'TELFORD INTERNATIONAL',
    usda_description: 'TELFORD INTERNATIONAL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 524,
  },
  3621: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 525,
  },
  3622: {
    edited_description: 'MERLIN HOTELS',
    combined_description: 'MERLIN HOTELS',
    usda_description: 'MERLIN HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 526,
  },
  3623: {
    edited_description: 'DORINT HOTELS',
    combined_description: 'DORINT HOTELS',
    usda_description: 'DORINT HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 527,
  },
  3624: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 528,
  },
  3625: {
    edited_description: 'HOTLE UNIVERSALE',
    combined_description: 'HOTLE UNIVERSALE',
    usda_description: 'HOTLE UNIVERSALE',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 529,
  },
  3626: {
    edited_description: 'PRINCE HOTELS',
    combined_description: 'PRINCE HOTELS',
    usda_description: 'PRINCE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 530,
  },
  3627: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 531,
  },
  3628: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 532,
  },
  3629: {
    edited_description: 'DAN HOTELS',
    combined_description: 'DAN HOTELS',
    usda_description: 'DAN HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 533,
  },
  3630: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 534,
  },
  3631: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 535,
  },
  3632: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 536,
  },
  3633: {
    edited_description: 'RANK HOTELS',
    combined_description: 'RANK HOTELS',
    usda_description: 'RANK HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 537,
  },
  3634: {
    edited_description: 'SWISSOTEL',
    combined_description: 'SWISSOTEL',
    usda_description: 'SWISSOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 538,
  },
  3635: {
    edited_description: 'RESO HOTELS',
    combined_description: 'RESO HOTELS',
    usda_description: 'RESO HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 539,
  },
  3636: {
    edited_description: 'SAROVA HOTELS',
    combined_description: 'SAROVA HOTELS',
    usda_description: 'SAROVA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 540,
  },
  3637: {
    edited_description: 'RAMADA INNS, RAMADA LIMITED',
    combined_description: 'RAMADA INNS, RAMADA LIMITED',
    usda_description: 'RAMADA INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 541,
  },
  3638: {
    edited_description: 'HO JO INN, HOWARD JOHNSON',
    combined_description: 'HO JO INN, HOWARD JOHNSON',
    usda_description: 'HO JO INN',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 542,
  },
  3639: {
    edited_description: 'MOUNT CHARLOTTE THISTLE',
    combined_description: 'MOUNT CHARLOTTE THISTLE',
    usda_description: 'MOUNT CHARLOTTE THISTLE',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 543,
  },
  3640: {
    edited_description: 'HYATT HOTEL',
    combined_description: 'HYATT HOTEL',
    usda_description: 'HYATT HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 544,
  },
  3641: {
    edited_description: 'SOFITEL HOTELS',
    combined_description: 'SOFITEL HOTELS',
    usda_description: 'SOFITEL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 545,
  },
  3642: {
    edited_description: 'NOVOTEL HOTELS',
    combined_description: 'NOVOTEL HOTELS',
    usda_description: 'NOVOTEL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 546,
  },
  3643: {
    edited_description: 'STEIGENBERGER HOTELS',
    combined_description: 'STEIGENBERGER HOTELS',
    usda_description: 'STEIGENBERGER HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 547,
  },
  3644: {
    edited_description: 'ECONO LODGES',
    combined_description: 'ECONO LODGES',
    usda_description: 'ECONO LODGES',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 548,
  },
  3645: {
    edited_description: 'QUEENS MOAT HOUSES',
    combined_description: 'QUEENS MOAT HOUSES',
    usda_description: 'QUEENS MOAT HOUSES',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 549,
  },
  3646: {
    edited_description: 'SWALLOW HOTELS',
    combined_description: 'SWALLOW HOTELS',
    usda_description: 'SWALLOW HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 550,
  },
  3647: {
    edited_description: 'HUSA HOTELS',
    combined_description: 'HUSA HOTELS',
    usda_description: 'HUSA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 551,
  },
  3648: {
    edited_description: 'DE VERE HOTELS',
    combined_description: 'DE VERE HOTELS',
    usda_description: 'DE VERE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 552,
  },
  3649: {
    edited_description: 'RADISSON HOTELS',
    combined_description: 'RADISSON HOTELS',
    usda_description: 'RADISSON HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 553,
  },
  3650: {
    edited_description: 'RED ROOK INNS',
    combined_description: 'RED ROOK INNS',
    usda_description: 'RED ROOK INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 554,
  },
  3651: {
    edited_description: 'IMPERIAL LONDON HOTEL',
    combined_description: 'IMPERIAL LONDON HOTEL',
    usda_description: 'IMPERIAL LONDON HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 555,
  },
  3652: {
    edited_description: 'EMBASSY HOTELS',
    combined_description: 'EMBASSY HOTELS',
    usda_description: 'EMBASSY HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 556,
  },
  3653: {
    edited_description: 'PENTA HOTELS',
    combined_description: 'PENTA HOTELS',
    usda_description: 'PENTA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 557,
  },
  3654: {
    edited_description: 'LOEWS HOTELS',
    combined_description: 'LOEWS HOTELS',
    usda_description: 'LOEWS HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 558,
  },
  3655: {
    edited_description: 'SCANDIC HOTELS',
    combined_description: 'SCANDIC HOTELS',
    usda_description: 'SCANDIC HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 559,
  },
  3656: {
    edited_description: 'SARA HOTELS',
    combined_description: 'SARA HOTELS',
    usda_description: 'SARA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 560,
  },
  3657: {
    edited_description: 'OBEROI HOTELS',
    combined_description: 'OBEROI HOTELS',
    usda_description: 'OBEROI HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 561,
  },
  3658: {
    edited_description: 'OTANI HOTELS',
    combined_description: 'OTANI HOTELS',
    usda_description: 'OTANI HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 562,
  },
  3659: {
    edited_description: 'TAJ HOTELS INTERNATIONAL',
    combined_description: 'TAJ HOTELS INTERNATIONAL',
    usda_description: 'TAJ HOTELS INTERNATIONAL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 563,
  },
  3660: {
    edited_description: 'KNIGHTS INNS',
    combined_description: 'KNIGHTS INNS',
    usda_description: 'KNIGHTS INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 564,
  },
  3661: {
    edited_description: 'METROPOLE HOTELS',
    combined_description: 'METROPOLE HOTELS',
    usda_description: 'METROPOLE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 565,
  },
  3662: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 566,
  },
  3663: {
    edited_description: 'HOTELES EL PRESIDENTS',
    combined_description: 'HOTELES EL PRESIDENTS',
    usda_description: 'HOTELES EL PRESIDENTS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 567,
  },
  3664: {
    edited_description: 'FLAG INN',
    combined_description: 'FLAG INN',
    usda_description: 'FLAG INN',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 568,
  },
  3665: {
    edited_description: 'HAMPTON INNS',
    combined_description: 'HAMPTON INNS',
    usda_description: 'HAMPTON INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 569,
  },
  3666: {
    edited_description: 'STAKIS HOTELS',
    combined_description: 'STAKIS HOTELS',
    usda_description: 'STAKIS HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 570,
  },
  3667: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 571,
  },
  3668: {
    edited_description: 'MARITIM HOTELS',
    combined_description: 'MARITIM HOTELS',
    usda_description: 'MARITIM HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 572,
  },
  3669: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 573,
  },
  3670: {
    edited_description: 'ARCARD HOTELS',
    combined_description: 'ARCARD HOTELS',
    usda_description: 'ARCARD HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 574,
  },
  3671: {
    edited_description: 'ARCTIA HOTELS',
    combined_description: 'ARCTIA HOTELS',
    usda_description: 'ARCTIA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 575,
  },
  3672: {
    edited_description: 'CAMPANIEL HOTELS',
    combined_description: 'CAMPANIEL HOTELS',
    usda_description: 'CAMPANIEL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 576,
  },
  3673: {
    edited_description: 'IBUSZ HOTELS',
    combined_description: 'IBUSZ HOTELS',
    usda_description: 'IBUSZ HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 577,
  },
  3674: {
    edited_description: 'RANTASIPI HOTELS',
    combined_description: 'RANTASIPI HOTELS',
    usda_description: 'RANTASIPI HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 578,
  },
  3675: {
    edited_description: 'INTERHOTEL CEDOK',
    combined_description: 'INTERHOTEL CEDOK',
    usda_description: 'INTERHOTEL CEDOK',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 579,
  },
  3676: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 580,
  },
  3677: {
    edited_description: 'CLIMAT DE FRANCE HOTELS',
    combined_description: 'CLIMAT DE FRANCE HOTELS',
    usda_description: 'CLIMAT DE FRANCE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 581,
  },
  3678: {
    edited_description: 'CUMULUS HOTELS',
    combined_description: 'CUMULUS HOTELS',
    usda_description: 'CUMULUS HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 582,
  },
  3679: {
    edited_description: 'DANUBIUS HOTEL',
    combined_description: 'DANUBIUS HOTEL',
    usda_description: 'DANUBIUS HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 583,
  },
  3680: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 584,
  },
  3681: {
    edited_description: 'ADAMS MARK HOTELS',
    combined_description: 'ADAMS MARK HOTELS',
    usda_description: 'ADAMS MARK HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 585,
  },
  3682: {
    edited_description: 'ALLSTAR INNS',
    combined_description: 'ALLSTAR INNS',
    usda_description: 'ALLSTAR INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 586,
  },
  3683: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 587,
  },
  3684: {
    edited_description: 'BUDGET HOST INNS',
    combined_description: 'BUDGET HOST INNS',
    usda_description: 'BUDGET HOST INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 588,
  },
  3685: {
    edited_description: 'BUDGETEL HOTELS',
    combined_description: 'BUDGETEL HOTELS',
    usda_description: 'BUDGETEL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 589,
  },
  3686: {
    edited_description: 'SUISSE CHALETS',
    combined_description: 'SUISSE CHALETS',
    usda_description: 'SUISSE CHALETS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 590,
  },
  3687: {
    edited_description: 'CLARION HOTELS',
    combined_description: 'CLARION HOTELS',
    usda_description: 'CLARION HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 591,
  },
  3688: {
    edited_description: 'COMPRI HOTELS',
    combined_description: 'COMPRI HOTELS',
    usda_description: 'COMPRI HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 592,
  },
  3689: {
    edited_description: 'CONSORT HOTELS',
    combined_description: 'CONSORT HOTELS',
    usda_description: 'CONSORT HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 593,
  },
  3690: {
    edited_description: 'COURTYARD BY MARRIOTT',
    combined_description: 'COURTYARD BY MARRIOTT',
    usda_description: 'COURTYARD BY MARRIOTT',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 594,
  },
  3691: {
    edited_description: 'DILLION INNS',
    combined_description: 'DILLION INNS',
    usda_description: 'DILLION INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 595,
  },
  3692: {
    edited_description: 'DOUBLETREE HOTELS',
    combined_description: 'DOUBLETREE HOTELS',
    usda_description: 'DOUBLETREE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 596,
  },
  3693: {
    edited_description: 'DRURY INNS',
    combined_description: 'DRURY INNS',
    usda_description: 'DRURY INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 597,
  },
  3694: {
    edited_description: 'ECONOMY INNS OF AMERICA',
    combined_description: 'ECONOMY INNS OF AMERICA',
    usda_description: 'ECONOMY INNS OF AMERICA',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 598,
  },
  3695: {
    edited_description: 'EMBASSY SUITES',
    combined_description: 'EMBASSY SUITES',
    usda_description: 'EMBASSY SUITES',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 599,
  },
  3696: {
    edited_description: 'EXEL INNS',
    combined_description: 'EXEL INNS',
    usda_description: 'EXEL INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 600,
  },
  3697: {
    edited_description: 'FARFIELD HOTELS',
    combined_description: 'FARFIELD HOTELS',
    usda_description: 'FARFIELD HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 601,
  },
  3698: {
    edited_description: 'HARLEY HOTELS',
    combined_description: 'HARLEY HOTELS',
    usda_description: 'HARLEY HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 602,
  },
  3699: {
    edited_description: 'MIDWAY MOTOR LODGE',
    combined_description: 'MIDWAY MOTOR LODGE',
    usda_description: 'MIDWAY MOTOR LODGE',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 603,
  },
  3700: {
    edited_description: 'MOTEL 6',
    combined_description: 'MOTEL 6',
    usda_description: 'MOTEL 6',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 604,
  },
  3701: {
    edited_description: 'GUEST QUARTERS (Formally PICKETT SUITE HOTELS)',
    combined_description: 'GUEST QUARTERS (Formally PICKETT SUITE HOTELS)',
    usda_description: 'GUEST QUARTERS (Formally PICKETT SUITE HOTELS)',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 605,
  },
  3702: {
    edited_description: 'THE REGISTRY HOTELS',
    combined_description: 'THE REGISTRY HOTELS',
    usda_description: 'THE REGISTRY HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 606,
  },
  3703: {
    edited_description: 'RESIDENCE INNS',
    combined_description: 'RESIDENCE INNS',
    usda_description: 'RESIDENCE INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 607,
  },
  3704: {
    edited_description: 'ROYCE HOTELS',
    combined_description: 'ROYCE HOTELS',
    usda_description: 'ROYCE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 608,
  },
  3705: {
    edited_description: 'SANDMAN INNS',
    combined_description: 'SANDMAN INNS',
    usda_description: 'SANDMAN INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 609,
  },
  3706: {
    edited_description: 'SHILO INNS',
    combined_description: 'SHILO INNS',
    usda_description: 'SHILO INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 610,
  },
  3707: {
    edited_description: 'SHONEY\u2019S INNS',
    combined_description: 'SHONEY\u2019S INNS',
    usda_description: 'SHONEY\u2019S INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 611,
  },
  3708: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 612,
  },
  3709: {
    edited_description: 'SUPER8 MOTELS',
    combined_description: 'SUPER8 MOTELS',
    usda_description: 'SUPER8 MOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 613,
  },
  3710: {
    edited_description: 'THE RITZ CARLTON HOTELS',
    combined_description: 'THE RITZ CARLTON HOTELS',
    usda_description: 'THE RITZ CARLTON HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 614,
  },
  3711: {
    edited_description: 'FLAG INNS (AUSRALIA)',
    combined_description: 'FLAG INNS (AUSRALIA)',
    usda_description: 'FLAG INNS (AUSRALIA)',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 615,
  },
  3712: {
    edited_description: 'GOLDEN CHAIN HOTEL',
    combined_description: 'GOLDEN CHAIN HOTEL',
    usda_description: 'GOLDEN CHAIN HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 616,
  },
  3713: {
    edited_description: 'QUALITY PACIFIC HOTEL',
    combined_description: 'QUALITY PACIFIC HOTEL',
    usda_description: 'QUALITY PACIFIC HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 617,
  },
  3714: {
    edited_description: 'FOUR SEASONS HOTEL (AUSTRALIA)',
    combined_description: 'FOUR SEASONS HOTEL (AUSTRALIA)',
    usda_description: 'FOUR SEASONS HOTEL (AUSTRALIA)',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 618,
  },
  3715: {
    edited_description: 'FARIFIELD INN',
    combined_description: 'FARIFIELD INN',
    usda_description: 'FARIFIELD INN',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 619,
  },
  3716: {
    edited_description: 'CARLTON HOTELS',
    combined_description: 'CARLTON HOTELS',
    usda_description: 'CARLTON HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 620,
  },
  3717: {
    edited_description: 'CITY LODGE HOTELS',
    combined_description: 'CITY LODGE HOTELS',
    usda_description: 'CITY LODGE HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 621,
  },
  3718: {
    edited_description: 'KAROS HOTELS',
    combined_description: 'KAROS HOTELS',
    usda_description: 'KAROS HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 622,
  },
  3719: {
    edited_description: 'PROTEA HOTELS',
    combined_description: 'PROTEA HOTELS',
    usda_description: 'PROTEA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 623,
  },
  3720: {
    edited_description: 'SOUTHERN SUN HOTELS',
    combined_description: 'SOUTHERN SUN HOTELS',
    usda_description: 'SOUTHERN SUN HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 624,
  },
  3721: {
    edited_description: 'HILTON CONRAD',
    combined_description: 'HILTON CONRAD',
    usda_description: 'HILTON CONRAD',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 625,
  },
  3722: {
    edited_description: 'WYNDHAM HOTEL AND RESORTS',
    combined_description: 'WYNDHAM HOTEL AND RESORTS',
    usda_description: 'WYNDHAM HOTEL AND RESORTS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 626,
  },
  3723: {
    edited_description: 'RICA HOTELS',
    combined_description: 'RICA HOTELS',
    usda_description: 'RICA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 627,
  },
  3724: {
    edited_description: 'INER NOR HOTELS',
    combined_description: 'INER NOR HOTELS',
    usda_description: 'INER NOR HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 628,
  },
  3725: {
    edited_description: 'SEAINES PLANATION',
    combined_description: 'SEAINES PLANATION',
    usda_description: 'SEAINES PLANATION',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 629,
  },
  3726: {
    edited_description: 'RIO SUITES',
    combined_description: 'RIO SUITES',
    usda_description: 'RIO SUITES',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 630,
  },
  3727: {
    edited_description: 'BROADMOOR HOTEL',
    combined_description: 'BROADMOOR HOTEL',
    usda_description: 'BROADMOOR HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 631,
  },
  3728: {
    edited_description: 'BALLY\u2019S HOTEL AND CASINO',
    combined_description: 'BALLY\u2019S HOTEL AND CASINO',
    usda_description: 'BALLY\u2019S HOTEL AND CASINO',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 632,
  },
  3729: {
    edited_description: 'JOHN ASCUAGA\u2019S NUGGET',
    combined_description: 'JOHN ASCUAGA\u2019S NUGGET',
    usda_description: 'JOHN ASCUAGA\u2019S NUGGET',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 633,
  },
  3730: {
    edited_description: 'MGM GRAND HOTEL',
    combined_description: 'MGM GRAND HOTEL',
    usda_description: 'MGM GRAND HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 634,
  },
  3731: {
    edited_description: 'HARRAH\u2019S HOTELS AND CASINOS',
    combined_description: 'HARRAH\u2019S HOTELS AND CASINOS',
    usda_description: 'HARRAH\u2019S HOTELS AND CASINOS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 635,
  },
  3732: {
    edited_description: 'OPRYLAND HOTEL',
    combined_description: 'OPRYLAND HOTEL',
    usda_description: 'OPRYLAND HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 636,
  },
  3733: {
    edited_description: 'BOCA RATON RESORT',
    combined_description: 'BOCA RATON RESORT',
    usda_description: 'BOCA RATON RESORT',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 637,
  },
  3734: {
    edited_description: 'HARVEY/BRISTOL HOTELS',
    combined_description: 'HARVEY/BRISTOL HOTELS',
    usda_description: 'HARVEY/BRISTOL HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 638,
  },
  3735: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 639,
  },
  3736: {
    edited_description: 'COLORADO BELLE/EDGEWATER RESORT',
    combined_description: 'COLORADO BELLE/EDGEWATER RESORT',
    usda_description: 'COLORADO BELLE/EDGEWATER RESORT',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 640,
  },
  3737: {
    edited_description: 'RIVIERA HOTEL AND CASINO',
    combined_description: 'RIVIERA HOTEL AND CASINO',
    usda_description: 'RIVIERA HOTEL AND CASINO',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 641,
  },
  3738: {
    edited_description: 'TROPICANA RESORT AND CASINO',
    combined_description: 'TROPICANA RESORT AND CASINO',
    usda_description: 'TROPICANA RESORT AND CASINO',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 642,
  },
  3739: {
    edited_description: 'WOODSIDE HOTELS AND RESORTS',
    combined_description: 'WOODSIDE HOTELS AND RESORTS',
    usda_description: 'WOODSIDE HOTELS AND RESORTS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 643,
  },
  3740: {
    edited_description: 'TOWNPLACE SUITES',
    combined_description: 'TOWNPLACE SUITES',
    usda_description: 'TOWNPLACE SUITES',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 644,
  },
  3741: {
    edited_description: 'MILLENIUM BROADWAY HOTEL',
    combined_description: 'MILLENIUM BROADWAY HOTEL',
    usda_description: 'MILLENIUM BROADWAY HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 645,
  },
  3742: {
    edited_description: 'CLUB MED',
    combined_description: 'CLUB MED',
    usda_description: 'CLUB MED',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 646,
  },
  3743: {
    edited_description: 'BILTMORE HOTEL AND SUITES',
    combined_description: 'BILTMORE HOTEL AND SUITES',
    usda_description: 'BILTMORE HOTEL AND SUITES',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 647,
  },
  3744: {
    edited_description: 'CAREFREE RESORTS',
    combined_description: 'CAREFREE RESORTS',
    usda_description: 'CAREFREE RESORTS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 648,
  },
  3745: {
    edited_description: 'ST. REGIS HOTEL',
    combined_description: 'ST. REGIS HOTEL',
    usda_description: 'ST. REGIS HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 649,
  },
  3746: {
    edited_description: 'THE ELIOT HOTEL',
    combined_description: 'THE ELIOT HOTEL',
    usda_description: 'THE ELIOT HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 650,
  },
  3747: {
    edited_description: 'CLUBCORP/CLUB RESORTS',
    combined_description: 'CLUBCORP/CLUB RESORTS',
    usda_description: 'CLUBCORP/CLUB RESORTS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 651,
  },
  3748: {
    edited_description: 'WELESLEY INNS',
    combined_description: 'WELESLEY INNS',
    usda_description: 'WELESLEY INNS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 652,
  },
  3749: {
    edited_description: 'THE BEVERLY HILLS HOTEL',
    combined_description: 'THE BEVERLY HILLS HOTEL',
    usda_description: 'THE BEVERLY HILLS HOTEL',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 653,
  },
  3750: {
    edited_description: 'CROWNE PLAZA HOTELS',
    combined_description: 'CROWNE PLAZA HOTELS',
    usda_description: 'CROWNE PLAZA HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 654,
  },
  3751: {
    edited_description: 'HOMEWOOD SUITES',
    combined_description: 'HOMEWOOD SUITES',
    usda_description: 'HOMEWOOD SUITES',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 655,
  },
  3752: {
    edited_description: 'PEABODY HOTELS',
    combined_description: 'PEABODY HOTELS',
    usda_description: 'PEABODY HOTELS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 656,
  },
  3753: {
    edited_description: 'GREENBRIAH RESORTS',
    combined_description: 'GREENBRIAH RESORTS',
    usda_description: 'GREENBRIAH RESORTS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 657,
  },
  3754: {
    edited_description: 'AMELIA ISLAND PLANATION',
    combined_description: 'AMELIA ISLAND PLANATION',
    usda_description: 'AMELIA ISLAND PLANATION',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 658,
  },
  3755: {
    edited_description: 'THE HOMESTEAD',
    combined_description: 'THE HOMESTEAD',
    usda_description: 'THE HOMESTEAD',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 659,
  },
  3756: {
    edited_description: 'SOUTH SEAS RESORTS',
    combined_description: 'SOUTH SEAS RESORTS',
    usda_description: 'SOUTH SEAS RESORTS',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 660,
  },
  3757: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 661,
  },
  3758: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 662,
  },
  3759: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 663,
  },
  3760: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 664,
  },
  3761: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 665,
  },
  3762: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 666,
  },
  3763: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 667,
  },
  3764: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 668,
  },
  3765: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 669,
  },
  3766: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 670,
  },
  3767: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 671,
  },
  3768: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 672,
  },
  3769: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 673,
  },
  3770: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 674,
  },
  3771: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 675,
  },
  3772: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 676,
  },
  3773: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 677,
  },
  3774: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 678,
  },
  3775: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 679,
  },
  3776: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 680,
  },
  3777: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 681,
  },
  3778: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 682,
  },
  3779: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 683,
  },
  3780: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 684,
  },
  3781: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 685,
  },
  3782: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 686,
  },
  3783: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 687,
  },
  3784: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 688,
  },
  3785: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 689,
  },
  3786: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 690,
  },
  3787: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 691,
  },
  3788: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 692,
  },
  3789: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 693,
  },
  3790: {
    edited_description: 'Hotels/Motels/Inns/Resorts',
    combined_description: 'Hotels/Motels/Inns/Resorts',
    usda_description: '',
    irs_description: 'Hotels/Motels/Inns/Resorts',
    irs_reportable: 'Yes',
    id: 694,
  },
  3816: {
    edited_description: 'Home2Suites',
    combined_description: '',
    usda_description: '',
    irs_description: '',
    irs_reportable: '',
    id: 695,
  },
  3835: {
    edited_description: '* MASTERS ECONOMY INNS',
    combined_description: '* MASTERS ECONOMY INNS',
    usda_description: '* MASTERS ECONOMY INNS',
    irs_description: '',
    irs_reportable: '',
    id: 696,
  },
  4011: {
    edited_description: 'Railroads',
    combined_description: 'Railroads',
    usda_description: '',
    irs_description: 'Railroads',
    irs_reportable: 'No1.6041-3(c)',
    id: 697,
  },
  4111: {
    edited_description:
      'Local/Suburban Commuter Passenger Transportation \u2013 Railroads, Feries, Local Water Transportation.',
    combined_description:
      'Local/Suburban Commuter Passenger Transportation \u2013 Railroads, Feries, Local Water Transportation.',
    usda_description:
      'Local/Suburban Commuter Passenger Transportation \u2013 Railroads, Feries, Local Water Transportation.',
    irs_description: 'Commuter Transport, Ferries',
    irs_reportable: 'Yes',
    id: 698,
  },
  4112: {
    edited_description: 'Passenger Railways',
    combined_description: 'Passenger Railways',
    usda_description: 'Passenger Railways',
    irs_description: 'Passenger Railways',
    irs_reportable: 'Yes',
    id: 699,
  },
  4119: {
    edited_description: 'Ambulance Services',
    combined_description: 'Ambulance Services',
    usda_description: 'Ambulance Services',
    irs_description: 'Ambulance Services',
    irs_reportable: 'Yes',
    id: 700,
  },
  4121: {
    edited_description: 'Taxicabs and Limousines',
    combined_description: 'Taxicabs and Limousines',
    usda_description: 'Taxicabs and Limousines',
    irs_description: 'Taxicabs/Limousines',
    irs_reportable: 'Yes',
    id: 701,
  },
  4131: {
    edited_description: 'Bus Lines, Including Charters, Tour Buses',
    combined_description: 'Bus Lines, Including Charters, Tour Buses',
    usda_description: 'Bus Lines, Including Charters, Tour Buses',
    irs_description: 'Bus Lines',
    irs_reportable: 'Yes',
    id: 702,
  },
  4214: {
    edited_description:
      'Motor Freight Carriers, Moving and Storage Companies, Trucking \u2013 Local/Long Distance, Delivery Services \u2013 Local',
    combined_description:
      'Motor Freight Carriers, Moving and Storage Companies, Trucking \u2013 Local/Long Distance, Delivery Services \u2013 Local',
    usda_description: 'Motor Freight Carriers',
    irs_description:
      'Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services ',
    irs_reportable: 'No1.6041-3(c)',
    id: 703,
  },
  4215: {
    edited_description:
      'Courier Services \u2013 Air or Ground, Freight forwarders',
    combined_description:
      'Courier Services \u2013 Air or Ground, Freight forwarders',
    usda_description: 'Courier Services \u2013 Air or Ground',
    irs_description: 'Courier Services ',
    irs_reportable: 'Yes',
    id: 704,
  },
  4225: {
    edited_description: 'Public warehousing, Storage',
    combined_description: 'Public warehousing, Storage',
    usda_description: 'Warehousing, Public',
    irs_description:
      'Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage ',
    irs_reportable: 'No1.6041-3(c)',
    id: 705,
  },
  4411: {
    edited_description: 'Cruise and Steamship Lines',
    combined_description: 'Cruise and Steamship Lines',
    usda_description: 'Cruise Lines',
    irs_description: 'Cruise Lines',
    irs_reportable: 'Yes',
    id: 706,
  },
  4457: {
    edited_description: 'Boat Rentals and Leases',
    combined_description: 'Boat Rentals and Leases',
    usda_description: 'Boat Rentals and Leases',
    irs_description: 'Boat Rentals and Leases',
    irs_reportable: 'Yes',
    id: 707,
  },
  4468: {
    edited_description: 'Marinas, Marine Service, and Supplies',
    combined_description: 'Marinas, Marine Service, and Supplies',
    usda_description: 'Marinas, Marine Service, and Supplies',
    irs_description: 'Marinas, Service and Supplies',
    irs_reportable: 'Yes',
    id: 708,
  },
  4511: {
    edited_description: 'Airlines, Air Carriers ( not listed elsewhere)',
    combined_description: 'Airlines, Air Carriers ( not listed elsewhere)',
    usda_description: 'Airlines, Air Carriers ( not listed elsewhere)',
    irs_description: 'Airlines, Air Carriers',
    irs_reportable: 'Yes',
    id: 709,
  },
  4582: {
    edited_description: 'Airports, Airport Terminals, Flying Fields',
    combined_description: 'Airports, Airport Terminals, Flying Fields',
    usda_description: 'Airports, Airport Terminals',
    irs_description: 'Airports, Flying Fields',
    irs_reportable: 'Yes',
    id: 710,
  },
  4722: {
    edited_description: 'Travel Agencies and Tour Operations',
    combined_description: 'Travel Agencies and Tour Operations',
    usda_description: 'Travel Agencies and Tour Operations',
    irs_description: 'Travel Agencies, Tour Operators',
    irs_reportable: 'Yes',
    id: 711,
  },
  4723: {
    edited_description: 'Package Tour Operators (For use in Germany only)',
    combined_description: 'Package Tour Operators (For use in Germany only)',
    usda_description: 'Package Tour Operators (For use in Germany only)',
    irs_description: 'TUI Travel - Germany',
    irs_reportable: 'Yes',
    id: 712,
  },
  4784: {
    edited_description: 'Toll and Bridge Fees',
    combined_description: 'Toll and Bridge Fees',
    usda_description: 'Toll and Bridge Fees',
    irs_description: 'Tolls/Bridge Fees',
    irs_reportable: 'No1.6041-3(c)',
    id: 713,
  },
  4789: {
    edited_description: 'Transportation Services, Not elsewhere classified)',
    combined_description: 'Transportation Services, Not elsewhere classified)',
    usda_description: 'Transportation Services, Not elsewhere classified)',
    irs_description: 'Transportation Services (Not Elsewhere Classified)',
    irs_reportable: 'Yes',
    id: 714,
  },
  4812: {
    edited_description:
      'Telecommunications Equipment including telephone sales',
    combined_description:
      'Telecommunications Equipment including telephone sales',
    usda_description: 'Telecommunications Equipment including telephone sales',
    irs_description: 'Telecommunication Equipment and Telephone Sales',
    irs_reportable: 'No1.6041-3(c)',
    id: 715,
  },
  4814: {
    edited_description: 'Fax services, Telecommunication Services',
    combined_description: 'Fax services, Telecommunication Services',
    usda_description: 'Fax services',
    irs_description: 'Telecommunication Services',
    irs_reportable: 'No1.6041-3(c)',
    id: 716,
  },
  4815: {
    edited_description: 'VisaPhone',
    combined_description: 'VisaPhone',
    usda_description: 'VisaPhone',
    irs_description: '',
    irs_reportable: '',
    id: 717,
  },
  4816: {
    edited_description: 'Computer Network Services',
    combined_description: 'Computer Network Services',
    usda_description: '',
    irs_description: 'Computer Network Services',
    irs_reportable: 'No1.6041-3(c)',
    id: 718,
  },
  4821: {
    edited_description: 'Telegraph services',
    combined_description: 'Telegraph services',
    usda_description: 'Telegraph services',
    irs_description: 'Telegraph Services',
    irs_reportable: 'No1.6041-3(c)',
    id: 719,
  },
  4829: {
    edited_description: 'Money Orders \u2013 Wire Transfer',
    combined_description: 'Money Orders \u2013 Wire Transfer',
    usda_description: 'Money Orders \u2013 Wire Transfer',
    irs_description: 'Wires, Money Orders',
    irs_reportable: 'No1.6041-3(c)',
    id: 720,
  },
  4899: {
    edited_description:
      'Cable and other pay television (previously Cable Services)',
    combined_description:
      'Cable and other pay television (previously Cable Services)',
    usda_description:
      'Cable and other pay television (previously Cable Services)',
    irs_description: 'Cable, Satellite, and Other Pay Television and Radio',
    irs_reportable: 'No1.6041-3(c)',
    id: 721,
  },
  4900: {
    edited_description: 'Electric, Gas, Sanitary and Water Utilities',
    combined_description: 'Electric, Gas, Sanitary and Water Utilities',
    usda_description: 'Electric, Gas, Sanitary and Water Utilities',
    irs_description: 'Utilities ',
    irs_reportable: 'No1.6041-3(c)',
    id: 722,
  },
  5013: {
    edited_description: 'Motor vehicle supplies and new parts',
    combined_description: 'Motor vehicle supplies and new parts',
    usda_description: 'Motor vehicle supplies and new parts',
    irs_description: 'Motor Vehicle Supplies and New Parts',
    irs_reportable: 'No1.6041-3(c)',
    id: 723,
  },
  5021: {
    edited_description: 'Office and Commercial Furniture',
    combined_description: 'Office and Commercial Furniture',
    usda_description: 'Office and Commercial Furniture',
    irs_description: 'Office and Commercial Furniture',
    irs_reportable: 'No1.6041-3(c)',
    id: 724,
  },
  5039: {
    edited_description: 'Construction Materials, Not Elsewhere Classified',
    combined_description: 'Construction Materials, Not Elsewhere Classified',
    usda_description: 'Construction Materials, Not Elsewhere Classified',
    irs_description: 'Construction Materials (Not Elsewhere Classified)',
    irs_reportable: 'No1.6041-3(c)',
    id: 725,
  },
  5044: {
    edited_description:
      'Office, Photographic, Photocopy, and Microfilm Equipment',
    combined_description:
      'Office, Photographic, Photocopy, and Microfilm Equipment',
    usda_description:
      'Office, Photographic, Photocopy, and Microfilm Equipment',
    irs_description:
      'Photographic, Photocopy, Microfilm Equipment, and Supplies',
    irs_reportable: 'No1.6041-3(c)',
    id: 726,
  },
  5045: {
    edited_description: 'Computers, Computer Peripheral Equipment, Software',
    combined_description: 'Computers, Computer Peripheral Equipment, Software',
    usda_description: 'Computers, Computer Peripheral Equipment, Software',
    irs_description: 'Computers, Peripherals, and Software',
    irs_reportable: 'No1.6041-3(c)',
    id: 727,
  },
  5046: {
    edited_description: 'Commercial Equipment, Not Elsewhere Classified',
    combined_description: 'Commercial Equipment, Not Elsewhere Classified',
    usda_description: 'Commercial Equipment, Not Elsewhere Classified',
    irs_description: 'Commercial Equipment (Not Elsewhere Classified)',
    irs_reportable: 'No1.6041-3(c)',
    id: 728,
  },
  5047: {
    edited_description:
      'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
    combined_description:
      'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
    usda_description:
      'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
    irs_description:
      'Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies',
    irs_reportable: 'No1.6041-3(c)',
    id: 729,
  },
  5051: {
    edited_description: 'Metal Service Centers and Offices',
    combined_description: 'Metal Service Centers and Offices',
    usda_description: 'Metal Service Centers and Offices',
    irs_description: 'Metal Service Centers',
    irs_reportable: 'No1.6041-3(c)',
    id: 730,
  },
  5065: {
    edited_description: 'Electrical Parts and Equipment',
    combined_description: 'Electrical Parts and Equipment',
    usda_description: 'Electrical Parts and Equipment',
    irs_description: 'Electrical Parts and Equipment',
    irs_reportable: 'No1.6041-3(c)',
    id: 731,
  },
  5072: {
    edited_description: 'Hardware Equipment and Supplies',
    combined_description: 'Hardware Equipment and Supplies',
    usda_description: 'Hardware Equipment and Supplies',
    irs_description: 'Hardware, Equipment, and Supplies',
    irs_reportable: 'No1.6041-3(c)',
    id: 732,
  },
  5074: {
    edited_description: 'Plumbing and Heating Equipment and Supplies',
    combined_description: 'Plumbing and Heating Equipment and Supplies',
    usda_description: 'Plumbing and Heating Equipment and Supplies',
    irs_description: 'Plumbing, Heating Equipment, and Supplies',
    irs_reportable: 'No1.6041-3(c)',
    id: 733,
  },
  5085: {
    edited_description: 'Industrial Supplies, Not Elsewhere Classified',
    combined_description: 'Industrial Supplies, Not Elsewhere Classified',
    usda_description: 'Industrial Supplies, Not Elsewhere Classified',
    irs_description: 'Industrial Supplies (Not Elsewhere Classified)',
    irs_reportable: 'No1.6041-3(c)',
    id: 734,
  },
  5094: {
    edited_description: 'Precious Stones and Metals, Watches and Jewelry',
    combined_description: 'Precious Stones and Metals, Watches and Jewelry',
    usda_description: 'Precious Stones and Metals, Watches and Jewelry',
    irs_description: 'Precious Stones and Metals, Watches and Jewelry',
    irs_reportable: 'No1.6041-3(c)',
    id: 735,
  },
  5099: {
    edited_description: 'Durable Goods, Not Elsewhere Classified',
    combined_description: 'Durable Goods, Not Elsewhere Classified',
    usda_description: 'Durable Goods, Not Elsewhere Classified',
    irs_description: 'Durable Goods (Not Elsewhere Classified)',
    irs_reportable: 'No1.6041-3(c)',
    id: 736,
  },
  5111: {
    edited_description:
      'Stationery, Office Supplies, Printing, and Writing Paper',
    combined_description:
      'Stationery, Office Supplies, Printing, and Writing Paper',
    usda_description:
      'Stationery, Office Supplies, Printing, and Writing Paper',
    irs_description: 'Stationary, Office Supplies, Printing and Writing Paper',
    irs_reportable: 'No1.6041-3(c)',
    id: 737,
  },
  5122: {
    edited_description: 'Drugs, Drug Proprietors, and Druggist\u2019s Sundries',
    combined_description:
      'Drugs, Drug Proprietors, and Druggist\u2019s Sundries',
    usda_description: 'Drugs, Drug Proprietors, and Druggist\u2019s Sundries',
    irs_description: 'Drugs, Drug Proprietaries, and Druggist Sundries',
    irs_reportable: 'No1.6041-3(c)',
    id: 738,
  },
  5131: {
    edited_description: 'Piece Goods, Notions, and Other Dry Goods',
    combined_description: 'Piece Goods, Notions, and Other Dry Goods',
    usda_description: 'Piece Goods, Notions, and Other Dry Goods',
    irs_description: 'Piece Goods, Notions, and Other Dry Goods',
    irs_reportable: 'No1.6041-3(c)',
    id: 739,
  },
  5137: {
    edited_description:
      'Men\u2019s Women\u2019s and Children\u2019s Uniforms and Commercial Clothing',
    combined_description:
      'Men\u2019s Women\u2019s and Children\u2019s Uniforms and Commercial Clothing',
    usda_description:
      'Men\u2019s Women\u2019s and Children\u2019s Uniforms and Commercial Clothing',
    irs_description: 'Uniforms, Commercial Clothing',
    irs_reportable: 'No1.6041-3(c)',
    id: 740,
  },
  5139: {
    edited_description: 'Commercial Footwear',
    combined_description: 'Commercial Footwear',
    usda_description: 'Commercial Footwear',
    irs_description: 'Commercial Footwear',
    irs_reportable: 'No1.6041-3(c)',
    id: 741,
  },
  5169: {
    edited_description:
      'Chemicals and Allied Products, Not Elsewhere Classified',
    combined_description:
      'Chemicals and Allied Products, Not Elsewhere Classified',
    usda_description: 'Chemicals and Allied Products, Not Elsewhere Classified',
    irs_description: 'Chemicals and Allied Products (Not Elsewhere Classified)',
    irs_reportable: 'No1.6041-3(c)',
    id: 742,
  },
  5172: {
    edited_description: 'Petroleum and Petroleum Products',
    combined_description: 'Petroleum and Petroleum Products',
    usda_description: 'Petroleum and Petroleum Products',
    irs_description: 'Petroleum and Petroleum Products',
    irs_reportable: 'No1.6041-3(c)',
    id: 743,
  },
  5192: {
    edited_description: 'Books, Periodicals, and Newspapers',
    combined_description: 'Books, Periodicals, and Newspapers',
    usda_description: 'Books, Periodicals, and Newspapers',
    irs_description: 'Books, Periodicals, and Newspapers',
    irs_reportable: 'No1.6041-3(c)',
    id: 744,
  },
  5193: {
    edited_description: 'Florists\u2019 Supplies, Nursery Stock and Flowers',
    combined_description: 'Florists\u2019 Supplies, Nursery Stock and Flowers',
    usda_description: 'Florists\u2019 Supplies, Nursery Stock and Flowers',
    irs_description: 'Florists Supplies, Nursery Stock, and Flowers',
    irs_reportable: 'No1.6041-3(c)',
    id: 745,
  },
  5198: {
    edited_description: 'Paints, Varnishes, and Supplies',
    combined_description: 'Paints, Varnishes, and Supplies',
    usda_description: 'Paints, Varnishes, and Supplies',
    irs_description: 'Paints, Varnishes, and Supplies',
    irs_reportable: 'No1.6041-3(c)',
    id: 746,
  },
  5199: {
    edited_description: 'Non-durable Goods, Not Elsewhere Classified',
    combined_description: 'Non-durable Goods, Not Elsewhere Classified',
    usda_description: 'Non-durable Goods, Not Elsewhere Classified',
    irs_description: 'Nondurable Goods (Not Elsewhere Classified)',
    irs_reportable: 'No1.6041-3(c)',
    id: 747,
  },
  5200: {
    edited_description: 'Home Supply Warehouse Stores',
    combined_description: 'Home Supply Warehouse Stores',
    usda_description: 'Home Supply Warehouse Stores',
    irs_description: 'Home Supply Warehouse Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 748,
  },
  5211: {
    edited_description: 'Lumber and Building Materials Stores',
    combined_description: 'Lumber and Building Materials Stores',
    usda_description: 'Lumber and Building Materials Stores',
    irs_description: 'Lumber, Building Materials Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 749,
  },
  5231: {
    edited_description: 'Glass, Paint, and Wallpaper Stores',
    combined_description: 'Glass, Paint, and Wallpaper Stores',
    usda_description: 'Wallpaper Stores',
    irs_description: 'Glass, Paint, and Wallpaper Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 750,
  },
  5251: {
    edited_description: 'Hardware Stores',
    combined_description: 'Hardware Stores',
    usda_description: 'Hardware Stores',
    irs_description: 'Hardware Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 751,
  },
  5261: {
    edited_description: 'Nurseries \u2013 Lawn and Garden Supply Store',
    combined_description: 'Nurseries \u2013 Lawn and Garden Supply Store',
    usda_description: 'Nurseries \u2013 Lawn and Garden Supply Store',
    irs_description: 'Nurseries, Lawn and Garden Supply Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 752,
  },
  5271: {
    edited_description: 'Mobile Home Dealers',
    combined_description: 'Mobile Home Dealers',
    usda_description: 'Mobile Home Dealers',
    irs_description: 'Mobile Home Dealers',
    irs_reportable: 'No1.6041-3(c)',
    id: 753,
  },
  5300: {
    edited_description: 'Wholesale Clubs',
    combined_description: 'Wholesale Clubs',
    usda_description: 'Wholesale Clubs',
    irs_description: 'Wholesale Clubs',
    irs_reportable: 'No1.6041-3(c)',
    id: 754,
  },
  5309: {
    edited_description: 'Duty Free Store',
    combined_description: 'Duty Free Store',
    usda_description: 'Duty Free Store',
    irs_description: 'Duty Free Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 755,
  },
  5310: {
    edited_description: 'Discount Stores',
    combined_description: 'Discount Stores',
    usda_description: 'Discount Stores',
    irs_description: 'Discount Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 756,
  },
  5311: {
    edited_description: 'Department Stores',
    combined_description: 'Department Stores',
    usda_description: 'Department Stores',
    irs_description: 'Department Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 757,
  },
  5331: {
    edited_description: 'Variety Stores',
    combined_description: 'Variety Stores',
    usda_description: 'Variety Stores',
    irs_description: 'Variety Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 758,
  },
  5399: {
    edited_description: 'Misc. General Merchandise',
    combined_description: 'Misc. General Merchandise',
    usda_description: 'Misc. General Merchandise',
    irs_description: 'Miscellaneous General Merchandise',
    irs_reportable: 'No1.6041-3(c)',
    id: 759,
  },
  5411: {
    edited_description: 'Grocery Stores, Supermarkets',
    combined_description: 'Grocery Stores, Supermarkets',
    usda_description: 'Grocery Stores',
    irs_description: 'Grocery Stores, Supermarkets',
    irs_reportable: 'No1.6041-3(c)',
    id: 760,
  },
  5422: {
    edited_description: 'Meat Provisioners \u2013 Freezer and Locker',
    combined_description: 'Meat Provisioners \u2013 Freezer and Locker',
    usda_description: 'Meat Provisioners \u2013 Freezer and Locker',
    irs_description: 'Freezer and Locker Meat Provisioners',
    irs_reportable: 'No1.6041-3(c)',
    id: 761,
  },
  5441: {
    edited_description: 'Candy, Nut, and Confectionery Stores',
    combined_description: 'Candy, Nut, and Confectionery Stores',
    usda_description: 'Candy Stores',
    irs_description: 'Candy, Nut, and Confectionery Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 762,
  },
  5451: {
    edited_description: 'Dairy Products Stores',
    combined_description: 'Dairy Products Stores',
    usda_description: 'Dairy Products Stores',
    irs_description: 'Dairy Products Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 763,
  },
  5462: {
    edited_description: 'Bakeries',
    combined_description: 'Bakeries',
    usda_description: 'Bakeries',
    irs_description: 'Bakeries',
    irs_reportable: 'No1.6041-3(c)',
    id: 764,
  },
  5499: {
    edited_description:
      'Misc. Food Stores \u2013 Convenience Stores and Specialty Markets',
    combined_description:
      'Misc. Food Stores \u2013 Convenience Stores and Specialty Markets',
    usda_description:
      'Misc. Food Stores \u2013 Convenience Stores and Specialty Markets',
    irs_description:
      'Miscellaneous Food Stores - Convenience Stores and Specialty Markets',
    irs_reportable: 'No1.6041-3(c)',
    id: 765,
  },
  5511: {
    edited_description:
      'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
    combined_description:
      'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
    usda_description:
      'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
    irs_description:
      'Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing ',
    irs_reportable: 'No1.6041-3(c)',
    id: 766,
  },
  5521: {
    edited_description: 'Automobile and Truck Dealers (Used Only)',
    combined_description: 'Automobile and Truck Dealers (Used Only)',
    usda_description: 'Automobile and Truck Dealers (Used Only)',
    irs_description:
      'Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing ',
    irs_reportable: 'No1.6041-3(c)',
    id: 767,
  },
  5531: {
    edited_description: 'Automobile Supply Stores',
    combined_description: 'Automobile Supply Stores',
    usda_description: 'Automobile Supply Stores',
    irs_description: 'Auto and Home Supply Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 768,
  },
  5532: {
    edited_description: 'Automotive Tire Stores',
    combined_description: 'Automotive Tire Stores',
    usda_description: 'Automotive Tire Stores',
    irs_description: 'Automotive Tire Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 769,
  },
  5533: {
    edited_description: 'Automotive Parts, Accessories Stores',
    combined_description: 'Automotive Parts, Accessories Stores',
    usda_description: 'Automotive Parts, Accessories Stores',
    irs_description: 'Automotive Parts and Accessories Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 770,
  },
  5541: {
    edited_description:
      'Service Stations ( with or without ancillary services)',
    combined_description:
      'Service Stations ( with or without ancillary services)',
    usda_description: 'Service Stations ( with or without ancillary services)',
    irs_description: 'Service Stations ',
    irs_reportable: 'No1.6041-3(c)',
    id: 771,
  },
  5542: {
    edited_description: 'Automated Fuel Dispensers',
    combined_description: 'Automated Fuel Dispensers',
    usda_description: 'Automated Fuel Dispensers',
    irs_description: 'Automated Fuel Dispensers',
    irs_reportable: 'No1.6041-3(c)',
    id: 772,
  },
  5551: {
    edited_description: 'Boat Dealers',
    combined_description: 'Boat Dealers',
    usda_description: 'Boat Dealers',
    irs_description: 'Boat Dealers',
    irs_reportable: 'No1.6041-3(c)',
    id: 773,
  },
  5561: {
    edited_description: 'Recreational and Utility Trailers, Camp Dealers',
    combined_description: 'Recreational and Utility Trailers, Camp Dealers',
    usda_description: 'Recreational and Utility Trailers, Camp Dealers',
    irs_description: 'Motorcycle Shops, Dealers',
    irs_reportable: 'No1.6041-3(c)',
    id: 774,
  },
  5571: {
    edited_description: 'Motorcycle Dealers',
    combined_description: 'Motorcycle Dealers',
    usda_description: 'Motorcycle Dealers',
    irs_description: 'Motorcycle Shops and Dealers',
    irs_reportable: 'No1.6041-3(c)',
    id: 775,
  },
  5592: {
    edited_description: 'Motor Home Dealers',
    combined_description: 'Motor Home Dealers',
    usda_description: 'Motor Home Dealers',
    irs_description: 'Motor Homes Dealers',
    irs_reportable: 'No1.6041-3(c)',
    id: 776,
  },
  5598: {
    edited_description: 'Snowmobile Dealers',
    combined_description: 'Snowmobile Dealers',
    usda_description: 'Snowmobile Dealers',
    irs_description: 'Snowmobile Dealers',
    irs_reportable: 'No1.6041-3(c)',
    id: 777,
  },
  5599: {
    edited_description: 'Miscellaneous Auto Dealers ',
    combined_description: 'Miscellaneous Auto Dealers ',
    usda_description: '',
    irs_description: 'Miscellaneous Auto Dealers ',
    irs_reportable: 'No1.6041-3(c)',
    id: 778,
  },
  5611: {
    edited_description:
      'Men\u2019s and Boy\u2019s Clothing and Accessories Stores',
    combined_description:
      'Men\u2019s and Boy\u2019s Clothing and Accessories Stores',
    usda_description:
      'Men\u2019s and Boy\u2019s Clothing and Accessories Stores',
    irs_description:
      'Men\u2019s and Boy\u2019s Clothing and Accessories Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 779,
  },
  5621: {
    edited_description: 'Women\u2019s Ready-to-Wear Stores',
    combined_description: 'Women\u2019s Ready-to-Wear Stores',
    usda_description: 'Women\u2019s Ready-to-Wear Stores',
    irs_description: 'Women\u2019s Ready-To-Wear Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 780,
  },
  5631: {
    edited_description: 'Women\u2019s Accessory and Specialty Shops',
    combined_description: 'Women\u2019s Accessory and Specialty Shops',
    usda_description: 'Women\u2019s Accessory and Specialty Shops',
    irs_description: 'Women\u2019s Accessory and Specialty Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 781,
  },
  5641: {
    edited_description: 'Children\u2019s and Infant\u2019s Wear Stores',
    combined_description: 'Children\u2019s and Infant\u2019s Wear Stores',
    usda_description: 'Children\u2019s and Infant\u2019s Wear Stores',
    irs_description: 'Children\u2019s and Infant\u2019s Wear Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 782,
  },
  5651: {
    edited_description: 'Family Clothing Stores',
    combined_description: 'Family Clothing Stores',
    usda_description: 'Family Clothing Stores',
    irs_description: 'Family Clothing Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 783,
  },
  5655: {
    edited_description: 'Sports Apparel, Riding Apparel Stores',
    combined_description: 'Sports Apparel, Riding Apparel Stores',
    usda_description: 'Sports Apparel, Riding Apparel Stores',
    irs_description: 'Sports and Riding Apparel Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 784,
  },
  5661: {
    edited_description: 'Shoe Stores',
    combined_description: 'Shoe Stores',
    usda_description: 'Shoe Stores',
    irs_description: 'Shoe Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 785,
  },
  5681: {
    edited_description: 'Furriers and Fur Shops',
    combined_description: 'Furriers and Fur Shops',
    usda_description: 'Furriers and Fur Shops',
    irs_description: 'Furriers and Fur Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 786,
  },
  5691: {
    edited_description: 'Men\u2019s and Women\u2019s Clothing Stores',
    combined_description: 'Men\u2019s and Women\u2019s Clothing Stores',
    usda_description: 'Men\u2019s and Women\u2019s Clothing Stores',
    irs_description: 'Men\u2019s, Women\u2019s Clothing Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 787,
  },
  5697: {
    edited_description: 'Tailors, Seamstress, Mending, and Alterations',
    combined_description: 'Tailors, Seamstress, Mending, and Alterations',
    usda_description: 'Tailors, Seamstress, Mending, and Alterations',
    irs_description: 'Tailors, Alterations',
    irs_reportable: 'Yes',
    id: 788,
  },
  5698: {
    edited_description: 'Wig and Toupee Stores',
    combined_description: 'Wig and Toupee Stores',
    usda_description: 'Wig and Toupee Stores',
    irs_description: 'Wig and Toupee Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 789,
  },
  5699: {
    edited_description: 'Miscellaneous Apparel and Accessory Shops',
    combined_description: 'Miscellaneous Apparel and Accessory Shops',
    usda_description: 'Miscellaneous Apparel and Accessory Shops',
    irs_description: 'Miscellaneous Apparel and Accessory Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 790,
  },
  5712: {
    edited_description:
      'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
    combined_description:
      'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
    usda_description:
      'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
    irs_description:
      'Furniture, Home Furnishings, and Equipment Stores, Except Appliances',
    irs_reportable: 'No1.6041-3(c)',
    id: 791,
  },
  5713: {
    edited_description: 'Floor Covering Stores',
    combined_description: 'Floor Covering Stores',
    usda_description: 'Floor Covering Stores',
    irs_description: 'Floor Covering Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 792,
  },
  5714: {
    edited_description: 'Drapery, Window Covering and Upholstery Stores',
    combined_description: 'Drapery, Window Covering and Upholstery Stores',
    usda_description: 'Drapery, Window Covering and Upholstery Stores',
    irs_description: 'Drapery, Window Covering, and Upholstery Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 793,
  },
  5718: {
    edited_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
    combined_description:
      'Fireplace, Fireplace Screens, and Accessories Stores',
    usda_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
    irs_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 794,
  },
  5719: {
    edited_description: 'Miscellaneous Home Furnishing Specialty Stores',
    combined_description: 'Miscellaneous Home Furnishing Specialty Stores',
    usda_description: 'Miscellaneous Home Furnishing Specialty Stores',
    irs_description: 'Miscellaneous Home Furnishing Specialty Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 795,
  },
  5722: {
    edited_description: 'Household Appliance Stores',
    combined_description: 'Household Appliance Stores',
    usda_description: 'Household Appliance Stores',
    irs_description: 'Household Appliance Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 796,
  },
  5732: {
    edited_description: 'Electronic Sales',
    combined_description: 'Electronic Sales',
    usda_description: 'Electronic Sales',
    irs_description: 'Electronics Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 797,
  },
  5733: {
    edited_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
    combined_description:
      'Music Stores, Musical Instruments, Piano Sheet Music',
    usda_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
    irs_description:
      'Music Stores-Musical Instruments, Pianos, and Sheet Music',
    irs_reportable: 'No1.6041-3(c)',
    id: 798,
  },
  5734: {
    edited_description: 'Computer Software Stores',
    combined_description: 'Computer Software Stores',
    usda_description: 'Computer Software Stores',
    irs_description: 'Computer Software Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 799,
  },
  5735: {
    edited_description: 'Record Shops',
    combined_description: 'Record Shops',
    usda_description: 'Record Shops',
    irs_description: 'Record Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 800,
  },
  5811: {
    edited_description: 'Caterers',
    combined_description: 'Caterers',
    usda_description: 'Caterers',
    irs_description: 'Caterers',
    irs_reportable: 'Yes',
    id: 801,
  },
  5812: {
    edited_description: 'Eating places and Restaurants',
    combined_description: 'Eating places and Restaurants',
    usda_description: 'Eating places and Restaurants',
    irs_description: 'Eating Places, Restaurants',
    irs_reportable: 'No1.6041-3(c)',
    id: 802,
  },
  5813: {
    edited_description:
      'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
    combined_description:
      'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
    usda_description:
      'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
    irs_description: 'Drinking Places',
    irs_reportable: 'No1.6041-3(c)',
    id: 803,
  },
  5814: {
    edited_description: 'Fast Food Restaurants',
    combined_description: 'Fast Food Restaurants',
    usda_description: 'Fast Food Restaurants',
    irs_description: 'Fast Food Restaurants',
    irs_reportable: 'No1.6041-3(c)',
    id: 804,
  },
  5815: {
    edited_description: 'Digital Goods: Media, Books, Movies, Music',
    combined_description: '',
    usda_description: '',
    irs_description: '',
    irs_reportable: '',
    id: 805,
  },
  5816: {
    edited_description: 'Digital Goods: Games',
    combined_description: '',
    usda_description: '',
    irs_description: '',
    irs_reportable: '',
    id: 806,
  },
  5817: {
    edited_description: 'Digital Goods: Applications (Excludes Games)',
    combined_description: '',
    usda_description: '',
    irs_description: '',
    irs_reportable: '',
    id: 807,
  },
  5818: {
    edited_description: 'Digital Goods: Large Digital Goods Merchant',
    combined_description: '',
    usda_description: '',
    irs_description: '',
    irs_reportable: '',
    id: 808,
  },
  5832: {
    edited_description:
      'Antique Shops \u2013 Sales, Repairs, and Restoration Services',
    combined_description:
      'Antique Shops \u2013 Sales, Repairs, and Restoration Services',
    usda_description:
      'Antique Shops \u2013 Sales, Repairs, and Restoration Services',
    irs_description: '',
    irs_reportable: '',
    id: 809,
  },
  5912: {
    edited_description: 'Drug Stores and Pharmacies',
    combined_description: 'Drug Stores and Pharmacies',
    usda_description: 'Drug Stores and Pharmacies',
    irs_description: 'Drug Stores and Pharmacies',
    irs_reportable: 'No1.6041-3(c)',
    id: 810,
  },
  5921: {
    edited_description: 'Package Stores \u2013 Beer, Wine, and Liquor',
    combined_description: 'Package Stores \u2013 Beer, Wine, and Liquor',
    usda_description: 'Package Stores \u2013 Beer, Wine, and Liquor',
    irs_description: 'Package Stores-Beer, Wine, and Liquor',
    irs_reportable: 'No1.6041-3(c)',
    id: 811,
  },
  5931: {
    edited_description: 'Used Merchandise and Secondhand Stores',
    combined_description: 'Used Merchandise and Secondhand Stores',
    usda_description: 'Used Merchandise and Secondhand Stores',
    irs_description: 'Used Merchandise and Secondhand Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 812,
  },
  5932: {
    edited_description: 'Antique Shops',
    combined_description: 'Antique Shops',
    usda_description: '',
    irs_description: 'Antique Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 813,
  },
  5933: {
    edited_description: 'Pawn Shops and Salvage Yards',
    combined_description: 'Pawn Shops and Salvage Yards',
    usda_description: 'Pawn Shops and Salvage Yards',
    irs_description: 'Pawn Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 814,
  },
  5935: {
    edited_description: 'Wrecking and Salvage Yards',
    combined_description: 'Wrecking and Salvage Yards',
    usda_description: 'Wrecking and Salvage Yards',
    irs_description: 'Wrecking and Salvage Yards',
    irs_reportable: 'Yes',
    id: 815,
  },
  5937: {
    edited_description: 'Antique Reproductions',
    combined_description: 'Antique Reproductions',
    usda_description: 'Antique Reproductions',
    irs_description: 'Antique Reproductions',
    irs_reportable: 'No1.6041-3(c)',
    id: 816,
  },
  5940: {
    edited_description: 'Bicycle Shops \u2013 Sales and Service',
    combined_description: 'Bicycle Shops \u2013 Sales and Service',
    usda_description: 'Bicycle Shops \u2013 Sales and Service',
    irs_description: 'Bicycle Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 817,
  },
  5941: {
    edited_description: 'Sporting Goods Stores',
    combined_description: 'Sporting Goods Stores',
    usda_description: 'Sporting Goods Stores',
    irs_description: 'Sporting Goods Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 818,
  },
  5942: {
    edited_description: 'Book Stores',
    combined_description: 'Book Stores',
    usda_description: 'Book Stores',
    irs_description: 'Book Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 819,
  },
  5943: {
    edited_description: 'Stationery Stores, Office and School Supply Stores',
    combined_description: 'Stationery Stores, Office and School Supply Stores',
    usda_description: 'Stationery Stores, Office and School Supply Stores',
    irs_description: 'Stationery Stores, Office, and School Supply Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 820,
  },
  5944: {
    edited_description: 'Watch, Clock, Jewelry, and Silverware Stores',
    combined_description: 'Watch, Clock, Jewelry, and Silverware Stores',
    usda_description: 'Watch, Clock, Jewelry, and Silverware Stores',
    irs_description: 'Jewelry Stores, Watches, Clocks, and Silverware Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 821,
  },
  5945: {
    edited_description: 'Hobby, Toy, and Game Shops',
    combined_description: 'Hobby, Toy, and Game Shops',
    usda_description: 'Hobby, Toy, and Game Shops',
    irs_description: 'Hobby, Toy, and Game Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 822,
  },
  5946: {
    edited_description: 'Camera and Photographic Supply Stores',
    combined_description: 'Camera and Photographic Supply Stores',
    usda_description: 'Camera and Photographic Supply Stores',
    irs_description: 'Camera and Photographic Supply Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 823,
  },
  5947: {
    edited_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
    combined_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
    usda_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
    irs_description: 'Gift, Card, Novelty, and Souvenir Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 824,
  },
  5948: {
    edited_description: 'Leather Goods Stores',
    combined_description: 'Leather Goods Stores',
    usda_description: 'Leather Goods Stores',
    irs_description: 'Luggage and Leather Goods Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 825,
  },
  5949: {
    edited_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
    combined_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
    usda_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
    irs_description: 'Sewing, Needlework, Fabric, and Piece Goods Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 826,
  },
  5950: {
    edited_description: 'Glassware/Crystal Stores',
    combined_description: 'Glassware/Crystal Stores',
    usda_description: 'Glassware/Crystal Stores',
    irs_description: 'Glassware, Crystal Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 827,
  },
  5960: {
    edited_description: 'Direct Marketing- Insurance Service',
    combined_description: 'Direct Marketing- Insurance Service',
    usda_description: 'Direct Marketing- Insurance Service',
    irs_description: 'Direct Marketing - Insurance Services',
    irs_reportable: 'Yes',
    id: 828,
  },
  5961: {
    edited_description:
      'Mail Order Houses Including Catalog Order Stores, Book/Record Clubs (No longer permitted for U.S. original presentments)',
    combined_description:
      'Mail Order Houses Including Catalog Order Stores, Book/Record Clubs (No longer permitted for U.S. original presentments)',
    usda_description:
      'Mail Order Houses Including Catalog Order Stores, Book/Record Clubs (No longer permitted for U.S. original presentments)',
    irs_description: '',
    irs_reportable: '',
    id: 829,
  },
  5962: {
    edited_description:
      'Direct Marketing \u2013 Travel Related Arrangements Services',
    combined_description:
      'Direct Marketing \u2013 Travel Related Arrangements Services',
    usda_description:
      'Direct Marketing \u2013 Travel Related Arrangements Services',
    irs_description: 'Direct Marketing - Travel',
    irs_reportable: 'Yes',
    id: 830,
  },
  5963: {
    edited_description: 'Door-to-Door Sales',
    combined_description: 'Door-to-Door Sales',
    usda_description: 'Door-to-Door Sales',
    irs_description: 'Door-To-Door Sales',
    irs_reportable: 'No1.6041-3(c)',
    id: 831,
  },
  5964: {
    edited_description: 'Direct Marketing \u2013 Catalog Merchant',
    combined_description: 'Direct Marketing \u2013 Catalog Merchant',
    usda_description: 'Direct Marketing \u2013 Catalog Merchant',
    irs_description: 'Direct Marketing - Catalog Merchant',
    irs_reportable: 'No1.6041-3(c)',
    id: 832,
  },
  5965: {
    edited_description:
      'Direct Marketing \u2013 Catalog and Catalog and Retail Merchant',
    combined_description:
      'Direct Marketing \u2013 Catalog and Catalog and Retail Merchant',
    usda_description:
      'Direct Marketing \u2013 Catalog and Catalog and Retail Merchant',
    irs_description:
      'Direct Marketing - Combination Catalog and Retail Merchant',
    irs_reportable: 'No1.6041-3(c)',
    id: 833,
  },
  5966: {
    edited_description: 'Direct Marketing- Outbound Telemarketing Merchant',
    combined_description: 'Direct Marketing- Outbound Telemarketing Merchant',
    usda_description: 'Direct Marketing- Outbound Telemarketing Merchant',
    irs_description: 'Direct Marketing - Outbound Tele',
    irs_reportable: 'No1.6041-3(c)',
    id: 834,
  },
  5967: {
    edited_description: 'Direct Marketing \u2013 Inbound Teleservices Merchant',
    combined_description:
      'Direct Marketing \u2013 Inbound Teleservices Merchant',
    usda_description: 'Direct Marketing \u2013 Inbound Teleservices Merchant',
    irs_description: 'Direct Marketing - Inbound Tele',
    irs_reportable: 'No1.6041-3(c)',
    id: 835,
  },
  5968: {
    edited_description:
      'Direct Marketing \u2013 Continuity/Subscription Merchant',
    combined_description:
      'Direct Marketing \u2013 Continuity/Subscription Merchant',
    usda_description:
      'Direct Marketing \u2013 Continuity/Subscription Merchant',
    irs_description: 'Direct Marketing - Subscription',
    irs_reportable: 'No1.6041-3(c)',
    id: 836,
  },
  5969: {
    edited_description: 'Direct Marketing \u2013 Not Elsewhere Classified',
    combined_description: 'Direct Marketing \u2013 Not Elsewhere Classified',
    usda_description: 'Direct Marketing \u2013 Not Elsewhere Classified',
    irs_description: 'Direct Marketing - Other ',
    irs_reportable: 'No1.6041-3(c)',
    id: 837,
  },
  5970: {
    edited_description: 'Artist\u2019s Supply and Craft Shops',
    combined_description: 'Artist\u2019s Supply and Craft Shops',
    usda_description: 'Artist\u2019s Supply and Craft Shops',
    irs_description: 'Artist\u2019s Supply and Craft Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 838,
  },
  5971: {
    edited_description: 'Art Dealers and Galleries',
    combined_description: 'Art Dealers and Galleries',
    usda_description: 'Art Dealers and Galleries',
    irs_description: 'Art Dealers and Galleries',
    irs_reportable: 'No1.6041-3(c)',
    id: 839,
  },
  5972: {
    edited_description:
      'Stamp and Coin Stores \u2013 Philatelic and Numismatic Supplies',
    combined_description:
      'Stamp and Coin Stores \u2013 Philatelic and Numismatic Supplies',
    usda_description:
      'Stamp and Coin Stores \u2013 Philatelic and Numismatic Supplies',
    irs_description: 'Stamp and Coin Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 840,
  },
  5973: {
    edited_description: 'Religious Goods Stores',
    combined_description: 'Religious Goods Stores',
    usda_description: 'Religious Goods Stores',
    irs_description: 'Religious Goods Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 841,
  },
  5975: {
    edited_description: 'Hearing Aids \u2013 Sales, Service, and Supply Stores',
    combined_description:
      'Hearing Aids \u2013 Sales, Service, and Supply Stores',
    usda_description: 'Hearing Aids \u2013 Sales, Service, and Supply Stores',
    irs_description: 'Hearing Aids Sales and Supplies',
    irs_reportable: 'No1.6041-3(c)',
    id: 842,
  },
  5976: {
    edited_description: 'Orthopedic Goods Prosthetic Devices',
    combined_description: 'Orthopedic Goods Prosthetic Devices',
    usda_description: 'Orthopedic Goods Prosthetic Devices',
    irs_description: 'Orthopedic Goods - Prosthetic Devices',
    irs_reportable: 'No1.6041-3(c)',
    id: 843,
  },
  5977: {
    edited_description: 'Cosmetic Stores',
    combined_description: 'Cosmetic Stores',
    usda_description: 'Cosmetic Stores',
    irs_description: 'Cosmetic Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 844,
  },
  5978: {
    edited_description: 'Typewriter Stores \u2013 Sales, Rental, Service',
    combined_description: 'Typewriter Stores \u2013 Sales, Rental, Service',
    usda_description: 'Typewriter Stores \u2013 Sales, Rental, Service',
    irs_description: 'Typewriter Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 845,
  },
  5983: {
    edited_description: 'Fuel \u2013 Fuel Oil, Wood, Coal, Liquefied Petroleum',
    combined_description:
      'Fuel \u2013 Fuel Oil, Wood, Coal, Liquefied Petroleum',
    usda_description: 'Fuel \u2013 Fuel Oil, Wood, Coal, Liquefied Petroleum',
    irs_description: 'Fuel Dealers (Non Automotive)',
    irs_reportable: 'No1.6041-3(c)',
    id: 846,
  },
  5992: {
    edited_description: 'Florists',
    combined_description: 'Florists',
    usda_description: 'Florists',
    irs_description: 'Florists',
    irs_reportable: 'No1.6041-3(c)',
    id: 847,
  },
  5993: {
    edited_description: 'Cigar Stores and Stands',
    combined_description: 'Cigar Stores and Stands',
    usda_description: 'Cigar Stores and Stands',
    irs_description: 'Cigar Stores and Stands',
    irs_reportable: 'No1.6041-3(c)',
    id: 848,
  },
  5994: {
    edited_description: 'News Dealers and Newsstands',
    combined_description: 'News Dealers and Newsstands',
    usda_description: 'News Dealers and Newsstands',
    irs_description: 'News Dealers and Newsstands',
    irs_reportable: 'No1.6041-3(c)',
    id: 849,
  },
  5995: {
    edited_description: 'Pet Shops, Pet Foods, and Supplies Stores',
    combined_description: 'Pet Shops, Pet Foods, and Supplies Stores',
    usda_description: 'Pet Shops, Pet Foods, and Supplies Stores',
    irs_description: 'Pet Shops, Pet Food, and Supplies',
    irs_reportable: 'No1.6041-3(c)',
    id: 850,
  },
  5996: {
    edited_description: 'Swimming Pools \u2013 Sales, Service, and Supplies',
    combined_description: 'Swimming Pools \u2013 Sales, Service, and Supplies',
    usda_description: 'Swimming Pools \u2013 Sales, Service, and Supplies',
    irs_description: 'Swimming Pools Sales',
    irs_reportable: 'No1.6041-3(c)',
    id: 851,
  },
  5997: {
    edited_description: 'Electric Razor Stores \u2013 Sales and Service',
    combined_description: 'Electric Razor Stores \u2013 Sales and Service',
    usda_description: 'Electric Razor Stores \u2013 Sales and Service',
    irs_description: 'Electric Razor Stores',
    irs_reportable: 'No1.6041-3(c)',
    id: 852,
  },
  5998: {
    edited_description: 'Tent and Awning Shops',
    combined_description: 'Tent and Awning Shops',
    usda_description: 'Tent and Awning Shops',
    irs_description: 'Tent and Awning Shops',
    irs_reportable: 'No1.6041-3(c)',
    id: 853,
  },
  5999: {
    edited_description: 'Miscellaneous and Specialty Retail Stores',
    combined_description: 'Miscellaneous and Specialty Retail Stores',
    usda_description: 'Miscellaneous and Specialty Retail Stores',
    irs_description: 'Miscellaneous Specialty Retail',
    irs_reportable: 'No1.6041-3(c)',
    id: 854,
  },
  6010: {
    edited_description:
      'Financial Institutions \u2013 Manual Cash Disbursements',
    combined_description:
      'Financial Institutions \u2013 Manual Cash Disbursements',
    usda_description: 'Financial Institutions \u2013 Manual Cash Disbursements',
    irs_description: 'Manual Cash Disburse',
    irs_reportable: 'No1.6041-3(c)',
    id: 855,
  },
  6011: {
    edited_description:
      'Financial Institutions \u2013 Manual Cash Disbursements',
    combined_description:
      'Financial Institutions \u2013 Manual Cash Disbursements',
    usda_description: 'Financial Institutions \u2013 Manual Cash Disbursements',
    irs_description: 'Automated Cash Disburse',
    irs_reportable: 'No1.6041-3(c)',
    id: 856,
  },
  6012: {
    edited_description:
      'Financial Institutions \u2013 Merchandise and Services',
    combined_description:
      'Financial Institutions \u2013 Merchandise and Services',
    usda_description: 'Financial Institutions \u2013 Merchandise and Services',
    irs_description: 'Financial Institutions',
    irs_reportable: 'Yes',
    id: 857,
  },
  6051: {
    edited_description:
      'Non-Financial Institutions \u2013 Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques',
    combined_description:
      'Non-Financial Institutions \u2013 Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques',
    usda_description:
      'Non-Financial Institutions \u2013 Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques',
    irs_description: 'Non-FI, Money Orders',
    irs_reportable: 'No1.6041-3(c)',
    id: 858,
  },
  6211: {
    edited_description: 'Security Brokers/Dealers',
    combined_description: 'Security Brokers/Dealers',
    usda_description: 'Security Brokers/Dealers',
    irs_description: 'Security Brokers/Dealers',
    irs_reportable: 'Yes',
    id: 859,
  },
  6300: {
    edited_description: 'Insurance Sales, Underwriting, and Premiums',
    combined_description: 'Insurance Sales, Underwriting, and Premiums',
    usda_description: 'Insurance Sales, Underwriting, and Premiums',
    irs_description: 'Insurance Underwriting, Premiums',
    irs_reportable: 'No1.6041-3(c)',
    id: 860,
  },
  6381: {
    edited_description:
      'Insurance Premiums, (no longer valid for first presentment work)',
    combined_description:
      'Insurance Premiums, (no longer valid for first presentment work)',
    usda_description:
      'Insurance Premiums, (no longer valid for first presentment work)',
    irs_description: '',
    irs_reportable: '',
    id: 861,
  },
  6399: {
    edited_description:
      'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
    combined_description:
      'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
    usda_description:
      'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
    irs_description: 'Insurance - Default',
    irs_reportable: 'No1.6041-3(c)',
    id: 862,
  },
  6513: {
    edited_description: 'Real Estate Agents and Managers - Rentals',
    combined_description: 'Real Estate Agents and Managers - Rentals',
    usda_description: '',
    irs_description: 'Real Estate Agents and Managers - Rentals',
    irs_reportable: 'Yes',
    id: 863,
  },
  7011: {
    edited_description:
      'Lodging \u2013 Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)',
    combined_description:
      'Lodging \u2013 Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)',
    usda_description:
      'Lodging \u2013 Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)',
    irs_description: 'Hotels, Motels, and Resorts',
    irs_reportable: 'Yes',
    id: 864,
  },
  7012: {
    edited_description: 'Timeshares',
    combined_description: 'Timeshares',
    usda_description: 'Timeshares',
    irs_description: 'Timeshares',
    irs_reportable: 'Yes',
    id: 865,
  },
  7032: {
    edited_description: 'Sporting and Recreational Camps',
    combined_description: 'Sporting and Recreational Camps',
    usda_description: 'Sporting and Recreational Camps',
    irs_description: 'Sporting/Recreation Camps',
    irs_reportable: 'Yes',
    id: 866,
  },
  7033: {
    edited_description: 'Trailer Parks and Camp Grounds',
    combined_description: 'Trailer Parks and Camp Grounds',
    usda_description: 'Trailer Parks and Camp Grounds',
    irs_description: 'Trailer Parks, Campgrounds',
    irs_reportable: 'Yes',
    id: 867,
  },
  7210: {
    edited_description: 'Laundry, Cleaning, and Garment Services',
    combined_description: 'Laundry, Cleaning, and Garment Services',
    usda_description: 'Laundry, Cleaning, and Garment Services',
    irs_description: 'Laundry, Cleaning Services',
    irs_reportable: 'Yes',
    id: 868,
  },
  7211: {
    edited_description: 'Laundry \u2013 Family and Commercial',
    combined_description: 'Laundry \u2013 Family and Commercial',
    usda_description: 'Laundry \u2013 Family and Commercial',
    irs_description: 'Laundries ',
    irs_reportable: 'Yes',
    id: 869,
  },
  7216: {
    edited_description: 'Dry Cleaners',
    combined_description: 'Dry Cleaners',
    usda_description: 'Dry Cleaners',
    irs_description: 'Dry Cleaners',
    irs_reportable: 'Yes',
    id: 870,
  },
  7217: {
    edited_description: 'Carpet and Upholstery Cleaning',
    combined_description: 'Carpet and Upholstery Cleaning',
    usda_description: 'Carpet and Upholstery Cleaning',
    irs_description: 'Carpet/Upholstery Cleaning',
    irs_reportable: 'Yes',
    id: 871,
  },
  7221: {
    edited_description: 'Photographic Studios',
    combined_description: 'Photographic Studios',
    usda_description: 'Photographic Studios',
    irs_description: 'Photographic Studios',
    irs_reportable: 'Yes',
    id: 872,
  },
  7230: {
    edited_description: 'Barber and Beauty Shops',
    combined_description: 'Barber and Beauty Shops',
    usda_description: 'Barber and Beauty Shops',
    irs_description: 'Barber and Beauty Shops',
    irs_reportable: 'Yes',
    id: 873,
  },
  7251: {
    edited_description:
      'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
    combined_description:
      'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
    usda_description:
      'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
    irs_description: 'Shoe Repair/Hat Cleaning',
    irs_reportable: 'Yes',
    id: 874,
  },
  7261: {
    edited_description: 'Funeral Service and Crematories',
    combined_description: 'Funeral Service and Crematories',
    usda_description: 'Funeral Service and Crematories',
    irs_description: 'Funeral Services, Crematories',
    irs_reportable: 'Yes',
    id: 875,
  },
  7273: {
    edited_description: 'Dating and Escort Services',
    combined_description: 'Dating and Escort Services',
    usda_description: 'Dating and Escort Services',
    irs_description: 'Dating/Escort Services',
    irs_reportable: 'Yes',
    id: 876,
  },
  7276: {
    edited_description: 'Tax Preparation Service',
    combined_description: 'Tax Preparation Service',
    usda_description: 'Tax Preparation Service',
    irs_description: 'Tax Preparation Services',
    irs_reportable: 'Yes',
    id: 877,
  },
  7277: {
    edited_description: 'Counseling Service \u2013 Debt, Marriage, Personal',
    combined_description: 'Counseling Service \u2013 Debt, Marriage, Personal',
    usda_description: 'Counseling Service \u2013 Debt, Marriage, Personal',
    irs_description: 'Counseling Services',
    irs_reportable: 'Yes',
    id: 878,
  },
  7278: {
    edited_description: 'Buying/Shopping Services, Clubs',
    combined_description: 'Buying/Shopping Services, Clubs',
    usda_description: 'Buying/Shopping Services, Clubs',
    irs_description: 'Buying/Shopping Services',
    irs_reportable: 'Yes',
    id: 879,
  },
  7296: {
    edited_description:
      'Clothing Rental \u2013 Costumes, Formal Wear, Uniforms',
    combined_description:
      'Clothing Rental \u2013 Costumes, Formal Wear, Uniforms',
    usda_description: 'Clothing Rental \u2013 Costumes, Formal Wear, Uniforms',
    irs_description: 'Clothing Rental ',
    irs_reportable: 'Yes',
    id: 880,
  },
  7297: {
    edited_description: 'Massage Parlors',
    combined_description: 'Massage Parlors',
    usda_description: 'Massage Parlors',
    irs_description: 'Massage Parlors',
    irs_reportable: 'Yes',
    id: 881,
  },
  7298: {
    edited_description: 'Health and Beauty Shops',
    combined_description: 'Health and Beauty Shops',
    usda_description: 'Health and Beauty Shops',
    irs_description: 'Health and Beauty Spas',
    irs_reportable: 'Yes',
    id: 882,
  },
  7299: {
    edited_description:
      'Miscellaneous Personal Services ( not elsewhere classifies)',
    combined_description:
      'Miscellaneous Personal Services ( not elsewhere classifies)',
    usda_description:
      'Miscellaneous Personal Services ( not elsewhere classifies)',
    irs_description: 'Miscellaneous General Services',
    irs_reportable: 'Yes',
    id: 883,
  },
  7311: {
    edited_description: 'Advertising Services',
    combined_description: 'Advertising Services',
    usda_description: 'Advertising Services',
    irs_description: 'Advertising Services',
    irs_reportable: 'Yes',
    id: 884,
  },
  7321: {
    edited_description: 'Consumer Credit Reporting Agencies',
    combined_description: 'Consumer Credit Reporting Agencies',
    usda_description: 'Consumer Credit Reporting Agencies',
    irs_description: 'Credit Reporting Agencies',
    irs_reportable: 'Yes',
    id: 885,
  },
  7332: {
    edited_description: 'Blueprinting and Photocopying Services',
    combined_description: 'Blueprinting and Photocopying Services',
    usda_description: 'Blueprinting and Photocopying Services',
    irs_description: '',
    irs_reportable: '',
    id: 886,
  },
  7333: {
    edited_description: 'Commercial Photography, Art and Graphics',
    combined_description: 'Commercial Photography, Art and Graphics',
    usda_description: 'Commercial Photography, Art and Graphics',
    irs_description: 'Commercial Photography, Art and Graphics',
    irs_reportable: 'Yes',
    id: 887,
  },
  7338: {
    edited_description: 'Quick Copy, Reproduction and Blueprinting Services',
    combined_description: 'Quick Copy, Reproduction and Blueprinting Services',
    usda_description: 'Quick Copy, Reproduction and Blueprinting Services',
    irs_description: 'Quick Copy, Repro, and Blueprint',
    irs_reportable: 'Yes',
    id: 888,
  },
  7339: {
    edited_description: 'Stenographic and Secretarial Support Services',
    combined_description: 'Stenographic and Secretarial Support Services',
    usda_description: 'Stenographic and Secretarial Support Services',
    irs_description: 'Secretarial Support Services',
    irs_reportable: 'Yes',
    id: 889,
  },
  7342: {
    edited_description: 'Exterminating and Disinfecting Services',
    combined_description: 'Exterminating and Disinfecting Services',
    usda_description: 'Exterminating and Disinfecting Services',
    irs_description: 'Exterminating Services',
    irs_reportable: 'Yes',
    id: 890,
  },
  7349: {
    edited_description: 'Cleaning and Maintenance, Janitorial Services',
    combined_description: 'Cleaning and Maintenance, Janitorial Services',
    usda_description: 'Cleaning and Maintenance, Janitorial Services',
    irs_description: 'Cleaning and Maintenance',
    irs_reportable: 'Yes',
    id: 891,
  },
  7361: {
    edited_description: 'Employment Agencies, Temporary Help Services',
    combined_description: 'Employment Agencies, Temporary Help Services',
    usda_description: 'Employment Agencies, Temporary Help Services',
    irs_description: 'Employment/Temp Agencies',
    irs_reportable: 'Yes',
    id: 892,
  },
  7372: {
    edited_description:
      'Computer Programming, Integrated Systems Design and Data Processing Services',
    combined_description:
      'Computer Programming, Integrated Systems Design and Data Processing Services',
    usda_description:
      'Computer Programming, Integrated Systems Design and Data Processing Services',
    irs_description: 'Computer Programming',
    irs_reportable: 'Yes',
    id: 893,
  },
  7375: {
    edited_description: 'Information Retrieval Services',
    combined_description: 'Information Retrieval Services',
    usda_description: 'Information Retrieval Services',
    irs_description: 'Information Retrieval Services',
    irs_reportable: 'Yes',
    id: 894,
  },
  7379: {
    edited_description:
      'Computer Maintenance and Repair Services, Not Elsewhere Classified',
    combined_description:
      'Computer Maintenance and Repair Services, Not Elsewhere Classified',
    usda_description:
      'Computer Maintenance and Repair Services, Not Elsewhere Classified',
    irs_description: 'Computer Repair',
    irs_reportable: 'Yes',
    id: 895,
  },
  7392: {
    edited_description: 'Management, Consulting, and Public Relations Services',
    combined_description:
      'Management, Consulting, and Public Relations Services',
    usda_description: 'Management, Consulting, and Public Relations Services',
    irs_description: 'Consulting, Public Relations',
    irs_reportable: 'Yes',
    id: 896,
  },
  7393: {
    edited_description:
      'Protective and Security Services \u2013 Including Armored Carsand Guard Dogs',
    combined_description:
      'Protective and Security Services \u2013 Including Armored Carsand Guard Dogs',
    usda_description:
      'Protective and Security Services \u2013 Including Armored Carsand Guard Dogs',
    irs_description: 'Detective Agencies',
    irs_reportable: 'Yes',
    id: 897,
  },
  7394: {
    edited_description:
      'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
    combined_description:
      'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
    usda_description:
      'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
    irs_description: 'Equipment Rental ',
    irs_reportable: 'Yes',
    id: 898,
  },
  7395: {
    edited_description: 'Photofinishing Laboratories, Photo Developing',
    combined_description: 'Photofinishing Laboratories, Photo Developing',
    usda_description: 'Photofinishing Laboratories, Photo Developing',
    irs_description: 'Photo Developing',
    irs_reportable: 'Yes',
    id: 899,
  },
  7399: {
    edited_description: 'Business Services, Not Elsewhere Classified',
    combined_description: 'Business Services, Not Elsewhere Classified',
    usda_description: 'Business Services, Not Elsewhere Classified',
    irs_description: 'Miscellaneous Business Services ',
    irs_reportable: 'Yes',
    id: 900,
  },
  7511: {
    edited_description: 'Truck Stop',
    combined_description: 'Truck Stop',
    usda_description: '',
    irs_description: 'Truck Stop',
    irs_reportable: 'Yes',
    id: 901,
  },
  7512: {
    edited_description: 'Car Rental Companies ( Not Listed Below)',
    combined_description: 'Car Rental Companies ( Not Listed Below)',
    usda_description: 'Car Rental Companies ( Not Listed Below)',
    irs_description: 'Car Rental Agencies',
    irs_reportable: 'Yes',
    id: 902,
  },
  7513: {
    edited_description: 'Truck and Utility Trailer Rentals',
    combined_description: 'Truck and Utility Trailer Rentals',
    usda_description: 'Truck and Utility Trailer Rentals',
    irs_description: 'Truck/Utility Trailer Rentals',
    irs_reportable: 'Yes',
    id: 903,
  },
  7519: {
    edited_description: 'Motor Home and Recreational Vehicle Rentals',
    combined_description: 'Motor Home and Recreational Vehicle Rentals',
    usda_description: 'Motor Home and Recreational Vehicle Rentals',
    irs_description: 'Recreational Vehicle Rentals',
    irs_reportable: 'Yes',
    id: 904,
  },
  7523: {
    edited_description: 'Automobile Parking Lots and Garages',
    combined_description: 'Automobile Parking Lots and Garages',
    usda_description: 'Automobile Parking Lots and Garages',
    irs_description: 'Parking Lots, Garages',
    irs_reportable: 'Yes',
    id: 905,
  },
  7531: {
    edited_description: 'Automotive Body Repair Shops',
    combined_description: 'Automotive Body Repair Shops',
    usda_description: 'Automotive Body Repair Shops',
    irs_description: 'Auto Body Repair Shops',
    irs_reportable: 'Yes',
    id: 906,
  },
  7534: {
    edited_description: 'Tire Re-treading and Repair Shops',
    combined_description: 'Tire Re-treading and Repair Shops',
    usda_description: 'Tire Re-treading and Repair Shops',
    irs_description: 'Tire Retreading and Repair',
    irs_reportable: 'Yes',
    id: 907,
  },
  7535: {
    edited_description: 'Paint Shops \u2013 Automotive',
    combined_description: 'Paint Shops \u2013 Automotive',
    usda_description: 'Paint Shops \u2013 Automotive',
    irs_description: 'Auto Paint Shops',
    irs_reportable: 'Yes',
    id: 908,
  },
  7538: {
    edited_description: 'Automotive Service Shops',
    combined_description: 'Automotive Service Shops',
    usda_description: 'Automotive Service Shops',
    irs_description: 'Auto Service Shops',
    irs_reportable: 'Yes',
    id: 909,
  },
  7542: {
    edited_description: 'Car Washes',
    combined_description: 'Car Washes',
    usda_description: 'Car Washes',
    irs_description: 'Car Washes',
    irs_reportable: 'Yes',
    id: 910,
  },
  7549: {
    edited_description: 'Towing Services',
    combined_description: 'Towing Services',
    usda_description: 'Towing Services',
    irs_description: 'Towing Services',
    irs_reportable: 'Yes',
    id: 911,
  },
  7622: {
    edited_description: 'Radio Repair Shops',
    combined_description: 'Radio Repair Shops',
    usda_description: 'Radio Repair Shops',
    irs_description: 'Electronics Repair Shops',
    irs_reportable: 'Yes',
    id: 912,
  },
  7623: {
    edited_description: 'Air Conditioning and Refrigeration Repair Shops',
    combined_description: 'Air Conditioning and Refrigeration Repair Shops',
    usda_description: 'Air Conditioning and Refrigeration Repair Shops',
    irs_description: 'A/C, Refrigeration Repair',
    irs_reportable: 'Yes',
    id: 913,
  },
  7629: {
    edited_description: 'Electrical And Small Appliance Repair Shops',
    combined_description: 'Electrical And Small Appliance Repair Shops',
    usda_description: 'Electrical And Small Appliance Repair Shops',
    irs_description: 'Small Appliance Repair',
    irs_reportable: 'Yes',
    id: 914,
  },
  7631: {
    edited_description: 'Watch, Clock, and Jewelry Repair',
    combined_description: 'Watch, Clock, and Jewelry Repair',
    usda_description: 'Watch, Clock, and Jewelry Repair',
    irs_description: 'Watch/Jewelry Repair',
    irs_reportable: 'Yes',
    id: 915,
  },
  7641: {
    edited_description:
      'Furniture, Furniture Repair, and Furniture Refinishing',
    combined_description:
      'Furniture, Furniture Repair, and Furniture Refinishing',
    usda_description: 'Furniture, Furniture Repair, and Furniture Refinishing',
    irs_description: 'Furniture Repair, Refinishing',
    irs_reportable: 'Yes',
    id: 916,
  },
  7692: {
    edited_description: 'Welding Repair',
    combined_description: 'Welding Repair',
    usda_description: 'Welding Repair',
    irs_description: 'Welding Repair',
    irs_reportable: 'Yes',
    id: 917,
  },
  7699: {
    edited_description: 'Repair Shops and Related Services \u2013Miscellaneous',
    combined_description:
      'Repair Shops and Related Services \u2013Miscellaneous',
    usda_description: 'Repair Shops and Related Services \u2013Miscellaneous',
    irs_description: 'Miscellaneous Repair Shops',
    irs_reportable: 'Yes',
    id: 918,
  },
  7800: {
    edited_description: 'Government-Owned Lotteries',
    combined_description: '',
    usda_description: '',
    irs_description: '',
    irs_reportable: '',
    id: 919,
  },
  7801: {
    edited_description:
      'Government-Licensed On-Line Casinos (On-Line Gambling)',
    combined_description: '',
    usda_description: '',
    irs_description: '',
    irs_reportable: '',
    id: 920,
  },
  7802: {
    edited_description: 'Government-Licensed Horse/Dog Racing',
    combined_description: '',
    usda_description: '',
    irs_description: '',
    irs_reportable: '',
    id: 921,
  },
  7829: {
    edited_description:
      'Motion Pictures and Video Tape Production and Distribution',
    combined_description:
      'Motion Pictures and Video Tape Production and Distribution',
    usda_description:
      'Motion Pictures and Video Tape Production and Distribution',
    irs_description: 'Picture/Video Production',
    irs_reportable: 'Yes',
    id: 922,
  },
  7832: {
    edited_description: 'Motion Picture Theaters',
    combined_description: 'Motion Picture Theaters',
    usda_description: 'Motion Picture Theaters',
    irs_description: 'Motion Picture Theaters',
    irs_reportable: 'Yes',
    id: 923,
  },
  7841: {
    edited_description: 'Video Tape Rental Stores',
    combined_description: 'Video Tape Rental Stores',
    usda_description: 'Video Tape Rental Stores',
    irs_description: 'Video Tape Rental Stores',
    irs_reportable: 'Yes',
    id: 924,
  },
  7911: {
    edited_description: 'Dance Halls, Studios and Schools',
    combined_description: 'Dance Halls, Studios and Schools',
    usda_description: 'Dance Halls, Studios and Schools',
    irs_description: 'Dance Hall, Studios, Schools',
    irs_reportable: 'Yes',
    id: 925,
  },
  7922: {
    edited_description:
      'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
    combined_description:
      'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
    usda_description:
      'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
    irs_description: 'Theatrical Ticket Agencies',
    irs_reportable: 'Yes',
    id: 926,
  },
  7929: {
    edited_description:
      'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
    combined_description:
      'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
    usda_description:
      'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
    irs_description: 'Bands, Orchestras',
    irs_reportable: 'Yes',
    id: 927,
  },
  7932: {
    edited_description: 'Billiard and Pool Establishments',
    combined_description: 'Billiard and Pool Establishments',
    usda_description: 'Billiard and Pool Establishments',
    irs_description: 'Billiard/Pool Establishments',
    irs_reportable: 'Yes',
    id: 928,
  },
  7933: {
    edited_description: 'Bowling Alleys',
    combined_description: 'Bowling Alleys',
    usda_description: 'Bowling Alleys',
    irs_description: 'Bowling Alleys',
    irs_reportable: 'Yes',
    id: 929,
  },
  7941: {
    edited_description:
      'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
    combined_description:
      'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
    usda_description:
      'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
    irs_description: 'Sports Clubs/Fields',
    irs_reportable: 'Yes',
    id: 930,
  },
  7991: {
    edited_description: 'Tourist Attractions and Exhibits',
    combined_description: 'Tourist Attractions and Exhibits',
    usda_description: 'Tourist Attractions and Exhibits',
    irs_description: 'Tourist Attractions and Exhibits',
    irs_reportable: 'Yes',
    id: 931,
  },
  7992: {
    edited_description: 'Golf Courses \u2013 Public',
    combined_description: 'Golf Courses \u2013 Public',
    usda_description: 'Golf Courses \u2013 Public',
    irs_description: 'Golf Courses - Public',
    irs_reportable: 'Yes',
    id: 932,
  },
  7993: {
    edited_description: 'Video Amusement Game Supplies',
    combined_description: 'Video Amusement Game Supplies',
    usda_description: 'Video Amusement Game Supplies',
    irs_description: 'Video Amusement Game Supplies',
    irs_reportable: 'No1.6041-3(c)',
    id: 933,
  },
  7994: {
    edited_description: 'Video Game Arcades/Establishments',
    combined_description: 'Video Game Arcades/Establishments',
    usda_description: 'Video Game Arcades/Establishments',
    irs_description: 'Video Game Arcades',
    irs_reportable: 'Yes',
    id: 934,
  },
  7995: {
    edited_description:
      'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
    combined_description:
      'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
    usda_description:
      'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
    irs_description: 'Betting/Casino Gambling',
    irs_reportable: 'Yes',
    id: 935,
  },
  7996: {
    edited_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
    combined_description:
      'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
    usda_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
    irs_description: 'Amusement Parks/Carnivals',
    irs_reportable: 'Yes',
    id: 936,
  },
  7997: {
    edited_description:
      'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
    combined_description:
      'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
    usda_description:
      'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
    irs_description: 'Country Clubs',
    irs_reportable: 'Yes',
    id: 937,
  },
  7998: {
    edited_description: 'Aquariums, Sea-aquariums, Dolphinariums',
    combined_description: 'Aquariums, Sea-aquariums, Dolphinariums',
    usda_description: 'Aquariums, Sea-aquariums, Dolphinariums',
    irs_description: 'Aquariums',
    irs_reportable: 'Yes',
    id: 938,
  },
  7999: {
    edited_description: 'Recreation Services (Not Elsewhere Classified)',
    combined_description: 'Recreation Services (Not Elsewhere Classified)',
    usda_description: 'Recreation Services (Not Elsewhere Classified)',
    irs_description: 'Miscellaneous Recreation Services',
    irs_reportable: 'Yes',
    id: 939,
  },
  8011: {
    edited_description: 'Doctors and Physicians (Not Elsewhere Classified)',
    combined_description: 'Doctors and Physicians (Not Elsewhere Classified)',
    usda_description: 'Doctors and Physicians (Not Elsewhere Classified)',
    irs_description: 'Doctors',
    irs_reportable: 'Yes',
    id: 940,
  },
  8021: {
    edited_description: 'Dentists and Orthodontists',
    combined_description: 'Dentists and Orthodontists',
    usda_description: 'Dentists and Orthodontists',
    irs_description: 'Dentists, Orthodontists',
    irs_reportable: 'Yes',
    id: 941,
  },
  8031: {
    edited_description: 'Osteopaths',
    combined_description: 'Osteopaths',
    usda_description: 'Osteopaths',
    irs_description: 'Osteopaths',
    irs_reportable: 'Yes',
    id: 942,
  },
  8041: {
    edited_description: 'Chiropractors',
    combined_description: 'Chiropractors',
    usda_description: 'Chiropractors',
    irs_description: 'Chiropractors',
    irs_reportable: 'Yes',
    id: 943,
  },
  8042: {
    edited_description: 'Optometrists and Ophthalmologists',
    combined_description: 'Optometrists and Ophthalmologists',
    usda_description: 'Optometrists and Ophthalmologists',
    irs_description: 'Optometrists, Ophthalmologist',
    irs_reportable: 'Yes',
    id: 944,
  },
  8043: {
    edited_description: 'Opticians, Opticians Goods and Eyeglasses',
    combined_description: 'Opticians, Opticians Goods and Eyeglasses',
    usda_description: 'Opticians, Opticians Goods and Eyeglasses',
    irs_description: 'Opticians, Eyeglasses',
    irs_reportable: 'Yes',
    id: 945,
  },
  8044: {
    edited_description:
      'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
    combined_description:
      'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
    usda_description:
      'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
    irs_description: '',
    irs_reportable: '',
    id: 946,
  },
  8049: {
    edited_description: 'Podiatrists and Chiropodists',
    combined_description: 'Podiatrists and Chiropodists',
    usda_description: 'Podiatrists and Chiropodists',
    irs_description: 'Chiropodists, Podiatrists',
    irs_reportable: 'Yes',
    id: 947,
  },
  8050: {
    edited_description: 'Nursing and Personal Care Facilities',
    combined_description: 'Nursing and Personal Care Facilities',
    usda_description: 'Nursing and Personal Care Facilities',
    irs_description: 'Nursing/Personal Care ',
    irs_reportable: 'Yes',
    id: 948,
  },
  8062: {
    edited_description: 'Hospitals',
    combined_description: 'Hospitals',
    usda_description: 'Hospitals',
    irs_description: 'Hospitals',
    irs_reportable: 'Yes',
    id: 949,
  },
  8071: {
    edited_description: 'Medical and Dental Laboratories',
    combined_description: 'Medical and Dental Laboratories',
    usda_description: 'Medical and Dental Laboratories',
    irs_description: 'Medical and Dental Labs',
    irs_reportable: 'Yes',
    id: 950,
  },
  8099: {
    edited_description:
      'Medical Services and Health Practitioners (Not Elsewhere Classified)',
    combined_description:
      'Medical Services and Health Practitioners (Not Elsewhere Classified)',
    usda_description:
      'Medical Services and Health Practitioners (Not Elsewhere Classified)',
    irs_description: 'Medical Services ',
    irs_reportable: 'Yes',
    id: 951,
  },
  8111: {
    edited_description: 'Legal Services and Attorneys',
    combined_description: 'Legal Services and Attorneys',
    usda_description: 'Legal Services and Attorneys',
    irs_description: 'Legal Services, Attorneys',
    irs_reportable: 'Yes',
    id: 952,
  },
  8211: {
    edited_description: 'Elementary and Secondary Schools',
    combined_description: 'Elementary and Secondary Schools',
    usda_description: 'Elementary and Secondary Schools',
    irs_description: 'Elementary, Secondary Schools',
    irs_reportable: 'No1.6041-3(p)(2)',
    id: 953,
  },
  8220: {
    edited_description:
      'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
    combined_description:
      'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
    usda_description:
      'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
    irs_description: 'Colleges, Universities',
    irs_reportable: 'No1.6041-3(p)(2)',
    id: 954,
  },
  8241: {
    edited_description: 'Correspondence Schools',
    combined_description: 'Correspondence Schools',
    usda_description: 'Correspondence Schools',
    irs_description: 'Correspondence Schools',
    irs_reportable: 'No1.6041-3(p)(2)',
    id: 955,
  },
  8244: {
    edited_description: 'Business and Secretarial Schools',
    combined_description: 'Business and Secretarial Schools',
    usda_description: 'Business and Secretarial Schools',
    irs_description: 'Business/Secretarial Schools',
    irs_reportable: 'No1.6041-3(p)(2)',
    id: 956,
  },
  8249: {
    edited_description: 'Vocational Schools and Trade Schools',
    combined_description: 'Vocational Schools and Trade Schools',
    usda_description: 'Vocational Schools and Trade Schools',
    irs_description: 'Vocational/Trade Schools',
    irs_reportable: 'No1.6041-3(p)(2)',
    id: 957,
  },
  8299: {
    edited_description:
      'Schools and Educational Services ( Not Elsewhere Classified)',
    combined_description:
      'Schools and Educational Services ( Not Elsewhere Classified)',
    usda_description:
      'Schools and Educational Services ( Not Elsewhere Classified)',
    irs_description: 'Educational Services ',
    irs_reportable: 'Yes',
    id: 958,
  },
  8351: {
    edited_description: 'Child Care Services',
    combined_description: 'Child Care Services',
    usda_description: 'Child Care Services',
    irs_description: 'Child Care Services',
    irs_reportable: 'Yes',
    id: 959,
  },
  8398: {
    edited_description: 'Charitable and Social Service Organizations',
    combined_description: 'Charitable and Social Service Organizations',
    usda_description: 'Charitable and Social Service Organizations',
    irs_description:
      'Charitable and Social Service Organizations - Fundraising',
    irs_reportable: 'No1.6041-3(p)(2)',
    id: 960,
  },
  8641: {
    edited_description: 'Civic, Fraternal, and Social Associations',
    combined_description: 'Civic, Fraternal, and Social Associations',
    usda_description: 'Civic, Fraternal, and Social Associations',
    irs_description: 'Civic, Social, Fraternal Associations',
    irs_reportable: 'No1.6041-3(p)(2)',
    id: 961,
  },
  8651: {
    edited_description: 'Political Organizations',
    combined_description: 'Political Organizations',
    usda_description: 'Political Organizations',
    irs_description: 'Political Organizations',
    irs_reportable: 'Yes',
    id: 962,
  },
  8661: {
    edited_description: 'Religious Organizations',
    combined_description: 'Religious Organizations',
    usda_description: 'Religious Organizations',
    irs_description: 'Religious Organizations',
    irs_reportable: 'No1.6041-3(p)(2)',
    id: 963,
  },
  8675: {
    edited_description: 'Automobile Associations',
    combined_description: 'Automobile Associations',
    usda_description: 'Automobile Associations',
    irs_description: 'Automobile Associations',
    irs_reportable: 'Yes',
    id: 964,
  },
  8699: {
    edited_description: 'Membership Organizations ( Not Elsewhere Classified)',
    combined_description:
      'Membership Organizations ( Not Elsewhere Classified)',
    usda_description: 'Membership Organizations ( Not Elsewhere Classified)',
    irs_description: 'Membership Organizations',
    irs_reportable: 'Yes',
    id: 965,
  },
  8734: {
    edited_description: 'Testing Laboratories ( non-medical)',
    combined_description: 'Testing Laboratories ( non-medical)',
    usda_description: 'Testing Laboratories ( non-medical)',
    irs_description: 'Testing Laboratories',
    irs_reportable: 'Yes',
    id: 966,
  },
  8911: {
    edited_description:
      'Architectural \u2013 Engineering and Surveying Services',
    combined_description:
      'Architectural \u2013 Engineering and Surveying Services',
    usda_description: 'Architectural \u2013 Engineering and Surveying Services',
    irs_description: 'Architectural/Surveying Services',
    irs_reportable: 'Yes',
    id: 967,
  },
  8931: {
    edited_description: 'Accounting, Auditing, and Bookkeeping Services',
    combined_description: 'Accounting, Auditing, and Bookkeeping Services',
    usda_description: 'Accounting, Auditing, and Bookkeeping Services',
    irs_description: 'Accounting/Bookkeeping Services',
    irs_reportable: 'Yes',
    id: 968,
  },
  8999: {
    edited_description: 'Professional Services ( Not Elsewhere Defined)',
    combined_description: 'Professional Services ( Not Elsewhere Defined)',
    usda_description: 'Professional Services ( Not Elsewhere Defined)',
    irs_description: 'Professional Services',
    irs_reportable: 'Yes',
    id: 969,
  },
  9211: {
    edited_description: 'Court Costs, including Alimony and Child Support',
    combined_description: 'Court Costs, including Alimony and Child Support',
    usda_description: 'Court Costs, including Alimony and Child Support',
    irs_description:
      'Court Costs, Including Alimony and Child Support - Courts of Law',
    irs_reportable: 'No1.6041-3(p)(4)',
    id: 970,
  },
  9222: {
    edited_description: 'Fines',
    combined_description: 'Fines',
    usda_description: 'Fines',
    irs_description: 'Fines - Government Administrative Entities',
    irs_reportable: 'No1.6041-3(p)(4)',
    id: 971,
  },
  9223: {
    edited_description: 'Bail and Bond Payments',
    combined_description: 'Bail and Bond Payments',
    usda_description: 'Bail and Bond Payments',
    irs_description:
      'Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency) ',
    irs_reportable: 'Yes',
    id: 972,
  },
  9311: {
    edited_description: 'Tax Payments',
    combined_description: 'Tax Payments',
    usda_description: 'Tax Payments',
    irs_description: 'Tax Payments - Government Agencies',
    irs_reportable: 'No1.6041-3(p)(4)',
    id: 973,
  },
  9399: {
    edited_description: 'Government Services ( Not Elsewhere Classified)',
    combined_description: 'Government Services ( Not Elsewhere Classified)',
    usda_description: 'Government Services ( Not Elsewhere Classified)',
    irs_description: 'Government Services (Not Elsewhere Classified)',
    irs_reportable: 'No1.6041-3(p)(4)',
    id: 974,
  },
  9402: {
    edited_description: 'Postal Services \u2013 Government Only',
    combined_description: 'Postal Services \u2013 Government Only',
    usda_description: 'Postal Services \u2013 Government Only',
    irs_description: 'Postal Services - Government Only',
    irs_reportable: 'No1.6041-3(p)(3)',
    id: 975,
  },
  9405: {
    edited_description: 'Intra \u2013 Government Transactions',
    combined_description: 'Intra \u2013 Government Transactions',
    usda_description: 'Intra \u2013 Government Transactions',
    irs_description: 'U.S. Federal Government Agencies or Departments',
    irs_reportable: 'No1.6041-3(p)(3)',
    id: 976,
  },
  9700: {
    edited_description: 'Automated Referral Service ( For Visa Only)',
    combined_description: 'Automated Referral Service ( For Visa Only)',
    usda_description: 'Automated Referral Service ( For Visa Only)',
    irs_description: '',
    irs_reportable: '',
    id: 977,
  },
  9701: {
    edited_description: 'Visa Credential Service ( For Visa Only)',
    combined_description: 'Visa Credential Service ( For Visa Only)',
    usda_description: 'Visa Credential Service ( For Visa Only)',
    irs_description: '',
    irs_reportable: '',
    id: 978,
  },
  9702: {
    edited_description: 'GCAS Emergency Services ( For Visa Only)',
    combined_description: 'GCAS Emergency Services ( For Visa Only)',
    usda_description: 'GCAS Emergency Services ( For Visa Only)',
    irs_description: '',
    irs_reportable: '',
    id: 979,
  },
  9950: {
    edited_description: 'Intra \u2013 Company Purchases ( For Visa Only)',
    combined_description: 'Intra \u2013 Company Purchases ( For Visa Only)',
    usda_description: 'Intra \u2013 Company Purchases ( For Visa Only)',
    irs_description: 'Intra-Company Purchases',
    irs_reportable: 'No1.6041-3(c)',
    id: 980,
  },
});

export default MerchantCategoryCode;
