import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import { fetchUserProfile } from 'state/actions/users';
import { selectFetchUserProfileState } from 'state/selectors/users';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import { createAudit, editAudit } from 'state/actions/audits';
import {
  selectCreateAuditState,
  selectEditAuditState,
} from 'state/selectors/audits';
import removeEmptyFieldsFromObject from 'utils/removeEmptyFieldsFromObject';
import classes from './AuditForm.module.scss';
import { createAuditSchema, editAuditSchema } from './AuditForm.schema';
import { getEditingFields, getInputFields } from './formFields';

const AuditForm = ({ title, subtitle, audit, isCreating, onCancel }) => {
  const dispatch = useDispatch();

  const { loading: editingAudit } = useSelector(
    selectEditAuditState,
    shallowEqual
  );

  const { userProfile } = useSelector(
    selectFetchUserProfileState,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const { loading: creatingAudit } = useSelector(
    selectCreateAuditState,
    shallowEqual
  );

  const [isEditing, setIsEditing] = useState(false);
  const [internalDispute, setInternalDispute] = useState(false);
  const [validDispute, setValidDispute] = useState(false);

  useEffect(() => {
    if (audit) {
      if (audit.internalDispute) {
        setInternalDispute(audit.internalDispute);
      }

      if (audit.validDispute) {
        setValidDispute(audit.validDispute);
      }
    }
  }, [audit]);

  const readOnly = useMemo(() => !isEditing && !isCreating, [
    isEditing,
    isCreating,
  ]);

  const onEditHandler = useCallback(() => {
    setTimeout(() => setIsEditing(true), 1);
  }, []);

  const onToggleHandler = useCallback(
    (setValue) => {
      if (!readOnly) {
        setValue((prevState) => !prevState);
      }
    },
    [readOnly]
  );

  const onSubmitHandler = useCallback(
    (values) => {
      const { auditId: _id, issuer } = audit;

      const valuesWithData = removeEmptyFieldsFromObject(values);

      const { transactionDate } = valuesWithData;

      const body = {
        ...valuesWithData,
        ...(transactionDate
          ? { transactionDate: new Date(transactionDate).toISOString() }
          : {}),
        ...(_id && { _id }),
        ...(issuer && { issuer }),
        ...(!isCreating && { internalDispute, validDispute }),
      };

      if (isCreating) {
        dispatch(createAudit(body));
      } else {
        dispatch(editAudit(audit.auditId, body));
      }
    },
    [audit, dispatch, internalDispute, validDispute, isCreating]
  );

  const isUserIssuer = userProfile?.roles.includes('reporter.issuer');

  const formFields = getInputFields({ isCreating, isEditing, readOnly, audit });

  const editingFields = getEditingFields({
    audit,
    internalDispute,
    isEditing,
    readOnly,
    validDispute,
    onSetInternalDisputeHandler: () => onToggleHandler(setInternalDispute),
    onSetValidDispute: () => onToggleHandler(setValidDispute),
  });

  return (
    <>
      <Form
        onSubmit={onSubmitHandler}
        validationSchema={isCreating ? createAuditSchema : editAuditSchema}
        validationMode={ValidationMode.OnChange}
        className={classes.form}
      >
        <Heading
          size={HeadingSize.M}
          className={classNames({ [classes.heading]: !!subtitle })}
        >
          {title}
        </Heading>
        {subtitle && (
          <Body
            size={BodySize.M}
            className={classes.subtitle}
            color={BodyColor.Gray}
          >
            {subtitle}
          </Body>
        )}
        <div>
          {!isCreating && (
            <FormControl
              name="auditId"
              render={(props) => (
                <Input
                  label="Audit ID:"
                  labelClassName={classes.labelWidth}
                  value={audit.auditId}
                  type={InputType.Text}
                  readOnly={readOnly}
                  size={InputSize.S}
                  disabled={isEditing}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
          )}
          <FormControl
            name="issuer"
            render={(props) => (
              <Input
                label={`Issuer ${isCreating ? '*' : ''}`}
                labelClassName={classes.labelWidth}
                value={isUserIssuer ? userProfile.issuerName : audit.issuer}
                className={
                  isUserIssuer ? classes.disabledInput : classes.enabledInput
                }
                type={InputType.Text}
                readOnly={readOnly || isUserIssuer}
                size={InputSize.S}
                disabled={isEditing}
                color={isEditing ? InputColor.Black : InputColor.Gray}
                {...props}
              />
            )}
          />
          {formFields.map(
            ({ name, component: Component, renderProps, ...otherProps }) => (
              <React.Fragment key={name}>
                {renderProps ? (
                  <FormControl
                    name={name}
                    render={(props) => (
                      <Input value={audit[name]} {...renderProps} {...props} />
                    )}
                  />
                ) : (
                  <Component name={name} {...otherProps} />
                )}
              </React.Fragment>
            )
          )}
          {!isCreating &&
            editingFields.map(
              ({ name, component: Component, renderProps, ...otherProps }) => (
                <React.Fragment key={name}>
                  {renderProps ? (
                    <FormControl
                      name={name}
                      render={(props) => (
                        <Component {...renderProps} {...props} />
                      )}
                    />
                  ) : (
                    <Component name={name} {...otherProps} />
                  )}
                </React.Fragment>
              )
            )}
        </div>
        <div className={classes.actionButtons}>
          {readOnly ? (
            <>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                kind={ButtonKind.Secondary}
                onClick={onCancel}
                className={classes.back}
              >
                Back
              </Button>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                className={classes.button}
                onClick={onEditHandler}
              >
                Edit
              </Button>
            </>
          ) : (
            <>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                onClick={onCancel}
                kind={ButtonKind.Secondary}
              >
                Cancel
              </Button>
              <Button
                type={ButtonType.Submit}
                size={ButtonSize.S}
                className={classes.submitButton}
                loading={editingAudit || creatingAudit}
              >
                {isCreating ? 'Create' : 'Save'}
              </Button>
            </>
          )}
        </div>
      </Form>
    </>
  );
};

AuditForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  audit: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
};

AuditForm.defaultProps = {
  audit: {},
  subtitle: null,
  isCreating: false,
  onCancel: () => {},
};

export default AuditForm;
