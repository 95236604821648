import client from 'services/kardAPI';

const reprocessPendingTransaction = async (pendingTransactionId) => {
  try {
    const { data } = await client.get(
      `transactions/portal/pending/groupby/${pendingTransactionId}`
    );
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default reprocessPendingTransaction;
