import client from 'services/kardAPI';

const fetchAudiences = async (filters = '') => {
  try {
    const { data } = await client.get(`/audience/portal/audience${filters}`);

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export default fetchAudiences;
