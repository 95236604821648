/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';
import Body, { Color as BodyColor } from 'components/Typography/Body';
import ErrorMessage from 'components/Typography/ErrorMessage';
import DatePicker from 'components/Common/DatePicker';

import classes from './FormControlDatePicker.module.scss';

export const LabelPosition = Object.freeze({
  Top: 'top',
  Left: 'left',
});

export const Color = Object.freeze({
  Black: 'black',
  Gray: 'gray',
});

const FormControlDatePicker = ({
  name,
  label,
  labelClassName,
  labelPosition,
  placeholder,
  defaultValue,
  className,
  disabled,
  readOnly,
  color,
  showTimeSelect,
  onChangeManual,
}) => {
  const { errors, control, setValue: setFormValue } = useFormContext();
  const error = errors[name];

  useEffect(() => {
    if (defaultValue) {
      setFormValue(name, defaultValue);
    }
  }, [setFormValue, name, defaultValue]);

  return (
    <div
      className={classNames(
        className,
        classes.container,
        classes[labelPosition],
        {
          [classes.error]: !!error,
        }
      )}
    >
      {label && (
        <Body
          color={BodyColor.Gray}
          className={classNames(labelClassName, classes.label)}
        >
          {label}
        </Body>
      )}
      <div
        className={classNames(classes.datePicker, {
          [classes.datePickerWithLeftLabel]:
            label && labelPosition === LabelPosition.Left,
        })}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ onChange, onBlur, value, defaultValue: dateValue }) => (
            <DatePicker
              onChange={(...args) => {
                onChangeManual?.(...args);
                onChange(...args);
              }}
              onBlur={onBlur}
              date={value}
              placeholder={placeholder}
              defaultValue={defaultValue || dateValue}
              disabled={disabled}
              readOnly={readOnly}
              error={!!error}
              color={color}
              showTimeSelect={showTimeSelect}
            />
          )}
        />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </div>
  );
};

FormControlDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(Color)),
  showTimeSelect: PropTypes.bool,
  onChangeManual: PropTypes.func,
};

FormControlDatePicker.defaultProps = {
  label: null,
  placeholder: 'MM/DD/YYYY',
  labelPosition: LabelPosition.Left,
  className: '',
  labelClassName: '',
  defaultValue: null,
  disabled: false,
  readOnly: false,
  color: Color.Gray,
  showTimeSelect: false,
  onChangeManual: null,
};

export default FormControlDatePicker;
