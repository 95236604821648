import { createAction } from '@reduxjs/toolkit';

export const signInInit = createAction('[Auth] Sign In Init');
export const signInSuccess = createAction('[Auth] Sign In Success');
export const signInFail = createAction('[Auth] Sign In Fail');

export const passwordChangeRequired = createAction(
  '[Auth] Password Change Required'
);

export const passwordChangeInit = createAction('[Auth] Password Change Init');
export const passwordChangeSuccess = createAction(
  '[Auth] Password Change Success'
);
export const passwordChangeFail = createAction('[Auth] Password Change Fail');

export const resumeSessionInit = createAction('[Auth] Resume Session Init');
export const resumeSessionSuccess = createAction(
  '[Auth] Resume Session Success'
);
export const resumeSessionFail = createAction('[Auth] Resume Session Fail');

export const forgotPasswordInit = createAction('[Auth] Forgot Password Init');
export const forgotPasswordSuccess = createAction(
  '[Auth] Forgot Password Success'
);
export const forgotPasswordFail = createAction('[Auth] Forgot Password Fail');

export const forgotPasswordConfirmationInit = createAction(
  '[Auth] Forgot Password Confirmation Init'
);
export const forgotPasswordConfirmationSuccess = createAction(
  '[Auth] Forgot Password Confirmation Success'
);
export const forgotPasswordConfirmationFail = createAction(
  '[Auth] Forgot Password Confirmation Fail'
);

export const clearForgotPasswordData = createAction(
  '[Auth] Clear Forgot Password Data'
);

export const logoutInit = createAction('[Auth] Logout Init');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const logoutFail = createAction('[Auth] Logout Fail');

export const resendConfirmationCodeInit = createAction(
  '[Auth] resendConfirmationCode Init'
);
export const resendConfirmationCodeSuccess = createAction(
  '[Auth] resendConfirmationCode Success'
);
export const resendConfirmationCodeFail = createAction(
  '[Auth] resendConfirmationCode Fail'
);
