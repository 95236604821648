/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const selectAuditsState = (state) => state.audits;

export const selectFetchAuditsState = createSelector(
  selectAuditsState,
  ({
    fetchAuditsLoading,
    fetchAuditsSuccess,
    fetchAuditsError,
    audits: { results, countTotal },
  }) => ({
    loading: fetchAuditsLoading,
    success: fetchAuditsSuccess,
    error: fetchAuditsError,
    auditsData: {
      audits: results || [],
      countTotal: countTotal || 0,
    },
  })
);

export const selectFetchAuditByIdState = createSelector(
  selectAuditsState,
  ({
    fetchAuditByIdLoading,
    fetchAuditByIdSuccess,
    fetchAuditByIdError,
    audit,
  }) => ({
    loading: fetchAuditByIdLoading,
    success: fetchAuditByIdSuccess,
    error: fetchAuditByIdError,
    audit,
  })
);

export const selectEditAuditState = createSelector(
  selectAuditsState,
  ({ editAuditLoading, editAuditSuccess, editAuditError }) => ({
    loading: editAuditLoading,
    success: editAuditSuccess,
    error: editAuditError,
  })
);

export const selectCreatePublicAuditState = createSelector(
  selectAuditsState,
  ({
    createPublicAuditsLoading,
    createPublicAuditsSuccess,
    createPublicAuditsError,
    createdAudit,
  }) => ({
    loading: createPublicAuditsLoading,
    success: createPublicAuditsSuccess,
    error: createPublicAuditsError,
    createdAudit,
  })
);

export const selectCreateAuditState = createSelector(
  selectAuditsState,
  ({ createAuditLoading, createAuditSuccess, createAuditError }) => ({
    loading: createAuditLoading,
    success: createAuditSuccess,
    error: createAuditError,
  })
);
