import React, { useState } from 'react';
import { Popover as HeadlessPopover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import PropTypes from 'prop-types';

import classes from './Popover.module.scss';

const Popover = ({
  button: Button,
  children,
  widthPanel,
  heightPanel,
  widthButton,
  closePopover,
  onKeyDown,
}) => {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: document.querySelector('main') ?? document.body,
        },
      },
    ],
  });

  return (
    <HeadlessPopover
      className={classes.popover}
      style={{ width: `${widthButton}%` }}
    >
      <HeadlessPopover.Button as="div" ref={setReferenceElement}>
        <Button />
      </HeadlessPopover.Button>
      <HeadlessPopover.Panel
        ref={setPopperElement}
        style={{
          ...styles.popper,
          width: `${widthPanel}rem`,
          maxHeight: `${heightPanel}px`,
        }}
        className={classes.panel}
        {...attributes.popper}
        onKeyDown={onKeyDown}
      >
        {({ close }) => {
          if (closePopover) {
            return close();
          }
          return children;
        }}
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  );
};

Popover.propTypes = {
  button: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  widthPanel: PropTypes.number.isRequired,
  heightPanel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  widthButton: PropTypes.number.isRequired,
  closePopover: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

Popover.defaultProps = {
  closePopover: false,
  heightPanel: '',
  onKeyDown: () => {},
};

export default Popover;
