/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchPendingTransactionsInit,
  fetchPendingTransactionsSuccess,
  fetchPendingTransactionsFail,
  editPendingTransactionInit,
  editPendingTransactionSuccess,
  editPendingTransactionFail,
  reprocessIncomingTransactionInit,
  reprocessIncomingTransactionSuccess,
  reprocessIncomingTransactionFail,
  clearReprocessIncomingTransactionValues,
  reprocessPendingTransactionInit,
  reprocessPendingTransactionSuccess,
  reprocessPendingTransactionFail,
} from 'state/actionCreators/pendingTransactions';

export const initialState = {
  fetchPendingTransactionsLoading: false,
  fetchPendingTransactionsSuccess: false,
  fetchPendingTransactionsError: null,
  pendingTransactions: [],
  countTotal: 0,
  editPendingTransactionLoading: false,
  editPendingTransactionSuccess: false,
  editPendingTransactionError: null,
  reprocessIncomingTransactionLoading: false,
  reprocessIncomingTransactionSuccess: false,
  reprocessIncomingTransactionError: null,
  reprocessPendingTxns: [],
  reprocessPendingTransactionLoading: false,
  reprocessPendingTransactionSuccess: false,
  reprocessPendingTransactionFail: null,
};

const persistConfig = {
  key: 'pendingTransactions',
  storage,
  blacklist: [
    'fetchPendingTransactionsLoading',
    'fetchPendingTransactionsSuccess',
    'fetchPendingTransactionsError',
    'pendingTransactions',
    'countTotal',
    'editPendingTransactionLoading',
    'editPendingTransactionSuccess',
    'editPendingTransactionError',
    'reprocessIncomingTransactionLoading',
    'reprocessIncomingTransactionSuccess',
    'reprocessIncomingTransactionError',
    'reprocessPendingTxns',
    'reprocessPendingTransactionLoading',
    'reprocessPendingTransactionSuccess',
    'reprocessPendingTransactionFail',
  ],
};

const pendingTransactionsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [fetchPendingTransactionsInit]: (state) => {
      state.fetchPendingTransactionsLoading = true;
      state.fetchPendingTransactionsError = null;
      state.fetchPendingTransactionsSuccess = false;
      state.pendingTransactions = [];
      state.countTotal = 0;
      state.editPendingTransactionLoading = false;
      state.editPendingTransactionError = null;
      state.editPendingTransactionSuccess = false;
    },
    [fetchPendingTransactionsSuccess]: (state, action) => {
      const { pendingTransactions, countTotal } = action.payload;
      state.fetchPendingTransactionsLoading = false;
      state.fetchPendingTransactionsError = null;
      state.fetchPendingTransactionsSuccess = true;
      state.pendingTransactions = pendingTransactions;
      state.countTotal = countTotal;
    },
    [fetchPendingTransactionsFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchPendingTransactionsLoading = false;
      state.fetchPendingTransactionsError = error;
      state.fetchPendingTransactionsSuccess = false;
      state.pendingTransactions = [];
      state.countTotal = 0;
    },
    [editPendingTransactionInit]: (state) => {
      state.editPendingTransactionLoading = true;
    },
    [editPendingTransactionSuccess]: (state) => {
      state.editPendingTransactionLoading = false;
      state.editPendingTransactionError = null;
      state.editPendingTransactionSuccess = true;
    },
    [editPendingTransactionFail]: (state, action) => {
      const { error } = action.payload;
      state.editPendingTransactionLoading = false;
      state.editPendingTransactionError = error;
      state.editPendingTransactionSuccess = false;
    },
    [reprocessIncomingTransactionInit]: (state) => {
      state.reprocessIncomingTransactionLoading = true;
    },
    [reprocessIncomingTransactionSuccess]: (state) => {
      state.reprocessIncomingTransactionLoading = false;
      state.reprocessIncomingTransactionError = null;
      state.reprocessIncomingTransactionSuccess = true;
    },
    [reprocessIncomingTransactionFail]: (state, action) => {
      const { error } = action.payload;
      state.reprocessIncomingTransactionLoading = false;
      state.reprocessIncomingTransactionError = error;
      state.reprocessIncomingTransactionSuccess = false;
    },
    [clearReprocessIncomingTransactionValues]: (state) => {
      state.reprocessIncomingTransactionLoading = false;
      state.reprocessIncomingTransactionError = null;
      state.reprocessIncomingTransactionSuccess = false;
    },
    [reprocessPendingTransactionInit]: (state) => {
      state.reprocessPendingTransactionLoading = true;
      state.reprocessPendingTransactionFail = null;
      state.reprocessPendingTransactionSuccess = false;
      state.reprocessPendingTxns = [];
    },
    [reprocessPendingTransactionSuccess]: (state, action) => {
      state.reprocessPendingTransactionLoading = false;
      state.reprocessPendingTransactionFail = null;
      state.reprocessPendingTransactionSuccess = true;
      state.reprocessPendingTxns = action.payload;
    },
    [reprocessPendingTransactionFail]: (state, action) => {
      const { error } = action.payload;
      state.reprocessPendingTransactionLoading = false;
      state.reprocessPendingTransactionFail = error;
      state.reprocessPendingTransactionSuccess = false;
      state.reprocessPendingTxns = [];
    },
  })
);

export default pendingTransactionsReducer;
