/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Table from 'components/Common/Table';
import columns from 'utils/locations/merchantLocationsColumns';
import mapIcon from 'assets/icons/color/map.svg';
import searchIcon from 'assets/icons/search.svg';
import { selectFetchLocationsByMerchantIdState } from 'state/selectors/locations';
import { fetchLocationsByMerchantId } from 'state/actions/locations';

import classes from './LocationsData.module.scss';

const LocationsData = ({ merchantName, merchantId, onCancel }) => {
  const dispatch = useDispatch();

  const { loading, locations } = useSelector(
    selectFetchLocationsByMerchantIdState,
    shallowEqual
  );

  const [merchantLocations, setMerchantLocations] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(fetchLocationsByMerchantId(merchantId));
  }, []);

  useEffect(() => {
    if (locations && merchantLocations.length === 0 && searchText === '') {
      setMerchantLocations(locations);
    }
  }, [locations, merchantLocations]);

  const onSearchHandler = useCallback(
    (text) => {
      setSearchText(text);
      if (text !== '') {
        const filteredLocations = locations.filter(
          (location) =>
            location.googleId
              .toLocaleLowerCase()
              .includes(text.toLocaleLowerCase()) ||
            location.street
              .toLocaleLowerCase()
              .includes(text.toLocaleLowerCase()) ||
            location.city
              .toLocaleLowerCase()
              .includes(text.toLocaleLowerCase()) ||
            location.state
              .toLocaleLowerCase()
              .includes(text.toLocaleLowerCase()) ||
            location.zipCode.includes(text.toLocaleLowerCase())
        );
        setMerchantLocations(filteredLocations);
      } else {
        setMerchantLocations([]);
      }
    },
    [locations]
  );

  return (
    <div className={classes.container}>
      <Heading size={HeadingSize.M} className={classes.heading}>
        {merchantName}
      </Heading>
      <div className={classes.location}>
        <img src={mapIcon} alt="location" className={classes.icon} />
        <Body
          size={BodySize.S}
          color={BodyColor.Gray}
          className={classes.locationText}
        >
          Locations
        </Body>
      </div>
      <div className={classes.panelSearch}>
        <Input
          name="search"
          placeholder="Search by Google ID or Address"
          type={InputType.Text}
          icon={searchIcon}
          size={InputSize.XS}
          className={classes.search}
          onChange={(e) => onSearchHandler(e.target.value)}
        />
      </div>
      {loading ? (
        <Spinner
          color={SpinnerColor.Black}
          size={SpinnerSize.L}
          className={classes.spinner}
        />
      ) : (
        <Table
          columns={columns}
          data={merchantLocations}
          pagination={false}
          className={classes.table}
        />
      )}
      <div className={classes.actionButtons}>
        <Button
          type={ButtonType.Default}
          size={ButtonSize.S}
          className={classes.button}
          onClick={onCancel}
          kind={ButtonKind.Secondary}
        >
          Go back
        </Button>
      </div>
    </div>
  );
};

LocationsData.propTypes = {
  onCancel: PropTypes.func.isRequired,
  merchantName: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
};

export default LocationsData;
