import { createAction } from '@reduxjs/toolkit';

export const fetchLocationsInit = createAction(
  '[Locations] Fetch Locations Init'
);
export const fetchLocationsSuccess = createAction(
  '[Locations] Fetch Locations Success'
);
export const fetchLocationsFail = createAction(
  '[Locations] Fetch Locations Fail'
);

export const fetchLocationsByMerchantIdInit = createAction(
  '[Locations] Fetch Locations By Merchant Id Init'
);
export const fetchLocationsByMerchantIdSuccess = createAction(
  '[Locations] Fetch Locations By Merchant Id Success'
);
export const fetchLocationsByMerchantIdFail = createAction(
  '[Locations] Fetch Locations By Merchant Id Fail'
);
export const clearLocationsByMerchantIdData = createAction(
  '[Locations] Clear Locations By Merchant Id Data'
);

export const fetchLocationByIdInit = createAction(
  '[Locations] Fetch Location By Id Init'
);
export const fetchLocationByIdSuccess = createAction(
  '[Locations] Fetch Location By Id Success'
);
export const fetchLocationByIdFail = createAction(
  '[Locations] Fetch Location By Id Fail'
);

export const editLocationInit = createAction('[Locations] Edit Location Init');
export const editLocationSuccess = createAction(
  '[Locations] Edit Location Success'
);
export const editLocationFail = createAction('[Locations] Edit Location Fail');
export const createLocationInit = createAction(
  '[Locations] Create Location Init'
);
export const createLocationSuccess = createAction(
  '[Locations] Create Location Success'
);
export const createLocationFail = createAction(
  '[Locations] Create Location Fail'
);
export const clearLocationSuccessProp = createAction(
  '[Locations] Clear Location Success Property'
);
export const clearLocationFailProp = createAction(
  '[Locations] Clear Location Fail Property'
);
export const uploadLocationsInit = createAction(
  '[Locations] Upload Locations Init'
);
export const uploadLocationsSuccess = createAction(
  '[Locations] Upload Locations Success'
);
export const uploadLocationsFail = createAction(
  '[Locations] Upload Locations Fail'
);
export const clearUploadLocationsData = createAction(
  '[Locations] Clear Upload Locations Data'
);
export const clearEditLocationSuccessProp = createAction(
  '[Locations] Clear Edit Locations Success Prop'
);
