import client from 'services/kardAPI';

const fetchAudits = async (filters = '') => {
  try {
    const { data } = await client.get(`/transactions/portal/audit${filters}`);

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchAudits;
