import { Auth } from 'aws-amplify';

const getIdToken = async () => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken().getJwtToken();

  return idToken;
};

export default getIdToken;
