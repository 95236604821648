import PropTypes from 'prop-types';

const merchantPropTypes = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  websiteURL: PropTypes.string,
  imgUrl: PropTypes.string,
  merchantNetwork: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  source: PropTypes.string,
  category: PropTypes.string,
  nameMatchRegex: PropTypes.arrayOf(PropTypes.string),
  acceptedCards: PropTypes.arrayOf(PropTypes.string),
  qualifiedIssuer: PropTypes.arrayOf(PropTypes.string),
  offers: PropTypes.arrayOf(PropTypes.shape({})),
  specificIssuers: PropTypes.bool,
  merchantNameReviewed: PropTypes.bool,
});

export default merchantPropTypes;
