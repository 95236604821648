import { Auth } from 'aws-amplify';

import amplifyErrorMessage from 'utils/amplifyErrorMessage';

const logout = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export default logout;
