import React from 'react';
import { Toaster as ReactToast } from 'react-hot-toast';

const Toaster = () => (
  <div>
    <ReactToast />
  </div>
);

export default Toaster;
