import Status from 'enums/status/status.enum';
import { statuses } from 'utils/audiences/values';

const getStatusOptions = (audience = {}, isCreating, isEditing) =>
  statuses
    .filter(({ value }) => {
      let isFiltered =
        value !== 'IN_PROGRESS' || Status[audience?.status] === 'IN_PROGRESS';

      // on creating, hide inactive status
      if (isCreating) {
        isFiltered &&= value !== 'INACTIVE';
      }

      return isFiltered;
    })
    .map((status) => ({
      ...status,
      ...(isEditing &&
        !isCreating &&
        !['ACTIVE', 'INACTIVE'].includes(status.value) && { disabled: true }),
    }));

export default getStatusOptions;
