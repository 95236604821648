import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Badge.module.scss';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
});

export const Kind = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
});

const Badge = ({
  text,
  endIcon,
  startIcon,
  altEndIcon,
  altStartIcon,
  size,
  kind,
  onClick,
  className,
}) => (
  <div className={classNames(classes.badge, classes[kind], className)}>
    {startIcon && (
      <button type="button" className={classes.icon} onClick={onClick}>
        <img src={startIcon} alt={altStartIcon} />
      </button>
    )}
    <p className={classNames(classes.text, classes[size])}>{text}</p>
    {endIcon && (
      <button type="button" className={classes.icon} onClick={onClick}>
        <img src={endIcon} alt={altEndIcon} />
      </button>
    )}
  </div>
);

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(Size)),
  kind: PropTypes.oneOf(Object.values(Kind)),
  onClick: PropTypes.func,
  altEndIcon: PropTypes.string,
  endIcon: PropTypes.string,
  altStartIcon: PropTypes.string,
  startIcon: PropTypes.string,
  className: PropTypes.string,
};

Badge.defaultProps = {
  size: Size.Small,
  kind: Kind.Primary,
  onClick: () => {},
  className: '',
  altEndIcon: '',
  endIcon: null,
  altStartIcon: '',
  startIcon: null,
};

export default Badge;
