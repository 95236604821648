import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';
import { EMAIL_MIN_LENGTH } from 'constants/emailMinLength.constant';

const changePasswordValidationSchema = yup.object().shape({
  code: yup.string().required(Validations.RequiredCode),
  newPassword: yup
    .string()
    .min(EMAIL_MIN_LENGTH, Validations.PasswordLength)
    .required(Validations.RequiredNewPassword)
    .matches(/(?=.*[A-Z])/, Validations.PasswordRequireUpperCaseLetter)
    .matches(/(?=.*[a-z])/, Validations.PasswordRequireLowerCaseLetter)
    .matches(/(?=.*\d)/, Validations.PasswordRequireDigit)
    .matches(/(?=.*\W)/, Validations.PasswordRequireSpecialCharacter),
});

export default changePasswordValidationSchema;
