import React from 'react';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import arrowSortByIcon from 'assets/icons/arrows/arrow-sort-by.svg';
import arrowSortBySelectedIcon from 'assets/icons/arrows/arrow-sort-by-selected.svg';

import classes from './SortBy.module.scss';

const SortByReport = ({
  sortBy,
  onChangeSortByAscending,
  onChangeSortByDescending,
}) => (
  <>
    <Body size={BodySize.XS} color={BodyColor.Gray} className={classes.sortBy}>
      Sort By
    </Body>
    <button
      className={classes.sortByOptions}
      type="button"
      onClick={onChangeSortByAscending}
    >
      <Body size={BodySize.XS} className={classes.sortByOptionsText}>
        Ascending
        <img
          src={sortBy.ascending ? arrowSortBySelectedIcon : arrowSortByIcon}
          alt="ascending"
          className={classes.arrowUp}
        />
      </Body>
    </button>
    <button
      className={classes.sortByOptions}
      type="button"
      onClick={onChangeSortByDescending}
    >
      <Body size={BodySize.XS} className={classes.sortByOptionsText}>
        Descending
        <img
          src={sortBy.descending ? arrowSortBySelectedIcon : arrowSortByIcon}
          alt="descending"
          className={classes.arrowDown}
        />
      </Body>
    </button>
  </>
);

SortByReport.propTypes = {
  sortBy: PropTypes.shape({
    ascending: PropTypes.bool.isRequired,
    descending: PropTypes.bool.isRequired,
  }).isRequired,
  onChangeSortByAscending: PropTypes.func.isRequired,
  onChangeSortByDescending: PropTypes.func.isRequired,
};

export default SortByReport;
