/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

export const selectFetchIssuersState = createSelector(
  (state) => state.issuers,
  ({
    fetchIssuersLoading,
    fetchIssuersSuccess,
    fetchIssuersError,
    issuers,
  }) => ({
    loading: fetchIssuersLoading,
    success: fetchIssuersSuccess,
    error: fetchIssuersError,
    issuers,
  })
);
