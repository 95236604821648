import { Type } from 'components/Common/Input';
import {
  locationStates,
  locationTypes,
  sourceTypes,
} from 'utils/locations/values';

import classes from './LocationForm.module.scss';

export const informationFields = [
  {
    name: 'locationId',
    renderProps: {
      label: 'Location ID: ',
      type: Type.Text,
      placeholder: 'Location ID',
      labelClassName: classes.labelWidth,
      isEditable: false,
    },
    isEdit: true,
    isCreate: false,
  },
  {
    name: 'name',
    renderProps: {
      label: 'Name: ',
      type: Type.Text,
      placeholder: 'Name',
      labelClassName: classes.labelWidth,
      isEditable: true,
    },
    isEdit: true,
    isCreate: true,
  },
  {
    name: 'merchantName',
    renderProps: {
      label: 'Merchant Name: ',
      type: Type.Text,
      placeholder: 'Merchant Name',
      labelClassName: classes.labelWidth,
      isEditable: false,
    },
    isEdit: true,
    isCreate: true,
  },
  {
    name: 'offerId',
    renderProps: {
      label: 'Offer Id: ',
      type: Type.Text,
      placeholder: 'Offer Id',
      labelClassName: classes.labelWidth,
      isEditable: false,
    },
    isEdit: false,
    isCreate: true,
    display: 'brother',
  },
];

export const addressFields = [
  {
    name: 'googleId',
    renderProps: {
      label: 'Google ID: ',
      type: Type.Text,
      placeholder: 'Google ID',
      labelClassName: classes.labelWidth,
    },
    isCreate: false,
  },
  {
    name: 'source',
    label: 'Source: ',
    placeholder: 'Source',
    options: sourceTypes,
    labelClassName: classes.labelWidth,
    isCreate: true,
  },
  {
    name: 'locationType',
    label: 'Location Type: ',
    placeholder: 'Location Type',
    options: locationTypes,
    labelClassName: classes.labelWidth,
    isCreate: true,
  },
  {
    name: 'street',
    renderProps: {
      label: 'Street: ',
      type: Type.Text,
      placeholder: 'Street',
      labelClassName: classes.labelWidth,
    },
    isCreate: false,
  },
  {
    name: 'city',
    renderProps: {
      label: 'City: ',
      type: Type.Text,
      placeholder: 'City',
      labelClassName: classes.labelWidth,
    },
    isCreate: false,
  },
  {
    name: 'state',
    label: 'State/Region: ',
    placeholder: 'State/Region',
    options: locationStates,
    labelClassName: classes.labelWidth,
    isCreate: false,
  },
  {
    name: 'zipCode',
    renderProps: {
      label: 'Zip Code: ',
      type: Type.Text,
      placeholder: 'Zip Code',
      labelClassName: classes.labelWidth,
    },
    isCreate: false,
  },
  {
    name: 'latitude',
    renderProps: {
      label: 'Latitude: ',
      type: Type.Text,
      placeholder: 'Latitude',
      labelClassName: classes.labelWidth,
    },
    isCreate: false,
  },
  {
    name: 'longitude',
    renderProps: {
      label: 'Longitude: ',
      type: Type.Text,
      placeholder: 'Longitude',
      labelClassName: classes.labelWidth,
    },
    isCreate: false,
  },
  {
    name: 'phone',
    renderProps: {
      label: 'Phone Number: ',
      type: Type.Text,
      placeholder: 'Phone Number',
      labelClassName: classes.labelWidth,
    },
    isCreate: false,
  },
];
