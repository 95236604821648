import React, { useEffect } from 'react';
import Table from 'refactored/components/Common/Table';
import columns from 'refactored/utils/queryPlayground/columns';
import PropTypes from 'prop-types';

const QueryPlaygroundContent = ({ data, updateQuery }) => {
  // TODO: remove the exampleProperties once the query actually starts being used
  useEffect(() => updateQuery({ exampleProperty2: 'fruit' }), [updateQuery]);
  return <Table id="table-playground" columns={columns} data={data} />;
};

QueryPlaygroundContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  query: PropTypes.shape({}).isRequired,
  updateQuery: PropTypes.func.isRequired,
};
export default QueryPlaygroundContent;
