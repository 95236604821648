/* eslint-disable no-param-reassign */
import axios from 'axios';
import { Auth } from 'aws-amplify';

const client = axios.create({
  baseURL: process.env.REACT_APP_KARD_BASE_API,
});

client.interceptors.request.use(
  async (config) => {
    const auth = await Auth.currentSession();

    if (auth) {
      config.headers.Authorization = auth.idToken.jwtToken;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default client;
