import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';

// see optimization guide here: https://www.botsplash.com/post/optimize-your-react-select-component-to-smoothly-render-10k-data

const height = 30;

const MenuList = ({ children, maxHeight }) => (
  <List height={maxHeight} itemCount={children.length} itemSize={height}>
    {({ index, style }) => <div style={style}>{children[index]}</div>}
  </List>
);

MenuList.propTypes = {
  children: PropTypes.node,
  maxHeight: PropTypes.number,
};

MenuList.defaultProps = {
  children: [],
  maxHeight: 200,
};

const SelectInput = (props) => <Select {...props} />;

export default SelectInput;
