import {
  signInInit,
  signInFail,
  signInSuccess,
  passwordChangeRequired,
  passwordChangeInit,
  passwordChangeSuccess,
  passwordChangeFail,
  resumeSessionInit,
  resumeSessionSuccess,
  resumeSessionFail,
  forgotPasswordInit,
  forgotPasswordSuccess,
  forgotPasswordFail,
  forgotPasswordConfirmationInit,
  forgotPasswordConfirmationSuccess,
  forgotPasswordConfirmationFail,
  clearForgotPasswordData,
  logoutInit,
  logoutSuccess,
  logoutFail,
  resendConfirmationCodeInit,
  resendConfirmationCodeSuccess,
  resendConfirmationCodeFail,
} from 'state/actionCreators/auth';

import signInService from 'services/auth/signIn';
import resumeSessionService from 'services/auth/resumeSession';
import completeNewPasswordService from 'services/auth/completeNewPassword';
import forgotPasswordService from 'services/auth/forgotPassword';
import forgotPasswordConfirmationService from 'services/auth/forgotPasswordConfirmation';
import logoutService from 'services/auth/logout';
import resendConfirmationCodeService from 'services/auth/reSendSignUp';

export const resendConfirmationCode = (username) => async (dispatch) => {
  dispatch(resendConfirmationCodeInit());

  try {
    const user = await resendConfirmationCodeService(username);

    return dispatch(resendConfirmationCodeSuccess({ user }));
  } catch (error) {
    return dispatch(resendConfirmationCodeFail({ error: error.message }));
  }
};

export const signIn = (username, password) => async (dispatch) => {
  dispatch(signInInit());

  try {
    const user = await signInService(username, password);

    if (!user) {
      return dispatch(
        passwordChangeRequired({ username, oldPassword: password })
      );
    }

    return dispatch(signInSuccess({ user }));
  } catch (error) {
    return dispatch(signInFail({ error: error.message }));
  }
};

export const completeNewPassword = (newPassword) => async (
  dispatch,
  getState
) => {
  const {
    auth: { username, oldPassword },
  } = getState();

  dispatch(passwordChangeInit());

  try {
    const user = await completeNewPasswordService(
      username,
      oldPassword,
      newPassword
    );

    return dispatch(passwordChangeSuccess({ user }));
  } catch (error) {
    return dispatch(passwordChangeFail(error));
  }
};

export const resumeSession = () => async (dispatch) => {
  dispatch(resumeSessionInit());

  try {
    const user = await resumeSessionService();

    return dispatch(resumeSessionSuccess({ user }));
  } catch (error) {
    return dispatch(resumeSessionFail({ error: error.message }));
  }
};

export const forgotPassword = (username) => async (dispatch) => {
  dispatch(forgotPasswordInit());

  try {
    await forgotPasswordService(username);
    return dispatch(forgotPasswordSuccess({ username }));
  } catch (error) {
    return dispatch(forgotPasswordFail({ error: error.message }));
  }
};

export const forgotPasswordConfirmation = (
  username,
  code,
  newPassword
) => async (dispatch) => {
  dispatch(forgotPasswordConfirmationInit());

  try {
    await forgotPasswordConfirmationService(username, code, newPassword);
    return dispatch(forgotPasswordConfirmationSuccess());
  } catch (error) {
    return dispatch(forgotPasswordConfirmationFail({ error: error.message }));
  }
};

export const clearForgotPassword = clearForgotPasswordData;

export const logout = () => async (dispatch) => {
  dispatch(logoutInit());

  try {
    await logoutService();
    return dispatch(logoutSuccess());
  } catch (error) {
    return dispatch(logoutFail({ error: error.message }));
  }
};
