import moment from 'moment';

import { audienceType } from '../values';

const getAudienceRuleData = (audience, merchant) => {
  if (audience) {
    const behaviorValue =
      audience.transactionCount?.lte ?? audience.transactionCount?.gte;

    const behaviorRange = audience.transactionCount?.gte
      ? 'Greater than or Equal to'
      : 'Less than or Equal to';

    const refreshAudience = audience.type === audienceType.Dynamic;

    const getTimescaleValue = () => {
      const rangeDates = audience.query?.compound?.must?.find(
        ({ range }) => range?.path === 'authorizationDate'
      );
      const startDate = moment(rangeDates?.range?.gte);
      const endDate = moment(rangeDates?.range?.lte);

      return endDate.diff(startDate, 'days');
    };

    return {
      behaviorValue,
      behaviorRange,
      period: 'days',
      refreshAudience,
      timescale: 'Last',
      behavior: 'Purchases',
      merchant,
      timescaleValue: getTimescaleValue(),
    };
  }

  return {};
};

export default getAudienceRuleData;
