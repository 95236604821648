/* eslint-disable react/prop-types */
import React from 'react';

import informationIcon from 'assets/icons/color/information.svg';
import FilterTypes from 'enums/reports/filterTypes.enum';
import Groups from 'enums/users/groups.enum';
import dateParse from 'utils/common/dateParse';
import categoryList from 'utils/categories/';
import Status from 'components/Common/Status';
import { merchantNetwork } from 'utils/common/merchantNetwork';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Merchant ID',
        accessor: 'merchantId',
        columnName: 'merchantId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Name',
        accessor: 'name',
        columnName: 'name',
        sortable: true,
        filterable: true,
        searchable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Source',
        accessor: 'source',
        columnName: 'source',
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.String,
          options: ['NATIONAL', 'LOCAL'],
        },
        allowedRoles: Groups.Everyone,
      },
      // TODO possibly add in affiliate merchants later?
      // {
      //   Header: 'Type',
      //   accessor: 'type',
      //   columnName: 'type',
      //   sortable: true,
      //   filterable: true,
      //   multiValue: false,
      //   filter: {
      //     type: FilterTypes.String,
      //     options: ['AFFILIATE', 'CARDLINKED'],
      //   },
      //   allowedRoles: Groups.Everyone,
      // },
      {
        Header: 'Merchant Network',
        accessor: 'merchantNetwork',
        columnName: 'merchantNetwork',
        sortable: true,
        filterable: true,
        multiValue: false,
        searchable: true,
        filter: {
          type: FilterTypes.String,
          options: merchantNetwork,
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
        columnName: 'createdDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Internal,
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: true,
        filterable: true,
        multiValue: true,
        showSwitchToggle: false,
        Cell: ({ value }) => <Status kind={value} />,
        filter: {
          type: FilterTypes.String,
          options: ['ACTIVE', 'INACTIVE', 'CLOSED'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Website URL',
        accessor: 'websiteURL',
        columnName: 'websiteURL',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Category',
        accessor: 'category',
        columnName: 'category',
        sortable: true,
        filterable: true,
        multiValue: false,
        searchable: true,
        filter: {
          type: FilterTypes.String,
          options: categoryList,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'More info',
        accessor: 'moreInfo',
        columnName: 'moreInfo',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={informationIcon} alt="More information" />,
        allowedRoles: Groups.Administrators,
      },
    ],
  },
];

export default columns;
