/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { toggleSidebar } from 'state/actionCreators/layout';

export const initialState = {
  sidebarExpanded: true,
};

const persistConfig = {
  key: 'layout',
  storage,
  whitelist: ['sidebarExpanded'],
};

const layoutReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [toggleSidebar]: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
  })
);

export default layoutReducer;
