import { createAction } from '@reduxjs/toolkit';

export const fetchOffersInit = createAction('[Offers] Fetch Offers Init');
export const fetchOffersSuccess = createAction('[Offers] Fetch Offers Success');
export const fetchOffersFail = createAction('[Offers] Fetch Offers Fail');

export const fetchOffersByMerchantIdInit = createAction(
  '[Offers] Fetch Offers By Merchant Id Init'
);
export const fetchOffersByMerchantIdSuccess = createAction(
  '[Offers] Fetch Offers By Merchant Id Success'
);
export const fetchOffersByMerchantIdFail = createAction(
  '[Offers] Fetch Offers By Merchant Id Fail'
);

export const fetchOfferByIdInit = createAction(
  '[Offers] Fetch Offer By Id Init'
);
export const fetchOfferByIdSuccess = createAction(
  '[Offers] Fetch Offer By Id Success'
);
export const fetchOfferByIdFail = createAction(
  '[Offers] Fetch Offer By Id Fail'
);

export const createOfferInit = createAction('[Offers] Create Offer Init');
export const createOfferSuccess = createAction('[Offers] Create Offer Success');
export const createOfferFail = createAction('[Offers] Create Offer Fail');

export const editOfferInit = createAction('[Offers] Edit Offer Init');
export const editOfferSuccess = createAction('[Offers] Edit Offer Success');
export const editOfferFail = createAction('[Offers] Edit Offer Fail');

export const msgOfferStatus = createAction('[Offers] Message Edit Offer');

export const clearOffersErrorsData = createAction(
  '[Offers] Clear Offers Errors Data'
);

export const clearOffersSuccessProp = createAction(
  '[Offers] Clear Offers Success Property'
);
