import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import msg from 'utils/commonMessages';
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Switch, { Size as SwitchSize } from 'components/Common/Switch';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import {
  clearEditPortalUser,
  createPortalUser,
  editPortalUser,
} from 'state/actions/users';
import {
  selectCreatePortalUserState,
  selectEditPortalUserState,
  selectFetchUserProfileState,
} from 'state/selectors/users';
import { fetchMerchantIssuers } from 'state/actions/merchants';
import { selectFetchMerchantIssuersState } from 'state/selectors/merchants';
import { resendConfirmationCode } from 'state/actions/auth';
import { selectResendConfirmationCodeState } from 'state/selectors/auth';
import Status from 'enums/status/status.enum';
import commonToast from 'components/Common/commonToast';
import { RoleOptionsForFormControlSelect } from 'utils/users/roles';
import Roles from 'enums/users/roles.enum';
import portalUserSchema from './UserForm.schema';
import classes from './UserForm.module.scss';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
  XXS: 'xxs',
});

const UserForm = ({ title, user, isCreating, isProfile, onCancel }) => {
  const dispatch = useDispatch();

  const {
    success: successResendingConfirmation,
    error: errorResendingConfirmation,
  } = useSelector(selectResendConfirmationCodeState, shallowEqual);

  const { issuers, loading: loadingMerchantIssuers } = useSelector(
    selectFetchMerchantIssuersState,
    shallowEqual
  );

  const issuersOptions = useMemo(
    () =>
      issuers?.map((category) => ({
        label: category,
        value: category,
      })),
    [issuers]
  );

  const {
    loading: loadingEdit,
    error: errorEdit,
    success: successEdit,
  } = useSelector(selectEditPortalUserState, shallowEqual);

  const {
    loading: loadingCreate,
    success: createPortalUserSuccess,
    error: errorCreate,
  } = useSelector(selectCreatePortalUserState, shallowEqual);

  const { loading: loadingUserProfile, userProfile } = useSelector(
    selectFetchUserProfileState,
    shallowEqual
  );

  const [isEditing, setIsEditing] = useState(false);
  const [userRoles, setUserRoles] = useState(user.roles ?? []);
  const userIsIssuer = useMemo(() => userRoles.includes(Roles.Issuer), [
    userRoles,
  ]);
  const [status, setStatus] = useState(
    isProfile || user.status === Status.ACTIVE
  );

  useEffect(() => {
    // in the condition we render the issuer name picker,
    // load the issuer name picker's options.
    if (userIsIssuer && !isProfile) {
      dispatch(fetchMerchantIssuers());
    }
  }, [dispatch, isCreating, isProfile, userIsIssuer]);

  // TODO: what is this doing?
  useEffect(() => {
    if (userProfile && Object.values(user).length === 0 && !isCreating) {
      setStatus(true);
    }
  }, [user, userProfile, isCreating]);

  useEffect(() => {
    if (successEdit) {
      dispatch(clearEditPortalUser());
      onCancel();
    }
  }, [successEdit, onCancel, dispatch]);

  const onEditHandler = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onResendSignUp = useCallback(() => {
    dispatch(resendConfirmationCode(user.username));
  }, [dispatch, user.username]);

  const onSubmitHandler = useCallback(
    (values) => {
      const body = {
        email: values.email,
        role: values.roles.join(','), // note: the API is not updated to use 'roles'
        ...(userIsIssuer && { issuerName: values.issuerName }),
        title: values.title,
        name: values.fullName,
        phone_number: values.phoneNumber,
      };
      if (isCreating) {
        if (!body.name) {
          delete body.name;
        }
        if (!body.title) {
          delete body.title;
        }
        if (!body.phone_number) {
          delete body.phone_number;
        }
        dispatch(createPortalUser(body));
      } else {
        const editBody = {
          ...body,
          username: user.username,
          enabled: status,
        };
        if (editBody.name === 'N/A') {
          delete editBody.name;
        }
        if (editBody.title === 'N/A') {
          delete editBody.title;
        }
        dispatch(editPortalUser(editBody));
      }
    },
    [userIsIssuer, isCreating, dispatch, user.username, status]
  );

  const onChangeStatusHandler = useCallback(() => {
    if (isEditing) {
      setStatus((prevState) => !prevState);
    }
  }, [isEditing]);

  const readOnly = useMemo(() => !isEditing && !isCreating, [
    isEditing,
    isCreating,
  ]);

  const isNotOwnProfile = useMemo(
    () => !user?.email || user?.email !== userProfile?.email,
    [user, userProfile]
  );

  return (
    <>
      {(errorCreate || errorEdit) && (
        <Toast
          id={errorCreate ? 'error create user' : 'error edit user'}
          text={errorCreate || errorEdit}
          type={ToastType.Error}
        />
      )}

      {createPortalUserSuccess && (
        <Toast
          id="success=creating-user"
          text="User created successfully!"
          type={ToastType.Success}
        />
      )}

      {successEdit && (
        <Toast
          id="success-updating-user"
          text="User updated successfully!"
          type={ToastType.Success}
        />
      )}
      {commonToast(
        successResendingConfirmation,
        errorResendingConfirmation,
        msg.ReSendInvitationSuccessMsg,
        msg.ReSendInvitationErrorMsg
      )}
      <Form
        onSubmit={onSubmitHandler}
        validationSchema={portalUserSchema}
        validationMode={ValidationMode.OnChange}
        className={classes.form}
      >
        <Heading size={HeadingSize.M}>{title}</Heading>
        {loadingUserProfile ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <div>
            <FormControl
              name="email"
              render={(props) => (
                <Input
                  label={isCreating ? '' : 'Email:'}
                  labelClassName={classes.labelWidth}
                  className={
                    isProfile || !isNotOwnProfile || isEditing
                      ? classes.disabledInput
                      : classes.enabledInput
                  }
                  value={user.email}
                  placeholder="Email"
                  type={InputType.Email}
                  readOnly={
                    readOnly || isProfile || !isNotOwnProfile || isEditing
                  }
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
            <FormControlSelect
              name="roles"
              defaultValue={userRoles}
              label={isCreating ? null : 'Roles:'}
              labelClassName={classes.labelWidth}
              placeholder="Roles"
              readOnly={readOnly || isProfile || !isNotOwnProfile}
              disabled={readOnly}
              options={RoleOptionsForFormControlSelect}
              color={isEditing ? SelectColor.Black : SelectColor.Gray}
              isMulti
              onChangeManual={setUserRoles}
            />

            {userIsIssuer &&
              (isProfile ? (
                <FormControl
                  name="issuerName"
                  render={(props) => (
                    <Input
                      label={isCreating ? '' : 'Issuer:'}
                      labelClassName={classes.labelWidth}
                      value={user.issuerName}
                      placeholder="Issuer"
                      type={InputType.Text}
                      readOnly
                      size={InputSize.S}
                      color={InputColor.Gray}
                      {...props}
                    />
                  )}
                />
              ) : (
                <FormControlSelect
                  name="issuerName"
                  defaultValue={user.issuerName}
                  manualValue={user.issuerName}
                  label={isCreating ? '' : 'Issuer:'}
                  placeholder={
                    loadingMerchantIssuers ? 'Loading options\u2026' : 'Issuer'
                  }
                  labelClassName={classes.labelWidth}
                  disabled={loadingMerchantIssuers}
                  readOnly={readOnly || isProfile}
                  options={issuersOptions}
                  color={isEditing ? SelectColor.Black : SelectColor.Gray}
                />
              ))}

            <FormControl
              name="title"
              render={(props) => (
                <Input
                  label={isCreating ? '' : 'Title:'}
                  labelClassName={classes.labelWidth}
                  value={user.title}
                  placeholder="Title"
                  type={InputType.Text}
                  readOnly={readOnly}
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
            <FormControl
              name="fullName"
              render={(props) => (
                <Input
                  label={isCreating ? '' : 'Full Name:'}
                  labelClassName={classes.labelWidth}
                  value={user.fullName}
                  placeholder="Full Name"
                  type={InputType.Text}
                  readOnly={readOnly}
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
            <FormControl
              name="phoneNumber"
              render={(props) => (
                <Input
                  label={isCreating ? '' : 'Phone Number:'}
                  labelClassName={classes.labelWidth}
                  value={user.phoneNumber}
                  placeholder="Phone Number"
                  type={InputType.Tel}
                  readOnly={readOnly}
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
            {!isCreating && !isProfile && isNotOwnProfile && (
              <Switch
                enabled={status}
                setEnabled={onChangeStatusHandler}
                label="Status: "
                labelClassName={classes.labelWidth}
                leftText="INACTIVE"
                rightText="ACTIVE"
                size={SwitchSize.S}
                disabled={!isEditing}
                className={classes.switch}
              />
            )}
            <div className={classes.actionButtons}>
              {(readOnly || isEditing) && !isProfile && (
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  kind={ButtonKind.Secondary}
                  onClick={onResendSignUp}
                  className={classes.back}
                >
                  Resend Invitation
                </Button>
              )}
              {readOnly && (
                <>
                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    kind={ButtonKind.Secondary}
                    onClick={onCancel}
                    className={classes.back}
                  >
                    Back
                  </Button>

                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    className={classes.button}
                    onClick={onEditHandler}
                  >
                    Edit
                  </Button>
                </>
              )}
              {(isEditing || isCreating) && (
                <>
                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    onClick={onCancel}
                    kind={ButtonKind.Secondary}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Submit}
                    size={ButtonSize.S}
                    className={classes.submitButton}
                    loading={loadingEdit || loadingCreate}
                  >
                    {isCreating ? 'Invite' : 'Save'}
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </Form>
    </>
  );
};

UserForm.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array])
  ),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
  isProfile: PropTypes.bool,
};

UserForm.defaultProps = {
  user: {},
  isCreating: false,
  onCancel: () => {},
  isProfile: false,
};

export default UserForm;
