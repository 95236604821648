import React from 'react';

import PropTypes from 'prop-types';
import classes from './Summary.module.scss';

const Summary = ({ data }) => (
  <div className={classes.summaryData}>
    <div key="Live Audiences" className={classes.summary}>
      <span>Live Audiences</span>
      <span className={classes.summaryValue}>
        {data?.liveAudiences?.toLocaleString()}
      </span>
    </div>

    <div key="Avg. Audience Size" className={classes.summary}>
      <span>Avg. Audience Size</span>
      <span className={classes.summaryValue}>
        {data?.audienceSize?.toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })}
      </span>
    </div>

    <div key="Total Audiences" className={classes.summary}>
      <span>Total Audiences</span>
      <span className={classes.summaryValue}>
        {data?.allAudiences?.toLocaleString()}
      </span>
    </div>
  </div>
);

Summary.propTypes = {
  data: PropTypes.shape({
    liveAudiences: PropTypes.number,
    audienceSize: PropTypes.number,
    allAudiences: PropTypes.number,
  }),
};

Summary.defaultProps = {
  data: {},
};

export default Summary;
