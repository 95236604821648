const AuditStatus = Object.freeze({
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  CLOSED: 'Closed',
});

const AuditStatusOriginal = Object.freeze({
  New: 'NEW',
  'In Progress': 'IN_PROGRESS',
  Closed: 'CLOSED',
});

export { AuditStatus, AuditStatusOriginal };
