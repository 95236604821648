import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import linkIcon from 'assets/icons/link.png';
import selectExportDataState from 'state/selectors/exportData';
import Button, { Type as ButtonType } from 'components/Common/Button';
import { closeExportBanner } from 'state/actions/exportData';

import classes from './ExportCompleted.module.scss';

const ExportCompleted = () => {
  const dispatch = useDispatch();

  const { data: downloadLink, model } = useSelector(
    selectExportDataState,
    shallowEqual
  );

  useEffect(() => {
    if (downloadLink) {
      window.open(downloadLink);
    }
  }, [downloadLink]);

  const onCloseButton = () => dispatch(closeExportBanner());

  return (
    <div className={classes.exportCompletedContainer}>
      <div className={classes.exportCompleted}>
        <a href={downloadLink} className={classes.link}>
          <img
            src={linkIcon}
            className={classes.exportCompletedIcon}
            alt="export completed"
          />
        </a>
        <div className={classes.exportText}>
          <span className={classes.principalText}>
            {model}s export completed!
          </span>
          <span>File not downloaded? Click pin icon to start download</span>
        </div>
      </div>
      <Button
        type={ButtonType.Default}
        className={classes.closeButton}
        onClick={onCloseButton}
        kind="minimal"
      >
        Close
      </Button>
    </div>
  );
};

export default ExportCompleted;
