import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, { Type as InputType } from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Link from 'components/Typography/Link';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import ErrorMessage from 'components/Typography/ErrorMessage';
import Path from 'enums/path.enum';
import { signIn } from 'state/actions/auth';
import { selectSignInState } from 'state/selectors/auth';
import emailIcon from 'assets/icons/mail-at.svg';
import lockIcon from 'assets/icons/lock.svg';

import validationSchema from './LoginFormSchema.schema';
import classes from './LoginForm.module.scss';

const LoginForm = () => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector(selectSignInState, shallowEqual);

  const onSubmitHandler = useCallback(
    ({ email, password }) => {
      dispatch(signIn(email, password));
    },
    [dispatch]
  );

  return (
    <>
      <Heading size={HeadingSize.XL}>Login</Heading>
      <Form
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnChange}
        onSubmit={onSubmitHandler}
      >
        <FormControl
          name="email"
          render={(props) => (
            <Input
              placeholder="Email Address"
              type={InputType.Email}
              icon={emailIcon}
              {...props}
            />
          )}
        />
        <FormControl
          name="password"
          render={(props) => (
            <Input
              placeholder="Password"
              type={InputType.Password}
              icon={lockIcon}
              {...props}
            />
          )}
        />
        <Button
          type={ButtonType.Submit}
          size={ButtonSize.L}
          loading={loading}
          isFullWidth
        >
          Login
        </Button>
        <div className={classes.link}>
          <Link to={Path.ForgotPassword}>Forgot Password?</Link>
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </>
  );
};

export default LoginForm;
