const ModalType = {
  USER_PROFILE: 'user-profile',
  USER_STATUS: 'user-status',
  ADD_USER: 'add-user',
  OFFER_STATUS: 'offer-status',
  ADD_OFFER: 'add-offer',
  OFFER_INFO: 'offer-info',
  // OFFER_MERCHANT_DATA: 'offer-merchant-data',
  OFFER_LOCATIONS_DATA: 'offer-locations-data',
  MERCHANT_INFO: 'merchant-info',
  ADD_MERCHANT: 'add-merchant',
  MERCHANT_STATUS: 'merchant-status',
  MERCHANT_OFFERS_DATA: 'merchant-offers-data',
  MERCHANT_LOCATIONS_DATA: 'merchant-locations-data',
  LOCATION_INFO: 'merchant-info',
  UPLOAD_LOCATION: 'upload-location',
  LOCATION_STATUS: 'location-status',
  ALL_MERCHANTS_SELECT: 'all-merchant-select',
  ALL_OFFERS_SELECT: 'all-merchant-select',
  ACCEPT_TRANSACTION: 'accept-transaction',
  REJECT_TRANSACTION: 'reject-transaction',
  TRANSACTION_DATA: 'transaction-data',
  LOCATION_BUSINESS_HOURS: 'location-business-hours',
  OFFER_ISSUERS_COMMISION: 'offer-issuers-commision',
  REPROCESS_TRANSACTION: 'reprocess-transaction',
  TRANSACTIONS_SUPPORT_INFO: 'transactions-support-info',
  ADD_AUDIT: 'add-audit',
  AUDIENCE_INFO: 'audience-info',
  ADD_AUDIENCE: 'add-audience',
};

export default ModalType;
