export const merchantNetwork = [
  'EMPYR',
  'REWARDSNETWORK',
  'KARD',
  'BUTTON',
  'DAYLIGHT',
  'FIZZ',
  'BENJAMIN',
  'ONEFINANCE',
  'ROBINHOOD',
  'IONIA',
  'M1',
  'CHIPPER',
  'LOLLI',
  'DROP',
];

export const merchantNetworks = [
  {
    label: 'Kard',
    value: 'KARD',
  },
  {
    label: 'Rewards Network',
    value: 'REWARDSNETWORK',
  },
  {
    label: 'Button',
    value: 'BUTTON',
  },
  {
    label: 'Daylight',
    value: 'DAYLIGHT',
  },
  {
    label: 'EMPYR',
    value: 'EMPYR',
  },
  {
    label: 'Collinson',
    value: 'COLLINSON',
  },
  {
    label: 'Fizz',
    value: 'FIZZ',
  },
  {
    label: 'Benjamin',
    value: 'BENJAMIN',
  },
  {
    label: 'OneFinance',
    value: 'ONEFINANCE',
  },
  {
    label: 'Robinhood',
    value: 'ROBINHOOD',
  },
  {
    label: 'Ionia',
    value: 'IONIA',
  },
  {
    label: 'M1',
    value: 'M1',
  },
  {
    label: 'Chipper',
    value: 'CHIPPER',
  },
  {
    label: 'Lolli',
    value: 'LOLLI',
  },
  {
    label: 'Drop',
    value: 'DROP',
  },
];
