/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchAudiencesInit,
  fetchAudiencesSuccess,
  fetchAudiencesFail,
  fetchAudienceByIdFail,
  fetchAudienceByIdInit,
  fetchAudienceByIdSuccess,
  clearAudiencesErrorsProps,
  addAudienceRule,
  saveAudienceData,
  selectedAudienceRule,
  clearSelectedRule,
  editAudienceInit,
  editAudienceSuccess,
  editAudienceFail,
  createAudienceInit,
  createAudienceSuccess,
  createAudienceFail,
  updateAudienceRule,
  clearAudience,
  editingAudience,
  addAudienceToOfferInit,
  addAudienceToOfferSuccess,
  addAudienceToOfferFail,
  removeAudienceToOfferInit,
  removeAudienceToOfferSuccess,
  removeAudienceToOfferFail,
  clearAddAndRemoveAudienceToOffer,
  fetchAudiencesDataPointsSuccess,
  fetchAudiencesDataPointsFail,
  fetchAudiencesDataPointsInit,
} from 'state/actionCreators/audiences';

export const initialState = {
  fetchAudiencesLoading: false,
  fetchAudiencesSuccess: false,
  fetchAudiencesError: null,
  fetchAudienceByIdLoading: false,
  fetchAudienceByIdSuccess: false,
  fetchAudienceByIdError: null,
  editAudienceLoading: false,
  editAudienceSuccess: false,
  editAudienceError: null,
  createAudienceLoading: false,
  createAudienceSuccess: false,
  createAudienceError: null,
  addAudienceToOfferLoading: false,
  addAudienceToOfferSuccess: false,
  addAudienceToOfferError: null,
  removeAudienceToOfferLoading: false,
  removeAudienceToOfferSuccess: false,
  removeAudienceToOfferError: null,
  audiences: {
    results: [],
    countTotal: 0,
  },
  audience: null,
  newAudience: {
    rule: null,
  },
  selectedRule: {},
  isEditing: false,
  fetchDataPointsLoading: false,
  fetchDataPointsSuccess: false,
  fetchDataPointsError: null,
  audienceDataSummaryPoints: {},
};

const persistConfig = {
  key: 'audiences',
  storage,
  blacklist: [
    'fetchAudiencesLoading',
    'fetchAudiencesSuccess',
    'fetchAudiencesError',
    'fetchAudienceByIdLoading',
    'fetchAudienceByIdSuccess',
    'fetchAudienceByIdError',
    'editAudienceLoading',
    'editAudienceSuccess',
    'editAudienceError',
    'createAudienceLoading',
    'createAudienceSuccess',
    'createAudienceError',
    'addAudienceToOfferLoading',
    'addAudienceToOfferSuccess',
    'addAudienceToOfferError',
    'removeAudienceToOfferLoading',
    'removeAudienceToOfferSuccess',
    'removeAudienceToOfferError',
    'audiences',
    'audience',
    'audienceDataSummaryPoints',
    'newAudience',
    'selectedRule',
    'isEditing',
    'fetchDataPointsLoading',
    'fetchDataPointsSuccess',
    'fetchDataPointsError',
  ],
};

const audiencesReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [fetchAudiencesInit]: (state) => {
      state.fetchAudiencesLoading = true;
      state.fetchAudiencesSuccess = false;
      state.fetchAudiencesError = null;
      state.audiences = {
        results: [],
        countTotal: 0,
      };
    },
    [fetchAudiencesSuccess]: (state, action) => {
      const { audiences } = action.payload;
      state.fetchAudiencesLoading = false;
      state.fetchAudiencesError = null;
      state.fetchAudiencesSuccess = true;
      state.audiences = audiences;
    },
    [fetchAudiencesFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchAudiencesLoading = false;
      state.fetchAudiencesError = error;
      state.fetchAudiencesSuccess = false;
    },
    [fetchAudienceByIdInit]: (state) => {
      state.fetchAudienceByIdLoading = true;
      state.fetchAudienceByIdSuccess = false;
      state.fetchAudienceByIdError = null;
      state.audience = null;
    },
    [fetchAudienceByIdSuccess]: (state, action) => {
      const { audience } = action.payload;
      state.fetchAudienceByIdLoading = false;
      state.fetchAudienceByIdError = null;
      state.fetchAudienceByIdSuccess = true;
      state.audience = audience;
    },
    [fetchAudienceByIdFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchAudienceByIdLoading = false;
      state.fetchAudienceByIdError = error;
      state.fetchAudienceByIdSuccess = false;
      state.audience = null;
    },
    [clearAudiencesErrorsProps]: (state) => {
      state.fetchAudienceByIdError = null;
      state.fetchAudiencesError = null;
      state.editAudienceError = null;
      state.createAudienceError = null;
      state.createAudienceSuccess = false;
      state.editAudienceSuccess = false;
    },
    [editAudienceInit]: (state) => {
      state.editAudienceLoading = true;
      state.editAudienceError = null;
      state.editAudienceSuccess = false;
    },
    [editAudienceSuccess]: (state) => {
      state.editAudienceLoading = false;
      state.editAudienceError = null;
      state.editAudienceSuccess = true;
    },
    [editAudienceFail]: (state, action) => {
      const { error } = action.payload;
      state.editAudienceLoading = false;
      state.editAudienceError = error;
      state.editAudienceSuccess = false;
    },
    [createAudienceInit]: (state) => {
      state.createAudienceLoading = true;
      state.createAudienceError = null;
      state.createAudienceSuccess = false;
    },
    [createAudienceSuccess]: (state) => {
      state.createAudienceLoading = false;
      state.createAudienceError = null;
      state.createAudienceSuccess = true;
    },
    [createAudienceFail]: (state, action) => {
      const { error } = action.payload;
      state.createAudienceLoading = false;
      state.createAudienceError = error;
      state.createAudienceSuccess = false;
    },
    [addAudienceToOfferInit]: (state) => {
      state.addAudienceToOfferLoading = true;
      state.addAudienceToOfferError = null;
      state.addAudienceToOfferSuccess = false;
    },
    [addAudienceToOfferSuccess]: (state) => {
      state.addAudienceToOfferLoading = false;
      state.addAudienceToOfferError = null;
      state.addAudienceToOfferSuccess = true;
    },
    [addAudienceToOfferFail]: (state, action) => {
      const { error } = action.payload;
      state.addAudienceToOfferLoading = false;
      state.addAudienceToOfferError = error;
      state.addAudienceToOfferSuccess = false;
    },
    [removeAudienceToOfferInit]: (state) => {
      state.removeAudienceToOfferLoading = true;
      state.removeAudienceToOfferError = null;
      state.removeAudienceToOfferSuccess = false;
    },
    [removeAudienceToOfferSuccess]: (state) => {
      state.removeAudienceToOfferLoading = false;
      state.removeAudienceToOfferError = null;
      state.removeAudienceToOfferSuccess = true;
    },
    [removeAudienceToOfferFail]: (state, action) => {
      const { error } = action.payload;
      state.removeAudienceToOfferLoading = false;
      state.removeAudienceToOfferError = error;
      state.removeAudienceToOfferSuccess = false;
    },
    [clearAddAndRemoveAudienceToOffer]: (state) => {
      state.addAudienceToOfferLoading = false;
      state.addAudienceToOfferSuccess = false;
      state.addAudienceToOfferError = null;
      state.removeAudienceToOfferLoading = false;
      state.removeAudienceToOfferSuccess = false;
      state.removeAudienceToOfferError = null;
      // [addAudienceRule]: (state, { payload }) => {
      //   const { rule } = payload;
      //   state.newAudience = {
      //     ...state.newAudience,
      //     rules: [...state.newAudience.rules, rule],
      //   };
      // },
    },
    [addAudienceRule]: (state, { payload }) => {
      const { rule } = payload;
      state.newAudience = {
        ...state.newAudience,
        rule,
      };
    },
    [saveAudienceData]: (state, { payload }) => {
      const { audience } = payload;
      state.newAudience = {
        ...state.newAudience,
        ...audience,
      };
    },
    [selectedAudienceRule]: (state, { payload }) => {
      const { rule } = payload;
      state.selectedRule = rule;
    },
    // [updateAudienceRule]: (state, { payload }) => {
    //   const { rule, index } = payload;

    //   const rules = [...state.newAudience.rules];

    //   rules[index] = rule;

    //   state.newAudience = {
    //     ...state.newAudience,
    //     rules,
    //   };
    //   state.isEditingRule = null;
    //   state.selectedRule = {};
    // },
    [updateAudienceRule]: (state, { payload }) => {
      const { rule } = payload;

      state.newAudience = {
        ...state.newAudience,
        rule,
      };
      state.isEditing = false;
      state.selectedRule = {};
    },
    [clearSelectedRule]: (state) => {
      state.selectedRule = {};
      state.isEditing = false;
    },
    [clearAudience]: (state) => {
      state.newAudience = {
        rule: null,
      };
    },
    [editingAudience]: (state) => {
      state.isEditing = true;
    },
    [fetchAudiencesDataPointsInit]: (state) => {
      state.fetchDataPointsLoading = true;
      state.fetchDataPointsSuccess = false;
      state.fetchDataPointsError = null;
      state.audienceDataSummaryPoint = {};
    },
    [fetchAudiencesDataPointsSuccess]: (state, action) => {
      const { audiences } = action.payload;
      state.fetchDataPointsLoading = false;
      state.fetchDataPointsError = null;
      state.fetchDataPointsSuccess = true;
      state.audienceDataSummaryPoint = audiences;
    },
    [fetchAudiencesDataPointsFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchDataPointsLoading = false;
      state.fetchDataPointsError = error;
      state.fetchDataPointsSuccess = false;
    },
  })
);

export default audiencesReducer;
