import client from '../index';

const createPublicAuditServices = async (body) => {
  try {
    const { data } = await client.post(`transactions/public/audit`, body);

    return data;
  } catch (error) {
    throw error.response;
  }
};

export default createPublicAuditServices;
