import client from 'services/kardAPI';

const editPendingTransactions = async (pendingTransactionId, params) => {
  try {
    const { data } = await client.put(
      `/transactions/portal/pending/${pendingTransactionId}`,
      params
    );
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default editPendingTransactions;
