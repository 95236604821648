const Columns = Object.freeze({
  ReprocessTransaction: 'reprocessTransaction',
  MoreInfo: 'moreInfo',
  Accept: 'accept',
  Reject: 'reject',
  Status: 'status',
  MerchantName: 'merchantName',
  CorrectMerchant: 'correctMerchant',
  CorrectLocation: 'correctLocation',
});

export default Columns;
