import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import Body, { Size as BodySize } from 'components/Typography/Body';
import ReactTooltip from 'react-tooltip';
import classes from './Table.module.scss';

const Table = ({ data, columns, id }) => {
  const tooltipId = `${id}-tip`;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.table} id={id}>
        <thead className={classes.thead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className={classes.th}>
                  <Body size={BodySize.XXS} className={classes.bold}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Body>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr>
              <td colSpan={table.getVisibleFlatColumns().length}>
                <Body className={classes.noData} size={BodySize.XXS}>
                  NO DATA TO SHOW
                </Body>
              </td>
            </tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={classes.tr}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={classes.td}>
                  <Body
                    size={BodySize.XXS}
                    className={classes.tdBody}
                    data-tip={
                      cell.column.columnDef.meta?.tooltip?.(cell.getValue()) ??
                      cell.getValue()
                    }
                    data-for={tooltipId}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Body>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <ReactTooltip
        clickable
        delayHide={100}
        multiline
        id={tooltipId}
        getContent={(dataTip) => `${dataTip}`}
      />
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
};

export default Table;
