import React from 'react';

import informationIcon from 'assets/icons/color/information.svg';
import FilterTypes from 'enums/reports/filterTypes.enum';
import Groups from 'enums/users/groups.enum';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Audit Id',
        accessor: 'auditId',
        columnName: 'auditId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Transaction Id',
        accessor: 'transactionId',
        columnName: 'transactionId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Customer ID',
        accessor: 'referringPartnerUserId',
        columnName: 'referringPartnerUserId',
        sortable: false,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: false,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.String,
          options: ['NEW', 'IN PROGRESS', 'CLOSED'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Issuer',
        accessor: 'issuer',
        columnName: 'issuer',
        sortable: false,
        filterable: true,
        multiValue: false,
        searchable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
        columnName: 'createdDate',
        Cell: ({ value }) =>
          value ? `${new Date(value).toLocaleString('en-US')} EST` : '-',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Internal Dispute',
        accessor: 'internalDispute',
        columnName: 'internalDispute',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'More info',
        accessor: 'moreInfo',
        columnName: 'moreInfo',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={informationIcon} alt="More information" />,
        allowedRoles: Groups.Administrators,
      },
    ],
  },
];

export default columns;
