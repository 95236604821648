import queryParamsToObject from 'utils/queryParamsToObject';
import transformFiltersToMatchSchema from 'utils/transformFiltersToMatchSchema';

const getParsedFilters = (filters) => {
  const objectFilters = queryParamsToObject(filters);

  return transformFiltersToMatchSchema(objectFilters);
};

export default getParsedFilters;
