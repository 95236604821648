/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const selectLocationsState = (state) => state.locations;

export const selectFetchLocationsState = createSelector(
  selectLocationsState,
  ({
    fetchLocationsLoading,
    fetchLocationsSuccess,
    fetchLocationsError,
    locations: { results, countTotal },
  }) => ({
    loading: fetchLocationsLoading,
    success: fetchLocationsSuccess,
    error: fetchLocationsError,
    locationsData: {
      locations: results || [],
      countTotal: countTotal || 0,
    },
  })
);

export const selectFetchLocationByIdState = createSelector(
  selectLocationsState,
  ({
    fetchLocationByIdLoading,
    fetchLocationByIdSuccess,
    fetchLocationByIdError,
    location,
  }) => ({
    loading: fetchLocationByIdLoading,
    success: fetchLocationByIdSuccess,
    error: fetchLocationByIdError,
    location,
  })
);

export const selectFetchLocationsByMerchantIdState = createSelector(
  selectLocationsState,
  ({
    fetchLocationsByMerchantIdLoading,
    fetchLocationsByMerchantIdSuccess,
    fetchLocationsByMerchantIdError,
    locationsByMerchant,
  }) => ({
    loading: fetchLocationsByMerchantIdLoading,
    success: fetchLocationsByMerchantIdSuccess,
    error: fetchLocationsByMerchantIdError,
    locations: locationsByMerchant,
  })
);

export const selectEditLocationState = createSelector(
  selectLocationsState,
  ({ editLocationLoading, editLocationSuccess, editLocationError }) => ({
    loading: editLocationLoading,
    success: editLocationSuccess,
    error: editLocationError,
  })
);

export const selectCreateLocationState = createSelector(
  selectLocationsState,
  ({ createLocationLoading, createLocationSuccess, createLocationError }) => ({
    loading: createLocationLoading,
    success: createLocationSuccess,
    error: createLocationError,
  })
);

export const selectUploadLocationsState = createSelector(
  selectLocationsState,
  ({
    uploadLocationsLoading,
    uploadLocationsSuccess,
    uploadLocationsError,
  }) => ({
    loading: uploadLocationsLoading,
    success: uploadLocationsSuccess,
    error: uploadLocationsError,
  })
);
