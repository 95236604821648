const toFormatTransactionId = (row) => {
  let text = '';
  if (row) {
    text += row.values.transactionId
      ? `<div className={height:'150px',border: '1px solid #FFF'}}><b>Transaction Id: </b> ${row.values.transactionId} </div><br/>`
      : '';
    text += row.values.merchantId
      ? `<div style={{height:'150px',border: '1px solid #FFF'}}><b>Merchant Id: </b>  ${row.values.merchantId} </div><br/>`
      : '';
    text += row.values.merchantAddrStreet
      ? `<div style={{height:'150px',border: '1px solid #FFF'}}><b> Address 1: </b>  ${row.values.merchantAddrStreet}</div> <br/>`
      : '';
    const address2 = `<b>Address 2:  </b>`;
    const city = row.values.merchantAddrCity
      ? ` ${row.values.merchantAddrCity} , `
      : '';
    const state = row.values.merchantAddrState
      ? `${row.values.merchantAddrState} , `
      : '';
    const zipcode = row.values.merchantAddrZipcode
      ? `${row.values.merchantAddrZipcode} `
      : '';
    text +=
      row.values.merchantAddrCity ||
      row.values.merchantAddrState ||
      row.values.merchantAddrZipcode
        ? `<div style={{height:'150px',border: '1px solid #FFF'}}>${address2}${city}${state}${zipcode}</div>`
        : '';
  }
  return text;
};

export default toFormatTransactionId;
