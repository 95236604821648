import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';

import { fetchUserProfile } from 'state/actions/users';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
// import Checkbox, {
//   Kind as CheckboxKind,
//   Size as CheckboxSize,
// } from 'components/Common/Checkbox';
import {
  addNewAudienceRule,
  clearAudienceData,
  clearSelectedAudienceRule,
  saveSelectedAudienceRule,
} from 'state/actions/audiences';
import { selectCurrentAudienceRule } from 'state/selectors/audiences';
import ModalType from 'enums/modal/modalType';
import MerchantsSelect from 'components/Pages/MerchantsSelect';
import useModal from 'hooks/useModal';
import Modal from 'components/Common/Modal';
import { useForm } from 'react-hook-form';
import Status from 'enums/status/status.enum';
import ruleBuilderSchema from './RuleBuilder.schema';
import getInputFields from './inputFields';

import classes from './RuleBuilder.module.scss';

// const RULE_CONDITION = Object.freeze({
//   OR: 'or',
//   AND: 'and',
// });

const MERCHANT_FILTERS = '?source=NATIONAL';

const RuleBuilder = ({ onCancel, goForward, isCreating, audience }) => {
  const dispatch = useDispatch();
  const { setValue } = useForm();

  const [audienceIsEditable, setAudienceIsEditable] = useState(false);

  // const { rules } = useSelector(selectNewAudienceState, shallowEqual);

  const { selectedRule, isEditing } = useSelector(
    selectCurrentAudienceRule,
    shallowEqual
  );

  useEffect(() => {
    if (selectedRule?.merchant) {
      setValue('merchant', selectedRule?.merchant);
    }
  }, [selectedRule, setValue]);

  const [refreshAudience, setRefreshAudience] = useState(
    selectedRule.refreshAudience ?? false
  );

  const [enrollNewUser, setEnrollNewUser] = useState(
    selectedRule.enrollNewUser ?? false
  );

  // const [ruleCondition, setRuleConnector] = useState(RULE_CONDITION.AND);

  const [selectedMerchant, setSelectedMerchant] = useState(
    selectedRule?.merchant || null
  );

  const [validateInputs, setValidateInputs] = useState([]);

  const [formErrors, setFormErrors] = useState({});

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setAudienceIsEditable(audience?.status === Status.DRAFT);
  }, [audience?.status]);

  // const rulesQuantity = rules.length;

  // const onNextHandler = useCallback(() => {
  //   dispatch(saveAudienceInformation({ ruleCondition }));
  //   goForward();
  // }, [goForward, dispatch, ruleCondition]);

  const OnSubmitRuleBuilder = (values) => {
    const newRule = {
      ...values,
      enrollNewUser,
      refreshAudience,
      merchant: selectedMerchant,
    };

    if (isCreating) {
      dispatch(addNewAudienceRule(newRule));
    }

    dispatch(saveSelectedAudienceRule(newRule, 0));
    goForward();
  };

  const onChangeCheckBoxHandler = (setState) => {
    setState((prevState) => !prevState);
  };

  // const onChangeRuleConnectorStateHandler = (value) => {
  //   setRuleConnector(value);
  // };

  const onChangeSelectedMerchantHandler = useCallback(
    (newValue) => {
      if (newValue === 'allMerchants') {
        onOpenModalHandler(ModalType.ALL_MERCHANTS_SELECT);
      }
    },
    [onOpenModalHandler]
  );

  const onChangePeriodValueHandler = useCallback(() => {
    setValidateInputs(['timescaleValue']);
    setFormErrors({});
  }, [setValidateInputs, setFormErrors]);

  const onSelectMerchantHandler = useCallback(
    (merchant) => {
      setSelectedMerchant(merchant);
      onCloseModalHandler();
    },
    [onCloseModalHandler]
  );

  // const onSelectRuleHandler = (rule, index) => {
  //   dispatch(saveSelectedAudienceRule(rule, index));
  // };

  const onCancelHandler = useCallback(() => {
    dispatch(clearAudienceData());
    dispatch(clearSelectedAudienceRule());
    onCancel();
  }, [dispatch, onCancel]);

  const inputFields = useMemo(
    () =>
      getInputFields({
        refreshAudience,
        selectedRule,
        onSelectRefreshAudienceCheckBoxHandler: () =>
          onChangeCheckBoxHandler(setRefreshAudience),
        onSelectEnrollNewUserCheckBoxHandler: () =>
          onChangeCheckBoxHandler(setEnrollNewUser),
        selectedMerchant,
        onChangeSelectedMerchantHandler,
        onChangePeriodValueHandler,
        audienceIsEditable: isCreating || audienceIsEditable,
        enrollNewUser,
      }),
    [
      onChangeSelectedMerchantHandler,
      onChangePeriodValueHandler,
      refreshAudience,
      selectedMerchant,
      selectedRule,
      isCreating,
      audienceIsEditable,
      enrollNewUser,
    ]
  );

  return (
    <>
      <Modal
        isOpen={modal.type === ModalType.ALL_MERCHANTS_SELECT}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <MerchantsSelect
          onCancel={onCloseModalHandler}
          onSubmit={onSelectMerchantHandler}
          filters={MERCHANT_FILTERS}
        />
      </Modal>
      <Form
        onSubmit={OnSubmitRuleBuilder}
        validationMode={ValidationMode.OnChange}
        className={classes.form}
        validationSchema={ruleBuilderSchema}
        setFormErrors={setFormErrors}
        validateInputs={validateInputs}
      >
        <Heading size={HeadingSize.M}>New Audience - Rule Builder</Heading>
        {/* rulesQuantity > 0 && !isEditing && (
          <>
            <div className={classes.AudienceRules}>
              {rules.map((ruleData, index) => {
                const {
                  behaviorValue,
                  timescale,
                  timescaleValue,
                  period,
                  merchant,
                } = ruleData;

                return (
                  <div className={classes.rulesContainer} key={index}>
                    <button
                      type="button"
                      onClick={() => onSelectRuleHandler(ruleData, index)}
                      className={classes.ruleButton}
                    >{`Rule ${
                      index + 1
                    } - ${behaviorValue} purchases in the ${timescale} ${timescaleValue} ${period} in ${merchant.name}`}</button>
                  </div>
                );
              })}
            </div>
            <div className={classes.ruleConnectors}>
              <Checkbox
                label="Or (Only one rule of the list must be met for a redemption to be executed"
                setSelected={() =>
                  onChangeRuleConnectorStateHandler(RULE_CONDITION.OR)
                }
                selected={ruleCondition === RULE_CONDITION.OR}
                className={classes.checkbox}
                kind={CheckboxKind.Secondary}
                size={CheckboxSize.S}
              />
              <Checkbox
                label="And (All the rules must be met for a redemption to be executed)"
                setSelected={() =>
                  onChangeRuleConnectorStateHandler(RULE_CONDITION.AND)
                }
                selected={ruleCondition === RULE_CONDITION.AND}
                className={classes.checkbox}
                kind={CheckboxKind.Secondary}
                size={CheckboxSize.S}
              />
            </div>
          </>
        )}
        {rulesQuantity > 0 && (
          <div>
            <Heading
              size={HeadingSize.M}
              className={classes.createAnotherRuleHeading}
            >
              {isEditing
                ? `Update Rule ${ruleIndex + 1}`
                : `Create Rule ${rulesQuantity + 1}`}
            </Heading>
          </div>
        )} */}
        <div>
          {inputFields.map(({ id, label, fields }) => (
            <div key={id} className={classes.row}>
              {label ? (
                <>
                  <span className={classes.label}>{label}</span>
                  {fields.map(
                    ({ component: Component, renderProps, ...otherProps }) => (
                      <Fragment key={otherProps.name}>
                        {renderProps ? (
                          <FormControl
                            {...otherProps}
                            className={classes.formControl}
                            render={(props) => (
                              <Component {...renderProps} {...props} />
                            )}
                          />
                        ) : (
                          <Component {...otherProps} />
                        )}
                      </Fragment>
                    )
                  )}
                </>
              ) : (
                fields.map(
                  ({
                    title,
                    component: Component,
                    renderProps,
                    ...otherProps
                  }) => (
                    <div className={classes.field} key={otherProps.name}>
                      <span>{title}</span>
                      {renderProps ? (
                        <FormControl
                          {...otherProps}
                          className={classes.formControl}
                          render={(props) => (
                            <Component {...renderProps} {...props} />
                          )}
                        />
                      ) : (
                        <Component {...otherProps} />
                      )}
                    </div>
                  )
                )
              )}
            </div>
          ))}
        </div>
        <div className={classes.errorMessages}>
          {Object.values(formErrors).map(({ message }) => (
            <div key={message}>{message}</div>
          ))}
        </div>
        <div className={classes.actionButtons}>
          {/* <div className={classes.buttonsContainer}> */}
          {/* {!isEditing && (
              <Button className={classes.addRuleLink} type={ButtonType.Submit}>
                + Add Rule
              </Button>
            )} */}
          <div>
            {!isEditing && (
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                onClick={onCancelHandler}
                kind={ButtonKind.Secondary}
              >
                Cancel
              </Button>
            )}
            <Button
              type={ButtonType.Submit}
              size={ButtonSize.S}
              className={classes.submitButton}
            >
              {isEditing && audienceIsEditable ? 'Update' : 'Next'}
            </Button>
          </div>
          {/* </div> */}
        </div>
      </Form>
    </>
  );
};

RuleBuilder.propTypes = {
  audience: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
  goForward: PropTypes.func.isRequired,
};

RuleBuilder.defaultProps = {
  audience: {},
  isCreating: true,
  onCancel: () => {},
};

export default RuleBuilder;
