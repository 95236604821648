/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination } from 'react-table';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Switch from 'components/Common/Switch';
import Checkbox, { Kind as CheckboxKind } from 'components/Common/Checkbox';
import ReportFilters from 'components/Pages/ReportFilters';
import Pagination from 'components/Common/Pagination';
import Status from 'enums/status/status.enum';

import columnsOfTypeDate from 'utils/table';
import dateParse from 'utils/common/dateParse';
import classes from './Table.module.scss';

const Table = ({
  columns,
  data,
  manualPagination,
  currentPage,
  itemsPerPage,
  fetchData,
  onSubmitFilter,
  onClickCellButton,
  onClickSelectedCell,
  selectedFilters,
  sortBy,
  canSelect,
  onChangeSwitch,
  pagination,
  className,
  bodyClassName,
  onRenderSelect,
  countTotal,
  newPagination,
  tableId,
  columnsToHide,
}) => {
  const noVisibleColumns = useMemo(
    () =>
      columns[0].columns
        .filter(({ isVisible }) => isVisible === false)
        .map(({ accessor }) => accessor),
    [columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    pageCount,
    pageOptions,
    state: { pageIndex, pageSize },
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage,
        pageSize: itemsPerPage,
        hiddenColumns: noVisibleColumns,
      },
      manualPagination,
      pageCount: manualPagination ? -1 : Math.ceil(data.length / itemsPerPage),
    },
    usePagination
  );

  useEffect(() => {
    setHiddenColumns([...noVisibleColumns, ...columnsToHide]);
  }, [setHiddenColumns, columnsToHide, noVisibleColumns]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const firstItemPagination = useMemo(
    () => (pageIndex + 1) * pageSize - itemsPerPage + 1,
    [pageIndex, pageSize, itemsPerPage]
  );

  const lastItemPagination = useMemo(
    () =>
      (pageIndex + 1) * pageSize < data.length
        ? (pageIndex + 1) * pageSize
        : data.length,
    [pageIndex, pageSize, data]
  );

  const getTooltipText = useCallback(({ column: { columnName }, value }) => {
    if (columnName === 'status') {
      return '';
    }

    if (columnsOfTypeDate.includes(columnName)) {
      return dateParse(value);
    }

    return value;
  }, []);

  return (
    <>
      <div
        className={classNames(classes.tableWrapper, className, {
          [classes.noPagination]: !pagination,
        })}
      >
        <table {...getTableProps()} className={classes.table}>
          <thead className={classes.thead}>
            {headerGroups
              .map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {canSelect && (
                    <th
                      className={classes.th}
                      style={{ minWidth: 4, maxWidth: 4 }}
                    />
                  )}
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={classes.th}>
                      {column.filterable || column.sortable ? (
                        <ReportFilters
                          header={column.render('Header')}
                          columnName={column.columnName}
                          filterable={column.filterable}
                          multiValue={column.multiValue}
                          sortable={column.sortable}
                          validateSearch={column.validateSearch}
                          searchable={column.searchable}
                          filter={column.filter}
                          selectedFilters={selectedFilters}
                          sortBy={sortBy}
                          onSubmit={onSubmitFilter}
                        />
                      ) : (
                        <Body
                          size={BodySize.XXS}
                          className={classNames(classes.bold, {
                            [classes.isSelectHeader]: column.isSelect,
                          })}
                        >
                          {column.render('Header')}
                        </Body>
                      )}
                    </th>
                  ))}
                </tr>
              ))
              .filter((_, index) => index !== 0)}
          </thead>
          <tbody {...getTableBodyProps()}>
            <tr>
              <td colSpan={columns[0].columns.length}>
                {data.length === 0 && (
                  <Body className={classes.noData} size={BodySize.XXS}>
                    NO DATA TO SHOW
                  </Body>
                )}
              </td>
            </tr>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={classes.tr} key={row.id}>
                  {canSelect && (
                    <td
                      className={classNames(classes.td, {
                        [classes.lastRow]: index === page.length - 1,
                      })}
                      style={{
                        minWidth: 4,
                        maxWidth: 4,
                      }}
                    >
                      <Checkbox
                        selected={row.original.selected}
                        setSelected={() => onClickSelectedCell(row.index)}
                        className={classes.selectCell}
                        kind={CheckboxKind.Secondary}
                      />
                    </td>
                  )}
                  {row.cells.map((cell) => {
                    if (cell.column.isButton) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classNames(classes.td, {
                            [classes.lastRow]: index === page.length - 1,
                          })}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              onClickCellButton(
                                cell.column.columnName,
                                cell.row.index
                              )
                            }
                            className={classes.cellButton}
                          >
                            <Body
                              size={BodySize.XXS}
                              className={classNames(
                                classes.tdBody,
                                classes.cellButtonText,
                                {
                                  [classes.cellButtonTextDisabled]:
                                    cell.row.original.status ===
                                    Status.DISABLED,
                                }
                              )}
                              data-tip={getTooltipText(cell)}
                            >
                              {cell.render('Cell')}
                            </Body>
                          </button>
                        </td>
                      );
                    }
                    if (cell.column.isToggle) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classNames(classes.td, {
                            [classes.lastRow]: index === page.length - 1,
                          })}
                        >
                          <div className={classes.tdSwitch}>
                            {cell.column.showSwitchToggle && (
                              <Switch
                                enabled={row.original.status === Status.ACTIVE}
                                setEnabled={() =>
                                  onChangeSwitch(
                                    cell.column.columnName,
                                    cell.row.index,
                                    row.original.status
                                  )
                                }
                                className={classes.cellSwitch}
                              />
                            )}
                            <Body
                              size={BodySize.XXS}
                              color={
                                cell.row.original.status === Status.INACTIVE
                                  ? BodyColor.Gray
                                  : BodyColor.Black
                              }
                              className={classes.tdBodySwitch}
                            >
                              {cell.render('Cell')}
                            </Body>
                          </div>
                        </td>
                      );
                    }
                    if (cell.column.isSelect) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classNames(classes.td, classes.isSelect, {
                            [classes.lastRow]: index === page.length - 1,
                          })}
                        >
                          {onRenderSelect(
                            cell.column.columnName,
                            cell.row.index
                          )}
                        </td>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={classNames(classes.td, {
                          [classes.lastRow]: index === page.length - 1,
                        })}
                      >
                        <Body
                          size={BodySize.XXS}
                          color={
                            cell.row.original.status === Status.DISABLED
                              ? BodyColor.Gray
                              : BodyColor.Black
                          }
                          className={classNames(classes.tdBody, bodyClassName)}
                          data-tip={getTooltipText(cell)}
                          data-for={tableId}
                        >
                          {cell.render('Cell')}
                        </Body>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ReactTooltip
          clickable
          delayHide={100}
          multiline
          id={tableId}
          getContent={(dataTip) => `${dataTip}`}
        />
      </div>
      {pagination && (
        <Pagination
          firstItemPagination={firstItemPagination}
          lastItemPagination={lastItemPagination}
          data={data}
          pageIndex={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          previousPage={() => {
            if (manualPagination) {
              fetchData({ pageIndex: pageIndex - 1, pageSize });
            }
            previousPage();
          }}
          canNextPage={canNextPage}
          nextPage={() => {
            if (manualPagination) {
              fetchData({ pageIndex: pageIndex + 1, pageSize });
            }
            nextPage();
          }}
          gotoPage={(somePage) => {
            if (manualPagination) {
              fetchData({ pageIndex: somePage, pageSize });
            }
            gotoPage();
          }}
          manualPagination={manualPagination}
          pageOptions={pageOptions}
          countTotal={countTotal}
          newPagination={newPagination}
        />
      )}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      columns: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  manualPagination: PropTypes.bool,
  fetchData: PropTypes.func,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  onSubmitFilter: PropTypes.func,
  onClickCellButton: PropTypes.func,
  onClickSelectedCell: PropTypes.func,
  selectedFilters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({}))),
  sortBy: PropTypes.shape({
    ascending: PropTypes.bool,
    descending: PropTypes.bool,
    columnName: PropTypes.string,
  }),
  canSelect: PropTypes.bool,
  onChangeSwitch: PropTypes.func,
  pagination: PropTypes.bool,
  className: PropTypes.string,
  onRenderSelect: PropTypes.func,
  bodyClassName: PropTypes.string,
  countTotal: PropTypes.number,
  newPagination: PropTypes.bool,
  tableId: PropTypes.string,
  columnsToHide: PropTypes.arrayOf(PropTypes.string),
};

Table.defaultProps = {
  onSubmitFilter: () => {},
  onClickCellButton: () => {},
  onClickSelectedCell: () => {},
  onChangeSwitch: () => {},
  onRenderSelect: () => <div />,
  itemsPerPage: 10,
  currentPage: 0,
  manualPagination: false,
  fetchData: () => {},
  selectedFilters: {},
  sortBy: {},
  canSelect: false,
  pagination: true,
  className: '',
  bodyClassName: '',
  countTotal: 0,
  newPagination: false,
  tableId: 'tooltiptable',
  columnsToHide: [],
};

export default Table;
