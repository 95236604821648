import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Heading.module.scss';

export const Size = Object.freeze({
  XXL: 'xxl',
  XL: 'xl',
  L: 'l',
  M: 'm',
  S: 's',
  XS: 'xs',
});

const Heading = ({ size, children, className }) => (
  <p className={classNames(classes.heading, classes[size], className)}>
    {children}
  </p>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
};

Heading.defaultProps = {
  size: Size.M,
  className: '',
};

export default Heading;
