import Switch from 'components/Common/Switch';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useQuery from 'refactored/hooks/useQuery';
import QueryPlaygroundContent from './QueryPlaygroundContent';
import QueryPlaygroundContentCompare from './QueryPlaygroundContentCompare';
import exampleData from './data';
import classes from './QueryPlayground.module.scss';

const QueryPlayground = ({ refactorOn: refactorOnProp }) => {
  const [refactorOn, setRefactorOn] = useState(refactorOnProp);
  const [dataOn, setDataOn] = useState(true);

  const data = useMemo(() => (dataOn ? exampleData : []), [dataOn]);

  const [query, updateQuery] = useQuery({ exampleProperty1: 'apple' });

  return (
    <div className={classes.wrapper}>
      <h2>Query Playground</h2>
      <div className={classes.controls}>
        <Switch
          enabled={refactorOn}
          setEnabled={setRefactorOn}
          leftText="Compare"
          rightText="Refactor"
        />
        <Switch
          enabled={dataOn}
          setEnabled={setDataOn}
          leftText="No Data"
          rightText="Data"
        />
      </div>
      <div className={classes.contentAndQuery}>
        <pre className={classes.query}>
          <code style={{ opacity: refactorOn ? 1 : 0.4 }}>
            {JSON.stringify(query, null, ' ')}
          </code>
        </pre>
        <div className={classes.content}>
          {refactorOn ? (
            <QueryPlaygroundContent
              data={data}
              query={query}
              updateQuery={updateQuery}
            />
          ) : (
            <QueryPlaygroundContentCompare data={data} />
          )}
        </div>
      </div>
    </div>
  );
};

QueryPlayground.propTypes = {
  refactorOn: PropTypes.bool,
};

QueryPlayground.defaultProps = {
  refactorOn: true,
};
export default QueryPlayground;
