/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const selectAudiencesState = (state) => state.audiences;

export const selectFetchAudiencesState = createSelector(
  selectAudiencesState,
  ({
    fetchAudiencesLoading,
    fetchAudiencesSuccess,
    fetchAudiencesError,
    audiences,
  }) => ({
    loading: fetchAudiencesLoading,
    success: fetchAudiencesSuccess,
    error: fetchAudiencesError,
    audiencesData: audiences || {},
  })
);

export const selectFetchAudienceByIdState = createSelector(
  selectAudiencesState,
  ({
    fetchAudienceByIdLoading,
    fetchAudienceByIdSuccess,
    fetchAudienceByIdError,
    audience,
  }) => ({
    loading: fetchAudienceByIdLoading,
    success: fetchAudienceByIdSuccess,
    error: fetchAudienceByIdError,
    audience,
  })
);

export const selectNewAudienceState = createSelector(
  selectAudiencesState,
  ({ newAudience }) => newAudience
);

export const selectCurrentAudienceRule = createSelector(
  selectAudiencesState,
  ({ selectedRule, isEditing }) => ({
    selectedRule,
    isEditing,
  })
);

export const selectEditAudienceState = createSelector(
  selectAudiencesState,
  ({ editAudienceLoading, editAudienceSuccess, editAudienceError }) => ({
    loading: editAudienceLoading,
    success: editAudienceSuccess,
    error: editAudienceError,
  })
);

export const selectCreateAudienceState = createSelector(
  selectAudiencesState,
  ({ createAudienceLoading, createAudienceSuccess, createAudienceError }) => ({
    loading: createAudienceLoading,
    success: createAudienceSuccess,
    error: createAudienceError,
  })
);

export const selectAddAudienceToOfferState = createSelector(
  selectAudiencesState,
  ({
    addAudienceToOfferLoading,
    addAudienceToOfferSuccess,
    addAudienceToOfferError,
  }) => ({
    loading: addAudienceToOfferLoading,
    success: addAudienceToOfferSuccess,
    error: addAudienceToOfferError,
  })
);

export const selectRemoveAudienceToOfferState = createSelector(
  selectAudiencesState,
  ({
    removeAudienceToOfferLoading,
    removeAudienceToOfferSuccess,
    removeAudienceToOfferError,
  }) => ({
    loading: removeAudienceToOfferLoading,
    success: removeAudienceToOfferSuccess,
    error: removeAudienceToOfferError,
  })
);

export const selectFetchAudienceSummaryDataPoints = createSelector(
  selectAudiencesState,
  ({
    fetchDataPointsLoading,
    fetchDataPointsSuccess,
    fetchDataPointsError,
    audienceDataSummaryPoint,
  }) => ({
    loading: fetchDataPointsLoading,
    success: fetchDataPointsSuccess,
    error: fetchDataPointsError,
    audienceDataSummaryPoint: audienceDataSummaryPoint || {},
  })
);
