import { Auth } from 'aws-amplify';
import amplifyErrorMessage from 'utils/amplifyErrorMessage';

const resendConfirmationCodeService = async (username) => {
  try {
    const resp = await Auth.resendSignUp(username);
    return resp;
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export default resendConfirmationCodeService;
