import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  selectEditMerchantState,
  selectFetchMerchantCategoriesState,
  selectFetchMerchantIssuersState,
} from 'state/selectors/merchants';
import {
  editMerchant,
  fetchMerchantCategories,
  fetchMerchantIssuers,
} from 'state/actions/merchants';

import Form, { ValidationMode } from 'components/Common/Form';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import FormControl from 'components/Common/FormControl';
import Input from 'components/Common/Input';
import Button, { Kind, Type } from 'components/Common/Button';
import Modal from 'components/Common/Modal';
import useModal from 'hooks/useModal';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Action from 'enums/actions/actions.enum';
import ModalType from 'enums/modal/modalType';
import Sources from 'enums/sources/sources.enum';
import Status from 'enums/status/status.enum';
import LocationsData from 'components/Pages/LocationsData';
import merchantPropTypes from 'utils/propTypes/merchants';
import getColumns from 'utils/offers/merchantInfoOffersColumns';
import Table from 'components/Common/Table';
import Body, { Color } from 'components/Typography/Body';
import reorderArray from 'utils/orderArray';

import validationSchema from './MerchantInfo.schema';
import classes from './MerchantInfo.module.scss';
import getInputFields from './getInputFields';

const MerchantInfo = ({ merchant, offers, closedOffers, onCancel }) => {
  const dispatch = useDispatch();
  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const [isEditing, setIsEditing] = useState(false);

  const [status, setStatus] = useState(merchant?.status);

  const [realTimeMatch, setRealTimeMatch] = useState(
    merchant?.realTimeMatch || false
  );

  const [reviewMatches, setReviewMatches] = useState(
    merchant?.reviewMatches || false
  );

  const [associatedNames, setAssociatedNames] = useState(
    merchant?.nameMatchRegex ?? []
  );

  const [excludedNames, setExcludedNames] = useState(
    merchant?.excludeRegex ?? []
  );

  const [priorityOffers, setPriorityOffers] = useState(offers);

  const offersLength = useMemo(() => priorityOffers?.length - 1, [
    priorityOffers,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeOrder = (index, direction) => {
    setPriorityOffers(
      reorderArray(
        {
          prevIndex: index,
          newIndex: index + (direction === Action.UP ? -1 : 1),
        },
        priorityOffers
      )
    );
  };

  const onOrderUp = useCallback(
    (rowIndex) => {
      changeOrder(rowIndex, Action.UP);
    },
    [changeOrder]
  );

  const onOrderDown = useCallback(
    (rowIndex) => {
      changeOrder(rowIndex, Action.DOWN);
    },
    [changeOrder]
  );

  const columns = useMemo(
    () => getColumns({ isEditing, onOrderUp, onOrderDown, offersLength }),
    [isEditing, onOrderUp, onOrderDown, offersLength]
  );

  useEffect(() => {
    dispatch(fetchMerchantCategories());
    dispatch(fetchMerchantIssuers());
  }, [dispatch]);

  const onChangeStatusHandler = useCallback(() => {
    if (isEditing) {
      setStatus((prevState) => {
        // ACTIVE to INACTIVE
        if (prevState === Status.ACTIVE) {
          return Status.INACTIVE;
        }

        // INACTIVE (or CLOSED) to ACTIVE
        return Status.ACTIVE;
      });
    }
  }, [isEditing]);

  const { categoriesOptions, loading: loadingMerchantCategories } = useSelector(
    selectFetchMerchantCategoriesState,
    shallowEqual
  );

  const {
    loading: loadingEdit,
    success: editMerchantSuccess,
    error: errorEdit,
  } = useSelector(selectEditMerchantState, shallowEqual);

  const { issuersOptions } = useSelector(
    selectFetchMerchantIssuersState,
    shallowEqual
  );

  const onChangeCheckboxStateHandler = useCallback(
    (setState) => {
      if (isEditing) {
        setState((prevState) => !prevState);
      }
    },
    [isEditing]
  );

  const onSubmitHandler = (values) => {
    const valuesCopy = { ...values };
    const savedOffers = [...priorityOffers, ...closedOffers];

    const updateBody = {
      ...merchant,
      ...valuesCopy,
      nameMatchRegex: associatedNames,
      excludeRegex: excludedNames,
      status: Status[status],
      offers: savedOffers.map(({ _id }) => _id) ?? [],
      realTimeMatch,
      reviewMatches,
    };

    const { merchantId } = merchant;

    const { fraudWarningAmountInCents } = updateBody;
    updateBody.fraudWarningAmountInCents = Number(fraudWarningAmountInCents);
    delete updateBody.merchantId;
    delete updateBody.__v;
    delete updateBody._id;
    delete updateBody.createdDate;
    delete updateBody.lastModified;

    dispatch(editMerchant(merchantId, updateBody));
  };

  const inputFields = getInputFields({
    isEditing,
    categoriesOptions,
    issuersOptions,
    loadingMerchantCategories,
    merchant,
    onChangeCheckboxStateHandler,
    onChangeStatusHandler,
    status,
    realTimeMatch,
    reviewMatches,
    setRealTimeMatch,
    setReviewMatches,
    associatedNames,
    setAssociatedNames,
    excludedNames,
    setExcludedNames,
  });

  const isNational = merchant?.source === Sources.NATIONAL;

  return (
    <>
      {errorEdit && (
        <Toast
          id="error-edit-merchant"
          text="Error updating a Merchant!"
          type={ToastType.Error}
        />
      )}
      {editMerchantSuccess && (
        <Toast
          id="success-updating-merchant"
          text="Merchant updated successfully!"
          type={ToastType.Success}
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.MERCHANT_LOCATIONS_DATA}
        onClose={onCloseModalHandler}
        className={classes.modal}
      >
        <LocationsData
          merchantName={merchant?.name || 'merchant'}
          merchantId={merchant?.merchantId || ''}
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <Form
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnChange}
      >
        <Heading size={HeadingSize.S} className={classes.title}>
          Additional Merchant Information
        </Heading>
        {inputFields.map(({ fields, rowId }) => (
          <div key={rowId} className={classes.row}>
            {fields.map(
              ({
                id,
                name,
                component: Component,
                renderProps,
                fieldClassName,
                hidden = false,
                ...otherProps
              }) => {
                if (hidden) {
                  return null;
                }

                return (
                  <div
                    key={id}
                    className={classNames(classes.field, fieldClassName)}
                  >
                    <FormControl
                      name={name}
                      render={(props) =>
                        renderProps ? (
                          <Input {...renderProps} {...props} />
                        ) : (
                          <Component {...otherProps} {...props} />
                        )
                      }
                    />
                  </div>
                );
              }
            )}
          </div>
        ))}
        {isNational && (
          <div className={classes.offers}>
            <Heading size={HeadingSize.S} className={classes.offersHeader}>
              Offers - ({offersLength + 1})
            </Heading>
            <Body color={Color.Gray}>
              Listed in highest (top) to lowest (bottom) priority
            </Body>
            <Table
              columns={columns}
              data={priorityOffers}
              pagination={false}
              itemsPerPage={100}
              className={classes.table}
              tableId="merchantModal"
            />
          </div>
        )}
        <div className={classes.buttons}>
          <Button
            kind={Kind.Underlined}
            onClick={() => {
              onOpenModalHandler(ModalType.MERCHANT_LOCATIONS_DATA);
            }}
          >
            See Locations
          </Button>
          <Button
            kind={Kind.Secondary}
            onClick={onCancel}
            className={classes.backButton}
          >
            {isEditing ? 'Cancel' : 'Back'}
          </Button>
          <Button
            className={classNames({
              [classes.display]: !isEditing,
            })}
            type={Type.Submit}
            loading={loadingEdit}
            disabled={loadingEdit}
          >
            Save
          </Button>
          <Button
            className={classNames(classes.editButton, {
              [classes.display]: isEditing,
            })}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        </div>
      </Form>
    </>
  );
};

MerchantInfo.propTypes = {
  merchant: merchantPropTypes,
  offers: PropTypes.arrayOf(PropTypes.shape({})),
  closedOffers: PropTypes.arrayOf(PropTypes.shape({})),
  onCancel: PropTypes.func,
};

MerchantInfo.defaultProps = {
  merchant: {},
  offers: [],
  closedOffers: [],
  onCancel: () => {},
};

export default MerchantInfo;
