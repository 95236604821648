/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchPortalUsersInit,
  fetchPortalUsersSuccess,
  fetchPortalUsersFail,
  editPortalUserInit,
  editPortalUserSuccess,
  editPortalUserFail,
  clearEditPortalUserData,
  createPortalUserInit,
  createPortalUserSuccess,
  createPortalUserFail,
  clearPortalUserErrorsData,
  fetchUserProfileInit,
  fetchUserProfileSuccess,
  fetchUserProfileFail,
} from 'state/actionCreators/users';

export const initialState = {
  fetchPortalUsersLoading: false,
  fetchPortalUsersSuccess: false,
  fetchPortalUsersError: null,
  editPortalUserLoading: false,
  editPortalUserSuccess: false,
  editPortalUserError: null,
  createPortalUserLoading: false,
  createPortalUserSuccess: false,
  createPortalUserError: null,
  fetchUserProfileLoading: false,
  fetchUserProfileSuccess: false,
  fetchUserProfileError: null,
  portalUsers: [],
  userProfile: null,
};

const persistConfig = {
  key: 'users',
  storage,
  blacklist: [
    'fetchPortalUsersLoading',
    'fetchPortalUsersSuccess',
    'fetchPortalUsersError',
    'editPortalUserLoading',
    'editPortalUserSuccess',
    'editPortalUserError',
    'createPortalUserLoading',
    'createPortalUserSuccess',
    'createPortalUserError',
    'fetchUserProfileLoading',
    'fetchUserProfileSuccess',
    'fetchUserProfileError',
    'userProfile',
    'portalUsers',
  ],
};

const usersReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [fetchPortalUsersInit]: (state) => {
      state.fetchPortalUsersLoading = true;
      state.editPortalUserSuccess = false;
      state.createPortalUserSuccess = false;
      state.portalUsers = [];
    },
    [fetchPortalUsersSuccess]: (state, action) => {
      const { users } = action.payload;
      state.fetchPortalUsersLoading = false;
      state.fetchPortalUsersError = null;
      state.fetchPortalUsersSuccess = true;
      state.portalUsers = users;
    },
    [fetchPortalUsersFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchPortalUsersLoading = false;
      state.fetchPortalUsersError = error;
      state.fetchPortalUsersSuccess = false;
      state.portalUsers = [];
    },
    [editPortalUserInit]: (state) => {
      state.editPortalUserLoading = true;
    },
    [editPortalUserSuccess]: (state) => {
      state.editPortalUserLoading = false;
      state.editPortalUserError = null;
      state.editPortalUserSuccess = true;
    },
    [editPortalUserFail]: (state, action) => {
      const { error } = action.payload;
      state.editPortalUserLoading = false;
      state.editPortalUserError = error;
      state.editPortalUserSuccess = false;
    },
    [clearEditPortalUserData]: (state) => {
      state.editPortalUserLoading = false;
      state.editPortalUserError = null;
      state.editPortalUserSuccess = false;
    },
    [createPortalUserInit]: (state) => {
      state.createPortalUserLoading = true;
    },
    [createPortalUserSuccess]: (state) => {
      state.createPortalUserLoading = false;
      state.createPortalUserError = null;
      state.createPortalUserSuccess = true;
    },
    [createPortalUserFail]: (state, action) => {
      const { error } = action.payload;
      state.createPortalUserLoading = false;
      state.createPortalUserError = error;
      state.createPortalUserSuccess = false;
    },
    [clearPortalUserErrorsData]: (state) => {
      state.createPortalUserLoading = false;
      state.createPortalUserError = null;
      state.createPortalUserSuccess = false;
      state.editPortalUserLoading = false;
      state.editPortalUserError = null;
      state.editPortalUserSuccess = false;
    },
    [fetchUserProfileInit]: (state) => {
      state.fetchUserProfileLoading = true;
    },
    [fetchUserProfileSuccess]: (state, action) => {
      const { user } = action.payload;
      state.fetchUserProfileLoading = false;
      state.fetchUserProfileError = null;
      state.fetchUserProfileSuccess = true;
      state.userProfile = user;
    },
    [fetchUserProfileFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchUserProfileLoading = false;
      state.fetchUserProfileError = error;
      state.fetchUserProfileSuccess = false;
    },
  })
);

export default usersReducer;
