import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Table from 'components/Common/Table';
import Modal from 'components/Common/Modal';
import UserForm from 'components/Pages/UserForm';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import ConfirmationMessage from 'components/Common/ConfirmationMessage';
import {
  clearPortalUserErrors,
  editPortalUser,
  fetchPortalUsers,
} from 'state/actions/users';
import {
  selectCreatePortalUserState,
  selectEditPortalUserState,
  selectFetchPortalUsersState,
} from 'state/selectors/users';
import searchIcon from 'assets/icons/search.svg';
import addIcon from 'assets/icons/close.svg';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType';
import Columns from 'enums/table/columns.enum';
import Status from 'enums/status/status.enum';
import getUsersFilters from 'utils/filters/usersFilter';
import columns from 'utils/users/columns';

import getPortalUserData from 'utils/users/getPortalUserData';
import classes from './PortalUsers.module.scss';

const LIMIT = 50;

const PortalUsers = () => {
  const dispatch = useDispatch();

  const {
    portalUsersData: { portalUsers, countTotal },
    loading,
  } = useSelector(selectFetchPortalUsersState, shallowEqual);

  const {
    success: successEdit,
    loading: loadingEdit,
    error: errorEdit,
  } = useSelector(selectEditPortalUserState, shallowEqual);

  const { success: successCreate, error: errorCreate } = useSelector(
    selectCreatePortalUserState,
    shallowEqual
  );

  const data = useMemo(() => portalUsers.map(getPortalUserData), [portalUsers]);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const [query, setQuery] = useState({ page: 0 });

  // used by the table. relevant fields are copied to query.
  const [selectedFilters, setSelectedFilters] = useState({});

  // if filters are reset/removed from the query, remove them on selectedFilters
  useEffect(() => {
    setSelectedFilters((prevSelectedFilters) =>
      Object.fromEntries(
        Object.entries(prevSelectedFilters).filter(
          ([column]) => column in query
        )
      )
    );
  }, [query]);

  useEffect(() => {
    const fetchSearchParams = getUsersFilters({
      ...query,
      limit: LIMIT,
    });
    dispatch(fetchPortalUsers(`?${fetchSearchParams}`));
  }, [dispatch, query]);

  // when I update or create a user, pagination and filters should be reset.
  useEffect(() => {
    if (successEdit || successCreate) {
      setQuery({ page: 0 });
      onCloseModalHandler();
    }
  }, [onCloseModalHandler, successCreate, successEdit]);

  // TODO: is this deprecated?
  useEffect(() => {
    if (errorCreate || errorEdit) {
      dispatch(clearPortalUserErrors());
      if (modal.type === ModalType.USER_STATUS) {
        onCloseModalHandler();
      }
    }
  }, [errorCreate, errorEdit, modal, onCloseModalHandler, dispatch]);

  const onClickCellButtonHandler = useCallback(
    (columnName, rowIndex) => {
      if (columnName === Columns.MoreInfo) {
        onOpenModalHandler(ModalType.USER_PROFILE, { user: data[rowIndex] });
      }
    },
    [onOpenModalHandler, data]
  );

  const onChangeSwitchHandler = useCallback(
    (columnName, index, status) => {
      if (columnName === Columns.Status) {
        onOpenModalHandler(ModalType.USER_STATUS, {
          status,
          user: data[index],
        });
      }
    },
    [onOpenModalHandler, data]
  );

  const onChangeUserStatusHandler = useCallback(() => {
    const { user } = modal;
    const actualStatus = user.status === Status.ACTIVE;
    const body = {
      enabled: !actualStatus,
      username: user.username,
      email: user.email,
      role: user.roles, // note: the API is not updated to use 'roles'
      name: user.fullName,
      title: user.title,
      phone_number: user.phoneNumber,
    };
    dispatch(editPortalUser(body));
  }, [modal, dispatch]);

  const onAddUserHandler = useCallback(() => {
    onOpenModalHandler(ModalType.ADD_USER);
  }, [onOpenModalHandler]);

  /**
   * Change the values some column is filtering by, if any.
   * @param {{columnName: string, selected: any[], resetPagination: boolean}} param0
   * The `columnName` being filtered on, the `selected` values to filter by, and whether it is necessary to `resetPagination`
   * after updating the filter.
   */
  const updateFilter = useCallback(
    ({
      columnName, // field name
      selected, // values being filtered by
      resetPagination,
    }) => {
      // Take the last query, except for this column - reset pagination if necessary and set the new value if it exists.
      setQuery(({ [columnName]: _, ...prevQuery }) => ({
        ...prevQuery,
        ...(resetPagination && { page: 0 }),
        ...(selected.length > 0 && {
          [columnName]: selected.map((x) => x.text).join(','), // if there are multiple values, comma-separate
        }),
      }));
      // Keep the existing filters as-is, but modify this one.
      setSelectedFilters((prevSelectedFilters) => ({
        ...prevSelectedFilters,
        [columnName]: selected,
      }));
    },
    []
  );

  return (
    <>
      {errorEdit && modal.type === ModalType.USER_STATUS && (
        <Toast id="edit user error" text={errorEdit} type={ToastType.Error} />
      )}
      <Modal
        isOpen={modal.type === ModalType.USER_STATUS}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <ConfirmationMessage
          message={`Are you sure you want to update the status of this user to ${
            modal.status === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE
          }?`}
          onAccept={onChangeUserStatusHandler}
          onCancel={onCloseModalHandler}
          loading={loadingEdit}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.USER_PROFILE}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <UserForm
          title="User Information"
          user={modal.user}
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ADD_USER}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <UserForm
          title="Invite User"
          isCreating
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <div className={classes.table}>
        <div className={classes.tableHeading}>
          <div className={classes.searchWidth}>
            <Input
              name="search"
              onKeyUp={(event) => {
                const search = event.target.value;
                if (event.key === 'Enter' || (search === '' && query.email)) {
                  setQuery({ page: 0, email: search });
                }
              }}
              placeholder="Search by Email"
              type={InputType.Text}
              icon={searchIcon}
              size={InputSize.S}
              className={classes.search}
            />
          </div>
          <Button
            type={ButtonType.Default}
            size={ButtonSize.S}
            className={classes.button}
            onClick={onAddUserHandler}
          >
            <div className={classes.addIcon}>
              <img src={addIcon} alt="add" />
            </div>
          </Button>
        </div>
        {loading ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={columns}
            data={data}
            onClickCellButton={onClickCellButtonHandler}
            onChangeSwitch={onChangeSwitchHandler}
            selectedFilters={selectedFilters}
            onSubmitFilter={updateFilter}
            fetchData={({ pageIndex: page }) =>
              setQuery((prevQuery) => ({ ...prevQuery, page }))
            }
            bodyClassName={classes.tableBody}
            itemsPerPage={LIMIT}
            currentPage={query.page}
            manualPagination
            newPagination
            countTotal={Math.ceil(countTotal / LIMIT)}
          />
        )}
      </div>
    </>
  );
};

export default PortalUsers;
