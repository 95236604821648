/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';

import classes from './OfferIssuersCommission.module.scss';

const OfferIssuersCommission = ({ issuersCommission, onClose }) => (
  <div className={classes.container}>
    <Heading size={HeadingSize.M} className={classes.heading}>
      Issuer Commissions Map
    </Heading>
    {Object.entries(issuersCommission).map((commission) => (
      <div className={classes.info}>
        <Body size={BodySize.S} className={classes.label}>
          {commission[0]}
        </Body>
        <Body size={BodySize.S} className={classes.value}>
          {commission[1]}
        </Body>
      </div>
    ))}
    {Object.values(issuersCommission).length === 0 && (
      <Body size={BodySize.S}>No commisions to show</Body>
    )}
    <div className={classes.actionButtons}>
      <Button
        type={ButtonType.Default}
        size={ButtonSize.S}
        className={classes.button}
        onClick={onClose}
        kind={ButtonKind.Secondary}
      >
        Go back
      </Button>
    </div>
  </div>
);

OfferIssuersCommission.propTypes = {
  issuersCommission: PropTypes.objectOf(PropTypes.number).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OfferIssuersCommission;
