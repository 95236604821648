import React from 'react';
import PropTypes from 'prop-types';

import MultipleStepForm from 'components/Common/MultipleStepForm';
import RuleBuilder from './RuleBuilder';
import AudienceInformation from './AudienceInformation';

// import classes from './CreateAudienceForm.module.scss';

const steps = [RuleBuilder, AudienceInformation];

const CreateAudienceForm = ({ onClose, isCreating, position, audience }) => (
  <MultipleStepForm
    steps={steps}
    onSubmit={onClose}
    onCancel={onClose}
    isCreating={isCreating}
    audience={audience}
    position={position}
  />
);

CreateAudienceForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  position: PropTypes.number,
  audience: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

CreateAudienceForm.defaultProps = {
  position: 0,
  audience: null,
};

export default CreateAudienceForm;
