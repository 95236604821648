import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'state/reducers/auth';
import reportsReducer from 'state/reducers/reports';
import locationsReducer from './locations';
import merchantsReducer from './merchants';
import issuersReducer from './issuers';
import offersReducer from './offers';
import usersReducer from './users';
import pendingTransactionsReducer from './pendingTransactions';
import auditsReducer from './audits';
import audiencesReducer from './audiences';
import exportDataReducer from './exportData';
import layoutReducer from './layout';

const combinedReducer = combineReducers({
  auth: authReducer,
  reports: reportsReducer,
  users: usersReducer,
  offers: offersReducer,
  merchants: merchantsReducer,
  issuers: issuersReducer,
  locations: locationsReducer,
  pendingTransactions: pendingTransactionsReducer,
  audits: auditsReducer,
  audiences: audiencesReducer,
  exportData: exportDataReducer,
  layout: layoutReducer,
});

const rootReducer = (state, action) => {
  if (action.type === '[Auth] Logout Success') {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;
