import client from 'services/kardAPI';

const fetchReports = async (filters) => {
  try {
    const reports = await client.get(
      `/transactions/portal/reporting${filters}`
    );

    return reports.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchReports;
