/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  signInInit,
  signInSuccess,
  signInFail,
  passwordChangeRequired,
  passwordChangeInit,
  passwordChangeSuccess,
  passwordChangeFail,
  resumeSessionSuccess,
  resumeSessionFail,
  forgotPasswordInit,
  forgotPasswordSuccess,
  forgotPasswordFail,
  forgotPasswordConfirmationInit,
  forgotPasswordConfirmationSuccess,
  forgotPasswordConfirmationFail,
  clearForgotPasswordData,
  logoutInit,
  logoutSuccess,
  logoutFail,
  resendConfirmationCodeInit,
  resendConfirmationCodeSuccess,
  resendConfirmationCodeFail,
  resumeSessionInit,
} from 'state/actionCreators/auth';

export const initialState = {
  resumeSessionError: null,
  signInLoading: false,
  signInSuccess: false,
  signInError: null,
  passwordChangeRequired: false,
  passwordChangeLoading: false,
  passwordChangeSuccess: false,
  passwordChangeError: null,
  forgotPasswordLoading: false,
  forgotPasswordSuccess: false,
  forgotPasswordError: null,
  forgotPasswordConfirmationLoading: false,
  forgotPasswordConfirmationSuccess: false,
  forgotPasswordConfirmationError: null,
  logoutLoading: false,
  logoutSuccess: false,
  logoutError: null,
  username: null,
  oldPassword: null,
  user: null,
  resendConfirmationCodeLoading: false,
  resendConfirmationCodeSuccess: false,
  resendConfirmationCodeError: null,
};

const persistConfig = {
  key: 'auth',
  storage,
  blacklist: [
    'signInLoading',
    'signInSuccess',
    'signInError',
    'resumeSessionError',
    'passwordChangeRequired',
    'passwordChangeLoading',
    'passwordChangeSuccess',
    'passwordChangeError',
    'username',
    'oldPassword',
    'forgotPasswordLoading',
    'forgotPasswordSuccess',
    'forgotPasswordError',
    'forgotPasswordConfirmationLoading',
    'forgotPasswordConfirmationSuccess',
    'forgotPasswordConfirmationError',
    'logoutLoading',
    'logoutSuccess',
    'logoutError',
    'resendConfirmationCodeLoading',
    'resendConfirmationCodeSuccess',
    'resendConfirmationCodeError',
  ],
};

const authReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [signInInit]: (state) => {
      state.signInLoading = true;
    },
    [signInSuccess]: (state, action) => {
      const { user } = action.payload;
      state.signInLoading = false;
      state.signInError = null;
      state.signInSuccess = true;
      state.user = user;
    },
    [signInFail]: (state, action) => {
      const { error } = action.payload;
      state.signInLoading = false;
      state.signInError = error;
      state.signInSuccess = false;
    },
    [passwordChangeRequired]: (state, action) => {
      const { username, oldPassword } = action.payload;
      state.passwordChangeRequired = true;
      state.signInLoading = false;
      state.signInError = null;
      state.username = username;
      state.oldPassword = oldPassword;
    },
    [passwordChangeInit]: (state) => {
      state.passwordChangeLoading = true;
    },
    [passwordChangeSuccess]: (state, action) => {
      const { user } = action.payload;
      state.passwordChangeLoading = false;
      state.passwordChangeError = null;
      state.passwordChangeSuccess = true;
      state.passwordChangeRequired = false;
      state.user = { ...user };
    },
    [passwordChangeFail]: (state, action) => {
      const { error } = action.payload;
      state.passwordChangeLoading = false;
      state.passwordChangeError = error;
      state.passwordChangeSuccess = false;
    },
    [forgotPasswordInit]: (state) => {
      state.forgotPasswordLoading = true;
    },
    [forgotPasswordSuccess]: (state, action) => {
      const { username } = action.payload;
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = true;
      state.forgotPasswordError = null;
      state.username = username;
    },
    [forgotPasswordFail]: (state, action) => {
      const { error } = action.payload;
      state.forgotPasswordLoading = false;
      state.forgotPasswordError = error;
      state.forgotPasswordSuccess = false;
    },
    [forgotPasswordConfirmationInit]: (state) => {
      state.forgotPasswordConfirmationLoading = true;
    },
    [forgotPasswordConfirmationSuccess]: (state) => {
      state.forgotPasswordConfirmationLoading = false;
      state.forgotPasswordConfirmationSuccess = true;
      state.forgotPasswordConfirmationError = null;
    },
    [forgotPasswordConfirmationFail]: (state, action) => {
      const { error } = action.payload;
      state.forgotPasswordConfirmationLoading = false;
      state.forgotPasswordConfirmationError = error;
      state.forgotPasswordConfirmationSuccess = false;
    },
    [clearForgotPasswordData]: (state) => {
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = false;
      state.forgotPasswordError = null;
      state.forgotPasswordConfirmationLoading = false;
      state.forgotPasswordConfirmationSuccess = false;
      state.forgotPasswordConfirmationError = null;
    },
    [resumeSessionInit]: (state) => {
      state.resumeSessionError = null;
    },
    [resumeSessionSuccess]: (state, action) => {
      const { user } = action.payload;
      state.user = user;
    },
    [resumeSessionFail]: (state, { payload }) => {
      const { error } = payload;
      state.user = null;
      state.resumeSessionError = error;
    },
    [logoutInit]: (state) => {
      state.logoutLoading = true;
    },
    [logoutSuccess]: (state) => {
      state.logoutLoading = false;
      state.logoutSuccess = true;
      state.logoutError = null;
      state.signInSuccess = false;
      state.user = null;
    },
    [logoutFail]: (state, action) => {
      const { error } = action.payload;
      state.logoutLoading = false;
      state.logoutSuccess = false;
      state.logoutError = error;
    },
    [resendConfirmationCodeInit]: (state) => {
      state.resendConfirmationCodeLoading = true;
    },
    [resendConfirmationCodeSuccess]: (state) => {
      state.resendConfirmationCodeLoading = false;
      state.resendConfirmationCodeSuccess = true;
      state.resendConfirmationCodeError = null;
    },
    [resendConfirmationCodeFail]: (state, action) => {
      const { error } = action.payload;
      state.resendConfirmationCodeLoading = false;
      state.resendConfirmationCodeSuccess = false;
      state.resendConfirmationCodeError = error;
    },
  })
);

export default authReducer;
