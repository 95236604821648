export const commonMessages = {
  ReSendInvitationSuccessMsg: 'Invitation has been resent successfully.',
  ReSendInvitationErrorMsg: 'there was a problem resending the invitation.',
  SuccessEditTransactionMsg: 'Transaction approved successfully.',
  RejectEditTransactionMsg: 'Transaction rejected successfully.',
  ErrorEditTransactionMsg: 'Transaction was not approved.',
  DescriptionSupportMessage:
    'Please provide some details on the transaction issue for our team to best assist you.',
  SuccessOfferMsg: 'Offer updated successfully.',
};

export default commonMessages;
