import client from 'services/kardAPI';

const fetchMerchants = async (filters = '') => {
  try {
    const merchants = await client.get(`/rewards/portal/merchant${filters}`);
    return merchants.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchMerchants;
