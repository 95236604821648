import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Body, { Color as BodyColor } from 'components/Typography/Body';
import checkboxIcon from 'assets/icons/checkbox.svg';
import primaryCheckboxSelectedIcon from 'assets/icons/color/checkbox-selected.svg';
import secondaryCheckboxSelectedIcon from 'assets/icons/checkbox-selected.svg';

import classes from './Checkbox.module.scss';

export const Kind = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
});

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
  XXS: 'xxs',
});

const Checkbox = ({
  label,
  selected,
  setSelected,
  className,
  kind,
  size,
  disabled,
}) => (
  <div className={classNames(className, classes.container)}>
    <button
      type="button"
      onClick={setSelected}
      className={classes.button}
      disabled={disabled}
    >
      {selected ? (
        <img
          src={
            kind === Kind.Primary
              ? primaryCheckboxSelectedIcon
              : secondaryCheckboxSelectedIcon
          }
          alt="selected"
          className={classNames(classes.img, classes[kind])}
        />
      ) : (
        <img
          src={checkboxIcon}
          alt="not selected"
          className={classNames(classes.img, classes[kind])}
        />
      )}
    </button>
    {label && (
      <Body size={size} color={BodyColor.Gray} className={classes.label}>
        {label}
      </Body>
    )}
  </div>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  kind: PropTypes.oneOf(Object.values(Kind)),
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Size)),
};

Checkbox.defaultProps = {
  label: null,
  disabled: false,
  className: '',
  kind: Kind.Primary,
  size: Size.S,
};

export default Checkbox;
