import {
  merchantSources,
  merchantTypes,
  merchantAcceptedCards,
} from 'utils/merchants/values';
import Checkbox, {
  Kind as CheckboxKind,
  Size as CheckboxSize,
} from 'components/Common/Checkbox';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import { LabelPosition } from 'enums/inputProps';
import Switch, {
  Size as SwitchSize,
  LabelPosition as SwitchLabelPosition,
} from 'components/Common/Switch';
import InputImage from 'components/Common/InputImage';

import MultiValueInput from 'components/Common/MultiValueInput';
import Status from 'enums/status/status.enum';
import { merchantNetworks } from 'utils/common/merchantNetwork';
import classes from './MerchantInfo.module.scss';

const getInputFields = ({
  isEditing,
  merchant,
  categoriesOptions,
  loadingMerchantCategories,
  issuersOptions,
  onChangeCheckboxStateHandler,
  realTimeMatch,
  setRealTimeMatch,
  setReviewMatches,
  reviewMatches,
  onChangeStatusHandler,
  status,
  setAssociatedNames,
  associatedNames,
  excludedNames,
  setExcludedNames,
}) => [
  {
    rowId: '1',
    fields: [
      {
        id: 'name',
        name: 'name',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Name',
          value: merchant?.name,
          placeholder: 'Name',
          readOnly: !isEditing,
          type: InputType.Text,
          size: InputSize.S,
          color: isEditing ? InputColor.Black : InputColor.Gray,
        },
      },
      {
        id: 'merchantId',
        name: 'merchantId',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Merchant ID',
          value: merchant?.merchantId,
          placeholder: 'Merchant ID',
          type: InputType.Text,
          readOnly: true,
          disabled: true,
          size: InputSize.S,
          color: InputColor.Black,
        },
      },
    ],
  },
  {
    rowId: '2',
    fields: [
      {
        id: 'description',
        name: 'description',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Descriptor',
          value: merchant?.description,
          placeholder: 'Descriptor',
          type: InputType.Text,
          readOnly: !isEditing,
          size: InputSize.S,
          color: isEditing ? InputColor.Black : InputColor.Gray,
        },
      },
    ],
  },
  {
    rowId: '3',
    fields: [
      {
        id: 'status:',
        name: 'status',
        fieldClassName: classes.status,
        component: Switch,
        enabled: status === Status.ACTIVE,
        setEnabled: onChangeStatusHandler,
        label: 'Status:',
        labelPosition: SwitchLabelPosition.Top,
        labelClassName: classes.labelWidth,
        rightText: Status[status],
        size: SwitchSize.S,
        disabled: !isEditing,
        className: classes.switch,
        hidden: !isEditing,
      },
      {
        id: 'status:',
        fieldClassName: classes.status,
        name: 'status',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Status',
          value: status?.toUpperCase(),
          placeholder: 'Status',
          type: InputType.Text,
          readOnly: !isEditing,
          size: InputSize.S,
          color: status === Status.ACTIVE ? InputColor.Green : InputColor.Red,
        },
        hidden: isEditing,
      },
      {
        id: 'createdDate',
        name: 'createdDate',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Created date',
          value: merchant?.createdDate
            ? new Date(merchant.createdDate).toLocaleString(undefined, {
                timeZoneName: 'short',
              })
            : '-',
          placeholder: 'Created date',
          type: InputType.Text,
          readOnly: true,
          disabled: true,
          size: InputSize.S,
          color: InputColor.Black,
        },
      },
      {
        id: 'source',
        name: 'source',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.source,
        label: 'Source',
        readOnly: !isEditing,
        options: merchantSources,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '4',
    fields: [
      {
        id: 'type',
        name: 'type',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.type,
        label: 'Type',
        readOnly: !isEditing,
        options: merchantTypes,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
      {
        id: 'merchantNetwork',
        name: 'merchantNetwork',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.merchantNetwork,
        label: 'Merchant Network',
        readOnly: !isEditing,
        options: merchantNetworks,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '5',
    fields: [
      {
        id: 'category',
        name: 'category',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.category,
        label: 'Category',
        readOnly: !isEditing,
        options: categoriesOptions,
        disabled: loadingMerchantCategories,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
      {
        id: 'acceptedCards',
        name: 'acceptedCards',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.acceptedCards,
        label: 'Accepted Cards',
        readOnly: !isEditing,
        options: merchantAcceptedCards,
        isMulti: true,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '6',
    fields: [
      {
        id: 'qualifiedIssuer',
        name: 'qualifiedIssuer',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.qualifiedIssuer,
        label: 'Qualified Issuer(s)',
        options: issuersOptions,
        disabled: true,
        isMulti: true,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '7',
    fields: [
      {
        id: 'imgUrl',
        name: 'imgUrl',
        component: InputImage,
        labelPosition: LabelPosition.Top,
        className: classes.input,
        label: 'Image URL',
        value: merchant?.imgUrl,
        placeholder: 'Image URL',
        readOnly: !isEditing,
        type: InputType.Text,
        size: InputSize.S,
        color: isEditing ? InputColor.Black : InputColor.Gray,
      },
      {
        id: 'bannerImgUrl',
        name: 'bannerImgUrl',
        component: InputImage,
        labelPosition: LabelPosition.Top,
        className: classes.input,
        label: 'Banner Image URL',
        value: merchant?.bannerImgUrl,
        placeholder: 'Banner Image URL',
        readOnly: !isEditing,
        type: InputType.Text,
        size: InputSize.S,
        color: isEditing ? InputColor.Black : InputColor.Gray,
      },
    ],
  },
  {
    rowId: '8',
    fields: [
      {
        id: 'websiteURL',
        name: 'websiteURL',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Website URL',
          value: merchant?.websiteURL,
          placeholder: 'Name',
          readOnly: !isEditing,
          type: InputType.Text,
          size: InputSize.S,
          color: isEditing ? InputColor.Black : InputColor.Gray,
        },
      },
      {
        id: 'fraudWarningAmountInCents',
        name: 'fraudWarningAmountInCents',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Max Allowable Transaction Amount for Notification',
          value: merchant?.fraudWarningAmountInCents,
          placeholder: 'Max Allowable Transaction Amount for Notification',
          readOnly: !isEditing,
          type: InputType.Text,
          size: InputSize.S,
          color: isEditing ? InputColor.Black : InputColor.Gray,
        },
      },
    ],
  },
  {
    rowId: '9',
    fields: [
      {
        id: 'Manual Review',
        name: 'Manual Review',
        component: Checkbox,
        label: 'Manual Review',
        setSelected: () => onChangeCheckboxStateHandler(setReviewMatches),
        selected: reviewMatches,
        disabled: !isEditing,
        className: classes.checkbox,
        kind: isEditing ? CheckboxKind.Primary : CheckboxKind.Secondary,
        size: CheckboxSize.S,
      },
    ],
  },
  {
    rowId: '10',
    fields: [
      {
        id: 'associatedName',
        component: MultiValueInput,
        name: 'associatedName',
        label: 'Matching Regular Expressions',
        emptyLabel: 'No names created',
        setValues: setAssociatedNames,
        values: associatedNames,
        disabled: !isEditing,
        readOnly: !isEditing,
      },
    ],
  },
  {
    rowId: '11',
    fields: [
      {
        id: 'excludedNames',
        component: MultiValueInput,
        name: 'excludedNames',
        label: 'Excluded Regular Expressions',
        emptyLabel: 'No expressions created',
        setValues: setExcludedNames,
        values: excludedNames,
        disabled: !isEditing,
        readOnly: !isEditing,
      },
    ],
  },
  {
    rowId: '12',
    fields: [
      {
        id: 'Real Time Match',
        name: 'Real Time Match',
        component: Checkbox,
        label: 'Real Time Match',
        setSelected: () => onChangeCheckboxStateHandler(setRealTimeMatch),
        selected: realTimeMatch,
        disabled: !isEditing,
        className: classes.checkbox,
        kind: isEditing ? CheckboxKind.Primary : CheckboxKind.Secondary,
        size: CheckboxSize.S,
      },
    ],
  },
];

export default getInputFields;
