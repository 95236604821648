const getAuditFilters = ({
  resetPagination,
  pageIndex = 0,
  pageLimit = 10,
  setPagination,
  auditId = null,
  selected = [],
  selectedFilters = [],
  setSelectedFilters = () => {},
  setIsDefaultFilter = () => {},
  columnName = '',
  dates = {},
  sortValue = false,
  sortBy = {},
  setSortBy = () => {},
}) => {
  const filtersQuery = new URLSearchParams();

  if (auditId) {
    filtersQuery.append('auditId', auditId);
  }

  let newSelectedValue = selected;
  if (dates.startDate) {
    newSelectedValue = dates;
  }

  Object.entries({
    ...selectedFilters,
    [columnName]: newSelectedValue,
  }).forEach((filter) => {
    const selectedValues = [];
    if (filter[1].isDate) {
      if (
        (filter[0] !== columnName && filter[1].startDate) ||
        (filter[0] === columnName && dates.startDate && dates.endDate)
      ) {
        const start = filter[1].startDate.format(
          'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
        );
        const end = filter[1].endDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

        filtersQuery.append(`${filter[0]}`, true);
        filtersQuery.append(`${filter[0]}Start`, start);
        filtersQuery.append(`${filter[0]}End`, end);
      }
    } else if (filter[1]) {
      filter[1].forEach((filterValue) => {
        const { text } = filterValue;
        selectedValues.push(text);
      });
      if (selectedValues.length > 0) {
        selectedValues.map((value) =>
          filtersQuery.append(`${filter[0]}`, value)
        );
      }
    }
  });
  if (sortValue.ascending || sortValue.descending) {
    const order = sortValue.ascending ? '1' : '-1';
    filtersQuery.append(`${sortValue.columnName}Sort`, order);
    setSortBy({
      ascending: sortValue.ascending,
      descending: sortValue.descending,
      columnName,
    });
  } else if (
    (!sortValue.ascending || !sortValue.descending) &&
    sortValue.columnName === sortBy.columnName
  ) {
    setSortBy({
      ascending: false,
      descending: false,
      columnName: null,
    });
  } else if (sortBy.ascending || sortBy.descending) {
    const order = sortBy.ascending ? '1' : '-1';
    filtersQuery.append(`${sortBy.columnName}Sort`, order);
  }

  setSelectedFilters((prevState) => {
    const newState = { ...prevState };

    if (dates && dates.startDate && dates.endDate) {
      newState[columnName] = { ...dates, isDate: true };
    } else if (selected.length > 0) {
      newState[columnName] = selected;
    } else {
      delete newState[columnName];
    }

    setIsDefaultFilter(false);
    return newState;
  });

  if (resetPagination) {
    filtersQuery.append('page', 0);
    filtersQuery.append('limit', pageLimit);
    setPagination({
      page: 0,
      limit: pageLimit,
    });
  } else {
    filtersQuery.append('page', pageIndex);
    filtersQuery.append('limit', pageLimit);
    setPagination({
      page: pageIndex,
      limit: pageLimit,
    });
  }

  return `?${filtersQuery.toString()}`;
};

export default getAuditFilters;
