/* eslint-disable import/prefer-default-export */
import {
  fetchOffersInit,
  fetchOffersSuccess,
  fetchOffersFail,
  createOfferInit,
  createOfferSuccess,
  createOfferFail,
  editOfferInit,
  editOfferSuccess,
  editOfferFail,
  clearOffersErrorsData,
  fetchOffersByMerchantIdInit,
  fetchOffersByMerchantIdSuccess,
  fetchOffersByMerchantIdFail,
  fetchOfferByIdInit,
  fetchOfferByIdSuccess,
  fetchOfferByIdFail,
  clearOffersSuccessProp,
} from 'state/actionCreators/offers';

import fetchOffersService from 'services/offers/fetchOffers';
import fetchOfferByIdService from 'services/offers/fetchOfferById';
import fetchOffersByMerchantIdService from 'services/offers/fetchOffersByMerchantId';
import createOfferService from 'services/offers/createOffer';
import editOfferService from 'services/offers/editOffer';

export const fetchOffers = (filters = '') => async (dispatch) => {
  dispatch(fetchOffersInit());

  try {
    const offers = await fetchOffersService(filters);
    return dispatch(fetchOffersSuccess({ offers }));
  } catch (error) {
    return dispatch(fetchOffersFail({ error: error.message }));
  }
};

export const fetchOffersByMerchantId = (id) => async (dispatch) => {
  dispatch(fetchOffersByMerchantIdInit());

  try {
    const offers = await fetchOffersByMerchantIdService(id);
    return dispatch(fetchOffersByMerchantIdSuccess({ offers }));
  } catch (error) {
    return dispatch(fetchOffersByMerchantIdFail({ error: error.message }));
  }
};

export const fetchOfferById = (id) => async (dispatch) => {
  dispatch(fetchOfferByIdInit());

  try {
    const offer = await fetchOfferByIdService(id);
    return dispatch(fetchOfferByIdSuccess({ offer }));
  } catch (error) {
    return dispatch(fetchOfferByIdFail({ error: error.message }));
  }
};

export const createOffer = (body) => async (dispatch) => {
  dispatch(createOfferInit());

  try {
    const offer = await createOfferService(body);
    return dispatch(createOfferSuccess({ offer }));
  } catch (error) {
    return dispatch(createOfferFail({ error: error.message }));
  }
};

export const editOffer = (id, body) => async (dispatch) => {
  dispatch(editOfferInit());

  try {
    await editOfferService(id, body);
    return dispatch(editOfferSuccess());
  } catch (error) {
    return dispatch(editOfferFail({ error: error.message }));
  }
};

export const clearOffersErrors = () => async (dispatch) => {
  dispatch(clearOffersErrorsData());
};

export const clearOffersSuccess = () => async (dispatch) => {
  dispatch(clearOffersSuccessProp());
};
