/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ErrorMessage from 'components/Typography/ErrorMessage';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, { Type as InputType } from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import { forgotPasswordConfirmation } from 'state/actions/auth';
import {
  selectForgotPasswordState,
  selectForgotPasswordConfirmationState,
} from 'state/selectors/auth';
import lockIcon from 'assets/icons/lock.svg';
import codeIcon from 'assets/icons/dial-numbers.svg';
import showPasswordIcon from 'assets/icons/eye-solid.svg';
import hidePasswordIcon from 'assets/icons/eye-slash-solid.svg';
import forgotPasswordConfirmationValidationSchema from './ForgotPasswordConfirmation.schema';

import classes from './ForgotPasswordConfirmation.module.scss';

const ForgotPasswordConfirmation = () => {
  const dispatch = useDispatch();

  const { username } = useSelector(selectForgotPasswordState, shallowEqual);

  const { loading, error } = useSelector(
    selectForgotPasswordConfirmationState,
    shallowEqual
  );

  const [showPassword, setShowPassword] = useState(false);

  const onSubmitHandler = useCallback(
    ({ code, newPassword }) => {
      dispatch(forgotPasswordConfirmation(username, code, newPassword));
    },
    [dispatch, username]
  );

  const onChangeShowPassword = useCallback(
    () => setShowPassword((prevState) => !prevState),
    []
  );

  return (
    <>
      <Heading size={HeadingSize.XL}>Forgot password</Heading>
      <Form
        validationSchema={forgotPasswordConfirmationValidationSchema}
        validationMode={ValidationMode.OnChange}
        onSubmit={onSubmitHandler}
      >
        <label className={classes.label}>Code</label>
        <FormControl
          name="code"
          render={(props) => (
            <Input type={InputType.Number} icon={codeIcon} {...props} />
          )}
        />
        <label className={classes.label}>New password</label>
        <div className={classes.passwordContainer}>
          <FormControl
            name="newPassword"
            render={(props) => (
              <Input
                type={showPassword ? InputType.Text : InputType.Password}
                icon={lockIcon}
                className={classes.newPasswordInput}
                {...props}
              />
            )}
          />
          <img
            src={showPassword ? hidePasswordIcon : showPasswordIcon}
            alt="hide or show password"
            className={classes.hideShowPassword}
            onClick={onChangeShowPassword}
          />
        </div>
        <Button
          type={ButtonType.Submit}
          size={ButtonSize.L}
          loading={loading}
          isFullWidth
        >
          Confirm
        </Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </>
  );
};

export default ForgotPasswordConfirmation;
