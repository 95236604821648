import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Popover from 'components/Common/Popover';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import SortByReport from 'components/Pages/ReportFilters/SortBy';
import FilterByReport from 'components/Pages/ReportFilters/FilterBy';
import arrowDownIcon from 'assets/icons/arrows/arrow-down.svg';
import FilterTypes from 'enums/reports/filterTypes.enum';

import classNames from 'classnames';
import onPressEnter from 'utils/onPressEnter';
import ErrorMessage from 'components/Typography/ErrorMessage';

import classes from './ReportFilters.module.scss';

const ReportFilters = ({
  header,
  columnName,
  filterable,
  multiValue,
  sortable,
  searchable,
  filter: { type, options },
  selectedFilters,
  sortBy,
  onSubmit,
  validateSearch,
}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});

  const [searchInput, setSearchInput] = useState('');
  const [inputFocus, setInputFocus] = useState(false);

  const [searchName, setSearchName] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');

  const [sortByColumn, setSortByColumn] = useState({
    ascending: false,
    descending: false,
  });

  const [focusedInput, setFocusedInput] = useState(null);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const [range, setRange] = useState({
    start: '',
    end: '',
  });

  const [closePopover, setClosePopover] = useState(false);

  const dateType = useMemo(() => type === FilterTypes.Date, [type]);
  const numberType = useMemo(() => type === FilterTypes.Number, [type]);
  const stringType = useMemo(() => type === FilterTypes.String, [type]);
  const boolType = useMemo(() => type === FilterTypes.Boolean, [type]);
  const searchType = useMemo(() => type === FilterTypes.Search, [type]);

  useEffect(() => {
    if (stringType || boolType || searchType) {
      setFilterOptions([...options]);

      const selected = {};
      if (selectedFilters[columnName]) {
        selectedFilters[columnName].forEach((filter) => {
          selected[filter.text] = { ...filter };
        });
      }
      setSelectedFilterOptions(selected);
    } else if (dateType) {
      if (selectedFilters[columnName]) {
        setDates({
          startDate: selectedFilters[columnName].startDate,
          endDate: selectedFilters[columnName].endDate,
        });
      }
    } else if (numberType) {
      if (selectedFilters[columnName]) {
        setRange({
          start: selectedFilters[columnName].start,
          end: selectedFilters[columnName].end,
        });
      }
    }
  }, [
    type,
    options,
    columnName,
    selectedFilters,
    dateType,
    numberType,
    stringType,
    boolType,
    searchType,
  ]);

  useEffect(() => {
    if (sortBy.columnName === columnName) {
      setSortByColumn({
        ascending: sortBy.ascending,
        descending: sortBy.descending,
      });
    }
  }, [sortBy, columnName]);

  useEffect(() => {
    if (closePopover) {
      setClosePopover(false);
    }
  }, [closePopover]);

  const onClickCategoryHandler = useCallback(
    (option) => {
      setSelectedFilterOptions((prevState) => {
        let newCategories = { ...prevState };
        if (newCategories[option]) {
          delete newCategories[option];
        } else if (multiValue) {
          newCategories[option] = { text: option, columnName, type };
        } else {
          newCategories = { [option]: { text: option, columnName, type } };
        }
        return newCategories;
      });
    },
    [columnName, multiValue, type]
  );

  const onSearchClickHandler = useCallback(
    (option) => {
      setSelectedFilterOptions((prevState) => {
        let newCategories = { ...prevState };
        if (multiValue) {
          newCategories[option] = { text: option, columnName, type };
        } else {
          newCategories = { [option]: { text: option, columnName, type } };
        }
        return newCategories;
      });
    },
    [columnName, multiValue, type]
  );

  const onSearchCategoryHandler = useCallback(
    (event) => {
      const text = event.target.value;
      if (text) {
        const newCategories = options.filter((option) =>
          option.toLowerCase().includes(text.toLowerCase())
        );
        setFilterOptions(newCategories);
      } else {
        setFilterOptions(options);
      }
    },
    [options]
  );

  const onChangeDatesHandler = useCallback((newDates) => {
    setDates(newDates);
  }, []);

  const onChangeRangeHandler = useCallback((key, value) => {
    setRange((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  const onChangeFocusHandler = useCallback((newFocus) => {
    setFocusedInput(newFocus);
  }, []);

  const hasSelectedOptions = useMemo(
    () => Object.values(selectedFilterOptions).length > 0,
    [selectedFilterOptions]
  );

  const submitSearchInput = useCallback(
    (input) => {
      validateSearch(input);
      onSearchClickHandler(searchInput);
      setSearchInput('');
      setErrorMessage('');
    },
    [
      validateSearch,
      onSearchClickHandler,
      searchInput,
      setSearchInput,
      setErrorMessage,
    ]
  );
  const onSubmitHandler = useCallback(() => {
    let selected = Object.values(selectedFilterOptions).map((option) => option);
    if (searchType && searchName) {
      selected = [{ text: searchName, type, columnName }];
    }

    try {
      if (!searchInput) {
        onSubmit({
          resetPagination: true,
          selected,
          columnName,
          dates: { ...dates, isDate: true },
          range: { ...range, isRange: true },
          sortValue: { ...sortByColumn, columnName },
        });
      }
      submitSearchInput(searchInput);
      setClosePopover(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  }, [
    selectedFilterOptions,
    searchType,
    searchName,
    type,
    columnName,
    onSubmit,
    dates,
    range,
    sortByColumn,
    searchInput,
    submitSearchInput,
  ]);

  const onRemoveSelectedCategoryHandler = useCallback(({ text }) => {
    setSelectedFilterOptions((prevState) => {
      const newCategories = { ...prevState };
      delete newCategories[text];
      return newCategories;
    });
    setInputFocus(true);
  }, []);

  const onClearAllHandler = useCallback(() => {
    if (type === FilterTypes.Date) {
      setDates({
        startDate: null,
        endDate: null,
      });
    } else if (type === FilterTypes.Number) {
      setRange({
        start: null,
        end: null,
      });
    } else {
      setSelectedFilterOptions({});
    }
    onSubmitHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const onChangeSortByDescendingHandler = useCallback(() => {
    setSortByColumn((prevState) => ({
      ascending: false,
      descending: !prevState.descending,
    }));
  }, []);

  const onChangeSortByAscendingHandler = useCallback(() => {
    setSortByColumn((prevState) => ({
      ascending: !prevState.ascending,
      descending: false,
    }));
  }, []);

  const buttonComponent = () => (
    <Body
      size={BodySize.XXS}
      className={classNames(classes.text, {
        [classes.selected]:
          Object.values(selectedFilterOptions).length > 0 ||
          dates.startDate ||
          range.start ||
          sortByColumn.descending ||
          sortByColumn.ascending,
      })}
    >
      {header}
      <img alt="arrow" className={classes.arrowIcon} src={arrowDownIcon} />
    </Body>
  );

  return (
    <Popover
      button={buttonComponent}
      widthButton={100}
      widthPanel={20}
      heightPanel={400}
      closePopover={false}
      onKeyDown={onPressEnter(onSubmitHandler)}
    >
      <div
        className={classNames(classes.popover, {
          [classes.overflow]: !dateType,
        })}
      >
        {sortable && (
          <SortByReport
            sortBy={sortByColumn}
            onChangeSortByAscending={onChangeSortByAscendingHandler}
            onChangeSortByDescending={onChangeSortByDescendingHandler}
          />
        )}
        {sortable && filterable && <div className={classes.divider} />}
        {filterable && (
          <FilterByReport
            dateType={dateType}
            numberType={numberType}
            stringType={stringType}
            boolType={boolType}
            searchType={searchType}
            onChangeNameSearch={setSearchName}
            multiValue={multiValue}
            dates={dates}
            onChangeDates={onChangeDatesHandler}
            range={range}
            onChangeRange={onChangeRangeHandler}
            focusedInput={focusedInput}
            onChangeFocus={onChangeFocusHandler}
            filterOptions={filterOptions}
            selectedFilterOptions={selectedFilterOptions}
            hasSelectedOptions={hasSelectedOptions}
            onSearchCategory={onSearchCategoryHandler}
            onClearAll={onClearAllHandler}
            onRemoveSelectedCategory={onRemoveSelectedCategoryHandler}
            onClickCategory={onClickCategoryHandler}
            searchable={searchable}
            columnName={columnName}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
            inputFocus={inputFocus}
          />
        )}
        <div className={classes.column}>
          <Button
            type={ButtonType.Default}
            size={ButtonSize.S}
            className={classes.button}
            onClick={onSubmitHandler}
          >
            Save
          </Button>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      </div>
    </Popover>
  );
};

ReportFilters.propTypes = {
  header: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(FilterTypes)),
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedFilters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ),
  sortBy: PropTypes.shape({
    ascending: PropTypes.bool,
    descending: PropTypes.bool,
    columnName: PropTypes.string,
  }),
  sortable: PropTypes.bool.isRequired,
  filterable: PropTypes.bool.isRequired,
  multiValue: PropTypes.bool,
  searchable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  validateSearch: PropTypes.func,
};

ReportFilters.defaultProps = {
  validateSearch: () => {},
  filter: {
    type: null,
    options: [],
  },
  multiValue: false,
  selectedFilters: {},
  sortBy: {},
  searchable: false,
};

export default ReportFilters;
