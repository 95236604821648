import client from 'services/kardAPI';

const fetchAudienceSummaryDataPoints = async () => {
  try {
    const { data } = await client.get(`/audience/portal/summary`);

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export default fetchAudienceSummaryDataPoints;
