/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';
import moment from 'moment';
import dayjs from 'dayjs';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Popover from 'components/Common/Popover';
import arrowUpIcon from 'assets/icons/arrows/arrow-up.svg';
import Form from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import FormControlSelect from 'components/Common/FormControlSelect';
import DateUnits from 'enums/reports/dateUnits.enum';
import cross from 'assets/icons/color/cross.png';
import RangeDatePicker from 'components/Common/RangeDatePicker';
import onPressEnter from 'utils/onPressEnter';

import classes from './ReportDateFilter.module.scss';

const MAX_VALUE = Object.freeze({
  Hours: 720,
  Days: 31,
});

const options = Object.entries(DateUnits).map(([label, value]) => ({
  label,
  value,
}));

const TAB_TYPE = Object.freeze({
  Period: 0,
  Dates: 1,
});

const DEFAULT_DATES = Object.freeze({
  startDate: null,
  endDate: null,
});

const ReportDateFilter = ({
  value,
  unit,
  onSubmit,
  loading,
  setDateFilter,
}) => {
  const [closePopover, setClosePopover] = useState(false);

  const defaultUnit = useMemo(
    () => options.find((option) => option.value === unit),
    [unit]
  );

  const [transactionTimeStamp, setTransactionTimeStamp] = useState(
    DEFAULT_DATES
  );

  const [focusedInput, setFocusedInput] = useState(null);

  const [currentTab, setCurrentTab] = useState(TAB_TYPE.Period);

  const [periodType, setPeriodType] = useState(defaultUnit);

  useEffect(() => {
    if (closePopover) {
      setClosePopover(false);
    }
  }, [closePopover]);

  const getDatesData = useCallback(
    (periodValues) => {
      let startDate = null;
      let endDate = null;
      let unitValue = null;
      let periodValue = null;

      if (currentTab === TAB_TYPE.Period) {
        periodValue = periodValues.value;
        unitValue = periodValues.unit.value || periodValues?.unit;

        endDate = dayjs(new Date());
        startDate = endDate.subtract(periodValues.value, unitValue);
      }

      if (currentTab === TAB_TYPE.Dates) {
        startDate = transactionTimeStamp.startDate;
        endDate = transactionTimeStamp.endDate;
      }

      if (!startDate && !endDate) {
        endDate = dayjs(new Date());
        startDate = endDate.subtract(1, 'day');
      }

      if (!startDate && endDate) {
        startDate = moment(endDate).subtract(1, 'd');
      }

      if (startDate && !endDate) {
        endDate = moment(startDate).add(1, 'd');
      }

      return {
        ...(periodValue && { value: periodValue }),
        ...(unitValue && { unit: unitValue }),
        startDate,
        endDate,
        isDate: true,
      };
    },
    [currentTab, transactionTimeStamp]
  );

  const onSubmitHandler = useCallback(
    (values) => {
      setDateFilter({
        value: values?.value ?? '0',
        unit: values.unit ?? DateUnits.Days,
      });

      if (values?.value) {
        setTransactionTimeStamp(DEFAULT_DATES);
      }

      const dates = getDatesData(values);

      onSubmit({
        dates,
        columnName: 'transactionTimeStamp',
        isDateFilter: true,
        sortValue: {
          ascending: false,
          descending: true,
          columnName: 'transactionTimeStamp',
        },
      });
      setClosePopover(true);
    },
    [onSubmit, getDatesData, setDateFilter]
  );

  const getReportDateFilterName = useCallback(() => {
    let name = 'Filter by period or dates';

    if (currentTab === TAB_TYPE.Period) {
      name = `Last ${value} ${defaultUnit.label}`;
    }

    if (currentTab === TAB_TYPE.Dates) {
      const startDate = transactionTimeStamp.startDate
        ? moment(transactionTimeStamp.startDate).format('MM/DD/YYYY')
        : 'start date';
      const endDate = transactionTimeStamp.endDate
        ? moment(transactionTimeStamp.endDate).format('MM/DD/YYYY')
        : 'end date';

      name = `From ${startDate} to ${endDate}`;
    }

    return name;
  }, [currentTab, transactionTimeStamp, defaultUnit, value]);

  const buttonComponent = () => (
    <div className={classes.select}>
      <Body size={BodySize.XS} className={classes.buttonLabel}>
        {getReportDateFilterName()}
      </Body>
      <div className={classes.arrow}>
        <img src={arrowUpIcon} alt="ReportArrow" />
      </div>
    </div>
  );

  const onChangeDatesHandler = (newValue) => {
    setTransactionTimeStamp((prevState) => {
      if (!prevState.startDate && prevState.endDate) {
        return {
          ...newValue,
        };
      }

      const isDifferenceOutOfRange =
        newValue.endDate &&
        newValue.startDate.isBefore(
          newValue.endDate?.clone().subtract(MAX_VALUE.Days, 'days')
        );

      if (
        prevState.startDate !== newValue.startDate &&
        isDifferenceOutOfRange
      ) {
        return {
          startDate: newValue.startDate,
          endDate: null,
        };
      }

      return { ...newValue };
    });
  };

  const onChangeTabs = (index) => setCurrentTab(index);

  const onClickClearDates = useCallback(() => {
    setTransactionTimeStamp({
      startDate: null,
      endDate: null,
    });
  }, []);

  const isMaxRange = (date) => {
    const { startDate } = transactionTimeStamp;

    const MAX_RANGE = Object.freeze({
      endDate:
        startDate &&
        date.isAfter(startDate?.clone().add(MAX_VALUE.Days, 'days')),
    });

    return MAX_RANGE[focusedInput];
  };

  return (
    <Popover
      button={buttonComponent}
      widthButton={30}
      widthPanel={32}
      closePopover={closePopover}
      onKeyDown={onPressEnter(onSubmitHandler)}
    >
      <Form onSubmit={onSubmitHandler} className={classes.container}>
        <Tab.Group onChange={onChangeTabs} defaultIndex={currentTab}>
          <Tab.List className={classes.tabList}>
            <Tab
              className={({ selected }) =>
                selected ? classes.selectedTab : classes.deselectedTab
              }
            >
              Filter by period
            </Tab>
            <Tab
              className={({ selected }) =>
                selected ? classes.selectedTab : classes.deselectedTab
              }
            >
              Filter by dates
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className={classes.formInputs}>
                <FormControl
                  name="value"
                  className={classes.formControl}
                  render={(props) => (
                    <Input
                      value={value}
                      type={InputType.Number}
                      minNumber={0}
                      max={MAX_VALUE[periodType?.label] ?? null}
                      size={InputSize.S}
                      className={classes.valueInput}
                      required
                      {...props}
                    />
                  )}
                />
                <FormControlSelect
                  name="unit"
                  defaultValue={defaultUnit.value}
                  options={options}
                  className={classes.unitSelect}
                  onChangeManual={setPeriodType}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className={classes.datesRangePickerContainer}>
                <RangeDatePicker
                  label="Start date and end date: "
                  startDate={transactionTimeStamp.startDate}
                  endDate={transactionTimeStamp.endDate}
                  onChangeDates={onChangeDatesHandler}
                  focusedInput={focusedInput}
                  onChangeFocus={setFocusedInput}
                  isOutsideRange={isMaxRange}
                />
                <img
                  src={cross}
                  alt="clear dates"
                  className={classes.clearDatesButton}
                  onClick={onClickClearDates}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <Button
          type={ButtonType.Submit}
          size={ButtonSize.S}
          className={classes.button}
          loading={loading}
        >
          Filter
        </Button>
      </Form>
    </Popover>
  );
};

ReportDateFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setDateFilter: PropTypes.func.isRequired,
  value: PropTypes.string,
  unit: PropTypes.oneOf(Object.values(DateUnits)),
  loading: PropTypes.bool,
};

ReportDateFilter.defaultProps = {
  value: 0,
  unit: DateUnits.Days,
  loading: false,
};

export default ReportDateFilter;
