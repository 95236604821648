/* eslint-disable import/prefer-default-export */
import {
  fetchIssuersInit,
  fetchIssuersSuccess,
  fetchIssuersFail,
} from 'state/actionCreators/issuers';

import fetchIssuersService from 'services/issuers/fetchIssuers';

export const fetchIssuers = () => async (dispatch) => {
  dispatch(fetchIssuersInit());

  try {
    const { data } = await fetchIssuersService();
    return dispatch(fetchIssuersSuccess({ issuers: data }));
  } catch (error) {
    return dispatch(fetchIssuersFail({ error: error.message }));
  }
};
