import PropTypes from 'prop-types';
import styled from 'styled-components';

const FlexBox = styled.div`
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  flex: ${(props) => props.flex};
  flex-direction: ${(props) => props.direction};
`;

FlexBox.propTypes = {
  display: PropTypes.string,
  justify: PropTypes.string,
  alignItems: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FlexBox.defaultProps = {
  display: 'flex',
  justify: 'flex-start',
  alignItems: 'initial',
  margin: '0',
  padding: '0',
  flex: '0',
  direction: 'row',
};

export default FlexBox;
