import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Input, {
  Type as InputType,
  Size as InputSize,
  IconPosition as InputIconPosition,
  Color as InputColor,
} from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Checkbox, {
  Kind as CheckboxKind,
  Size as CheckboxSize,
} from 'components/Common/Checkbox';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import FormControlDatePicker from 'components/Common/FormControlDatePicker';
import Switch, { Size as SwitchSize } from 'components/Common/Switch';
import Modal from 'components/Common/Modal';
import {
  clearOffersSuccess,
  createOffer,
  editOffer,
  fetchOffersByMerchantId,
} from 'state/actions/offers';

import { clearAudiencesErrors, fetchAudiences } from 'state/actions/audiences';
import { commissionTypes, offerTypes, sources } from 'utils/offers/values';
import CommissionType from 'enums/offers/commissionTypes.enum';
import {
  selectCreateMerchantState,
  selectFetchMerchantByIdState,
  selectFetchMerchantIssuersState,
} from 'state/selectors/merchants';
import Status from 'enums/status/status.enum';
import {
  selectCreateOfferState,
  selectEditOfferState,
} from 'state/selectors/offers';
import dollarIcon from 'assets/icons/dollar.png';
import percentIcon from 'assets/icons/percent.png';
import StatusValue from 'enums/status/statusValue.enum';
import ModalType from 'enums/modal/modalType';
import { fetchMerchantIssuers } from 'state/actions/merchants';
import informationIcon from 'assets/icons/color/information.svg';
import useModal from 'hooks/useModal';
import Source from 'enums/sources/sources.enum';
import { selectFetchAudiencesState } from 'state/selectors/audiences';
import {
  MerchantCategoryCodeOptions,
  filterMerchantCategoryCode,
} from 'utils/offers/merchantCategoryCodes';
import Types from 'enums/type/type.enum';
import { merchantNetworks } from 'utils/common/merchantNetwork';
import OfferIssuersCommission from '../OfferIssuersCommission';
import validationSchema from './OfferForm.schema';
import classes from './OfferForm.module.scss';

const FILTER_BY_CREATED_DATE_DESC = `createdDateSort=-1`;
const SELECT_ALL_WHITESPACE_REGEX = /\s/g;
const AUDIENCES_LIMIT = 'limit=400';
const AUDIENCES_FILTERS = `?${FILTER_BY_CREATED_DATE_DESC}&${AUDIENCES_LIMIT}`;

const CommissionTypeIcon = Object.freeze({
  [CommissionType.Percent]: percentIcon,
  [CommissionType.Flat]: dollarIcon,
});

export const OfferFormMode = Object.freeze({
  NEW: 'Add New Offer',
  NEWMERCHANT: 'Add New Merchant Offer',
  VIEW: 'Offer Information',
  EDIT: 'Edit Offer',
  CLONE: 'Clone Offer',
});

const OfferForm = ({
  initialMode,
  subtitle,
  offer,
  onCancel,
  merchant,
  setBodyOffer,
  bodyOffer,
}) => {
  const dispatch = useDispatch();

  const [displayMode, setDisplayMode] = useState(initialMode);

  const [dataAudiences, setDataAudiences] = useState([]);

  const {
    audiencesData,
    loading: loadingFetchAudiences,
    error: errorFetchAudiences,
  } = useSelector(selectFetchAudiencesState, shallowEqual);

  const { createdMerchant } = useSelector(
    selectCreateMerchantState,
    shallowEqual
  );

  const {
    loading: loadingCreateOffer,
    error: errorCreate,
    success: successCreateOffer,
  } = useSelector(selectCreateOfferState, shallowEqual);

  const {
    loading: loadingEditOffer,
    error: errorEdit,
    success: editOfferSuccess,
  } = useSelector(selectEditOfferState, shallowEqual);

  const { issuers, loading: loadingIssuers } = useSelector(
    selectFetchMerchantIssuersState,
    shallowEqual
  );
  const [locationMissingError, setLocationMissingError] = useState(false);
  const [locationSpecific, setLocationSpecific] = useState(false);
  const [optInRequired, setOptInRequired] = useState(false);
  const [redeemableOnce, setRedeemableOnce] = useState(false);
  const [displayAlways, setDisplayAlways] = useState(false);
  const [status, setStatus] = useState(false);
  const [issuersOptions, setIssuersOptions] = useState([]);
  const [selectedCommissionType, setSelectedCommissionType] = useState(
    offer?.commissionType
  );
  const [selectedAudience, setSelectedAudience] = useState(
    offer?.audiences ? offer.audiences[0] : null
  );

  const isCreatingOffer =
    displayMode === OfferFormMode.NEW ||
    displayMode === OfferFormMode.NEWMERCHANT ||
    displayMode === OfferFormMode.CLONE;

  const isCloning = displayMode === OfferFormMode.CLONE;

  const [expDate, setExpDate] = useState(offer.expirationDate);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const { results } = audiencesData;

  useEffect(() => {
    if (offer?.audiences?.length > 0) {
      const { _id: audienceId, name: audienceName } = offer.audiences[0];

      setSelectedAudience(audienceId);

      if (dataAudiences.length === 0) {
        const offerAudienceOption = [
          {
            label: audienceName,
            value: audienceId,
          },
        ];

        setDataAudiences(offerAudienceOption);
      }
    }
  }, [dataAudiences.length, offer?.audiences]);

  useEffect(() => {
    // length <= 1 because dataAudiences could contain the inital audience from the offer
    if (results?.length > 0 && dataAudiences.length <= 1) {
      const transformedAudiencesData = results?.map((audience) => ({
        label: audience.name,
        value: audience._id,
      }));

      setDataAudiences(transformedAudiencesData);
    }
  }, [results, dataAudiences]);

  useEffect(() => {
    if (errorFetchAudiences) {
      dispatch(clearAudiencesErrors());
    }
  }, [dispatch, errorFetchAudiences]);

  useEffect(() => {
    dispatch(fetchAudiences(AUDIENCES_FILTERS));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMerchantIssuers());
  }, [dispatch, offer]);

  useEffect(() => {
    if (Object.values(offer).length > 0) {
      setLocationSpecific(offer.isLocationSpecific);
      setOptInRequired(offer.optInRequired);
      setRedeemableOnce(offer.redeemableOnce);
      setExpDate(offer.expirationDate);
      setStatus(offer.status === Status.active);
      setDisplayAlways(offer.displayAlways ?? false);
    }
  }, [offer]);

  useEffect(() => {
    if (issuers.length > 0 && issuersOptions.length === 0) {
      const transformedIssuers = issuers.map((category) => ({
        label: category,
        value: category,
      }));
      setIssuersOptions(transformedIssuers);
    }
  }, [issuers, issuersOptions]);

  const readOnly = displayMode === OfferFormMode.VIEW;
  const editableTextColor = readOnly ? InputColor.Gray : InputColor.Black;
  const editableSelectColor = readOnly ? SelectColor.Gray : SelectColor.Black;

  const onEditHandler = () => setDisplayMode(OfferFormMode.EDIT);
  const onChangeCheckboxStateHandler = useCallback((setState) => {
    setState((prevState) => !prevState);
  }, []);

  const onChangeStatusHandler = useCallback(
    (val) => {
      if (readOnly) return;
      const newExpDate = val ? offer.expirationDate : new Date();
      setExpDate(newExpDate);

      if (!offer.merchantLocationIds?.length) {
        setLocationMissingError(true);
        return;
      }
      setStatus((prevState) => !prevState);
    },
    [offer, readOnly]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLocationMissingError(false));

  const merchantId = useMemo(
    () =>
      offer.merchantId ||
      createdMerchant?._id ||
      merchant?.merchantId ||
      bodyOffer?.merchantId,
    [offer, createdMerchant, merchant, bodyOffer]
  );

  const locationIdsOptions = useMemo(
    () =>
      offer?.merchantLocationIds?.map((id) => ({
        label: id,
        value: id,
        isFixed: true,
      })) ?? [],
    [offer]
  );

  const onClickIssuerCommissionHandler = useCallback(
    (event) => {
      event.preventDefault();
      onOpenModalHandler(ModalType.OFFER_ISSUERS_COMMISION);
    },
    [onOpenModalHandler]
  );

  const isValidNumber = useCallback(
    (value) => value !== undefined && value >= 0,
    []
  );

  const { merchant: fetchedMerchant } = useSelector(
    selectFetchMerchantByIdState,
    shallowEqual
  );

  const isNational = fetchedMerchant?.source === Source.NATIONAL;

  const onCloneHandler = useCallback(() => {
    setDisplayMode(OfferFormMode.CLONE);
    setStatus(false);
  }, []);

  const getOfferStatus = useCallback(() => {
    // Cloned offers cannot be CLOSED.
    if (!isCloning && offer.status === StatusValue.Closed) {
      return StatusValue.Closed;
    }

    return status ? StatusValue.Active : StatusValue.Inactive;
  }, [isCloning, offer.status, status]);

  const onSubmitHandler = useCallback(
    (values) => {
      const body = {
        ...values,
        status: getOfferStatus(),
        isLocationSpecific: locationSpecific,
        optInRequired,
        startDate: values.startDate?.toISOString(),
        expirationDate: expDate,
        merchantId: values.merchantId
          ? values.merchantId.replace(SELECT_ALL_WHITESPACE_REGEX, '')
          : merchantId,
        redeemableOnce,
        commissionValue: Number(values.commissionValue),
        minTransactionAmount: Number(values.minTransactionAmount),
        maxTransactionAmount: Number(values.maxTransactionAmount),
        minRewardAmount: Number(values.minRewardAmount),
        maxRewardAmount: Number(values.maxRewardAmount),
        mcc: values.mcc ?? [],
        displayAlways,
      };

      delete body.category;
      delete body.merchantName;
      delete body.issuerCommissionsMap;

      if (!isCloning) {
        delete body.merchantLocationIds;
      }

      if (!isCreatingOffer) {
        if (body.audiencesList) {
          body.audiences = [body.audiencesList];
        }

        if (!body.audiencesList) {
          body.audiences = [];
        }

        delete body.audiencesList;
      }

      if (!values.terms) {
        delete body.terms;
      }

      if (!isValidNumber(values.minTransactionAmount)) {
        delete body.minTransactionAmount;
      }

      if (!isValidNumber(values.maxTransactionAmount)) {
        delete body.maxTransactionAmount;
      }

      if (!isValidNumber(values.minRewardAmount)) {
        delete body.minRewardAmount;
      }

      if (!isValidNumber(values.maxRewardAmount)) {
        delete body.maxRewardAmount;
      }

      if (!values.startDate) {
        delete body.startDate;
      }

      if (isCreatingOffer) {
        setBodyOffer(body);
        dispatch(createOffer(body));
        return;
      }
      dispatch(editOffer(offer._id, body));
    },
    [
      isCreatingOffer,
      isCloning,
      offer,
      locationSpecific,
      optInRequired,
      merchantId,
      redeemableOnce,
      dispatch,
      isValidNumber,
      expDate,
      setBodyOffer,
      getOfferStatus,
      displayAlways,
    ]
  );

  const firstIssuerCommission = useMemo(() => {
    const actualObject = offer.issuerCommissionsMap || {};
    const first = Object.entries(actualObject)[0] || [];
    let text = '';
    if (first.length > 0) {
      text = `${first[0] || ''}: ${first[1] || ''} ...`;
    }
    return text;
  }, [offer]);

  useEffect(() => {
    if (successCreateOffer) {
      if (merchantId) {
        dispatch(fetchOffersByMerchantId(merchantId));
      }

      if (
        // if this is not a new online offer (excluding clone), close out the modal
        !(
          bodyOffer.offerType === Types.ONLINE &&
          bodyOffer.merchantLocationIds === undefined
        )
      ) {
        dispatch(clearOffersSuccess());
        onCancel();
      }
    }
  }, [successCreateOffer, onCancel, dispatch, merchantId, bodyOffer]);

  const merchantName = useMemo(
    () =>
      displayMode === OfferFormMode.NEWMERCHANT
        ? createdMerchant.name
        : offer.merchantName,
    [displayMode, createdMerchant, offer]
  );

  const commissionTypeIcon = CommissionTypeIcon[selectedCommissionType];

  const form = useRef('');

  const statusColor = useMemo(() => {
    if (readOnly) return status ? InputColor.Green : InputColor.Red;
    return InputColor.Gray;
  }, [readOnly, status]);

  return (
    <>
      {locationMissingError && (
        <Toast
          id="status-warning"
          text="Please associate locations with this offer before activating it."
          type={ToastType.Error}
        />
      )}
      {(errorCreate || errorEdit) && (
        <Toast
          id={errorCreate ? 'error create offer' : 'error edit offer'}
          text={errorCreate || errorEdit}
          type={ToastType.Error}
        />
      )}
      {editOfferSuccess && (
        <Toast
          id="success-updating-offer"
          text="Offer updated successfully!"
          type={ToastType.Success}
        />
      )}
      {successCreateOffer && (
        <Toast
          id="success-creating-offer"
          text={
            isNational
              ? 'Offer created successfully. Please set Priority order as needed.'
              : 'Offer created successfully!'
          }
          type={ToastType.Success}
        />
      )}

      <Modal
        isOpen={modal.type === ModalType.OFFER_ISSUERS_COMMISION}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <OfferIssuersCommission
          issuersCommission={offer.issuerCommissionsMap || {}}
          onClose={onCloseModalHandler}
        />
      </Modal>
      <Form
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnChange}
        className={classes.form}
        ref={form}
      >
        <Heading
          size={HeadingSize.M}
          className={classNames({ [classes.heading]: !!subtitle })}
        >
          {displayMode}
        </Heading>
        {subtitle && (
          <Body
            size={BodySize.M}
            className={classes.subtitle}
            color={BodyColor.Gray}
          >
            {subtitle}
          </Body>
        )}
        <div>
          {(!isCreatingOffer || isCloning) && (
            <FormControl
              name="offerId"
              render={(props) => (
                <Input
                  label={`${isCloning ? 'Original ' : ''}Offer ID:`}
                  labelClassName={classes.labelWidth}
                  value={offer._id}
                  type={InputType.Text}
                  readOnly={readOnly}
                  size={InputSize.S}
                  disabled={!readOnly}
                  {...props}
                />
              )}
            />
          )}
          <FormControl
            name="merchantId"
            render={(props) => (
              <Input
                label="Merchant ID:"
                labelClassName={classes.labelWidth}
                value={merchantId}
                type={InputType.Text}
                readOnly={readOnly}
                size={InputSize.S}
                disabled={!(readOnly || displayMode === OfferFormMode.NEW)}
                {...props}
              />
            )}
          />
          {(!isCreatingOffer || isCloning) && (
            <>
              <FormControl
                name="merchantName"
                render={(props) => (
                  <Input
                    label="Merchant Name:"
                    labelClassName={classes.labelWidth}
                    value={merchantName}
                    type={InputType.Text}
                    readOnly={readOnly}
                    size={InputSize.S}
                    disabled={!readOnly}
                    {...props}
                  />
                )}
              />
            </>
          )}
          <FormControl
            name="name"
            render={(props) => (
              <Input
                label="Offer Name:"
                labelClassName={classes.labelWidth}
                value={offer.name}
                type={InputType.Text}
                readOnly={readOnly}
                size={InputSize.S}
                color={editableTextColor}
                {...props}
              />
            )}
          />
          <FormControlDatePicker
            name="startDate"
            label="Start Date:"
            labelClassName={classes.labelWidth}
            defaultValue={offer.startDate}
            readOnly={readOnly}
            color={editableSelectColor}
            showTimeSelect
          />

          <FormControlDatePicker
            name="expirationDate"
            label="Expiration Date:"
            labelClassName={classes.labelWidth}
            defaultValue={expDate}
            readOnly={readOnly}
            color={editableSelectColor}
            showTimeSelect
            onChangeManual={setExpDate}
          />
          {/* If editing/creating an offer which is not closed, or cloning, show the switch. */}
          {(!readOnly && offer.status !== Status.closed) || isCloning ? (
            <Switch
              disabled={
                displayMode === OfferFormMode.NEW ||
                displayMode === OfferFormMode.NEWMERCHANT
              }
              enabled={status}
              setEnabled={onChangeStatusHandler}
              label="Status:"
              labelClassName={classes.labelWidth}
              leftText="Inactive"
              rightText="Active"
              size={SwitchSize.S}
              className={classes.switch}
            />
          ) : (
            <FormControl
              name="status"
              render={(props) => (
                <Input
                  label="Status:"
                  labelClassName={classes.labelWidth}
                  value={offer.status}
                  type={InputType.Text}
                  readOnly
                  size={InputSize.S}
                  color={statusColor}
                  disabled={!readOnly}
                  {...props}
                />
              )}
            />
          )}
          <FormControlSelect
            name="source"
            defaultValue={offer.source}
            label="Source:"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            options={sources}
            color={editableSelectColor}
          />
          <FormControlSelect
            name="offerType"
            defaultValue={offer.offerType}
            label="Offer Type:"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            options={offerTypes}
            color={editableSelectColor}
          />
          <FormControlSelect
            name="merchantNetwork"
            label="Merchant Network:"
            labelClassName={classes.labelWidth}
            defaultValue={offer.merchantNetwork}
            readOnly={readOnly}
            options={merchantNetworks}
            color={editableSelectColor}
          />
          <Checkbox
            label="Location Specific"
            setSelected={() =>
              onChangeCheckboxStateHandler(setLocationSpecific)
            }
            selected={locationSpecific}
            className={classes.checkbox}
            kind={!readOnly ? CheckboxKind.Primary : CheckboxKind.Secondary}
            size={CheckboxSize.S}
          />
          {!isCreatingOffer && (
            <FormControlSelect
              name="audiencesList"
              label="Audience:"
              defaultValue={selectedAudience}
              labelClassName={classes.labelWidth}
              readOnly={readOnly}
              options={dataAudiences}
              color={editableSelectColor}
              onChangeManual={setSelectedAudience}
              isClearable
              isLoading={loadingFetchAudiences}
              disabled={loadingFetchAudiences}
            />
          )}
          <Checkbox
            label="Opt-in Required"
            setSelected={() => onChangeCheckboxStateHandler(setOptInRequired)}
            selected={optInRequired}
            className={classes.checkbox}
            isDisabled={readOnly}
            kind={!readOnly ? CheckboxKind.Primary : CheckboxKind.Secondary}
            size={CheckboxSize.S}
          />
          <Checkbox
            label="Redeemable Once"
            setSelected={() => onChangeCheckboxStateHandler(setRedeemableOnce)}
            selected={redeemableOnce}
            className={classes.checkbox}
            isDisabled={readOnly}
            kind={!readOnly ? CheckboxKind.Primary : CheckboxKind.Secondary}
            size={CheckboxSize.S}
          />
          <Checkbox
            label="Always Appears to Cardholder"
            setSelected={() => onChangeCheckboxStateHandler(setDisplayAlways)}
            selected={displayAlways}
            className={classes.checkbox}
            isDisabled={readOnly}
            kind={!readOnly ? CheckboxKind.Primary : CheckboxKind.Secondary}
            size={CheckboxSize.S}
          />
          <FormControlSelect
            name="qualifiedIssuer"
            defaultValue={offer.qualifiedIssuer}
            label="Qualified Issuer(s):"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            disabled={loadingIssuers}
            options={issuersOptions}
            isMulti
            color={editableSelectColor}
          />
          <FormControlSelect
            filterOption={filterMerchantCategoryCode}
            name="mcc"
            defaultValue={offer.mcc}
            label="Merchant Category Codes:"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            disabled={loadingIssuers}
            options={MerchantCategoryCodeOptions}
            isMulti
            allowSelectAll={false}
            noOptionsMessage={() =>
              'Start typing MCC number or keyword for suggestions...'
            }
            color={editableSelectColor}
          />
          <FormControl
            name="terms"
            render={(props) => (
              <Input
                label="Terms And Conditions:"
                labelClassName={classes.labelWidth}
                value={offer.terms}
                type={InputType.Text}
                readOnly={readOnly}
                size={InputSize.S}
                color={editableTextColor}
                {...props}
              />
            )}
          />
          <FormControlSelect
            name="commissionType"
            defaultValue={offer.commissionType}
            label="Commission Type"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            options={commissionTypes}
            color={editableSelectColor}
            onChangeManual={setSelectedCommissionType}
          />
          <FormControl
            name="commissionValue"
            render={(props) => (
              <Input
                label="Commission Value"
                labelClassName={classes.labelWidth}
                value={offer.commissionValue}
                type={InputType.Number}
                readOnly={readOnly}
                size={InputSize.S}
                color={editableTextColor}
                step=".01"
                icon={commissionTypeIcon}
                iconPosition={InputIconPosition.Right}
                {...props}
              />
            )}
          />
          <FormControl
            name="minTransactionAmount"
            render={(props) => (
              <Input
                label="Min Txn Amount In Cents"
                labelClassName={classes.labelWidth}
                value={offer.minTransactionAmount}
                type={InputType.Number}
                readOnly={readOnly}
                size={InputSize.S}
                color={editableTextColor}
                {...props}
              />
            )}
          />
          <FormControl
            name="maxTransactionAmount"
            render={(props) => (
              <Input
                label="Max Txn Amount In Cents"
                labelClassName={classes.labelWidth}
                value={offer.maxTransactionAmount}
                type={InputType.Number}
                readOnly={readOnly}
                size={InputSize.S}
                color={editableTextColor}
                {...props}
              />
            )}
          />
          <FormControl
            name="minRewardAmount"
            render={(props) => (
              <Input
                label="Min Reward Amount In Cents"
                labelClassName={classes.labelWidth}
                value={offer.minRewardAmount}
                type={InputType.Number}
                readOnly={readOnly}
                size={InputSize.S}
                color={editableTextColor}
                {...props}
              />
            )}
          />
          <FormControl
            name="maxRewardAmount"
            render={(props) => (
              <Input
                label="Max Reward Amount In Cents"
                labelClassName={classes.labelWidth}
                value={offer.maxRewardAmount}
                type={InputType.Number}
                readOnly={readOnly}
                size={InputSize.S}
                color={editableTextColor}
                {...props}
              />
            )}
          />
          {!isCreatingOffer && (
            <FormControl
              name="issuerCommissionsMap"
              render={(props) => (
                <Input
                  label="Issuer Commissions Map:"
                  labelClassName={classes.labelWidth}
                  value={firstIssuerCommission}
                  icon={informationIcon}
                  iconPosition={InputIconPosition.Right}
                  onClickIcon={onClickIssuerCommissionHandler}
                  placeholder="Issuer Commissions Map"
                  type={InputType.Text}
                  readOnly={readOnly}
                  disabled={!readOnly}
                  size={InputSize.S}
                  {...props}
                />
              )}
            />
          )}
          <FormControlSelect
            name="merchantLocationIds"
            defaultValue={offer?.merchantLocationIds}
            placeholder="No locations"
            label={`Locations (${offer?.merchantLocationIds?.length ?? 0})`}
            labelClassName={classes.labelWidth}
            options={locationIdsOptions}
            readOnly
            disabled
            isMulti
            color={SelectColor.Gray}
          />
        </div>
        <div className={classes.actionButtons}>
          {readOnly ? (
            <>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                kind={ButtonKind.Secondary}
                onClick={onCloneHandler}
                className={classes.clone}
              >
                Clone
              </Button>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                kind={ButtonKind.Secondary}
                onClick={onCancel}
              >
                Back
              </Button>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                onClick={onEditHandler}
                loading={loadingFetchAudiences}
                disabled={loadingFetchAudiences}
                className={classes.primaryButton}
              >
                Edit
              </Button>
            </>
          ) : (
            <>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                onClick={onCancel}
                kind={ButtonKind.Secondary}
              >
                {displayMode === OfferFormMode.NEWMERCHANT
                  ? 'Go back'
                  : 'Cancel'}
              </Button>
              <Button
                type={ButtonType.Submit}
                size={ButtonSize.S}
                className={classes.primaryButton}
                loading={loadingCreateOffer || loadingEditOffer}
              >
                {displayMode === OfferFormMode.EDIT ? 'Save' : 'Create'}
              </Button>
            </>
          )}
        </div>
      </Form>
    </>
  );
};

OfferForm.propTypes = {
  subtitle: PropTypes.string,
  offer: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  initialMode: PropTypes.oneOf(Object.values(OfferFormMode)),
  onCancel: PropTypes.func,
  merchant: PropTypes.objectOf(PropTypes.string),
  setBodyOffer: PropTypes.func,
  bodyOffer: PropTypes.shape({
    offerType: PropTypes.string,
    merchantId: PropTypes.string,
    merchantLocationIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
};
OfferForm.defaultProps = {
  initialMode: OfferFormMode.VIEW,
  offer: {},
  subtitle: null,
  onCancel: () => {},
  merchant: null,
  setBodyOffer: () => {},
  bodyOffer: {},
};

export default OfferForm;
