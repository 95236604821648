import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Table from 'components/Common/Table';
import Modal from 'components/Common/Modal';
import LocationForm from 'components/Pages/LocationForm';
import LocationsUpload from 'components/Pages/LocationsUpload';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import ConfirmationMessage from 'components/Common/ConfirmationMessage';
import allLocationsColumns from 'utils/locations/columns';
import addIcon from 'assets/icons/close.svg';
import searchIcon from 'assets/icons/search.svg';
import exportIcon from 'assets/icons/export.png';
import {
  selectEditLocationState,
  selectFetchLocationsState,
} from 'state/selectors/locations';
import { editLocation, fetchLocations } from 'state/actions/locations';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType';
import TableConfig from 'enums/table/table.config';
import Columns from 'enums/table/columns.enum';
import Status from 'enums/status/status.enum';
import StatusValue from 'enums/status/statusValue.enum';
import getLocationsFilters from 'utils/filters/locationsFilters';
import { selectUserAttributesState } from 'state/selectors/auth';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import { exportData } from 'state/actions/exportData';
import getIdToken from 'utils/getIdToken';
import selectExportDataState from 'state/selectors/exportData';
import getParsedFilters from 'utils/getParsedFilters';
import { clearEditLocationSuccessProp } from 'state/actionCreators/locations';

import classes from './Locations.module.scss';

const FILTER_BY_CREATED_DATE_DESC = `?createdDateSort=-1&page=${TableConfig.PAGE}&limit=${TableConfig.LIMIT}`;

const Locations = () => {
  const dispatch = useDispatch();

  const {
    locationsData: { countTotal, locations },
    loading: loadingFetchLocations,
    error: errorFetchLocations,
  } = useSelector(selectFetchLocationsState, shallowEqual);

  const {
    success: successEdit,
    loading: loadingEdit,
    error: errorEdit,
  } = useSelector(selectEditLocationState, shallowEqual);

  const { roles: userRoles, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual
  );

  const { loading: exportInProgress } = useSelector(
    selectExportDataState,
    shallowEqual
  );

  const columns = useMemo(
    () => [
      {
        Header: 'header',
        columns: getAllowedColumns(allLocationsColumns[0].columns, userRoles),
      },
    ],
    [userRoles]
  );

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFiltersGeneral, setSelectedFiltersGeneral] = useState(
    FILTER_BY_CREATED_DATE_DESC
  );
  const [sortBy, setSortBy] = useState({
    ascending: false,
    descending: true,
    columnName: 'createdDate',
  });

  const [pagination, setPagination] = useState({
    page: TableConfig.PAGE,
    limit: TableConfig.LIMIT,
  });
  const [searchText, setSearchText] = useState('');

  const [generalCount, setGeneralCount] = useState(0);

  useEffect(() => {
    dispatch(fetchLocations(FILTER_BY_CREATED_DATE_DESC));
  }, [dispatch]);

  useEffect(() => {
    if (locations.length > 0 && data.length === 0) {
      const transformedData = locations.map((location) => ({
        ...location,
        locationName: location.name,
        locationId: location._id,
        status: Status[location.status],
        merchantName: location.merchant?.name,
        city: location.address?.city || '-',
        state: location.address?.state || '-',
        street: location.address?.street || '-',
        zipCode: location.address?.zipCode || '-',
        latitude: location.geoLocation?.latitude || '-',
        longitude: location.geoLocation?.longitude || '-',
      }));
      setData(transformedData);
      setGeneralCount(
        Math.ceil(parseInt(countTotal, 10) / parseInt(pagination.limit, 10))
      );
    } else if (locations.length === 0 && data.length > 0) {
      setData([]);
    }
  }, [locations, data, searchText, countTotal, pagination]);

  useEffect(() => {
    if (successEdit) {
      dispatch(
        fetchLocations(
          `${
            typeof selectedFiltersGeneral === 'string'
              ? selectedFiltersGeneral
              : FILTER_BY_CREATED_DATE_DESC
          }`
        )
      );

      dispatch(clearEditLocationSuccessProp());

      onCloseModalHandler();
    }
  }, [
    successEdit,
    errorEdit,
    onCloseModalHandler,
    dispatch,
    selectedFiltersGeneral,
    searchText,
  ]);

  const onClickCellButtonHandler = useCallback(
    (columnName, rowIndex) => {
      if (columnName === Columns.MoreInfo) {
        onOpenModalHandler(ModalType.LOCATION_INFO, {
          location: data[rowIndex],
        });
      }
    },
    [onOpenModalHandler, data]
  );

  const onChangeSwitchHandler = useCallback(
    (columnName, index, status) => {
      if (columnName === 'status') {
        onOpenModalHandler(ModalType.LOCATION_STATUS, {
          status,
          location: data[index],
        });
      }
    },
    [onOpenModalHandler, data]
  );

  const onChangeLocationStatusHandler = useCallback(() => {
    const { location } = modal;
    const actualStatus =
      location.status === Status.ACTIVE
        ? StatusValue.Inactive
        : StatusValue.Active;
    const body = {
      ...location,
      status: actualStatus,
      merchantId: location.merchant
        ? location.merchant._id
        : location.merchantId,
    };
    delete body.locationId;
    delete body.__v;
    delete body._id;
    delete body.createdDate;
    delete body.lastModified;
    delete body.merchant;
    delete body.offers;
    delete body.locationName;
    delete body.merchantName;
    delete body.city;
    delete body.state;
    delete body.street;
    delete body.zipCode;
    delete body.latitude;
    delete body.longitude;
    dispatch(editLocation(location.locationId, body));
  }, [modal, dispatch]);

  const onClickUploadLocationHandler = useCallback(() => {
    onOpenModalHandler(ModalType.UPLOAD_LOCATION);
  }, [onOpenModalHandler]);

  const onSubmitFilterHandler = useCallback(
    ({
      resetPagination = false,
      selected,
      locationId,
      columnName,
      dates,
      sortValue,
      pageIndex,
      pageSize,
      reset,
    }) => {
      const actualID = locationId === '' ? locationId : searchText;
      const actualPage =
        pageIndex || pageIndex === 0 ? pageIndex : pagination.page;

      const filters = getLocationsFilters({
        resetPagination,
        pageIndex: actualPage,
        pageLimit: pageSize || pagination.limit,
        setPagination,
        locationId: actualID,
        selected,
        selectedFilters,
        setSelectedFilters,
        columnName,
        dates,
        sortValue,
        sortBy,
        setSortBy,
        reset,
      });
      setData([]);
      setSelectedFiltersGeneral(filters);
      dispatch(fetchLocations(filters));
    },
    [selectedFilters, sortBy, pagination, searchText, dispatch]
  );

  const onChangeSearchTextHandler = useCallback(
    (text) => {
      setSearchText(text);
      if (text === '') {
        onSubmitFilterHandler({
          locationId: text,
        });
      }
      setSelectedFilters({});
      setSortBy({});
    },
    [onSubmitFilterHandler]
  );

  const onExportDataHandler = async () => {
    const idToken = await getIdToken();
    let filters = getParsedFilters(selectedFiltersGeneral);

    if (searchText !== '') {
      filters = {
        ...filters,
        _id: searchText,
      };
    }

    const exportInfo = {
      idToken,
      filters,
      model: 'Location',
    };

    dispatch(exportData(exportInfo));
  };

  const noDataToShow = data.length === 0;

  const getExportLocationsTooltip = () => {
    if (loadingFetchLocations) {
      return '';
    }

    if (exportInProgress) {
      return 'There is another export in progress. Try again after!';
    }

    if (noDataToShow) {
      return 'There is no data to export';
    }

    return '';
  };

  const disableExportButton =
    loadingFetchLocations || exportInProgress || noDataToShow;

  return (
    <>
      {successEdit && (
        <Toast
          id="update location success"
          text="Location updated successfully"
          type={ToastType.Success}
        />
      )}
      {errorEdit && (
        <Toast id="submit error" text={errorEdit} type={ToastType.Error} />
      )}
      {errorFetchLocations && (
        <Toast
          id="fetch location by id"
          text={
            searchText
              ? 'There is no location that matches that ID'
              : 'Error fetching locations'
          }
          type={ToastType.Error}
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.LOCATION_INFO}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <LocationForm
          title="Location Information"
          onCancel={onCloseModalHandler}
          location={modal.location}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.UPLOAD_LOCATION}
        onClose={onCloseModalHandler}
        className={classes.uploadLocationModalWidth}
      >
        <LocationsUpload
          title="Add/Update Location"
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.LOCATION_STATUS}
        onClose={onCloseModalHandler}
        className={classes.confirmationModalWidth}
      >
        <ConfirmationMessage
          message="Are you sure you want to change the location status?"
          onAccept={onChangeLocationStatusHandler}
          onCancel={onCloseModalHandler}
          loading={loadingEdit}
        />
      </Modal>
      <div className={classes.table}>
        <div className={classes.tableHeading}>
          <div className={classes.searchWidth}>
            <Input
              name="search"
              onChange={(event) =>
                onChangeSearchTextHandler(event.target.value)
              }
              placeholder="Search By Location ID"
              type={InputType.Text}
              icon={searchIcon}
              size={InputSize.S}
              className={classes.search}
              onKeyPress={() =>
                onSubmitFilterHandler({ resetPagination: true })
              }
            />
          </div>
          {isAdmin && (
            <div className={classes.actions}>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                className={classes.button}
                onClick={onClickUploadLocationHandler}
              >
                <div className={classes.addIcon}>
                  <img src={addIcon} alt="add" />
                </div>
              </Button>
            </div>
          )}
          {!isAdmin && (
            <span
              data-tip={getExportLocationsTooltip()}
              data-for="exportLocations"
            >
              <Button
                type={ButtonType.Default}
                className={classes.exportButton}
                onClick={onExportDataHandler}
                kind="minimal"
                disabled={disableExportButton}
              >
                <span className={classes.exportLocations}>
                  <img
                    src={exportIcon}
                    className={classes.exportIcon}
                    alt="export locations"
                  />
                  Export
                </span>
              </Button>
            </span>
          )}
        </div>
        {loadingFetchLocations ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={columns}
            data={data}
            selectedFilters={selectedFilters}
            sortBy={sortBy}
            onSubmitFilter={onSubmitFilterHandler}
            onClickCellButton={onClickCellButtonHandler}
            onChangeSwitch={onChangeSwitchHandler}
            itemsPerPage={pagination.limit}
            currentPage={pagination.page}
            manualPagination
            fetchData={onSubmitFilterHandler}
            bodyClassName={classes.tableBody}
            countTotal={generalCount}
            newPagination
          />
        )}
      </div>
      <ReactTooltip id="exportLocations" />
    </>
  );
};

export default Locations;
