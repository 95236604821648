import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(Validations.InvalidEmail)
    .required(Validations.RequiredEmail),
  password: yup.string().required(Validations.RequiredPassword),
});

export default validationSchema;
