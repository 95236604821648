import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, { Color as BodyColor } from 'components/Typography/Body';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';

import classes from './Input.module.scss';

export const Type = Object.freeze({
  Text: 'text',
  Number: 'number',
  Password: 'password',
  Submit: 'submit',
  Reset: 'reset',
  Radio: 'radio',
  Checkbox: 'checkbox',
  Button: 'button',
  File: 'file',
  Image: 'image',
  Email: 'email',
  Tel: 'tel',
});

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
});

export const LabelPosition = Object.freeze({
  Top: 'top',
  Left: 'left',
});

export const IconPosition = Object.freeze({
  Left: 'left',
  Right: 'right',
});

export const Color = Object.freeze({
  Black: 'black',
  Gray: 'gray',
  Red: 'red',
  Green: 'green',
});

const Input = ({
  type,
  label,
  labelPosition,
  name,
  value,
  required,
  placeholder,
  disabled,
  readOnly,
  error,
  register,
  onChange,
  onKeyUp,
  altIcon,
  icon,
  iconPosition,
  size,
  className,
  containerClassName,
  labelClassName,
  minNumber,
  onClickIcon,
  onKeyPress,
  color,
  isSearch,
  inputFocus,
  ...rest
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputFocus) {
      inputRef.current?.focus();
    }
  }, [inputFocus]);

  return (
    <div
      className={classNames(containerClassName, classes.container, {
        [classes.leftLabel]: labelPosition === LabelPosition.Left,
      })}
    >
      {label && (
        <Body
          className={classNames(
            labelClassName,
            classes.labelClassName,
            classes.labelText,
            {
              [classes.leftLabelText]: labelPosition === LabelPosition.Left,
            }
          )}
          color={BodyColor.Gray}
        >
          {label}
        </Body>
      )}
      <input
        className={classNames(
          className,
          classes.input,
          classes[size],
          classes[color],
          {
            [classes.inputHasIconLeft]:
              icon && iconPosition === IconPosition.Left,
            // [classes.inputHasIconRight]:
            //   icon && iconPosition === IconPosition.Right,
            [classes.hasError]: error,
            [classes.isDisabled]: disabled,
            [classes.leftLabelInput]:
              labelPosition === LabelPosition.Left && label,
          }
        )}
        id={name}
        name={name}
        // the value prop actually behaves like defaultValue
        // because this input's value is usually controlled by a form.
        // you must explicitly set isSearch to control the value.
        defaultValue={isSearch ? undefined : value}
        value={isSearch ? value : undefined}
        ref={inputFocus ? inputRef : register}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        aria-label={name}
        disabled={disabled}
        readOnly={readOnly}
        min={minNumber}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onKeyPress();
          }
        }}
        {...rest}
      />
      {icon && onClickIcon ? (
        <Button
          type={ButtonType.Submit}
          size={ButtonSize.S}
          className={classNames(classes.button, classes.icon, {
            [classes.iconWithLabelTop]:
              label && labelPosition === LabelPosition.Top,
            [classes.rightIcon]: iconPosition === IconPosition.Right,
            [classes.leftIcon]: iconPosition === IconPosition.Left,
            [classes.disabledButton]: disabled,
          })}
          onClick={onClickIcon}
        >
          <img src={icon} alt={altIcon} />
        </Button>
      ) : (
        <img
          alt={altIcon}
          className={classNames(classes.icon, classes.imageIcon, {
            [classes.iconWithLabelTop]:
              label && labelPosition === LabelPosition.Top,
            [classes.iconWithLabelLeft]:
              label && labelPosition === LabelPosition.Left,
            [classes.rightIcon]: iconPosition === IconPosition.Right,
            [classes.leftIcon]: iconPosition === IconPosition.Left,
          })}
          src={icon}
        />
      )}
    </div>
  );
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Type)),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  altIcon: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  minNumber: PropTypes.number,
  onClickIcon: PropTypes.func,
  onKeyPress: PropTypes.func,
  iconPosition: PropTypes.oneOf(Object.values(IconPosition)),
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  color: PropTypes.oneOf(Object.values(Color)),
  isSearch: PropTypes.bool,
  inputFocus: PropTypes.bool,
};

Input.defaultProps = {
  type: Type.Text,
  register: null,
  required: false,
  placeholder: '',
  altIcon: '',
  icon: null,
  onChange: () => {},
  onKeyUp: () => {},
  onKeyPress: () => {},
  onClickIcon: null,
  error: false,
  disabled: false,
  size: Size.M,
  className: '',
  containerClassName: '',
  labelClassName: '',
  readOnly: false,
  value: null,
  label: null,
  minNumber: null,
  iconPosition: IconPosition.Left,
  labelPosition: LabelPosition.Left,
  color: Color.Gray,
  isSearch: false,
  inputFocus: false,
};

export default Input;
