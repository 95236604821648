import React from 'react';
import Table from 'components/Common/Table';
import columns from 'refactored/utils/queryPlayground/columns/compare';
import PropTypes from 'prop-types';

const QueryPlaygroundContent = ({ data }) => (
  <Table columns={columns} data={data} pagination={false} />
);
QueryPlaygroundContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default QueryPlaygroundContent;
