import { Auth } from 'aws-amplify';
import getCognitoUserData from 'utils/users/getCognitoUserData';

const fetchUserProfile = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    return getCognitoUserData(user);
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchUserProfile;
