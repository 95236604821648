import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import momentPropTypes from 'react-moment-proptypes';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Badge, {
  Size as BadgeSize,
  Kind as BadgeKind,
} from 'components/Common/Badge';
import RangeDatePicker from 'components/Common/RangeDatePicker';
import searchIcon from 'assets/icons/search.svg';
import closeIcon from 'assets/icons/close.svg';
import closeGrayIcon from 'assets/icons/color/close-gray.svg';

import classes from './FilterBy.module.scss';

const FilterByReport = ({
  dateType,
  numberType,
  stringType,
  boolType,
  searchType,
  dates,
  multiValue,
  onChangeDates,
  range,
  onChangeRange,
  focusedInput,
  onChangeFocus,
  filterOptions,
  selectedFilterOptions,
  hasSelectedOptions,
  onSearchCategory,
  onClearAll,
  onRemoveSelectedCategory,
  onClickCategory,
  searchable,
  columnName,
  setSearchInput,
  searchInput,
  inputFocus,
}) => {
  const searchHandler = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <>
      <Body
        size={BodySize.XS}
        color={BodyColor.Gray}
        className={classes.filterBy}
      >
        Filter By
      </Body>
      {dateType && (
        <div className={classes.dates}>
          {dates.startDate && (
            <Badge
              text="Clear dates"
              endIcon={closeGrayIcon}
              altEndIcon="remove"
              size={BadgeSize.S}
              kind={BadgeKind.Tertiary}
              className={classes.clearDates}
              onClick={onClearAll}
            />
          )}
          <RangeDatePicker
            startDate={dates.startDate}
            endDate={dates.endDate}
            onChangeDates={onChangeDates}
            focusedInput={focusedInput}
            onChangeFocus={onChangeFocus}
          />
        </div>
      )}
      {numberType && (
        <>
          {range.start && (
            <Badge
              text="Clear range"
              endIcon={closeGrayIcon}
              altEndIcon="remove"
              size={BadgeSize.S}
              kind={BadgeKind.Tertiary}
              className={classes.clearRange}
              onClick={onClearAll}
            />
          )}
          <div className={classes.number}>
            <Input
              name="min"
              placeholder="Min"
              value={range.start}
              type={InputType.Number}
              size={InputSize.XS}
              className={classes.numberInput}
              containerClassName={classes.numberInputContainer}
              onChange={(event) => onChangeRange('start', event.target.value)}
            />
            -
            <Input
              name="max"
              placeholder="Max"
              value={range.end}
              type={InputType.Number}
              size={InputSize.XS}
              className={classes.numberInput}
              containerClassName={classes.numberInputContainer}
              onChange={(event) => onChangeRange('end', event.target.value)}
            />
          </div>
        </>
      )}
      {searchType && (
        <>
          {hasSelectedOptions && (
            <>
              <div
                className={classNames(
                  classes.searchSelectedFields,
                  classes.selectedFields
                )}
              >
                {multiValue && (
                  <Badge
                    text="Clear all"
                    endIcon={closeGrayIcon}
                    altEndIcon="remove"
                    size={BadgeSize.S}
                    kind={BadgeKind.Tertiary}
                    className={classes.clearAll}
                    onClick={onClearAll}
                  />
                )}
                {Object.values(selectedFilterOptions).map(
                  (option, optionIndex) => (
                    <Badge
                      key={`bdge ${optionIndex.toString()}`}
                      text={option.text}
                      endIcon={closeIcon}
                      altEndIcon="remove"
                      size={BadgeSize.S}
                      kind={BadgeKind.Primary}
                      className={classes.badge}
                      onClick={() =>
                        onRemoveSelectedCategory({
                          text: option.text,
                        })
                      }
                    />
                  )
                )}
              </div>
            </>
          )}
          <div className={classes.panelSearch}>
            <Input
              name="search"
              placeholder={`Search By ${columnName}`}
              type={InputType.Text}
              icon={searchIcon}
              size={InputSize.XS}
              value={searchInput}
              className={classes.search}
              onChange={(e) => searchHandler(e)}
              inputFocus={inputFocus}
              isSearch
            />
          </div>
        </>
      )}
      {(stringType || boolType) && (
        <>
          {searchable && (
            <div className={classes.panelSearch}>
              <Input
                name="search"
                placeholder="Search by field values"
                type={InputType.Text}
                icon={searchIcon}
                size={InputSize.XS}
                className={classes.search}
                onChange={onSearchCategory}
                inputFocus={inputFocus}
              />
            </div>
          )}
          {hasSelectedOptions && (
            <>
              {multiValue && (
                <Badge
                  text="Clear all"
                  endIcon={closeGrayIcon}
                  altEndIcon="remove"
                  size={BadgeSize.S}
                  kind={BadgeKind.Tertiary}
                  className={classes.clearAll}
                  onClick={onClearAll}
                />
              )}
              <div className={classes.selectedFields}>
                {Object.values(selectedFilterOptions).map(
                  (option, optionIndex) => (
                    <Badge
                      key={`bdg ${optionIndex.toString()}`}
                      text={option.text}
                      endIcon={closeIcon}
                      altEndIcon="remove"
                      size={BadgeSize.S}
                      kind={BadgeKind.Primary}
                      className={classes.badge}
                      onClick={() =>
                        onRemoveSelectedCategory({
                          text: option.text,
                        })
                      }
                    />
                  )
                )}
              </div>
            </>
          )}
          <div className={classes.list}>
            {filterOptions.map((item, itemIndex) => (
              <button
                key={`option ${itemIndex.toString()}`}
                className={classNames(classes.item, {
                  [classes.clicked]: selectedFilterOptions[item],
                })}
                onClick={() => onClickCategory(item)}
                type="button"
              >
                <Body size={BodySize.XS}>{item}</Body>
              </button>
            ))}
          </div>
        </>
      )}
    </>
  );
};
FilterByReport.propTypes = {
  dateType: PropTypes.bool.isRequired,
  numberType: PropTypes.bool.isRequired,
  stringType: PropTypes.bool.isRequired,
  boolType: PropTypes.bool.isRequired,
  searchType: PropTypes.bool.isRequired,
  dates: PropTypes.shape({
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
  }).isRequired,
  range: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }).isRequired,
  onChangeRange: PropTypes.func.isRequired,
  multiValue: PropTypes.bool.isRequired,
  onChangeDates: PropTypes.func.isRequired,
  focusedInput: PropTypes.oneOf(['startDate', 'endDate']),
  onChangeFocus: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedFilterOptions: PropTypes.objectOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  hasSelectedOptions: PropTypes.bool.isRequired,
  onSearchCategory: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onRemoveSelectedCategory: PropTypes.func.isRequired,
  onClickCategory: PropTypes.func.isRequired,
  searchable: PropTypes.bool.isRequired,
  columnName: PropTypes.string.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  inputFocus: PropTypes.bool,
};

FilterByReport.defaultProps = {
  focusedInput: null,
  inputFocus: false,
};

export default FilterByReport;
