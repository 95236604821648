/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import Toaster from 'components/Common/Toaster';
import Router from 'pages/Router';
import store from 'state/store';
import amplifyConfig from 'utils/amplify-config';

// Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import reportWebVitals from './reportWebVitals';
import './index.scss';

Amplify.configure(amplifyConfig);
Auth.configure();

const persistor = persistStore(store);

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
} = process.env;

if (!REACT_APP_SENTRY_DSN || !/^https:\/\//.test(REACT_APP_SENTRY_DSN)) {
  console.warn('REACT_APP_SENTRY_DSN is missing or invalid', {
    REACT_APP_SENTRY_DSN,
  });
}

if (!REACT_APP_SENTRY_ENVIRONMENT || REACT_APP_SENTRY_ENVIRONMENT === '') {
  console.warn('REACT_APP_SENTRY_ENVIRONMENT is missing or invalid', {
    REACT_APP_SENTRY_ENVIRONMENT,
  });
}

if (
  !REACT_APP_SENTRY_TRACES_SAMPLE_RATE ||
  REACT_APP_SENTRY_TRACES_SAMPLE_RATE === '' ||
  Number.isNaN(parseFloat(REACT_APP_SENTRY_TRACES_SAMPLE_RATE))
) {
  console.warn('REACT_APP_SENTRY_TRACES_SAMPLE_RATE is missing or invalid', {
    REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  });
}

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: REACT_APP_SENTRY_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: parseFloat(REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
});

ReactDOM.render(
  <React.StrictMode>
    <Toaster />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
