import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

export const Type = Object.freeze({
  Error: 'error',
  Success: 'success',
});

const Toast = ({ id, text, type, icon, position, className }) => {
  const defaultOptions = useMemo(
    () => ({
      id,
      duration: 4000,
      position: position || 'bottom-center',
    }),
    [id, position]
  );

  const customOptions = useMemo(
    () => ({
      className: className || '',
      icon: icon || null,
      iconTheme: {
        primary: '#000',
        secondary: '#fff',
      },
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    }),
    [className, icon]
  );

  if (type === Type.Success) {
    toast.success(text, defaultOptions);
    return null;
  }

  if (type === Type.Error) {
    toast.error(text, defaultOptions);
    return null;
  }

  toast(text, customOptions);
  return null;
};

Toast.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Type)),
  icon: PropTypes.string,
  position: PropTypes.string,
  className: PropTypes.string,
};

Toast.defaultProps = {
  type: null,
  icon: '',
  position: '',
  className: '',
};

export default Toast;
