import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import notFound from 'assets/icons/color/notFound.png';
import { LabelPosition } from 'enums/inputProps';
import Input from '../Input';

import classes from './InputImage.module.scss';

const InputImage = (props) => {
  const { name, labelPosition } = props;

  const { watch, register } = useFormContext();

  const [imageData, setImageData] = useState({});

  const imageValue = watch(name);

  useEffect(() => {
    if (imageValue) {
      setImageData({ src: imageValue, hint: '' });
    }

    if (!imageValue) {
      setImageData({ src: notFound, hint: 'Enter URL for preview' }); // TODO: Waiting for icon for no URL selected, change src here when ready
    }
  }, [imageValue]);

  const onImgError = useCallback(
    () => setImageData({ src: notFound, hint: 'Unable to display image' }),
    []
  );

  return (
    <>
      <Input {...props} register={register()} />
      <div className={classes.flex}>
        <img
          src={imageData.src}
          alt="imgPreview"
          onError={onImgError}
          className={classNames(
            classes.imagePreview,
            { [classes.imageMarginLeft]: labelPosition === LabelPosition.Left },
            { [classes.imageMarginY]: labelPosition !== LabelPosition.Left }
          )}
        />
        <p className={classes.imageHint}>{imageData.hint}</p>
      </div>
    </>
  );
};

InputImage.propTypes = {
  name: PropTypes.string.isRequired,
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
};

InputImage.defaultProps = {
  labelPosition: LabelPosition.Top,
};

export default InputImage;
