/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';

import classes from './Button.module.scss';

export const Size = Object.freeze({
  L: 'large',
  S: 'small',
});

export const Kind = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
  Underlined: 'underlined',
  Minimal: 'minimal',
});

export const Type = Object.freeze({
  Default: 'button',
  Reset: 'reset',
  Submit: 'submit',
});

const Button = ({
  size,
  kind,
  type,
  disabled,
  loading,
  isFullWidth,
  children,
  onClick,
  className,
  loaderColor,
}) => (
  <button
    type={type}
    className={classNames(
      classes.button,
      classes[size],
      className,
      classes[kind],
      {
        [classes.loading]: loading,
        [classes.isFullWidth]: isFullWidth,
        [classes.disabled]: disabled,
      }
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {loading ? (
      <Spinner
        color={loaderColor ? SpinnerColor[loaderColor] : SpinnerColor.White}
        size={size === Size.L ? SpinnerSize.L : SpinnerSize.M}
      />
    ) : (
      children
    )}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(Size)),
  kind: PropTypes.oneOf(Object.values(Kind)),
  type: PropTypes.oneOf(Object.values(Type)),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  loaderColor: PropTypes.string,
};

Button.defaultProps = {
  size: Size.S,
  kind: Kind.Primary,
  type: Type.Default,
  disabled: false,
  loading: false,
  isFullWidth: false,
  onClick: () => {},
  className: '',
  loaderColor: '',
};

export default Button;
