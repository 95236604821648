import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reprocessColumns } from 'utils/pendingTransactions/columns';
import Table from 'components/Common/Table';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import { selectReprocessPendingTransactionState } from 'state/selectors/pendingTransactions';
import { shallowEqual, useSelector } from 'react-redux';
import ConfirmationMessage from 'components/Common/ConfirmationMessage';
import Checkbox, { Kind as CheckboxKind } from 'components/Common/Checkbox';
import classes from './PendingTransactionsReprocess.module.scss';

const PendingTransactionReprocess = ({ isApproved, onSubmit, onCancel }) => {
  const [selectTxns, setSelectedTxns] = useState([]);
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [submitLoading, setLoading] = useState(false);

  const {
    loading,
    success: reprocessTransactionSuccess,
    reprocessPendingTxns,
  } = useSelector(selectReprocessPendingTransactionState, shallowEqual);

  const onSubmitHandler = useCallback(() => {
    setLoading(true);
    onSubmit(selectTxns);
  }, [onSubmit, selectTxns]);

  useEffect(() => {
    const list =
      reprocessTransactionSuccess && reprocessPendingTxns.length
        ? reprocessPendingTxns.map((transaction) => ({
            ...transaction,
            selected: selectAll,
          }))
        : [];
    setData(list);
    if (selectAll) {
      const selectedIds = reprocessPendingTxns.map((o) => o._id);
      setSelectedTxns(selectedIds);
    } else {
      setSelectedTxns([]);
    }
  }, [reprocessPendingTxns, reprocessTransactionSuccess, selectAll]);

  const onChangeCheckBox = (index) => {
    const list = [...data];
    list[index].selected = !list[index].selected;
    setData(list);
    let selected = [...selectTxns];
    if (list[index].selected) {
      selected.push(list[index]._id);
      setSelectedTxns(selected);
    } else {
      selected = selected.filter((element) => element !== list[index]._id);
    }
    setSelectedTxns(selected);
  };

  return data.length !== 0 ? (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.heading}>
          <Heading size={HeadingSize.M} className={classes.headingText}>
            Similar Pending Transactions
          </Heading>
          <Checkbox
            selected={selectAll}
            setSelected={() => setSelectAll(!selectAll)}
            className={classes.selectCell}
            kind={CheckboxKind.Secondary}
            label="Select All"
          />
        </div>

        <div className={classes.table}>
          {loading ? (
            <Spinner
              color={SpinnerColor.Black}
              size={SpinnerSize.L}
              className={classes.spinner}
            />
          ) : (
            <Table
              columns={reprocessColumns}
              data={data}
              canSelect
              onClickSelectedCell={onChangeCheckBox}
            />
          )}
        </div>
      </div>
      <div className={classes.actionButtons}>
        <Button
          type={ButtonType.Default}
          size={ButtonSize.S}
          className={classes.button}
          onClick={onCancel}
          kind={ButtonKind.Secondary}
        >
          Cancel
        </Button>
        <Button
          type={ButtonType.Default}
          size={ButtonSize.S}
          className={classes.button}
          onClick={onSubmitHandler}
          disabled={!selectTxns.length}
          kind={ButtonKind.Primary}
          loading={submitLoading}
        >
          Save
        </Button>
      </div>
    </div>
  ) : (
    <ConfirmationMessage
      message={
        isApproved
          ? 'Are you sure you want to approve this transaction?'
          : 'Are you sure you want to reject this transaction?'
      }
      onAccept={onSubmitHandler}
      onCancel={onCancel}
      loading={loading}
    />
  );
};

PendingTransactionReprocess.propTypes = {
  isApproved: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default PendingTransactionReprocess;
