const SideBarItems = {
  DASHBOARD: 'Dashboard',
  AUDIENCE: 'Audiences',
  PENDING_TRANSACTIONS: 'Pending Transactions',
  REPORTS: 'Reports',
  PORTAL_USERS: 'Portal Users',
  OFFERS: 'Offers',
  MERCHANTS: 'Merchants',
  LOCATIONS: 'Locations',
  TRANSACTIONS_SUPPORT: 'Transactions Support',
};

export default SideBarItems;
