import { TRANSACTION_STATUSES } from 'utils/pendingTransactions/transactionStatus';

export const ALL_ISSUERS = 'ALL';

export const generateGetPendingTransactionsQueryString = ({
  page = 0,
  limit = 10,
  reviewStatus = null,
  transactionStatus = null,
  issuer = null,
  startDate = null,
  endDate = null,
}) => {
  const filtersQuery = new URLSearchParams({
    page,
    limit,
    ...(issuer && issuer !== ALL_ISSUERS && { issuer }),
    ...(transactionStatus &&
      transactionStatus !== TRANSACTION_STATUSES.ALL && { transactionStatus }),
  });

  if (reviewStatus) {
    filtersQuery.append('reviewStatus', reviewStatus);
  }

  if (startDate) {
    filtersQuery.append('startDate', startDate.toISOString());
  }

  if (endDate) {
    filtersQuery.append('endDate', endDate.toISOString());
  }

  return `?${filtersQuery.toString()}`;
};
