import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';
import Roles from 'enums/users/roles.enum';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+$/;
const fullNameRegex = /^[\u00C0-\u017Fa-zA-Z- '`.]+$/;
const phoneRegex = /^[+]\d{11,13}$/;

const portalUserSchema = yup.object().shape({
  email: yup
    .string()
    .email(Validations.InvalidEmail)
    .required(Validations.RequiredEmail)
    .matches(emailRegex, Validations.InvalidEmail),
  roles: yup
    .array()
    .min(1, Validations.RequiredRoles)
    .required(Validations.RequiredRoles),
  issuerName: yup.string().when('roles', {
    is: (roles) => roles.includes(Roles.Issuer),
    then: yup
      .string()
      .nullable()
      .required(Validations.RequiredIssuer)
      // As of writing this comment, the backend sets null issuerName to ' '
      // so that Cognito does not delete the property.
      // Only succeed if there is some non-whitespace value in the issuerName.
      .matches(/[^\s]/, Validations.RequiredIssuer),
    otherwise: yup.string().nullable(),
  }),
  title: yup.string(),
  fullName: yup
    .string()
    .required(Validations.RequiredName)
    .matches(fullNameRegex, Validations.FullNameFormat),
  phoneNumber: yup.string().matches(phoneRegex, {
    message: Validations.PhoneNumberFormat,
    excludeEmptyString: true,
  }),
});

export default portalUserSchema;
