import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import groupIcon from 'assets/icons/color/group.svg';
import reportsIcon from 'assets/icons/color/reports.svg';
import userIcon from 'assets/icons/color/user.svg';
import basketIcon from 'assets/icons/color/basket.svg';
import homeIcon from 'assets/icons/color/home.svg';
import mapIcon from 'assets/icons/color/map.svg';
import fileIcon from 'assets/icons/color/file.svg';
import transactionsSupport from 'assets/icons/color/technical-support.png';
import audience from 'assets/icons/color/users.png';
import Path from 'enums/path.enum';
import SideBarItems from 'enums/sideBar/items.enum';
import { selectUserAttributesState } from 'state/selectors/auth';
import kardLogo from 'assets/logos/blue.svg';

import classNames from 'classnames';
import { selectSidebarExpandedState } from 'state/selectors/layout';
import { toggleSidebar } from 'state/actionCreators/layout';
import ReactTooltip from 'react-tooltip';
import Item from './Item';
import classes from './SideBar.module.scss';
import ExpandButton from './ExpandButton';

const SideBar = () => {
  const location = useLocation();

  const [selected, setSelected] = useState(null);

  const { isAdmin } = useSelector(selectUserAttributesState, shallowEqual);

  useEffect(() => {
    if (!selected) {
      setSelected(location.pathname);
    }
  }, [location, selected]);

  const sidebarExpanded = useSelector(selectSidebarExpandedState, shallowEqual);

  useEffect(() => ReactTooltip.rebuild(), [sidebarExpanded]);

  const dispatch = useDispatch();

  const onToggleCollapse = useCallback(() => {
    dispatch(toggleSidebar());
  }, [dispatch]);

  return (
    <nav
      className={classNames(classes.content, {
        [classes.collapsed]: !sidebarExpanded,
      })}
    >
      <div className={classes.fixedContent}>
        <img
          className={classes.headerImg}
          src={kardLogo}
          alt="Kard Financial Header Logo"
        />
        <Item
          title={SideBarItems.DASHBOARD}
          logo={groupIcon}
          to={Path.Home}
          selected={location.pathname === Path.Home}
        />
        {isAdmin && (
          <Item
            title={SideBarItems.AUDIENCE}
            logo={audience}
            to={Path.Audience}
            selected={location.pathname.includes(Path.Audience)}
          />
        )}
        {isAdmin && (
          <Item
            title={SideBarItems.PENDING_TRANSACTIONS}
            logo={fileIcon}
            to={Path.PendingTransactions}
            selected={location.pathname.includes(Path.PendingTransactions)}
          />
        )}
        <Item
          title={SideBarItems.REPORTS}
          logo={reportsIcon}
          to={Path.Reports}
          selected={location.pathname.includes(Path.Reports)}
        />
        {isAdmin && (
          <Item
            title={SideBarItems.PORTAL_USERS}
            logo={userIcon}
            to={Path.PortalUsers}
            selected={location.pathname.includes(Path.PortalUsers)}
          />
        )}
        <Item
          title={SideBarItems.OFFERS}
          logo={basketIcon}
          to={Path.Offers}
          selected={location.pathname.includes(Path.Offers)}
        />
        <Item
          title={SideBarItems.MERCHANTS}
          logo={homeIcon}
          to={Path.Merchants}
          selected={location.pathname.includes(Path.Merchants)}
        />
        <Item
          title={SideBarItems.LOCATIONS}
          logo={mapIcon}
          to={Path.Locations}
          selected={location.pathname.includes(Path.Locations)}
        />
        <Item
          title={SideBarItems.TRANSACTIONS_SUPPORT}
          logo={transactionsSupport}
          to={Path.TransactionsSupport}
          selected={location.pathname.includes(Path.TransactionsSupport)}
        />
        <div className={classes.spacer} />
        <ExpandButton clicked={onToggleCollapse} />
        <ReactTooltip
          delayHide={100}
          place="right"
          effect="solid"
          id="sidebarTip"
          html
          getContent={(dataTip) => `${dataTip}`}
        />
      </div>
    </nav>
  );
};

export default SideBar;
