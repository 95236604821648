import { createSelector } from '@reduxjs/toolkit';
import Status from 'enums/status/status.enum';
import StatusValue from 'enums/status/statusValue.enum';

const selectOffersState = (state) => state.offers;

export const selectFetchOffersState = createSelector(
  selectOffersState,
  ({
    fetchOffersLoading,
    fetchOffersSuccess,
    fetchOffersError,
    offers: { results, countTotal },
  }) => ({
    loading: fetchOffersLoading,
    success: fetchOffersSuccess,
    error: fetchOffersError,
    offersData: {
      offers: results || [],
      countTotal: countTotal || 0,
    },
  })
);

export const selectFetchOffersByMerchantIdState = createSelector(
  selectOffersState,
  ({
    fetchOffersByMerchantIdLoading,
    fetchOffersByMerchantIdSuccess,
    fetchOffersByMerchantIdError,
    offersByMerchant: { results },
  }) => ({
    loading: fetchOffersByMerchantIdLoading,
    success: fetchOffersByMerchantIdSuccess,
    error: fetchOffersByMerchantIdError,
    offers: results,
    selectedOffers:
      results?.map((offer) => ({
        ...offer,
        offerId: offer._id,
        merchantName: offer.name,
        status:
          offer.status === StatusValue.Active ? Status.ACTIVE : Status.INACTIVE,
      })) ?? [],
  })
);

export const selectFetchOfferByIdState = createSelector(
  selectOffersState,
  ({
    fetchOfferByIdLoading,
    fetchOfferByIdSuccess,
    fetchOfferByIdError,
    offer,
  }) => ({
    loading: fetchOfferByIdLoading,
    success: fetchOfferByIdSuccess,
    error: fetchOfferByIdError,
    offer,
  })
);

export const selectCreateOfferState = createSelector(
  selectOffersState,
  ({
    createOfferLoading,
    createOfferSuccess,
    createOfferError,
    createdOffer,
  }) => ({
    loading: createOfferLoading,
    success: createOfferSuccess,
    error: createOfferError,
    createdOffer,
  })
);

export const selectEditOfferState = createSelector(
  selectOffersState,
  ({ editOfferLoading, editOfferSuccess, editOfferError, msgOfferStatus }) => ({
    loading: editOfferLoading,
    success: editOfferSuccess,
    error: editOfferError,
    msg: msgOfferStatus,
  })
);
