/* eslint-disable no-unused-vars */

const removeEmptyFieldsFromObject = (obj) =>
  Object.entries(obj)
    .filter(
      ([_, value]) => value !== undefined && value !== null && value !== ''
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export default removeEmptyFieldsFromObject;
