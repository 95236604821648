import client from 'services/kardAPI';

const fetchAuditById = async (id) => {
  try {
    const { data } = await client.get(`/transactions/portal/audit/${id}`);

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchAuditById;
