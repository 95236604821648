const csvData = [
  [
    'Street',
    'City',
    'State',
    'Zip',
    'Longitude',
    'Latitude',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Phone',
    'URL',
    'MerchantId',
    'Name',
    'OfferId',
    'LocationType',
    'Status',
    'Network',
    'LocationId',
    'IsValidated',
    'PermanentlyClosed',
    'GoogleId',
    'StoreId',
    'Source',
  ],
  [
    '3413 Test St',
    'Charlotte',
    'NC',
    '28226',
    '-80.786571',
    '35.095474',
    'Closed',
    '9:00 AM - 5:00 PM',
    '9:00 AM - 5:00 PM',
    '9:00 AM - 5:00 PM',
    '9:00 AM - 5:00 PM',
    '9:00 AM - 5:00 PM',
    'Closed',
    '(999) 999-9999',
    'https://stores.com',
    '621551be128e130009e14bc4',
    'Matthews Avos',
    '62155375d170920009d34210',
    'INSTORE',
    'ACTIVE',
    'KARD',
    '',
    'TRUE',
    'FALSE',
    'fake',
    '1111STOREID1111',
    'NATIONAL',
  ],
];

export default csvData;
