import { createAction } from '@reduxjs/toolkit';

export const fetchPendingTransactionsInit = createAction(
  '[Pending Transactions] Fetch Pending Transactions Init'
);
export const fetchPendingTransactionsSuccess = createAction(
  '[Pending Transactions] Fetch Pending Transactions Success'
);
export const fetchPendingTransactionsFail = createAction(
  '[Pending Transactions] Fetch Pending Transactions Fail'
);

export const editPendingTransactionInit = createAction(
  '[Pending Transactions] Edit Pending Transaction Init'
);
export const editPendingTransactionSuccess = createAction(
  '[Pending Transactions] Edit Pending Transaction Success'
);
export const editPendingTransactionFail = createAction(
  '[Pending Transactions] Edit Pending Transaction Fail'
);

export const reprocessIncomingTransactionInit = createAction(
  '[Pending Transactions] Reprocess Pending Transaction Init'
);
export const reprocessIncomingTransactionSuccess = createAction(
  '[Pending Transactions] Reprocess Pending Transaction Success'
);
export const reprocessIncomingTransactionFail = createAction(
  '[Pending Transactions] Reprocess Pending Transaction Fail'
);
export const clearReprocessIncomingTransactionValues = createAction(
  '[Pending Transactions] Reprocess Pending Transaction Clear State'
);

export const reprocessPendingTransactionInit = createAction(
  '[Pending Transactions - 2] Reprocess Pending Transaction Init'
);
export const reprocessPendingTransactionSuccess = createAction(
  '[Pending Transactions - 2] Reprocess Pending Transaction Success'
);
export const reprocessPendingTransactionFail = createAction(
  '[Pending Transactions - 2] Reprocess Pending Transaction Fail'
);
export const clearReprocessPendingTransactionValues = createAction(
  '[Pending Transactions - 2] Reprocess Pending Transaction Clear State'
);
