import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import FlexBox from 'components/Common/FlexBox';
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import FormControlSelect, {
  Size as SelectLabelSize,
  LabelPosition as SelectLabelPosition,
} from 'components/Common/FormControlSelect';
import Input, {
  Type as InputType,
  LabelPosition as InputLabelPosition,
} from 'components/Common/Input';
import Heading from 'components/Typography/Heading';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import kardLogo from 'assets/logos/blue.svg';
import msg from 'utils/commonMessages';
import { createPublicAudits } from 'state/actions/audits';
import { selectCreatePublicAuditState } from 'state/selectors/audits';
import auditFormSchema from './auditForm.schema';
import classes from './Support.module.scss';

const auditCodes = [
  {
    value: 3005,
    label: '3005 - Incorrect Cashback Amount',
  },
  {
    value: 3006,
    label: '3006 - Missing Cashback Award',
  },
  {
    value: 8001,
    label: '8001 - Other',
  },
];

const Support = () => {
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [shouldReset, setShouldReset] = useState(false);
  const dispatch = useDispatch();

  const { loading: loadingCreate, success, error: errorCreate } = useSelector(
    selectCreatePublicAuditState,
    shallowEqual
  );

  useEffect(() => {
    if (success) {
      setShouldReset(true);
      setNotificationMessage(
        'Kard has received your request and will respond with an update in status within 48 hours!'
      );
      setNotificationType(ToastType.Success);
    } else if (errorCreate) {
      setNotificationMessage(errorCreate);
      setNotificationType(ToastType.Error);
    }
  }, [loadingCreate, success, errorCreate, setShouldReset]);

  const onSubmitHandler = useCallback(
    (data) => {
      dispatch(createPublicAudits(data));
    },
    [dispatch]
  );

  return (
    <>
      {!loadingCreate && notificationMessage && notificationType && (
        <Toast
          position="top-center"
          id="notification"
          text={notificationMessage}
          type={notificationType}
        />
      )}
      <FlexBox
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <img src={kardLogo} alt="Kard Financial Header Logo" />
        <Heading>Create Support Request</Heading>
        <Form
          validationSchema={auditFormSchema}
          validationMode={ValidationMode.OnChange}
          onSubmit={onSubmitHandler}
          className={classes.form}
          shouldReset={shouldReset}
        >
          <FormControl
            name="issuer"
            render={(props) => (
              <Input
                label="Issuer"
                type={InputType.Text}
                labelPosition={InputLabelPosition.Top}
                className={classes.width}
                {...props}
              />
            )}
          />
          <FormControl
            name="transactionId"
            render={(props) => (
              <Input
                label="Transaction ID"
                labelPosition={InputLabelPosition.Top}
                type={InputType.Text}
                className={classes.width}
                {...props}
              />
            )}
          />
          <FormControlSelect
            name="auditCode"
            label="Audit Code"
            labelPosition={SelectLabelPosition.Top}
            size={SelectLabelSize.S}
            options={auditCodes}
          />
          <FormControl
            name="merchantName"
            render={(props) => (
              <Input
                label="Merchant Name"
                labelPosition={InputLabelPosition.Top}
                type={InputType.Text}
                className={classes.width}
                {...props}
              />
            )}
          />
          <FormControl
            name="preferredContactEmail"
            render={(props) => (
              <Input
                label="Preferred Contact Email"
                labelPosition={InputLabelPosition.Top}
                type={InputType.Text}
                className={classes.width}
                {...props}
              />
            )}
          />
          <FormControl
            name="auditDescription"
            render={(props) => (
              <Input
                label="(Optional) Description"
                labelPosition={InputLabelPosition.Top}
                type={InputType.Text}
                className={classes.width}
                placeholder={msg.DescriptionSupportMessage}
                {...props}
              />
            )}
          />
          <FormControl
            name="referringPartnerUserId"
            render={(props) => (
              <Input
                label="(Optional) Referring Partner User ID"
                labelPosition={InputLabelPosition.Top}
                type={InputType.Text}
                className={classes.width}
                {...props}
              />
            )}
          />
          <FormControl
            name="transactionAmountInCents"
            render={(props) => (
              <Input
                label="(Optional) Transaction Amount in Cents"
                labelPosition={InputLabelPosition.Top}
                type={InputType.Number}
                className={classes.width}
                {...props}
              />
            )}
          />
          <Button
            type={ButtonType.Submit}
            size={ButtonSize.L}
            loading={loadingCreate}
          >
            Submit
          </Button>
        </Form>
      </FlexBox>
    </>
  );
};

export default Support;
