import dateParse from 'utils/common/dateParse';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Zip',
        accessor: 'zip',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'US State',
        accessor: 'usState',
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
        Cell: ({ value }) => (value ? dateParse(value) : '-'),
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
    ],
  },
];

export default columns;
