/* eslint-disable import/prefer-default-export */
import {
  fetchAuditsInit,
  fetchAuditsSuccess,
  fetchAuditsFail,
  fetchAuditByIdInit,
  fetchAuditByIdSuccess,
  fetchAuditByIdFail,
  editAuditFail,
  editAuditInit,
  editAuditSuccess,
  clearAuditsErrorsProps,
  createPublicAuditsInit,
  createPublicAuditsSuccess,
  createPublicAuditsFail,
  createAuditFail,
  createAuditInit,
  createAuditSuccess,
} from 'state/actionCreators/audits';

import fetchAuditsService from 'services/audits/fetchAudits';
import fetchAuditByIdService from 'services/audits/fetchAuditById';
import editAuditService from 'services/audits/editAudit';
import createAuditService from 'services/audits/createAudit';
import createPublicAuditServices from 'services/publicAPI/createPublicAudit';

export const fetchAudits = (filters = '') => async (dispatch) => {
  dispatch(fetchAuditsInit());

  try {
    const audits = await fetchAuditsService(filters);
    return dispatch(fetchAuditsSuccess({ audits }));
  } catch (error) {
    return dispatch(fetchAuditsFail({ error: error.message }));
  }
};

export const fetchAuditById = (id) => async (dispatch) => {
  dispatch(fetchAuditByIdInit());

  try {
    const audit = await fetchAuditByIdService(id);
    return dispatch(fetchAuditByIdSuccess({ audit }));
  } catch (error) {
    return dispatch(fetchAuditByIdFail({ error: error.message }));
  }
};

export const editAudit = (id, body) => async (dispatch) => {
  dispatch(editAuditInit());

  try {
    await editAuditService(id, body);
    return dispatch(editAuditSuccess());
  } catch (error) {
    return dispatch(editAuditFail({ error: error.message }));
  }
};

export const createPublicAudits = (data) => async (dispatch) => {
  dispatch(createPublicAuditsInit());

  try {
    const resp = await createPublicAuditServices(data);
    return dispatch(createPublicAuditsSuccess(resp));
  } catch (error) {
    return dispatch(createPublicAuditsFail({ error: error.data }));
  }
};

export const clearAuditsErrors = () => async (dispatch) => {
  dispatch(clearAuditsErrorsProps());
};

export const createAudit = (body) => async (dispatch) => {
  dispatch(createAuditInit());

  try {
    const resp = await createAuditService(body);
    return dispatch(createAuditSuccess(resp));
  } catch (error) {
    return dispatch(createAuditFail({ error: error.message }));
  }
};
