import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import getNameInitials from 'utils/getNameInitials';

import classes from './Avatar.module.scss';

export const Size = Object.freeze({
  XL: 'xl',
  L: 'l',
  M: 'm',
  S: 's',
  XS: 'xs',
});

const Avatar = ({ name, image, imageAlt, size, className }) => {
  const nameInitials = useMemo(() => getNameInitials(name), [name]);

  return (
    <div
      className={classNames(
        classes.avatar,
        classes[size],
        {
          [classes.hasImage]: !!image,
        },
        className
      )}
    >
      {image && <img className={classes.image} src={image} alt={imageAlt} />}
      {!image && nameInitials}
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
};

Avatar.defaultProps = {
  name: null,
  image: null,
  imageAlt: null,
  size: Size.M,
  className: '',
};

export default Avatar;
