import client from 'services/kardAPI';

const fetchOffersByMerchantId = async (id) => {
  try {
    const offers = await client.get(
      `/rewards/portal/merchant/offers?merchantId=${id}`
    );
    return offers.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchOffersByMerchantId;
