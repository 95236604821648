export const auditCodes = [
  {
    label: '3005 - Incorrect Cashback Amount',
    value: 3005,
  },
  {
    label: '3006 - Missing Cashback Award',
    value: 3006,
  },
  {
    label: '8001 - Other',
    value: 8001,
  },
];

export const statuses = [
  {
    label: 'New',
    value: 'NEW',
  },
  {
    label: 'In Progress',
    value: 'IN_PROGRESS',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
  },
];

export const resolutionCodes = [
  {
    label: '5001 - Transaction will be deleted',
    value: 5001,
  },
  {
    label: '5002 - Settlement amount will be adjusted',
    value: 5002,
  },
  {
    label: '5003 - Return amount will be adjusted',
    value: 5003,
  },
  {
    label: '5004 - Reward amount will be adjusted',
    value: 5004,
  },
  {
    label: '5005 - Transaction will be marked for writeoff',
    value: 5005,
  },
  {
    label: '5006 - Transaction will be marked as rejected',
    value: 5006,
  },
  {
    label: '5007 - Transaction will be resent through webhook',
    value: 5007,
  },
  {
    label: '5008 - Transaction will be resent through daily file',
    value: 5008,
  },
  {
    label: '5009 - No changes needed',
    value: 5009,
  },
  {
    label: '9001 - Ineligible item in purchase',
    value: 9001,
  },
  {
    label: '9002 - Return was made',
    value: 9002,
  },
  {
    label:
      '9003 - User ineligible for offer (usually because of participation through another program)',
    value: 9003,
  },
  {
    label:
      "9004 - Redemption limit hit (if offer has a set number of redemptions and it isn't handled programmatically)",
    value: 9004,
  },
  {
    label: '9005 - Transaction not captured',
    value: 9005,
  },
];

export const sources = [
  {
    label: 'Operations',
    value: 'OPERATIONS',
  },
  {
    label: 'Issuer',
    value: 'ISSUER',
  },
  {
    label: 'API',
    value: 'API',
  },
];

export const merchantSources = [
  {
    label: 'Local',
    value: 'LOCAL',
  },
  {
    label: 'National',
    value: 'NATIONAL',
  },
];
