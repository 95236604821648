import { createAction } from '@reduxjs/toolkit';

export const fetchPortalUsersInit = createAction(
  '[Users] Fetch Portal Users Init'
);
export const fetchPortalUsersSuccess = createAction(
  '[Users] Fetch Portal Users Success'
);
export const fetchPortalUsersFail = createAction(
  '[Users] Fetch Portal Users Fail'
);

export const editPortalUserInit = createAction('[Users] Edit Portal User Init');
export const editPortalUserSuccess = createAction(
  '[Users] Edit Portal User Success'
);
export const editPortalUserFail = createAction('[Users] Edit Portal User Fail');
export const clearEditPortalUserData = createAction(
  '[Users] Clear Edit Portal User Data'
);

export const fetchUserProfileInit = createAction(
  '[Users] Fetch User Profile Init'
);
export const fetchUserProfileSuccess = createAction(
  '[Users] Fetch User Profile Success'
);
export const fetchUserProfileFail = createAction(
  '[Users] Fetch User Profile Fail'
);

export const createPortalUserInit = createAction(
  '[Users] Create Portal User Init'
);
export const createPortalUserSuccess = createAction(
  '[Users] Create Portal User Success'
);
export const createPortalUserFail = createAction(
  '[Users] Create Portal User Fail'
);

export const clearPortalUserErrorsData = createAction(
  '[Users] Clear Portal User Errors Data'
);
