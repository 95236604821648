export const merchantSources = [
  {
    label: 'Local',
    value: 'LOCAL',
  },
  {
    label: 'National',
    value: 'NATIONAL',
  },
];

export const merchantTypes = [
  {
    label: 'Card Linked',
    value: 'CARDLINKED',
  },
  {
    label: 'Affiliate',
    value: 'AFFILIATE',
  },
];

export const merchantAcceptedCards = [
  {
    label: 'Visa',
    value: 'VISA',
  },
  {
    label: 'Master Card',
    value: 'MASTERCARD',
  },
  {
    label: 'American Express',
    value: 'AMERICAN EXPRESS',
  },
];

export const merchantQualifiedIssuers = [
  {
    label: 'Varo',
    value: 'VARO',
  },
  {
    label: 'Current',
    value: 'CURRENT',
  },
];

export const merchantCategories = [
  {
    label: 'Food & Beverage',
    value: 'Food & Beverage',
  },
  {
    label: 'Category 2',
    value: 'category2',
  },
];
