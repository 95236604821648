import {
  fetchPortalUsersInit,
  fetchPortalUsersSuccess,
  fetchPortalUsersFail,
  editPortalUserInit,
  editPortalUserSuccess,
  editPortalUserFail,
  clearEditPortalUserData,
  createPortalUserInit,
  createPortalUserSuccess,
  createPortalUserFail,
  clearPortalUserErrorsData,
  fetchUserProfileInit,
  fetchUserProfileSuccess,
  fetchUserProfileFail,
} from 'state/actionCreators/users';

import fetchPortalUsersService from 'services/users/fetchPortalUsers';
import editPortalUserService from 'services/users/editPortalUser';
import fetchUserProfileService from 'services/users/fetchUserProfile';
import createPortalUserService from 'services/users/createPortalUser';

export const fetchPortalUsers = (filters) => async (dispatch) => {
  dispatch(fetchPortalUsersInit());

  try {
    const users = await fetchPortalUsersService(filters);
    return dispatch(fetchPortalUsersSuccess({ users }));
  } catch (error) {
    return dispatch(fetchPortalUsersFail({ error: error.message }));
  }
};

export const editPortalUser = ({ username, ...rest }) => async (dispatch) => {
  dispatch(editPortalUserInit());
  try {
    await editPortalUserService(username, rest);
    return dispatch(editPortalUserSuccess());
  } catch (error) {
    return dispatch(editPortalUserFail({ error: error.message }));
  }
};

export const clearEditPortalUser = () => clearEditPortalUserData();

export const fetchUserProfile = (id) => async (dispatch) => {
  dispatch(fetchUserProfileInit());

  try {
    const user = await fetchUserProfileService(id);
    return dispatch(fetchUserProfileSuccess({ user }));
  } catch (error) {
    return dispatch(fetchUserProfileFail({ error: error.message }));
  }
};

export const createPortalUser = (body) => async (dispatch) => {
  dispatch(createPortalUserInit());
  try {
    await createPortalUserService(body);
    return dispatch(createPortalUserSuccess());
  } catch (error) {
    return dispatch(createPortalUserFail({ error: error.message }));
  }
};

export const clearPortalUserErrors = () => clearPortalUserErrorsData();
