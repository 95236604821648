/* eslint-disable react/prop-types */
import React from 'react';

import informationIcon from 'assets/icons/color/information.svg';
import FilterTypes from 'enums/reports/filterTypes.enum';
import Groups from 'enums/users/groups.enum';
import dateParse from 'utils/common/dateParse';
import { ZIP_CODE_LENGTH } from 'constants/zipCode.constant';
import US_STATES from 'constants/states.constant';
import Status from 'components/Common/Status';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Location ID',
        accessor: 'locationId',
        columnName: 'locationId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Location Name',
        accessor: 'locationName',
        columnName: 'locationName',
        sortable: true,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      // TODO: Hide until we can fix backend to always return the merchant name
      // {
      //   Header: 'Merchant Name',
      //   accessor: 'merchantName',
      //   columnName: 'merchantName',
      //   sortable: false,
      //   filterable: false,
      //   allowedRoles: Groups.Everyone,
      // },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: true,
        filterable: true,
        multiValue: true,
        showSwitchToggle: false,
        Cell: ({ value }) => (value ? <Status kind={value} /> : ''),
        filter: {
          type: FilterTypes.String,
          options: ['ACTIVE', 'INACTIVE', 'CLOSED'],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Google ID',
        accessor: 'googleId',
        columnName: 'googleId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Location Type',
        accessor: 'locationType',
        columnName: 'locationType',
        sortable: false,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.String,
          options: ['INSTORE', 'ONLINE'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Street',
        accessor: 'street',
        columnName: 'street',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'City',
        accessor: 'city',
        columnName: 'city',
        sortable: true,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'State',
        accessor: 'state',
        columnName: 'state',
        sortable: true,
        filterable: true,
        multiValue: true,
        searchable: true,
        filter: {
          type: FilterTypes.String,
          options: US_STATES,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Zip Code',
        accessor: 'zipCode',
        columnName: 'zipCode',
        sortable: true,
        filterable: true,
        multiValue: true,
        validateSearch: (value) => {
          if (value && ![0, ZIP_CODE_LENGTH].includes(value.length)) {
            throw new Error('Zip codes need to be 5 digits');
          }
        },
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
        columnName: 'createdDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Internal,
      },
      {
        Header: 'More info',
        accessor: 'moreInfo',
        columnName: 'moreInfo',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={informationIcon} alt="More information" />,
        allowedRoles: Groups.Administrators,
      },
    ],
  },
];

export default columns;
