import classNames from 'classnames';

import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Checkbox, {
  Kind as CheckboxKind,
  Size as CheckboxSize,
} from 'components/Common/Checkbox';
import {
  behaviorOptions,
  behaviorRangeOptions,
  periodOptions,
  timescaleOptions,
} from 'utils/audiences/values';

import classes from './RuleBuilder.module.scss';

const getInputFields = ({
  selectedRule,
  enrollNewUser,
  refreshAudience,
  selectedMerchant,
  audienceIsEditable,
  onChangeSelectedMerchantHandler,
  onChangePeriodValueHandler,
  onSelectEnrollNewUserCheckBoxHandler,
  onSelectRefreshAudienceCheckBoxHandler,
}) => {
  const merchantOptions = [
    ...(selectedMerchant
      ? [
          {
            label: 'Selected merchant',
            options: [
              {
                label: selectedMerchant.name,
                value: selectedMerchant._id,
              },
            ],
          },
        ]
      : []),
    {
      label: 'Choose merchant',
      options: [{ label: 'All Merchants..', value: 'allMerchants' }],
    },
  ];

  return [
    {
      id: '1',
      fields: [
        {
          title: 'What Behavior do you want to target?',
          component: FormControlSelect,
          className: classes.formControlSelect,
          name: 'behavior',
          defaultValue: selectedRule.behavior,
          options: behaviorOptions,
          color: SelectColor.Gray,
          placeholder: 'Select Behavior',
          noErrorMessage: true,
          disabled: !audienceIsEditable,
        },
        {
          title: 'What Merchant is this for?',
          component: FormControlSelect,
          className: classes.formControlSelect,
          name: 'merchant',
          defaultValue: selectedMerchant?._id,
          value: selectedMerchant?._id,
          onChangeManual: onChangeSelectedMerchantHandler,
          options: merchantOptions,
          color: SelectColor.Gray,
          placeholder: 'Select Merchant',
          noErrorMessage: true,
          disabled: !audienceIsEditable,
        },
      ],
    },
    {
      id: '2',
      label: 'Purchase count',
      fields: [
        {
          component: FormControlSelect,
          name: 'behaviorRange',
          className: classes.behaviorSelect,
          defaultValue: selectedRule.behaviorRange,
          options: behaviorRangeOptions,
          color: SelectColor.Gray,
          placeholder: 'Select range',
          noErrorMessage: true,
          disabled: !audienceIsEditable,
        },
        {
          name: 'behaviorValue',
          noErrorMessage: true,
          component: Input,
          renderProps: {
            className: classNames(classes.behaviorValue, {
              [classes.disabledInput]: !audienceIsEditable,
            }),
            value: selectedRule.behaviorValue,
            type: InputType.Number,
            size: InputSize.S,
            color: InputColor.Gray,
            minNumber: 0,
            readOnly: !audienceIsEditable,
          },
        },
      ],
    },
    {
      id: '3',
      label: 'Select timescale, between 7 days and 2 years',
      fields: [
        {
          component: FormControlSelect,
          name: 'timescale',
          className: classes.timescaleSelect,
          defaultValue: selectedRule.timescale,
          options: timescaleOptions,
          color: SelectColor.Gray,
          placeholder: 'Select timescale',
          noErrorMessage: true,
          disabled: !audienceIsEditable,
        },
        {
          name: 'timescaleValue',
          noErrorMessage: true,
          component: Input,
          renderProps: {
            className: classNames(classes.timescaleValue, {
              [classes.disabledInput]: !audienceIsEditable,
            }),
            value: selectedRule.timescaleValue,
            type: InputType.Number,
            size: InputSize.S,
            color: InputColor.Gray,
            minNumber: 0,
            readOnly: !audienceIsEditable,
          },
        },
        {
          component: FormControlSelect,
          name: 'period',
          className: classes.daySelect,
          defaultValue: selectedRule.period,
          options: periodOptions,
          color: SelectColor.Gray,
          noErrorMessage: true,
          disabled: !audienceIsEditable,
          onChangeManual: onChangePeriodValueHandler,
        },
      ],
    },
    {
      id: '4',
      fields: [
        {
          component: Checkbox,
          name: 'enrollNewUser',
          label: 'Allow new cardholders to be added automatically',
          setSelected: onSelectEnrollNewUserCheckBoxHandler,
          selected: enrollNewUser,
          className: classes.checkbox,
          kind: CheckboxKind.Secondary,
          size: CheckboxSize.S,
          disabled: !audienceIsEditable,
        },
      ],
    },
    {
      id: '5',
      label: 'Will this audience refresh',
      fields: [
        {
          component: Checkbox,
          name: 'willRefreshYes',
          label: 'Yes',
          setSelected: onSelectRefreshAudienceCheckBoxHandler,
          selected: refreshAudience,
          className: classes.checkbox,
          kind: CheckboxKind.Secondary,
          size: CheckboxSize.S,
          disabled: !audienceIsEditable,
        },
        {
          component: Checkbox,
          name: 'willRefreshNo',
          label: 'No',
          setSelected: onSelectRefreshAudienceCheckBoxHandler,
          selected: !refreshAudience,
          className: classes.checkbox,
          kind: CheckboxKind.Secondary,
          size: CheckboxSize.S,
          disabled: !audienceIsEditable,
        },
      ],
    },
  ];
};

export default getInputFields;
