import client from 'services/kardAPI';

const fetchLocationsByMerchantId = async (id) => {
  try {
    const locations = await client.get(
      `/rewards/portal/merchant/locations/${id}`
    );
    return locations.data.map((location) => ({
      ...location,
      street: location.address?.street,
      city: location.address?.city,
      zipCode: location.address?.zipCode,
      state: location.address?.state,
    }));
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchLocationsByMerchantId;
