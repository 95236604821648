export const offerTypes = [
  {
    label: 'Instore',
    value: 'INSTORE',
  },
  {
    label: 'Online',
    value: 'ONLINE',
  },
];

export const commissionTypes = [
  {
    label: 'Flat',
    value: 'FLAT',
  },
  {
    label: 'Percent',
    value: 'PERCENT',
  },
];

export const sources = [
  {
    label: 'National',
    value: 'NATIONAL',
  },
  {
    label: 'Local',
    value: 'LOCAL',
  },
];
