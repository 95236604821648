import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Modal.module.scss';

const Modal = ({ isOpen, onClose, children, className }) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className={classes.parentDialog} onClose={onClose}>
      <>
        <Transition.Child
          as={Fragment}
          enter={classes.enterTransition}
          enterFrom={classes.noOpacity}
          enterTo={classes.fullOpacity}
          leave={classes.leaveTransition}
          leaveFrom={classes.fullOpacity}
          leaveTo={classes.noOpacity}
        >
          <div className={classNames(classes.childDialog, className)}>
            {children}
          </div>
        </Transition.Child>
        <Dialog.Overlay className={classes.overlay} />
      </>
    </Dialog>
  </Transition>
);

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
