import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Toast, { Type as ToastType } from 'components/Common/Toast';

import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Table from 'components/Common/Table';
import Modal from 'components/Common/Modal';
import useModal from 'hooks/useModal';
import allAudiencesColumns from 'utils/audiences/columns';
import getAudiencesFilters from 'utils/filters/audiencesFilters';
import addIcon from 'assets/icons/close.svg';
import Columns from 'enums/table/columns.enum';
import TableConfig from 'enums/table/table.config';
import ModalType from 'enums/modal/modalType';
import Status from 'enums/status/status.enum';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import { selectUserAttributesState } from 'state/selectors/auth';
import {
  selectCreateAudienceState,
  selectEditAudienceState,
  selectFetchAudienceByIdState,
  selectFetchAudiencesState,
  selectFetchAudienceSummaryDataPoints,
} from 'state/selectors/audiences';
import {
  clearAudienceData,
  clearAudiencesErrors,
  clearSelectedAudienceRule,
  fetchAudienceById,
  fetchAudiences,
  fetchAudiencesSummaryDataPoints,
} from 'state/actions/audiences';
import CreateAudienceForm from 'components/Pages/CreateAudienceForm';
import { DEFAULT_SORT_BY } from 'utils/audiences/values';
import Summary from './Summary';

import classes from './Audience.module.scss';

const FILTER_BY_CREATED_DATE_DESC = `?createdDateSort=-1&page=${TableConfig.PAGE}&limit=${TableConfig.LIMIT}`;

const Audience = () => {
  const dispatch = useDispatch();

  const {
    audiencesData,
    loading: loadingFetchAudiences,
    error: errorFetchAudiences,
  } = useSelector(selectFetchAudiencesState, shallowEqual);

  const {
    audience: audienceById,
    loading: loadingFetchAudienceById,
    error: errorFetchAudienceById,
  } = useSelector(selectFetchAudienceByIdState, shallowEqual);

  const { success: successCreateAudience } = useSelector(
    selectCreateAudienceState,
    shallowEqual
  );

  const { success: successUpdateAudience } = useSelector(
    selectEditAudienceState,
    shallowEqual
  );

  const { roles: userRoles, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual
  );

  const {
    audienceDataSummaryPoint,
    loading: fetchDataPointsLoading,
  } = useSelector(selectFetchAudienceSummaryDataPoints, shallowEqual);

  const columns = useMemo(
    () => [
      {
        Header: 'header',
        columns: getAllowedColumns(allAudiencesColumns[0].columns, userRoles),
      },
    ],
    [userRoles]
  );

  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 100,
  });

  const [generalCount, setGeneralCount] = useState();

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const { countTotal, results: audiences } = audiencesData;

  useEffect(() => {
    dispatch(fetchAudiencesSummaryDataPoints());
  }, [dispatch]);

  useEffect(() => {
    if (successCreateAudience || successUpdateAudience) {
      setSelectedFilters({});
      onCloseModalHandler();
    }
    dispatch(fetchAudiences(FILTER_BY_CREATED_DATE_DESC));
  }, [
    dispatch,
    successCreateAudience,
    successUpdateAudience,
    onCloseModalHandler,
  ]);

  const onCloseAudienceForm = useCallback(() => {
    dispatch(clearAudienceData());
    dispatch(clearSelectedAudienceRule());
    onCloseModalHandler();
  }, [dispatch, onCloseModalHandler]);

  useEffect(() => {
    if (audiences.length > 0 && data.length === 0) {
      const transformedData = audiences.map((audience) => ({
        ...audience,
        audienceId: audience._id,
        status: Status[audience.status],
      }));

      setData(transformedData);

      setGeneralCount(
        Math.ceil(parseInt(countTotal, 10) / parseInt(pagination.limit, 10))
      );
    } else if (audiences.length === 0 && data.length > 0) {
      setData([]);
    }
  }, [audiences, data, countTotal, pagination]);

  useEffect(() => {
    if (audienceById) {
      const transformedData = [
        {
          ...audienceById,
          audienceId: audienceById._id,
          status: Status[audienceById.status],
        },
      ];

      setData(transformedData);
    }
  }, [audienceById]);

  useEffect(() => {
    if (errorFetchAudiences || errorFetchAudienceById) {
      dispatch(clearAudiencesErrors());
    }
  }, [dispatch, errorFetchAudiences, errorFetchAudienceById]);

  const onSubmitFilterHandler = useCallback(
    ({
      resetPagination = false,
      selected,
      id,
      columnName,
      dates,
      sortValue,
      pageIndex,
      pageSize,
    }) => {
      const actualID = id;
      const actualPage = pageIndex ?? pagination.page;

      const filters = getAudiencesFilters({
        resetPagination,
        pageIndex: actualPage,
        pageLimit: pageSize || pagination.limit,
        setPagination,
        id: actualID,
        selected,
        selectedFilters,
        setSelectedFilters,
        columnName,
        dates,
        sortValue,
        sortBy,
        setSortBy,
      });

      setData([]);

      const filterAudienceById = selected?.find(
        (filter) => filter.columnName === 'id' && filter.type === 'search'
      );

      if (filterAudienceById) {
        return dispatch(fetchAudienceById(filterAudienceById.text));
      }

      return dispatch(fetchAudiences(filters));
    },
    [selectedFilters, sortBy, pagination, dispatch]
  );

  const onClickCellButtonHandler = useCallback(
    (columnName, rowIndex) => {
      if (columnName === Columns.MoreInfo) {
        onOpenModalHandler(ModalType.AUDIENCE_INFO, {
          audience: data[rowIndex],
        });
      }
    },
    [onOpenModalHandler, data]
  );

  const onAddAudienceHandler = useCallback(() => {
    onOpenModalHandler(ModalType.ADD_AUDIENCE);
  }, [onOpenModalHandler]);

  const addAudience = modal.type === ModalType.ADD_AUDIENCE;
  const audienceInfo = modal.type === ModalType.AUDIENCE_INFO;

  return (
    <>
      {errorFetchAudiences && (
        <Toast
          id="fetch audiences"
          text="Error fetching the audiences"
          type={ToastType.Error}
        />
      )}
      {errorFetchAudienceById && (
        <Toast
          id="fetch audience by id"
          text="Error fetching the audience"
          type={ToastType.Error}
        />
      )}
      <Modal
        isOpen={addAudience || audienceInfo}
        onClose={onCloseAudienceForm}
        className={classes.ruleBuilderModalWidth}
      >
        <CreateAudienceForm
          isCreating={addAudience}
          onClose={onCloseAudienceForm}
          {...(audienceInfo && { position: 1 })}
          {...(audienceInfo && { audience: modal.audience })}
        />
      </Modal>
      <div className={classes.table}>
        {fetchDataPointsLoading ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Summary data={audienceDataSummaryPoint} />
        )}

        <div className={classes.tableHeading}>
          {isAdmin && (
            <div className={classes.actions}>
              <Button
                type={ButtonType.Submit}
                size={ButtonSize.S}
                className={classes.button}
                onClick={onAddAudienceHandler}
              >
                <div className={classes.newAudience}>
                  <img className={classes.icon} src={addIcon} alt="add" />
                  New Audience
                </div>
              </Button>
            </div>
          )}
        </div>
        {loadingFetchAudiences || loadingFetchAudienceById ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={columns}
            data={data}
            onClickCellButton={onClickCellButtonHandler}
            selectedFilters={selectedFilters}
            sortBy={sortBy}
            onSubmitFilter={onSubmitFilterHandler}
            itemsPerPage={pagination.limit}
            currentPage={pagination.page}
            manualPagination
            fetchData={onSubmitFilterHandler}
            bodyClassName={classes.tableBody}
            countTotal={generalCount}
            newPagination
          />
        )}
      </div>
    </>
  );
};

export default Audience;
