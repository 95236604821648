/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';

import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Table from 'components/Common/Table';
import ReportDateFilter from 'components/Pages/ReportDateFilter';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import GenerateReport from 'components/Pages/GenerateReport';
import { clearFetchReportsData, fetchReports } from 'state/actions/reports';
import { selectFetchReportsState } from 'state/selectors/reports';
import allReportsColumns from 'utils/reports/columns';
import fields from 'utils/reports/fields';
import getReportsFilters from 'utils/filters/reportsFilter';
import searchIcon from 'assets/icons/search.svg';
import DateUnits from 'enums/reports/dateUnits.enum';
import KardApiErrors from 'enums/kardApi/errors.enum';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import { selectUserAttributesState } from 'state/selectors/auth';
import iconAdv from 'assets/icons/info.png';
import classNames from 'classnames';
import classes from './Reports.module.scss';

const ReportsPage = () => {
  const dispatch = useDispatch();

  const { reportsData, loading, error } = useSelector(
    selectFetchReportsState,
    shallowEqual
  );

  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual
  );

  const columns = useMemo(
    () => [
      {
        Header: 'header',
        columns: getAllowedColumns(allReportsColumns[0].columns, userRoles),
      },
    ],
    [allReportsColumns, userRoles]
  );

  const [data, setData] = useState([]);
  const [tableColumns, setTableColumns] = useState(columns);
  const [changedColumnValues, setChangedColumnValues] = useState(false);
  const [displayDefaultValues, setDisplayDefaultValues] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [sortBy, setSortBy] = useState({
    ascending: false,
    descending: false,
    columnName: null,
  });
  const [dateFilter, setDateFilter] = useState({
    value: '1',
    unit: DateUnits.Days,
  });

  const [generalCount, setGeneralCount] = useState();

  const [transactionTimeStamp, setTransactionTimeStamp] = useState({
    startDate: dayjs(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)),
    endDate: dayjs(new Date()),
  });
  const [searchTransactionId, setSearchTransactionId] = useState('');

  const [nameValues, setNameValues] = useState({
    fetched: false,
    allIssuers: [],
    allMerchantNames: [],
  });

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 100,
  });

  useEffect(() => {
    const filters = getReportsFilters({
      pageIndex: pagination.page,
      pageLimit: pagination.limit,
      setPagination,
      transactionTimeStamp,
      setTransactionTimeStamp,
    });
    dispatch(fetchReports(filters));
  }, []);

  const { countTotal, reports } = reportsData;

  useEffect(() => {
    if (data.length > 0 && !nameValues.fetched) {
      const issuers = [];
      const merchantNames = [];
      const merchantAddrCountries = [];
      const merchantAddrStates = [];
      const merchantAddrCities = [];

      data.forEach((row) => {
        if (!issuers.includes(row.issuer)) {
          issuers.push(row.issuer);
        }
        if (!merchantNames.includes(row.merchantName)) {
          merchantNames.push(row.merchantName);
        }
        if (!merchantAddrCountries.includes(row.merchantAddrCountry)) {
          if (row.merchantAddrCountry) {
            merchantAddrCountries.push(row.merchantAddrCountry);
          }
        }
        if (!merchantAddrStates.includes(row.merchantAddrState)) {
          if (row.merchantAddrState) {
            merchantAddrStates.push(row.merchantAddrState);
          }
        }
        if (!merchantAddrCities.includes(row.merchantAddrCity)) {
          if (row.merchantAddrState) {
            merchantAddrCities.push(row.merchantAddrCity);
          }
        }
      });

      setNameValues({
        fetched: true,
        allIssuers: issuers,
        allMerchantNames: merchantNames,
        allMerchantAddrCountries: merchantAddrCountries,
        allMerchantAddrStates: merchantAddrStates,
        allMerchantAddrCities: merchantAddrCities,
      });
    }
  }, [data, nameValues]);

  useEffect(() => {
    if (nameValues.fetched && !changedColumnValues) {
      const newColumns = tableColumns[0]?.columns?.map((column) => {
        if (column.columnName === 'issuer') {
          return {
            ...column,
            filter: {
              ...column.filter,
              options: nameValues.allIssuers,
            },
          };
        }
        if (column.columnName === 'merchantName') {
          return {
            ...column,
            filter: {
              ...column.filter,
              options: nameValues.allMerchantNames,
            },
          };
        }
        if (column.columnName === 'merchantAddrCountry') {
          return {
            ...column,
            filter: {
              ...column.filter,
              options: nameValues.allMerchantAddrCountries,
            },
          };
        }
        if (column.columnName === 'merchantAddrState') {
          return {
            ...column,
            filter: {
              ...column.filter,
              options: nameValues.allMerchantAddrStates,
            },
          };
        }
        if (column.columnName === 'merchantAddrCity') {
          return {
            ...column,
            filter: {
              ...column.filter,
              options: nameValues.allMerchantAddrCities,
            },
          };
        }
        return column;
      });
      setTableColumns([{ Header: 'header', columns: newColumns }]);
      setChangedColumnValues(true);
    }
  }, [nameValues, tableColumns, changedColumnValues]);

  useEffect(() => {
    if (reports.length > 0 && data.length === 0) {
      const newData = reports.map((field) => {
        const transformedData = {
          ...field,
          ...field.matchedTransaction,
          transactionTimeStamp: field.transactionDate,
        };
        return transformedData;
      });
      setData(newData);
      setGeneralCount(
        Math.ceil(parseInt(countTotal, 10) / parseInt(pagination.limit, 10))
      );
    } else if (reports.length === 0 && data.length > 0) {
      setData([]);
    }
  }, [reports, data, countTotal, pagination]);

  const onGenerateReportErrorHandler = useCallback(() => {
    dispatch(clearFetchReportsData());
  }, [dispatch]);

  const onGenerateReportHandler = useCallback(
    (selectedFields) => {
      setSelectedFilters({});
      setChangedColumnValues(false);
      if (selectedFields.length > 0) {
        const allColumns = [...columns];
        const previousColumns = [...allColumns[0].columns];
        const filteredColumns = previousColumns.filter((column) =>
          selectedFields.includes(column.Header)
        );

        const newTableColumns = [];
        newTableColumns.push({
          Header: 'header',
          columns: filteredColumns,
        });

        setTableColumns(newTableColumns);
      } else {
        setTableColumns(columns);
      }
    },
    [transactionTimeStamp, dispatch]
  );

  const onSubmitFilterHandler = ({
    resetPagination = false,
    transactionId = null,
    selected,
    columnName,
    dates,
    range,
    sortValue,
    isDateFilter,
    pageIndex,
    pageSize,
  }) => {
    const actualPage =
      pageIndex || pageIndex === 0 ? pageIndex : pagination.page;
    const filters = getReportsFilters({
      resetPagination,
      pageIndex: actualPage,
      pageLimit: pageSize || pagination.limit,
      setPagination,
      transactionId,
      ...(transactionId ? {} : { transactionTimeStamp }),
      setTransactionTimeStamp,
      selected,
      selectedFilters,
      setSelectedFilters,
      columnName,
      dates,
      range,
      sortValue,
      sortBy,
      setSortBy,
      isDateFilter,
      setDateFilter,
    });
    setData([]);
    dispatch(fetchReports(filters));
  };

  const onChangeSearchTextHandler = (text) => {
    setSearchTransactionId(text);
    if (text === '') {
      onSubmitFilterHandler({
        pageIndex: pagination.page,
        pageLimit: pagination.limit,
        transactionTimeStamp,
      });
    }
  };

  const allowedReportFields = useMemo(
    () => getAllowedColumns(fields, userRoles),
    [fields, userRoles]
  );

  return (
    <>
      {error && (
        <Toast
          id="fetch reports error"
          text={KardApiErrors.FETCH_REPORTS}
          type={ToastType.Error}
        />
      )}
      <div className={classes.heading}>
        <div className={classes.generateReport}>
          <GenerateReport
            onSubmit={onGenerateReportHandler}
            fields={allowedReportFields}
            onError={onGenerateReportErrorHandler}
            error={error}
            setDisplayDefaultValues={setDisplayDefaultValues}
          />
        </div>
        <div className={classes.reportDateFilter}>
          <img
            src={iconAdv}
            alt="Filtering by Transaction Date"
            data-tip="Filtering by Transaction Date"
            data-for="reportDateFilter"
            className={classNames(classes.img)}
          />
          <ReportDateFilter
            value={dateFilter.value}
            unit={dateFilter.unit}
            setDateFilter={setDateFilter}
            onSubmit={onSubmitFilterHandler}
          />
        </div>
      </div>
      <div className={classes.table}>
        <div className={classes.tableHeading}>
          <div className={classes.searchContainer}>
            <Input
              name="search"
              onChange={(event) =>
                onChangeSearchTextHandler(event.target.value)
              }
              placeholder="Search by transaction ID"
              type={InputType.Text}
              size={InputSize.S}
              className={classes.search}
              onKeyPress={() =>
                onSubmitFilterHandler({
                  resetPagination: true,
                  transactionId: searchTransactionId,
                })
              }
            />
            <button
              type="button"
              className={classes.searchIcon}
              onClick={() =>
                onSubmitFilterHandler({
                  resetPagination: true,
                  transactionId: searchTransactionId,
                })
              }
            >
              <img src={searchIcon} alt="search" />
            </button>
          </div>
        </div>
        {loading || !displayDefaultValues ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={tableColumns}
            data={data}
            selectedFilters={selectedFilters}
            sortBy={sortBy}
            onSubmitFilter={onSubmitFilterHandler}
            itemsPerPage={pagination.limit}
            currentPage={pagination.page}
            manualPagination
            fetchData={onSubmitFilterHandler}
            bodyClassName={classes.tableBody}
            countTotal={generalCount}
          />
        )}
      </div>
      <ReactTooltip id="reportDateFilter" />
    </>
  );
};

export default ReportsPage;
