import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  selectForgotPasswordConfirmationState,
  selectForgotPasswordState,
} from 'state/selectors/auth';
import ForgotPasswordForm from 'components/Forms/ForgotPassword';
import Path from 'enums/path.enum';
import ForgotPasswordConfirmation from 'components/Forms/ForgotPasswordConfirmation';
import { clearForgotPassword } from 'state/actions/auth';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(clearForgotPassword()), [dispatch]);

  const { success: successChangingPassword } = useSelector(
    selectForgotPasswordConfirmationState,
    shallowEqual
  );

  const { success: successValidatingEmail } = useSelector(
    selectForgotPasswordState,
    shallowEqual
  );

  return (
    <>
      {!successValidatingEmail && <ForgotPasswordForm />}
      {successValidatingEmail && <ForgotPasswordConfirmation />}
      {successChangingPassword && <Redirect to={Path.Login} />}
    </>
  );
};

export default ForgotPassword;
