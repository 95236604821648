/* eslint-disable no-underscore-dangle */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import ReactTooltip from 'react-tooltip';

import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Table from 'components/Common/Table';
import Modal from 'components/Common/Modal';
import ConfirmationMessage from 'components/Common/ConfirmationMessage';
import MerchantForm from 'components/Pages/MerchantForm';
import MerchantOffersAndLocations from 'components/Pages/MerchantOffersAndLocations';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import {
  selectCreateMerchantState,
  selectEditMerchantState,
  selectFetchMerchantsState,
} from 'state/selectors/merchants';
import {
  clearCreateMerchant,
  clearMerchantsErrors,
  editMerchant,
  fetchMerchants,
} from 'state/actions/merchants';
import useModal from 'hooks/useModal';
import allMerchantsColumns from 'utils/merchants/columns';
import getMerchantFilters from 'utils/filters/merchantsFilters';
import addIcon from 'assets/icons/close.svg';
import searchIcon from 'assets/icons/search.svg';
import exportIcon from 'assets/icons/export.png';
import Columns from 'enums/table/columns.enum';
import TableConfig from 'enums/table/table.config';
import ModalType from 'enums/modal/modalType';
import Status from 'enums/status/status.enum';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import StatusValue from 'enums/status/statusValue.enum';
import { selectUserAttributesState } from 'state/selectors/auth';
import MerchantInfo from 'components/Forms/MerchantInfo';
import getIdToken from 'utils/getIdToken';
import { exportData } from 'state/actions/exportData';
import getParsedFilters from 'utils/getParsedFilters';
import selectExportDataState from 'state/selectors/exportData';
import isAnyStatusActive from 'utils/isAnyStatusActive';

import classes from './Merchants.module.scss';

const filtersMerchantGeneral = `?sortBy=createdDate&sortDirection=-1&page=${TableConfig.PAGE}&limit=${TableConfig.LIMIT}`;

const Merchants = () => {
  const dispatch = useDispatch();

  const { merchantsData, loading: loadingFetchMerchants } = useSelector(
    selectFetchMerchantsState,
    shallowEqual
  );

  const { success: successCreate, error: errorCreate } = useSelector(
    selectCreateMerchantState,
    shallowEqual
  );

  const {
    success: successEdit,
    loading: loadingEdit,
    error: errorEdit,
  } = useSelector(selectEditMerchantState, shallowEqual);

  const { roles: userRoles, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual
  );

  const { loading: exportInProgress } = useSelector(
    selectExportDataState,
    shallowEqual
  );

  const columns = useMemo(
    () => [
      {
        Header: 'header',
        columns: getAllowedColumns(allMerchantsColumns[0].columns, userRoles),
      },
    ],
    [userRoles]
  );

  const [showOffersAndLocations, setShowOffersAndLocations] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFiltersGeneral, setSelectedFiltersGeneral] = useState(
    filtersMerchantGeneral
  );
  const [sortBy, setSortBy] = useState({
    ascending: false,
    descending: false,
    columnName: null,
  });

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 100,
  });

  const [generalCount, setGeneralCount] = useState();

  const [isStatusActive, setIsStatusActive] = useState(true);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const { countTotal, merchants } = merchantsData;

  useEffect(() => {
    dispatch(fetchMerchants(filtersMerchantGeneral));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (merchants.length > 0 && data.length === 0) {
      const transformedData = merchants.map((merchant) => ({
        ...merchant,
        merchantId: merchant._id,
        status: Status[merchant.status],
      }));
      setData(transformedData);
      setGeneralCount(
        Math.ceil(parseInt(countTotal, 10) / parseInt(pagination.limit, 10))
      );
    } else if (merchants.length === 0 && data.length > 0) {
      setData([]);
    }
  }, [merchants, data, countTotal, pagination]);

  useEffect(() => {
    if (successCreate && !showOffersAndLocations) {
      setShowOffersAndLocations(true);
      dispatch(clearMerchantsErrors());
    }
  }, [successCreate, showOffersAndLocations, dispatch]);

  useEffect(() => {
    if (successEdit || successCreate) {
      dispatch(
        fetchMerchants(
          `${
            typeof selectedFiltersGeneral === 'string'
              ? selectedFiltersGeneral
              : ''
          }`
        )
      );
      if (successEdit) {
        onCloseModalHandler();
      }
    }
  }, [
    successEdit,
    successCreate,
    onCloseModalHandler,
    dispatch,
    selectedFiltersGeneral,
  ]);

  useEffect(() => {
    if (errorCreate || errorEdit) {
      dispatch(clearMerchantsErrors());
      if (modal.type === ModalType.MERCHANT_STATUS) {
        onCloseModalHandler();
      }
    }
  }, [errorCreate, errorEdit, modal, onCloseModalHandler, dispatch]);

  useEffect(() => {
    const anyStatusActive = isAnyStatusActive(selectedFiltersGeneral);

    setIsStatusActive(anyStatusActive);
  }, [selectedFiltersGeneral]);

  const onCloseCreateMerchantModalHandler = useCallback(() => {
    onCloseModalHandler();
    dispatch(clearCreateMerchant());
    setShowOffersAndLocations(false);
  }, [dispatch, onCloseModalHandler]);

  const onSubmitFilterHandler = useCallback(
    ({
      resetPagination = false,
      selected,
      id,
      columnName,
      dates,
      sortValue,
      pageIndex,
      pageSize,
    }) => {
      const actualID = id === '' ? id : searchText;
      const actualPage =
        pageIndex || pageIndex === 0 ? pageIndex : pagination.page;
      const filters = getMerchantFilters({
        resetPagination,
        pageIndex: actualPage,
        pageLimit: pageSize || pagination.limit,
        setPagination,
        id: actualID,
        selected,
        selectedFilters,
        setSelectedFilters,
        columnName,
        dates,
        sortValue,
        sortBy,
        setSortBy,
      });
      setData([]);
      setSelectedFiltersGeneral(filters);
      dispatch(fetchMerchants(filters));
    },
    [selectedFilters, sortBy, pagination, searchText, dispatch]
  );

  const onClickCellButtonHandler = useCallback(
    (columnName, rowIndex) => {
      if (columnName === Columns.MoreInfo) {
        onOpenModalHandler(ModalType.MERCHANT_INFO, {
          merchant: data[rowIndex],
        });
      }
    },
    [onOpenModalHandler, data]
  );

  const onAddMerchantHandler = useCallback(() => {
    onOpenModalHandler(ModalType.ADD_MERCHANT);
  }, [onOpenModalHandler]);

  const onChangeSwitchHandler = useCallback(
    (columnName, rowIndex, status) => {
      if (columnName === Columns.Status) {
        const { merchantId, ...merchant } = data[rowIndex];
        onOpenModalHandler(ModalType.MERCHANT_STATUS, {
          merchant,
          status,
          merchantId,
        });
      }
    },
    [onOpenModalHandler, data]
  );

  const onChangeMerchantStatusHandler = useCallback(() => {
    const { merchant, status, merchantId } = modal;
    const newStatus =
      status === Status.ACTIVE ? StatusValue.Inactive : StatusValue.Active;

    const body = {
      ...merchant,
      status: newStatus,
      offers: merchant.offers.map(({ _id }) => _id),
    };

    delete body.__v;
    delete body._id;
    delete body.createdDate;
    delete body.lastModified;

    dispatch(editMerchant(merchantId, body));
  }, [modal, dispatch]);

  const onChangeSearchTextHandler = useCallback(
    (text) => {
      setSearchText(text);
      if (text === '') {
        onSubmitFilterHandler({ id: text });
      }
    },
    [onSubmitFilterHandler]
  );

  const onExportDataHandler = async () => {
    const idToken = await getIdToken();
    let filters = getParsedFilters(selectedFiltersGeneral);

    if (searchText !== '') {
      filters = {
        ...filters,
        _id: searchText,
      };
    }

    const exportInfo = {
      idToken,
      filters,
      model: 'Merchant',
    };

    dispatch(exportData(exportInfo));
  };

  const noActiveMerchantById =
    searchText && data.length > 0 && data[0].status !== Status.ACTIVE;
  const noDataToShow = data.length === 0;

  const getExportMerchantsTooltip = () => {
    if (loadingFetchMerchants) {
      return '';
    }

    if (exportInProgress) {
      return 'There is another export in progress. Try again after!';
    }

    if (!isStatusActive) {
      return 'There are no active merchants to export';
    }

    if (noActiveMerchantById) {
      return 'Cannot export inactive/closed merchants';
    }

    if (noDataToShow) {
      return 'There is no data to export';
    }

    return '';
  };

  const disableExportButton =
    !isStatusActive ||
    loadingFetchMerchants ||
    exportInProgress ||
    noDataToShow ||
    noActiveMerchantById;

  return (
    <>
      {errorEdit && (
        <Toast
          id="edit merchant"
          text="Error editing the merchant"
          type={ToastType.Error}
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.MERCHANT_STATUS}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <ConfirmationMessage
          message="Are you sure you want to update the status of this merchant?"
          onAccept={onChangeMerchantStatusHandler}
          onCancel={onCloseModalHandler}
          loading={loadingEdit}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.MERCHANT_INFO}
        onClose={onCloseModalHandler}
        className={classes.modalInfo}
      >
        <MerchantInfo
          onCancel={onCloseModalHandler}
          merchant={modal.merchant}
          offers={modal.merchant?.offers.filter(
            (offer) => offer.status !== Status.closed
          )}
          closedOffers={modal.merchant?.offers.filter(
            (offer) => offer.status === Status.closed
          )}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ADD_MERCHANT}
        onClose={onCloseCreateMerchantModalHandler}
        className={classes.modalWidth}
      >
        {showOffersAndLocations ? (
          <MerchantOffersAndLocations
            onCancel={onCloseCreateMerchantModalHandler}
          />
        ) : (
          <MerchantForm
            title="Add New Merchant"
            isCreating
            onCancel={onCloseCreateMerchantModalHandler}
          />
        )}
      </Modal>
      <div className={classes.table}>
        <div className={classes.tableHeading}>
          <div className={classes.searchWidth}>
            <Input
              name="search"
              onChange={(event) =>
                onChangeSearchTextHandler(event.target.value)
              }
              placeholder="Search By Merchant ID"
              type={InputType.Text}
              icon={searchIcon}
              size={InputSize.S}
              className={classes.search}
              onKeyPress={() =>
                onSubmitFilterHandler({ resetPagination: true })
              }
            />
          </div>
          {isAdmin && (
            <div className={classes.actions}>
              <Button
                type={ButtonType.Submit}
                size={ButtonSize.S}
                className={classes.button}
                onClick={onAddMerchantHandler}
              >
                <div className={classes.addIcon}>
                  <img src={addIcon} alt="add" />
                </div>
              </Button>
            </div>
          )}
          {!isAdmin && (
            <span
              data-tip={getExportMerchantsTooltip()}
              data-for="exportMerchants"
            >
              <Button
                type={ButtonType.Default}
                className={classes.exportButton}
                onClick={onExportDataHandler}
                kind="minimal"
                disabled={disableExportButton}
              >
                <span className={classes.exportMerchants}>
                  <img
                    src={exportIcon}
                    className={classes.exportIcon}
                    alt="export merchants"
                  />
                  Export
                </span>
              </Button>
            </span>
          )}
        </div>
        {loadingFetchMerchants ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={columns}
            data={data}
            onClickCellButton={onClickCellButtonHandler}
            onChangeSwitch={onChangeSwitchHandler}
            selectedFilters={selectedFilters}
            sortBy={sortBy}
            onSubmitFilter={onSubmitFilterHandler}
            itemsPerPage={pagination.limit}
            currentPage={pagination.page}
            manualPagination
            fetchData={onSubmitFilterHandler}
            bodyClassName={classes.tableBody}
            countTotal={generalCount}
            newPagination
          />
        )}
      </div>
      <ReactTooltip id="exportMerchants" />
    </>
  );
};

export default Merchants;
