/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const selectReportsState = (state) => state.reports;

export const selectFetchReportsState = createSelector(
  selectReportsState,
  ({
    fetchReportsLoading,
    fetchReportsSuccess,
    fetchReportsError,
    reports: { results, countTotal },
  }) => ({
    loading: fetchReportsLoading,
    success: fetchReportsSuccess,
    error: fetchReportsError,
    reportsData: {
      reports: results || [],
      countTotal: countTotal || 0,
    },
  })
);
