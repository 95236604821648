import React, { useState, useCallback, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Badge, {
  Size as BadgeSize,
  Kind as BadgeKind,
} from 'components/Common/Badge';
import Popover from 'components/Common/Popover';
import arrowUpIcon from 'assets/icons/arrows/arrow-up.svg';
import searchIcon from 'assets/icons/search.svg';
import closeIcon from 'assets/icons/close.svg';
import closeGrayIcon from 'assets/icons/color/close-gray.svg';

import classes from './GenerateReport.module.scss';

const GenerateReport = ({
  onSubmit,
  fields,
  error,
  onError,
  setDisplayDefaultValues,
}) => {
  const [reportFields, setReportFields] = useState(fields);
  const [selectedReportFields, setSelectedReportFields] = useState({});
  const [closePopover, setClosePopover] = useState(false);

  const [selectedDefault, setSelectedDefault] = useState(false);
  const [submittedDefault, setSubmittedDefault] = useState(false);

  useEffect(() => {
    if (closePopover) {
      setClosePopover(false);
    }
  }, [closePopover]);

  useEffect(() => {
    if (!selectedDefault) {
      fields.forEach((field, fieldIndex) => {
        if (field.default) {
          setSelectedReportFields((prevState) => {
            const newFields = { ...prevState };
            newFields[field.text] = {
              ...field,
              fieldIndex,
            };
            return newFields;
          });
        }
      });
      setSelectedDefault(true);
    }
  }, [fields, selectedDefault, onSubmit, selectedReportFields]);

  const onSumbitHandler = useCallback(() => {
    const selectedFields = [];
    Object.keys(selectedReportFields).forEach((field) => {
      selectedFields.push(field);
    });
    onSubmit(selectedFields);
    setClosePopover(true);
  }, [onSubmit, selectedReportFields]);

  useEffect(() => {
    if (selectedDefault && !submittedDefault) {
      setSubmittedDefault(true);
      setDisplayDefaultValues(true);
      onSumbitHandler();
    }
  }, [
    selectedDefault,
    submittedDefault,
    setDisplayDefaultValues,
    onSumbitHandler,
  ]);

  useEffect(() => {
    if (error) {
      onError();
    }
  }, [error, onError]);

  const onClickFieldHandler = useCallback((fieldIndex, option) => {
    const { text } = option;

    setSelectedReportFields((prevState) => {
      const newFields = { ...prevState };
      if (newFields[text]) {
        delete newFields[text];
      } else {
        newFields[text] = { ...option, fieldIndex };
      }
      return newFields;
    });
  }, []);

  const onRemoveSelectedFieldHandler = useCallback(({ text }) => {
    setSelectedReportFields((prevState) => {
      const newFields = { ...prevState };
      delete newFields[text];
      return newFields;
    });
  }, []);

  const onClearAllHandler = useCallback(() => {
    setSelectedReportFields({});
  }, []);

  const onSearchFieldHandler = useCallback(
    (event) => {
      const text = event.target.value;
      if (text) {
        const matchFields = fields.filter((field) =>
          field.text.toLowerCase().includes(text.toLowerCase())
        );
        setReportFields(matchFields);
      } else {
        setReportFields(fields);
      }
    },
    [fields]
  );

  const hasSelectedFields = useMemo(
    () => Object.values(selectedReportFields).length > 0,
    [selectedReportFields]
  );

  const buttonComponent = () => (
    <div className={classes.select}>
      <Body size={BodySize.XS} className={classes.text}>
        Generate Report
      </Body>
      <div className={classes.arrow}>
        <img src={arrowUpIcon} alt="ReportArrow" />
      </div>
    </div>
  );

  return (
    <>
      <Popover
        button={buttonComponent}
        widthButton={38}
        widthPanel={25}
        closePopover={closePopover}
      >
        <div className={classes.panelSearch}>
          <Input
            name="search"
            placeholder="Search by Field Name"
            type={InputType.Text}
            icon={searchIcon}
            size={InputSize.XS}
            className={classes.search}
            onChange={onSearchFieldHandler}
          />
        </div>
        {hasSelectedFields && (
          <>
            <Badge
              text="Clear all"
              endIcon={closeGrayIcon}
              altEndIcon="remove"
              size={BadgeSize.S}
              kind={BadgeKind.Tertiary}
              className={classes.clearAll}
              onClick={onClearAllHandler}
            />
            <div className={classes.selectedFields}>
              {Object.values(selectedReportFields).map((field, nameIndex) => (
                <Badge
                  key={`opt ${nameIndex.toString()}`}
                  text={field.text}
                  endIcon={closeIcon}
                  altEndIcon="remove"
                  size={BadgeSize.S}
                  kind={BadgeKind.Primary}
                  className={classes.badge}
                  onClick={() => onRemoveSelectedFieldHandler(field)}
                />
              ))}
            </div>
          </>
        )}
        <div className={classes.list}>
          {reportFields.map((field, fieldIndex) => (
            <div key={`header ${fieldIndex.toString()}`}>
              <button
                key={`option ${fieldIndex.toString()}`}
                className={classNames(classes.item, {
                  [classes.clicked]: selectedReportFields[field.text],
                })}
                onClick={() => onClickFieldHandler(fieldIndex, field)}
                type="button"
              >
                <Body size={BodySize.XS} className={classes.text}>
                  {field.text}
                </Body>
              </button>
            </div>
          ))}
        </div>
        <Button
          type={ButtonType.Default}
          size={ButtonSize.S}
          className={classes.button}
          onClick={onSumbitHandler}
        >
          Generate report
        </Button>
      </Popover>
    </>
  );
};

GenerateReport.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onError: PropTypes.func.isRequired,
  error: PropTypes.string,
  setDisplayDefaultValues: PropTypes.func,
};

GenerateReport.defaultProps = {
  error: null,
  setDisplayDefaultValues: () => {},
};

export default GenerateReport;
