import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';

const validationSchema = yup.object().shape({
  // when we are making a completely new offer, this will be a string;
  // else it is null because this field is disabled.
  // so it needs to be nullable (meaning we can't use .required),
  // but have a min length of 1 - i.e., not be an empty string - when it is not null.
  merchantId: yup.string().nullable().min(1, Validations.RequiredMerchantId),
  name: yup.string().required(Validations.RequiredName),
  source: yup.string().nullable().required(Validations.RequiredSource),
  offerType: yup.string().nullable().required(Validations.RequiredType),
  merchantNetwork: yup
    .string()
    .nullable()
    .required(Validations.RequiredNetwork),
  qualifiedIssuer: yup
    .array()
    .nullable()
    .required(Validations.RequiredQualifiedIssuers),
  mcc: yup.array().nullable(),
  commissionType: yup
    .string()
    .nullable()
    .required(Validations.RequiredCommissionType),
  startDate: yup.date().nullable().required(Validations.RequiredStartDate),
  expirationDate: yup
    .date()
    .nullable()
    .required(Validations.RequiredExpirationDate)
    .when('startDate', (startDate, schema) =>
      schema.test({
        test: (expirationDate) => expirationDate > startDate,
        message: Validations.ExpirationDateGreaterThanStartDate,
      })
    ),
  commissionValue: yup
    .number()
    .min(0, Validations.CommissionValueMinValue)
    .max(100, Validations.CommissionValueMaxValue)
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(Validations.RequiredCommissionValue),
  minTransactionAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MinTransactionAmountMinValue),
  maxTransactionAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MaxTransactionAmountMinValue),
  minRewardAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MinRewardAmountMinValue),
  maxRewardAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MaxRewardAmountMinValue),
});

export default validationSchema;
