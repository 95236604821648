import React from 'react';
import Toast, { Type as ToastType } from 'components/Common/Toast';

const commonToast = (success, error, successMessage, errorMessage) =>
  (success || error) && (
    <Toast
      id={success ? successMessage : errorMessage}
      text={success ? successMessage : errorMessage}
      type={success ? ToastType.Success : ToastType.Error}
    />
  );

export default commonToast;
