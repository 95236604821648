const Roles = Object.freeze({
  Admin: 'admin',
  Reporter: 'reporter',
  TransactionReviewer: 'pending_tx_reviewer',
  KardAdmin: 'admin.kard',
  KardReporter: 'reporter.kard',
  Issuer: 'reporter.issuer',
});

/**
 * These are the roles that are usable in the UI and their visual labels.
 * NOTE: This map should be one-to-one, such that labels are unique
 * and an ID can be derived from a label.
 * @see {@link utils/users/roles}
 */
export const RoleLabels = Object.freeze({
  // [Roles.Admin]: 'Admin',
  // [Roles.Reporter]: 'Read Only',
  // [Roles.TransactionReviewer]: 'Transaction Reviewer',
  [Roles.KardAdmin]: 'Kard Admin',
  [Roles.KardReporter]: 'Kard Read Only',
  [Roles.Issuer]: 'Issuer Read Only',
});

export default Roles;
