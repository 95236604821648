/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  fetchLocationsInit,
  fetchLocationsSuccess,
  fetchLocationsFail,
  editLocationInit,
  editLocationSuccess,
  editLocationFail,
  createLocationInit,
  createLocationSuccess,
  createLocationFail,
  clearLocationSuccessProp,
  clearLocationFailProp,
  uploadLocationsInit,
  uploadLocationsSuccess,
  uploadLocationsFail,
  clearUploadLocationsData,
  fetchLocationsByMerchantIdInit,
  fetchLocationsByMerchantIdSuccess,
  fetchLocationsByMerchantIdFail,
  clearLocationsByMerchantIdData,
  fetchLocationByIdInit,
  fetchLocationByIdSuccess,
  fetchLocationByIdFail,
  clearEditLocationSuccessProp,
} from 'state/actionCreators/locations';

export const initialState = {
  fetchLocationsLoading: false,
  fetchLocationsSuccess: false,
  fetchLocationsError: null,
  locations: [],
  fetchLocationsByMerchantIdLoading: false,
  fetchLocationsByMerchantIdSuccess: false,
  fetchLocationsByMerchantIdError: null,
  locationsByMerchant: [],
  editLocationLoading: false,
  editLocationSuccess: false,
  editLocationError: null,
  createLocationLoading: false,
  createLocationSuccess: false,
  createLocationError: null,
  uploadLocationsLoading: false,
  uploadLocationsSuccess: false,
  uploadLocationsError: null,
  fetchLocationByIdLoading: false,
  fetchLocationByIdSuccess: false,
  fetchLocationByIdError: null,
  location: null,
};
const persistConfig = {
  key: 'locations',
  storage,
  blacklist: [
    'fetchLocationsLoading',
    'fetchLocationsSuccess',
    'fetchLocationsError',
    'locations',
    'fetchLocationsByMerchantIdLoading',
    'fetchLocationsByMerchantIdSuccess',
    'fetchLocationsByMerchantIdError',
    'locationsByMerchant',
    'editLocationLoading',
    'editLocationSuccess',
    'editLocationError',
    'createLocationLoading',
    'createLocationSuccess',
    'createLocationError',
    'clearLocationSuccessProp',
    'uploadLocationsLoading',
    'uploadLocationsSuccess',
    'uploadLocationsError',
    'fetchLocationByIdLoading',
    'fetchLocationByIdSuccess',
    'fetchLocationByIdError',
    'location',
  ],
};
const locationsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [fetchLocationsInit]: (state) => {
      state.fetchLocationsLoading = true;
      state.fetchLocationsError = null;
      state.fetchLocationsSuccess = false;
      state.locations = [];
      state.location = null;
      state.fetchLocationByIdError = null;
      state.uploadLocationsError = null;
      state.uploadLocationsSuccess = false;
    },
    [fetchLocationsSuccess]: (state, action) => {
      const { locations } = action.payload;
      state.fetchLocationsLoading = false;
      state.fetchLocationsError = null;
      state.fetchLocationsSuccess = true;
      state.locations = locations;
    },
    [fetchLocationsFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchLocationsLoading = false;
      state.fetchLocationsError = error;
      state.fetchLocationsSuccess = false;
    },
    [fetchLocationsByMerchantIdInit]: (state) => {
      state.fetchLocationsByMerchantIdLoading = true;
      state.fetchLocationsByMerchantIdError = null;
      state.fetchLocationsByMerchantIdSuccess = false;
      state.locationsByMerchant = [];
    },
    [fetchLocationsByMerchantIdSuccess]: (state, action) => {
      const { locations } = action.payload;
      state.fetchLocationsByMerchantIdLoading = false;
      state.fetchLocationsByMerchantIdError = null;
      state.fetchLocationsByMerchantIdSuccess = true;
      state.locationsByMerchant = locations;
    },
    [fetchLocationsByMerchantIdFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchLocationsByMerchantIdLoading = false;
      state.fetchLocationsByMerchantIdError = error;
      state.fetchLocationsByMerchantIdSuccess = false;
    },
    [fetchLocationByIdInit]: (state) => {
      state.fetchLocationByIdLoading = true;
      state.fetchLocationByIdError = null;
      state.fetchLocationByIdSuccess = false;
      state.location = null;
    },
    [fetchLocationByIdSuccess]: (state, action) => {
      const { location } = action.payload;
      state.fetchLocationByIdLoading = false;
      state.fetchLocationByIdError = null;
      state.fetchLocationByIdSuccess = true;
      state.location = location;
    },
    [fetchLocationByIdFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchLocationByIdLoading = false;
      state.fetchLocationByIdError = error;
      state.fetchLocationByIdSuccess = false;
      state.location = null;
    },
    [clearLocationsByMerchantIdData]: (state) => {
      state.fetchLocationsByMerchantIdLoading = false;
      state.fetchLocationsByMerchantIdError = null;
      state.fetchLocationsByMerchantIdSuccess = false;
      state.locationsByMerchant = [];
    },
    [editLocationInit]: (state) => {
      state.editLocationLoading = true;
      state.editLocationError = null;
      state.editLocationSuccess = false;
    },
    [editLocationSuccess]: (state) => {
      state.editLocationLoading = false;
      state.editLocationError = null;
      state.editLocationSuccess = true;
    },
    [editLocationFail]: (state, action) => {
      const { error } = action.payload;
      state.editLocationLoading = false;
      state.editLocationError = error;
      state.editLocationSuccess = false;
    },
    [createLocationInit]: (state) => {
      state.createLocationLoading = true;
      state.createLocationError = null;
      state.createLocationSuccess = false;
    },
    [createLocationSuccess]: (state) => {
      state.createLocationLoading = false;
      state.createLocationError = null;
      state.createLocationSuccess = true;
    },
    [createLocationFail]: (state, action) => {
      const { error } = action.payload;
      state.createLocationLoading = false;
      state.createLocationError = error;
      state.createLocationSuccess = false;
    },
    [clearLocationSuccessProp]: (state) => {
      state.createLocationSuccess = false;
      state.createLocationError = null;
      state.createLocationLoading = false;
    },
    [clearLocationFailProp]: (state) => {
      state.createLocationLoading = false;
      state.createLocationError = null;
      state.createLocationSuccess = false;
    },
    [uploadLocationsInit]: (state) => {
      state.uploadLocationsLoading = true;
      state.uploadLocationsError = null;
      state.uploadLocationsSuccess = false;
    },
    [uploadLocationsSuccess]: (state) => {
      state.uploadLocationsLoading = false;
      state.uploadLocationsError = null;
      state.uploadLocationsSuccess = true;
    },
    [uploadLocationsFail]: (state, action) => {
      const { error } = action.payload;
      state.uploadLocationsLoading = false;
      state.uploadLocationsError = error;
      state.uploadLocationsSuccess = false;
    },
    [clearUploadLocationsData]: (state) => {
      state.uploadLocationsLoading = false;
      state.uploadLocationsError = null;
      state.uploadLocationsSuccess = false;
    },
    [clearEditLocationSuccessProp]: (state) => {
      state.editLocationLoading = false;
      state.editLocationError = null;
      state.editLocationSuccess = false;
    },
  })
);
export default locationsReducer;
