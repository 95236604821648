import client from 'services/kardAPI';

const fetchOfferById = async (id) => {
  try {
    const offer = await client.get(`/rewards/portal/merchant/offer/${id}`);
    return offer.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchOfferById;
