import client from 'services/kardAPI';

const createLocation = async (body) => {
  try {
    await client.post(`/rewards/portal/merchant/location`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createLocation;
