import client from 'services/kardAPI';

const fetchMerchantById = async (id) => {
  try {
    const merchant = await client.get(`/rewards/portal/merchant/${id}`);
    return merchant.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchMerchantById;
