import dateParse from 'utils/common/dateParse';

const columns = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Title',
    accessorKey: 'title',
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Zip',
    accessorKey: 'zip',
  },
  {
    header: 'Status',
    accessorKey: 'status',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'US State',
    accessorKey: 'usState',
  },
  {
    header: 'Created Date',
    accessorKey: 'createdDate',
    cell: ({ getValue }) => (getValue() ? dateParse(getValue()) : '-'),
    meta: {
      tooltip: (value) => (value ? dateParse(value) : '-'),
    },
  },
  {
    header: 'Age',
    accessorKey: 'age',
  },
];

export default columns;
