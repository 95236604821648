export default [
  {
    id: '01101010',
    name: 'Jordan Mann',
    zip: '94105',
    status: 'ACTIVE',
    usState: 'CA',
    createdDate: '2023-05-26T22:00:44.483Z',
    age: '20',
  },
  {
    id: '23456789',
    name: 'Test Account',
    zip: '03755',
    status: 'INACTIVE',
    usState: 'NH',
    createdDate: '2023-04-15T12:35:45.000Z',
    age: '25',
  },
];
