export const TRANSACTION_STATUSES = Object.freeze({
  ALL: 'ALL',
  APPROVED: 'APPROVED',
  SETTLED: 'SETTLED',
});

export const TransactionStatusOptions = Object.freeze([
  {
    label: TRANSACTION_STATUSES.ALL,
    value: TRANSACTION_STATUSES.ALL,
  },
  {
    label: TRANSACTION_STATUSES.APPROVED,
    value: TRANSACTION_STATUSES.APPROVED,
  },
  {
    label: TRANSACTION_STATUSES.SETTLED,
    value: TRANSACTION_STATUSES.SETTLED,
  },
]);
