export const ReviewStatusValues = Object.freeze({
  Pending: 'OPEN',
  Accepted: 'APPROVED',
  Rejected: 'REJECTED',
});

export const ReviewStatusOptions = Object.freeze(
  Object.entries(ReviewStatusValues).map(([label, value]) => ({
    label,
    value,
  }))
);
