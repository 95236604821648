import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Body.module.scss';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
  XXS: 'xxs',
});

export const Color = Object.freeze({
  Black: 'black',
  Gray: 'gray',
  White: 'white',
  Blue: 'blue',
  Red: 'red',
});

const Body = ({ size, color, children, className, ...props }) => (
  <p
    className={classNames(classes[color], classes[size], className)}
    {...props}
  >
    {children}
  </p>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(Size)),
  color: PropTypes.oneOf(Object.values(Color)),
  className: PropTypes.string,
};

Body.defaultProps = {
  size: Size.S,
  color: Color.Black,
  className: '',
};

export default Body;
