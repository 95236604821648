import client from 'services/kardAPI';

const fetchOffers = async (filters = '') => {
  try {
    const offers = await client.get(
      `/rewards/portal/merchant/offers${filters}`
    );
    return offers.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchOffers;
