import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const ValidationMode = Object.freeze({
  OnChange: 'onChange',
  OnBlur: 'onBlur',
  OnSubmit: 'onSubmit',
  OnTouched: 'onTouched',
});

const Form = forwardRef(
  (
    {
      children,
      validationSchema,
      validationMode,
      onSubmit,
      className,
      shouldReset,
      defaultValues,
      setFormErrors,
      validateInputs,
    },
    ref
  ) => {
    const formMethods = useForm({
      resolver: validationSchema && yupResolver(validationSchema),
      mode: validationMode,
      defaultValues,
    });
    const {
      handleSubmit,
      reset,
      getValues,
      setValue,
      trigger,
      errors,
    } = formMethods;

    useImperativeHandle(ref, () => ({
      setFormValues(string, value) {
        setValue(string, value);
      },
      getFormValues() {
        return getValues();
      },
      resetForm() {
        reset();
      },
    }));

    useEffect(() => {
      if (shouldReset) {
        reset();
      }
    }, [shouldReset, reset]);

    useEffect(() => {
      // If there is an error with the validation itself,
      // then it will be set as errors[undefined].
      // We want to be aware of that validation error.
      if (errors[undefined] !== undefined) {
        // eslint-disable-next-line no-console
        console.warn('Error while validating:', errors[undefined].message);
      }
      if (setFormErrors) {
        setFormErrors(errors);
      }
    }, [errors, setFormErrors]);

    useEffect(() => {
      if (validateInputs.length > 0) {
        trigger(validateInputs);
      }
    }, [validateInputs, trigger]);

    return (
      <FormProvider {...formMethods}>
        <form className={className} onSubmit={handleSubmit(onSubmit)}>
          {children}
        </form>
      </FormProvider>
    );
  }
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.shape({}),
  defaultValues: PropTypes.shape({}),
  validationMode: PropTypes.oneOf(Object.values(ValidationMode)),
  className: PropTypes.string,
  shouldReset: PropTypes.bool,
  setFormErrors: PropTypes.func,
  validateInputs: PropTypes.arrayOf(PropTypes.string),
};

Form.defaultProps = {
  validationSchema: null,
  defaultValues: {},
  validationMode: ValidationMode.OnSubmit,
  className: '',
  shouldReset: false,
  setFormErrors: null,
  validateInputs: [],
};

export default Form;
