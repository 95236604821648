const columnsOfTypeDate = [
  'createdDate',
  'startDate',
  'expirationDate',
  'transactionDate',
  'transactionTimeStamp',
  'authorizationDate',
  'settledDate',
  'notificationDate',
];

export default columnsOfTypeDate;

export function checkingReportsPath() {
  return !window.location.pathname.includes('reports');
}
