export const statuses = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'In Progress',
    value: 'IN_PROGRESS',
  },
];

export const rewardTypes = Object.freeze({
  static: 'STATIC',
  dynamic: 'DYNAMIC',
});

export const behaviorOptions = [
  // { label: 'Redemptions', value: 'Redemptions' },
  { label: 'Purchases', value: 'Purchases' },
];

export const behaviorRangeValues = Object.freeze({
  LessThanOrEqualTo: 'Less than or Equal to',
  GreaterThanOrEqualTo: 'Greater than or Equal to',
});

export const behaviorRangeOptions = [
  {
    label: behaviorRangeValues.LessThanOrEqualTo,
    value: behaviorRangeValues.LessThanOrEqualTo,
  },
  {
    label: behaviorRangeValues.GreaterThanOrEqualTo,
    value: behaviorRangeValues.GreaterThanOrEqualTo,
  },
];

export const periodOptions = [
  { label: 'Days', value: 'days' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
];

export const timescaleOptions = [
  // { label: 'Equal to', value: 'Equal to' },
  { label: 'Last', value: 'Last' },
];

export const audienceType = Object.freeze({
  Dynamic: 'DYNAMIC',
  Static: 'STATIC',
});

export const DEFAULT_SORT_BY = Object.freeze({
  ascending: false,
  descending: true,
  columnName: 'createdDate',
});
