import client from 'services/kardAPI';

const fetchPendingTransactions = async (filters = '') => {
  try {
    const users = await client.get(`/transactions/portal/pending${filters}`);
    return users.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchPendingTransactions;
