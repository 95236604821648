import { Auth } from 'aws-amplify';

import amplifyErrorMessage from 'utils/amplifyErrorMessage';

const forgotPasswordConfirmation = async (username, code, newPassword) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export default forgotPasswordConfirmation;
