import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import Papa from 'papaparse';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import uploadIcon from 'assets/icons/color/upload_file.svg';
import checkedFileIcon from 'assets/icons/color/checked_file.svg';
import errorFileIcon from 'assets/icons/color/error_file.svg';
import { selectUploadLocationsState } from 'state/selectors/locations';
import {
  clearUploadLocations,
  fetchLocations,
  uploadLocations,
} from 'state/actions/locations';

import csvData from 'utils/locations/bulkExample';
import { CSVLink } from 'react-csv';
import classes from './LocationsUpload.module.scss';

const FILTER_BY_CREATED_DATE_DESC = '?createdDateSort=-1';

const LocationsUpload = ({
  title,
  subtitle,
  isCreateMerchantFlow,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const inputFileRef = useRef();

  const { loading, success, error } = useSelector(
    selectUploadLocationsState,
    shallowEqual
  );

  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successUploading, setSuccessUploading] = useState(false);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
      dispatch(clearUploadLocations());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (success) {
      setSuccessUploading(true);
      dispatch(fetchLocations(FILTER_BY_CREATED_DATE_DESC));
    }
  }, [success, dispatch]);

  const onClickUploadFileHandler = useCallback(() => {
    inputFileRef.current.click();
    setSuccessUploading(false);
    setErrorMessage(null);
  }, []);

  const onChangeFileInputHandler = useCallback((event) => {
    const newFile = event.target.files[0];
    setFile(newFile);
    setFileName(newFile.name);
  }, []);

  const transformStringToBoolean = useCallback(
    (value) => value.toLowerCase() === 'true',
    []
  );

  const onSubmitFile = useCallback(() => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete(results) {
        const { data } = results;
        const transformedLocations = data.map((location) => ({
          name: location.Name || '',
          merchantId: location.MerchantId || '',
          locationType: location.LocationType || '',
          address: {
            street: location.Street || '',
            city: location.City || '',
            state: location.State || '',
            zipCode: location.Zip || '',
          },
          source: location.Source,
          phone: location.Phone,
          operationHours: {
            SUNDAY: location.Sunday || 'N/A',
            MONDAY: location.Monday || 'N/A',
            TUESDAY: location.Tuesday || 'N/A',
            WEDNESDAY: location.Wednesday || 'N/A',
            THURSDAY: location.Thursday || 'N/A',
            FRIDAY: location.Friday || 'N/A',
            SATURDAY: location.Saturday || 'N/A',
          },
          networkData: [
            {
              network: location.Network || '',
              networkMerchantId: location.NetworkMerchantId || '',
            },
          ],
          geoLocation: {
            longitude: Number(location.Longitude) || '',
            latitude: Number(location.Latitude) || '',
          },
          status: location.Status || 'ACTIVE',
          websiteURL: location.URL || '',
          offerId: location.OfferId || '',
          ...(location.LocationId && { _id: location.LocationId }),
          ...(location.IsValidated && {
            isValidated: transformStringToBoolean(location.IsValidated),
          }),
          ...(location.PermanentlyClosed && {
            permanentlyClosed: transformStringToBoolean(
              location.PermanentlyClosed
            ),
          }),
          ...(location.GoogleId && { googleId: location.GoogleId }),
          ...(location.StoreId && { storeId: location.StoreId }),
        }));

        if (transformedLocations.length > 0) {
          const jsonObject = { locations: transformedLocations };
          dispatch(uploadLocations(jsonObject));
        }
      },
    });
  }, [file, dispatch, transformStringToBoolean]);

  return (
    <div className={classes.container}>
      <div>
        <Heading size={HeadingSize.M} className={classes.heading}>
          {title}
        </Heading>
        {subtitle && (
          <Body
            size={BodySize.S}
            className={classes.subtitle}
            color={BodyColor.Gray}
          >
            {subtitle}
          </Body>
        )}
        <Body size={BodySize.S} className={classes.body} color={BodyColor.Gray}>
          Locations can be updated by sending in an existing ID and new data
        </Body>
      </div>
      <div className={classes.content}>
        <Body size={BodySize.S} className={classes.explanationText}>
          Upload your templated locations data here. See the attached csv format
          required.
        </Body>
        <CSVLink
          data={csvData}
          filename="bulk-upload-example.csv"
          className={classes.csv}
        >
          <Body size={BodySize.S} className={classes.csvText}>
            Download example
          </Body>
        </CSVLink>
        <input
          type="file"
          accept=".csv"
          onChange={onChangeFileInputHandler}
          className={classes.inputFile}
          ref={inputFileRef}
        />
        <Button
          type={ButtonType.Default}
          size={ButtonSize.L}
          className={classes.button}
          loading={loading}
          onClick={onClickUploadFileHandler}
        >
          <img src={uploadIcon} alt="upload file" className={classes.image} />
          Upload File
        </Button>
        {fileName && !successUploading && !errorMessage && (
          <div className={classes.response}>
            <Body
              size={BodySize.XS}
              className={classes.contentText}
              color={BodyColor.Blue}
            >
              Selected file:
            </Body>
            <Body size={BodySize.XS} className={classes.fileName}>
              {fileName}
            </Body>
          </div>
        )}
        {successUploading && (
          <>
            <div className={classes.response}>
              <img
                src={checkedFileIcon}
                alt="success"
                className={classes.image}
              />
              <Body
                size={BodySize.XS}
                className={classes.contentText}
                color={BodyColor.Blue}
              >
                Your file was successfully uploaded
              </Body>
            </div>
            {fileName && (
              <Body size={BodySize.XS} className={classes.fileName}>
                {fileName}
              </Body>
            )}
          </>
        )}
        {errorMessage && (
          <div className={classes.response}>
            <div className={`${classes.flexColumn} ${classes.flexCenter}`}>
              <div className={classes.flex}>
                <img
                  src={errorFileIcon}
                  alt="error"
                  className={classes.image}
                />
                <Body
                  size={BodySize.XS}
                  className={classes.contentText}
                  color={BodyColor.Red}
                >
                  Your file cannot be uploaded
                </Body>
              </div>
              <Body
                size={BodySize.M}
                className={classes.contentText}
                color={BodyColor.Red}
              >
                {errorMessage}
              </Body>
            </div>
          </div>
        )}
      </div>
      <div className={classes.buttons}>
        {successUploading ? (
          <Button
            type={ButtonType.Default}
            size={ButtonSize.S}
            onClick={onCancel}
            kind={ButtonKind.Secondary}
            className={classes.cancelButton}
          >
            Close
          </Button>
        ) : (
          <>
            <Button
              type={ButtonType.Default}
              size={ButtonSize.S}
              onClick={onCancel}
              kind={ButtonKind.Secondary}
              className={classes.cancelButton}
            >
              {isCreateMerchantFlow ? 'Go back' : 'Cancel'}
            </Button>
            <Button
              type={ButtonType.Default}
              size={ButtonSize.S}
              onClick={onSubmitFile}
              disabled={!file}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

LocationsUpload.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  isCreateMerchantFlow: PropTypes.bool,
};

LocationsUpload.defaultProps = {
  subtitle: null,
  isCreateMerchantFlow: false,
};

export default LocationsUpload;
