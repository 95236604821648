/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchIssuersInit,
  fetchIssuersSuccess,
  fetchIssuersFail,
} from 'state/actionCreators/issuers';

export const initialState = {
  fetchIssuersLoading: false,
  fetchIssuersSuccess: false,
  fetchIssuersError: null,
  issuers: [],
};

const persistConfig = {
  key: 'issuers',
  storage,
  blacklist: [
    'fetchIssuersLoading',
    'fetchIssuersSuccess',
    'fetchIssuersError',
    'issuers',
  ],
};

const issuersReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [fetchIssuersInit]: (state) => {
      state.fetchIssuersLoading = true;
    },
    [fetchIssuersSuccess]: (state, action) => {
      const { issuers } = action.payload;
      state.fetchIssuersLoading = false;
      state.fetchIssuersError = null;
      state.fetchIssuersSuccess = true;
      state.issuers = issuers;
    },
    [fetchIssuersFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchIssuersLoading = false;
      state.fetchIssuersError = error;
      state.fetchIssuersSuccess = false;
    },
  })
);

export default issuersReducer;
