import Validations from 'enums/schemaValidations/validations.enum';
import * as yup from 'yup';

const audienceInformationSchema = yup.object().shape({
  name: yup.string().required(Validations.RequiredName),
  status: yup.string().nullable().required(Validations.RequiredStatus),
  description: yup.string(),
});

export default audienceInformationSchema;
