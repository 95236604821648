import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import addIcon from 'assets/icons/plus.svg';
import closeIcon from 'assets/icons/color/close-gray.svg';
import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';

import classes from './MultiValueInput.module.scss';

const MultiValueInput = ({
  disabled,
  readOnly,
  values,
  setValues,
  label,
  emptyLabel,
  name,
  placeholder,
}) => {
  const [addNewValue, setAddNewValue] = useState(false);
  const [newValue, setNewValue] = useState('');

  const onCancelAddAssociatedNameHandler = () => {
    setNewValue('');
    setAddNewValue(false);
  };

  const onDeleteAssociatedNameHandler = (index) => {
    setValues((prevState) => {
      const previousNames = [...prevState];
      previousNames.splice(index, 1);
      return previousNames;
    });
  };

  const onClickAddAssociatedNameHandler = () => {
    setAddNewValue(true);
  };

  const onAddNewAssociatedNameHandler = () => {
    setValues((prevState) => [...prevState, newValue]);
    onCancelAddAssociatedNameHandler();
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {label && (
          <Body
            size={BodySize.S}
            className={classes.labelText}
            color={BodyColor.Gray}
          >
            {label}
          </Body>
        )}
        {!readOnly && (
          <Button
            size={ButtonSize.L}
            kind={ButtonKind.Minimal}
            className={classes.addValueButton}
            onClick={onClickAddAssociatedNameHandler}
          >
            <span>Add</span>
            <img src={addIcon} alt="add" />
          </Button>
        )}
      </div>
      {values.length === 0 && !addNewValue && (
        <Body
          size={BodySize.XS}
          color={BodyColor.Gray}
          className={classes.itemText}
        >
          {emptyLabel}
        </Body>
      )}
      {values.map((value, index) => (
        <div
          key={`opt ${index.toString()}`}
          className={classNames(classes.item, {
            [classes.lastItem]: index === values.length - 1 && addNewValue,
          })}
        >
          <Body
            size={BodySize.XS}
            color={disabled ? BodyColor.Black : BodyColor.Gray}
            className={classes.itemText}
          >
            {value}
          </Body>
          {(!readOnly || !disabled) && (
            <button
              type="button"
              className={classes.removeIcon}
              onClick={() => onDeleteAssociatedNameHandler(index)}
            >
              <img src={closeIcon} alt="remove" />
            </button>
          )}
        </div>
      ))}
      {addNewValue && (
        <>
          <Input
            name={name}
            value={newValue}
            onChange={({ target }) => setNewValue(target.value)}
            placeholder={placeholder}
            type={InputType.Text}
            size={InputSize.S}
            className={classes.input}
          />
          <div className={classes.actions}>
            <Button
              type={ButtonType.Default}
              size={ButtonSize.S}
              onClick={onCancelAddAssociatedNameHandler}
              kind={ButtonKind.Secondary}
            >
              Cancel
            </Button>
            <Button
              type={ButtonType.Default}
              size={ButtonSize.S}
              className={classes.submitButton}
              onClick={onAddNewAssociatedNameHandler}
              disabled={newValue === ''}
            >
              Add
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

MultiValueInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.array,
  setValues: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  emptyLabel: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

MultiValueInput.defaultProps = {
  values: [],
  label: null,
  placeholder: '',
  disabled: false,
  readOnly: false,
};

export default MultiValueInput;
