const Path = Object.freeze({
  Home: '/',
  Users: '/users',
  User: '/users/:id',
  Login: '/login',
  ForgotPassword: '/forgot-password',
  PendingTransactions: '/pending-transactions',
  Reports: '/reports',
  Profile: '/profile',
  Help: '/help',
  PortalUsers: '/users',
  Offers: '/offers',
  Merchants: '/merchants',
  Locations: '/locations',
  Support: '/support',
  TransactionsSupport: '/transactions-support',
  Audience: '/audience',
});

export default Path;
