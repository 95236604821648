import { useCallback, useState } from 'react';

/**
 * Custom hook for managing the filter, sort, pagination,
 * and other state of requests for table data.
 *
 * {@link https://kardfinancial.atlassian.net/l/cp/6jGm0pHy}
 * @param defaults describes the default or initial query -
 * these are the values to which the query will be reset when necessary.
 * @returns an array containing the query and its updater function.
 */
const useQuery = (givenDefaults = {}) => {
  // if you useQuery({...}) then givenDefaults will be different on every render.
  // this isn't a problem for defining query. but since updateQuery depends on defaults,
  // if the defaults were not memoized here, then updateQuery would update on every render.
  const [defaults] = useState(givenDefaults);
  const [query, setQuery] = useState(defaults);
  /**
   * This function accepts a partial update to the query,
   * and optionally resets specified properties to their defaults.
   */
  const updateQuery = useCallback(
    (next, reset = []) =>
      setQuery((last) => {
        let nextQuery = { ...last };
        reset.forEach((key) => {
          delete nextQuery[key];
          if (Object.hasOwn(defaults, key)) {
            nextQuery[key] = defaults[key];
          }
        });
        nextQuery = { ...nextQuery, ...next };
        return nextQuery;
      }),
    [defaults]
  );
  return [query, updateQuery];
};

export default useQuery;
