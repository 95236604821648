import React from 'react';
import { selectSidebarExpandedState } from 'state/selectors/layout';
import { shallowEqual, useSelector } from 'react-redux';
import collapseIcon from 'assets/icons/arrows/arrow-left-white.svg';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classes from './ExpandButton.module.scss';

const ExpandButton = ({ clicked }) => {
  const sidebarExpanded = useSelector(selectSidebarExpandedState, shallowEqual);

  return (
    <button
      aria-label={`${sidebarExpanded ? 'Collapse' : 'Expand'} sidebar`}
      data-tip={`${sidebarExpanded ? 'Collapse' : 'Expand'} sidebar`}
      data-for="sidebarTip"
      name="previous"
      type="button"
      onClick={() => {
        clicked(!sidebarExpanded);
        ReactTooltip.hide();
      }}
      className={classes.collapseButton}
      style={{ transform: `scaleX(${sidebarExpanded ? 1 : -1})` }}
    >
      <img src={collapseIcon} alt="previous" />
    </button>
  );
};

ExpandButton.propTypes = {
  clicked: PropTypes.func.isRequired,
};

export default ExpandButton;
