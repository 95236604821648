import { Auth } from 'aws-amplify';

import getCognitoUserData from 'utils/users/getCognitoUserData';

const resumeSession = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    return getCognitoUserData(cognitoUser);
  } catch (error) {
    throw new Error(error);
  }
};

export default resumeSession;
