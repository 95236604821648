import client from 'services/kardAPI';

const fetchWssURL = async () => {
  try {
    const URL = await client.get(`/rewards/portal/websocket`);
    return URL.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchWssURL;
