/* eslint-disable react/prop-types */
import React from 'react';

import trueIcon from 'assets/icons/color/boolean-true.svg';
import falseIcon from 'assets/icons/color/boolean-false.svg';
import FilterTypes from 'enums/reports/filterTypes.enum';
import Groups from 'enums/users/groups.enum';
import dateParse from 'utils/common/dateParse';
import { merchantNetwork } from 'utils/common/merchantNetwork';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Issuer',
        accessor: 'issuer',
        columnName: 'issuer',
        sortable: false,
        filterable: true,
        searchable: true,
        filter: {
          type: FilterTypes.String,
          options: [],
        },
        multiValue: true,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Reward Type',
        accessor: 'matchedTransaction.rewardType',
        columnName: 'rewardType',
        sortable: false,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.String,
          options: ['AFFILIATE', 'CARDLINKED'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Transaction ID',
        accessor: 'transactionId',
        columnName: 'transactionId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: false,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.String,
          options: ['APPROVED', 'SETTLED', 'REVERSED', 'DECLINED'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Transaction Date',
        accessor: 'transactionTimeStamp',
        columnName: 'transactionTimeStamp',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: false,
        multiValue: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
        columnName: 'createdDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Received Payment',
        accessor: 'matchedTransaction.receivedPaymentStatus',
        columnName: 'receivedPaymentStatus',
        sortable: false,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.String,
          options: ['PENDING', 'PAID IN FULL', 'PAID IN PART'],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Paid To Issuer',
        accessor: 'matchedTransaction.paidToIssuer',
        columnName: 'paidToIssuer',
        Cell: ({ value }) => {
          if (value) return <img src={trueIcon} alt="valid" />;
          return <img src={falseIcon} alt="invalid" />;
        },
        sortable: false,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Boolean,
          options: ['true', 'false'],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'ReferringPartner User ID',
        accessor: 'referringPartnerUserId',
        columnName: 'referringPartnerUserId',
        sortable: false,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Card BIN',
        accessor: 'cardBIN',
        columnName: 'cardBIN',
        sortable: false,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Card Last 4',
        accessor: 'cardLastFour',
        columnName: 'cardLast4',
        sortable: false,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Matched Merchant Name',
        accessor: 'matchedTransaction.merchantName',
        columnName: 'merchantName',
        sortable: false,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.String,
          options: [],
        },
        allowedRoles: Groups.Internal,
      },
      {
        Header: 'First Offer',
        accessor: 'matchedTransaction.isFirstMerchantOffer',
        columnName: 'isFirstMerchantOffer',
        Cell: ({ value }) => {
          if (value) return <img src={trueIcon} alt="valid" />;
          return <img src={falseIcon} alt="invalid" />;
        },
        sortable: false,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Boolean,
          options: ['TRUE', 'FALSE'],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Merchant Network',
        accessor: 'merchantNetwork',
        columnName: 'merchantNetwork',
        sortable: false,
        filterable: true,
        multiValue: true,
        searchable: true,
        filter: {
          type: FilterTypes.String,
          options: merchantNetwork,
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Transaction Amount (in cents)',
        accessor: 'amount',
        columnName: 'transactionAmountInCents',
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Number,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Offer ID',
        accessor: 'matchedTransaction.offer._id',
        columnName: 'offerId',
        sortable: false,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Valid Reward',
        accessor: 'validReward',
        columnName: 'validReward',
        Cell: ({ value }) => {
          if (value) return <img src={trueIcon} alt="valid" />;
          return <img src={falseIcon} alt="invalid" />;
        },
        sortable: false,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Boolean,
          options: ['TRUE', 'FALSE'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Under Review',
        accessor: 'underReview',
        columnName: 'underReview',
        sortable: false,
        filterable: true,
        Cell: ({ value }) => value || '-',
        filter: {
          type: FilterTypes.String,
          options: ['PENDING', 'COMPLETE'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Received Commission',
        accessor: 'matchedTransaction.receivedCommissionInCents',
        columnName: 'receivedCommissionInCents',
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Number,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Issuer Commission',
        accessor: 'matchedTransaction.commissionToIssuer',
        columnName: 'commissionToIssuer',
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Number,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Authorization Date',
        accessor: 'authorizationDate',
        columnName: 'authorizationDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Settled Date',
        accessor: 'settledDate',
        columnName: 'settledDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Issuer ID',
        accessor: 'issuerId',
        columnName: 'issuerId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Txn Merchant Name',
        accessor: 'merchantName',
        columnName: 'merchantName',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Merchant City',
        accessor: 'merchantAddrCity',
        columnName: 'merchantAddrCity',
        sortable: false,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.String,
          options: [],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Merchant Street Address',
        accessor: 'merchantAddrStreet',
        columnName: 'merchantAddrStreet',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Store Google ID',
        accessor: 'googleID',
        columnName: 'googleID',
        sortable: true,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Transaction Description',
        accessor: 'description',
        columnName: 'description',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Merchant Latitude',
        accessor: 'merchantLat',
        columnName: 'merchantLat',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Merchant Longitude',
        accessor: 'merchantLong',
        columnName: 'merchantLong',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Merchant State',
        accessor: 'merchantAddrState',
        columnName: 'merchantAddrState',
        sortable: false,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.String,
          options: [],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Merchant MCC',
        accessor: 'mcc',
        columnName: 'mcc',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Merchant Country',
        accessor: 'merchantAddrCountry',
        columnName: 'merchantAddrCountry',
        sortable: false,
        filterable: true,
        multiValue: true,
        filter: {
          type: FilterTypes.String,
          options: [],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Merchant Zip Code',
        accessor: 'merchantAddrZipcode',
        columnName: 'merchantAddrZipcode',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Provided Merchant ID',
        accessor: 'merchantId',
        columnName: 'merchantId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Notification Date',
        accessor: 'notificationDate??',
        columnName: 'notificationDate??',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Offer Total Commission',
        accessor: 'matchedTransaction.offer.totalCommission',
        columnName: 'matchedTransaction.offer.totalCommission',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Offer Commission Type',
        accessor: 'matchedTransaction.offer.commissionType',
        columnName: 'commissionType',
        sortable: false,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.String,
          options: ['FLAT', 'PERCENT'],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Matched to an Offer',
        accessor: 'matchedToAnOffer??',
        columnName: 'matchedToAnOffer??',
        Cell: ({ value }) => {
          if (value) return <img src={trueIcon} alt="valid" />;
          return <img src={falseIcon} alt="invalid" />;
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Boolean,
          options: ['true', 'false'],
        },
        allowedRoles: Groups.Everyone,
      },
    ],
  },
];

export default columns;
