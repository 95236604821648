import React from 'react';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import PropTypes from 'prop-types';

import Body, { Color as BodyColor } from 'components/Typography/Body';

import classNames from 'classnames';
import classes from './Switch.module.scss';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
});

export const Kind = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
});

export const LabelPosition = Object.freeze({
  Top: 'topLabel',
  Left: 'leftLabel',
});

const Switch = ({
  enabled,
  setEnabled,
  size,
  label,
  labelPosition,
  labelClassName,
  leftText,
  rightText,
  kind,
  disabled,
  className,
}) => {
  const actualKind = disabled ? Kind.Secondary : kind;

  return (
    <HeadlessSwitch.Group>
      <div
        className={classNames(
          className,
          classes.container,
          classes[labelPosition]
        )}
      >
        {label && (
          <Body
            size={size}
            color={BodyColor.Gray}
            className={classNames(labelClassName, classes.label)}
          >
            {label}
          </Body>
        )}
        <div className={classNames(classes.group)}>
          {leftText && (
            <HeadlessSwitch.Label className={classes.leftLabel}>
              <Body size={Size.XS} color={BodyColor.Gray}>
                {leftText}
              </Body>
            </HeadlessSwitch.Label>
          )}
          <HeadlessSwitch
            checked={enabled}
            onChange={setEnabled}
            disabled={disabled}
            className={classNames(
              enabled
                ? classes[`${actualKind}EnabledSwitch`]
                : classes[`${actualKind}DisabledSwitch`],
              classes.switch,
              { [classes.disabled]: disabled }
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled
                  ? classes[`${actualKind}EnabledCircle`]
                  : classes[`${actualKind}DisabledCircle`],
                classes.circle
              )}
            />
          </HeadlessSwitch>
          {rightText && (
            <HeadlessSwitch.Label className={classes.rightLabel}>
              <Body
                size={Size.XS}
                className={classes.labelText}
                color={BodyColor.Gray}
              >
                {rightText}
              </Body>
            </HeadlessSwitch.Label>
          )}
        </div>
      </div>
    </HeadlessSwitch.Group>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  setEnabled: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.values(Size)),
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  kind: PropTypes.oneOf(Object.values(Kind)),
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
};

Switch.defaultProps = {
  className: '',
  size: Size.S,
  label: null,
  labelPosition: LabelPosition.Left,
  leftText: null,
  rightText: null,
  disabled: false,
  kind: Kind.Primary,
  labelClassName: '',
};

export default Switch;
