import CognitoUserAttributes from 'enums/users/cognitoAttributes.enum';
import Groups from 'enums/users/groups.enum';
import canAccessFeature from 'utils/canAccessFeature';

const getCognitoUserData = (user, userName = null) => {
  const { challengeParam, attributes, username } = user;

  const cognitoUserAttributes = Object.fromEntries(
    Object.entries(CognitoUserAttributes).map(([key, attributeName]) => [
      key,
      (challengeParam?.userAttributes ?? attributes)?.[attributeName],
    ])
  );

  const roles =
    cognitoUserAttributes.roles?.split(',').map((role) => role.toLowerCase()) ||
    [];

  return {
    ...cognitoUserAttributes,
    roles,
    username: userName ?? username,
    isAdmin: canAccessFeature(roles, Groups.Administrators),
  };
};

export default getCognitoUserData;
