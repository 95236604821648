import client from 'services/kardAPI';

const fetchMerchantIssuers = async () => {
  try {
    const issuersData = await client.get(`/issuers/portal/issuers`);
    const issuers = issuersData.data.map((issuer) => issuer.issuerName);
    return issuers;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchMerchantIssuers;
