/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Checkbox, {
  Kind as CheckboxKind,
  Size as CheckboxSize,
} from 'components/Common/Checkbox';
import Switch, { Size as SwitchSize } from 'components/Common/Switch';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Modal from 'components/Common/Modal';
import LocationsData from 'components/Pages/LocationsData';
import OffersData from 'components/Pages/OffersData';
import {
  createMerchant,
  editMerchant,
  fetchMerchantCategories,
  fetchMerchantIssuers,
} from 'state/actions/merchants';
import {
  selectCreateMerchantState,
  selectEditMerchantState,
  selectFetchMerchantCategoriesState,
  selectFetchMerchantIssuersState,
} from 'state/selectors/merchants';
import {
  merchantSources,
  merchantTypes,
  merchantAcceptedCards,
} from 'utils/merchants/values';
import Status from 'enums/status/status.enum';
import ModalType from 'enums/modal/modalType';
import closeIcon from 'assets/icons/color/close-gray.svg';
import addIcon from 'assets/icons/close.svg';
import mapIcon from 'assets/icons/color/map.svg';
import offerIcon from 'assets/icons/color/offer.svg';
import useModal from 'hooks/useModal';
import StatusValue from 'enums/status/statusValue.enum';
import InputImage from 'components/Common/InputImage';
import dateParse from 'utils/common/dateParse';
import merchantPropTypes from 'utils/propTypes/merchants';
import { LabelPosition } from 'enums/inputProps';

import { merchantNetworks } from 'utils/common/merchantNetwork';
import validationSchema from './MerchantForm.schema';
import classes from './MerchantForm.module.scss';

const MerchantForm = ({ title, merchant, isCreating, onCancel }) => {
  const dispatch = useDispatch();

  const { categories, loading: loadingMerchantCategories } = useSelector(
    selectFetchMerchantCategoriesState,
    shallowEqual
  );

  const { issuers } = useSelector(
    selectFetchMerchantIssuersState,
    shallowEqual
  );

  const {
    loading: loadingCreate,
    success: createMerchantSuccess,
    error: errorCreate,
  } = useSelector(selectCreateMerchantState, shallowEqual);

  const {
    loading: loadingEdit,
    success: editMerchantSuccess,
    error: errorEdit,
  } = useSelector(selectEditMerchantState, shallowEqual);

  const [isEditing, setIsEditing] = useState(false);
  const [status, setStatus] = useState(merchant.status === Status.ACTIVE);
  const [associatedNames, setAssociatedNames] = useState(
    merchant.nameMatchRegex || []
  );
  const [excludedNames, setExcludedNames] = useState(
    merchant?.excludeRegex ?? []
  );
  const [addNewAssociatedName, setAddNewAssociatedName] = useState(false);
  const [addNewExcludedName, setAddNewExcludedName] = useState(false);
  const [newAssociatedNameValue, setNewAssociatedNameValue] = useState('');
  const [newExcludedNameValue, setNewExcludedNameValue] = useState('');
  const [realTimeMatch, setRealTimeMatch] = useState(true);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [issuersOptions, setIssuersOptions] = useState([]);
  const [specificIssuers, setSpecificIssuers] = useState(false);
  const [reviewMatches, setReviewMatches] = useState(false);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    dispatch(fetchMerchantCategories());
    dispatch(fetchMerchantIssuers());
  }, [dispatch]);

  useEffect(() => {
    if (Object.values(merchant).length > 0) {
      setRealTimeMatch(merchant.realTimeMatch);
      setSpecificIssuers(merchant.specificIssuers);
      setReviewMatches(merchant.reviewMatches);
    }
  }, [merchant]);

  useEffect(() => {
    if (categories.length > 0 && categoriesOptions.length === 0) {
      const transformedCategories = categories.map((category) => ({
        label: category,
        value: category,
      }));
      setCategoriesOptions(transformedCategories);
    }
  }, [categories, categoriesOptions]);

  useEffect(() => {
    if (issuers.length > 0 && issuersOptions.length === 0) {
      const transformedIssuers = issuers.map((category) => ({
        label: category,
        value: category,
      }));
      setIssuersOptions(transformedIssuers);
    }
  }, [issuers, issuersOptions]);

  const readOnly = useMemo(() => !isEditing && !isCreating, [
    isEditing,
    isCreating,
  ]);

  const onChangeCheckboxStateHandler = useCallback(
    (setState) => {
      if (!readOnly) {
        setState((prevState) => !prevState);
      }
    },
    [readOnly]
  );

  const onChangeStatusHandler = useCallback(() => {
    if (!readOnly) {
      setStatus((prevState) => !prevState);
    }
  }, [readOnly]);

  const onDeleteAssociatedNameHandler = useCallback((index) => {
    setAssociatedNames((prevState) => {
      const previousNames = [...prevState];
      previousNames.splice(index, 1);
      return previousNames;
    });
  }, []);

  const onDeleteExcludedNameHandler = useCallback((index) => {
    setExcludedNames((prevState) => {
      const previousNames = [...prevState];
      previousNames.splice(index, 1);
      return previousNames;
    });
  }, []);

  const onClickAddAssociatedNameHandler = useCallback(() => {
    setAddNewAssociatedName(true);
  }, []);

  const onClickAddExcludedNameHandler = useCallback(() => {
    setAddNewExcludedName(true);
  }, []);

  const onCancelAddAssociatedNameHandler = useCallback(() => {
    setNewAssociatedNameValue('');
    setAddNewAssociatedName(false);
  }, []);

  const onCancelAddExcludedNameHandler = useCallback(() => {
    setNewExcludedNameValue('');
    setAddNewExcludedName(false);
  }, []);

  const onAddNewAssociatedNameHandler = useCallback(() => {
    setAssociatedNames((prevState) => [...prevState, newAssociatedNameValue]);
    onCancelAddAssociatedNameHandler();
  }, [newAssociatedNameValue, onCancelAddAssociatedNameHandler]);

  const onAddNewExcludedNameHandler = useCallback(() => {
    setExcludedNames((prevState) => [...prevState, newExcludedNameValue]);
    onCancelAddExcludedNameHandler();
  }, [newExcludedNameValue, onCancelAddExcludedNameHandler]);

  const onSeeMerchantOffersHandler = useCallback(() => {
    onOpenModalHandler(ModalType.MERCHANT_OFFERS_DATA);
  }, [onOpenModalHandler]);

  const onSeeMerchantLocationsHandler = useCallback(() => {
    onOpenModalHandler(ModalType.MERCHANT_LOCATIONS_DATA);
  }, [onOpenModalHandler]);

  const onSubmitHandler = useCallback(
    (values) => {
      const valuesCopy = { ...values };

      // TODO: can the form validation remove this field instead of manually removing empty optional fields here?
      if (valuesCopy.bannerImgUrl === '') {
        delete valuesCopy.bannerImgUrl;
      }

      if (isCreating) {
        const body = {
          ...valuesCopy,
          status: status ? StatusValue.Active : StatusValue.Inactive,
          realTimeMatch,
          nameMatchRegex: associatedNames,
          excludeRegex: excludedNames,
          specificIssuers,
          reviewMatches,
          merchantNameReviewed: false,
        };

        dispatch(createMerchant(body));
      } else {
        const updateBody = {
          ...merchant,
          ...valuesCopy,
          nameMatchRegex: associatedNames,
          excludeRegex: excludedNames,
          status: status ? StatusValue.Active : StatusValue.Inactive,
          offers: merchant.offers.map(({ _id }) => _id),
          realTimeMatch,
          reviewMatches,
          specificIssuers,
        };

        const { merchantId } = merchant;

        delete updateBody.merchantId;
        delete updateBody.__v;
        delete updateBody._id;
        delete updateBody.createdDate;
        delete updateBody.lastModified;
        dispatch(editMerchant(merchantId, updateBody));
      }
    },
    [
      isCreating,
      dispatch,
      associatedNames,
      excludedNames,
      status,
      realTimeMatch,
      specificIssuers,
      merchant,
      reviewMatches,
    ]
  );

  const defaultMerchantAcceptedCards = useMemo(
    () =>
      isCreating
        ? merchantAcceptedCards.map(({ value }) => value)
        : merchant.acceptedCards,
    [isCreating, merchant.acceptedCards]
  );

  return (
    <>
      {(errorCreate || errorEdit) && (
        <Toast
          id={errorCreate ? 'error create merchant' : 'error edit merchant'}
          text={errorCreate || errorEdit}
          type={ToastType.Error}
        />
      )}

      {createMerchantSuccess && (
        <Toast
          id="success-creating-merchant"
          text="Merchant created successfully!"
          type={ToastType.Success}
        />
      )}
      {editMerchantSuccess && (
        <Toast
          id="success-updating-merchant"
          text="Merchant updated successfully!"
          type={ToastType.Success}
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.MERCHANT_LOCATIONS_DATA}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <LocationsData
          merchantName={merchant.name || 'merchant'}
          merchantId={merchant.merchantId || ''}
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.MERCHANT_OFFERS_DATA}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <OffersData
          merchantName={merchant.name || 'merchant'}
          merchantId={merchant.merchantId || ''}
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <Form
        onSubmit={onSubmitHandler}
        className={classes.form}
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnChange}
      >
        <Heading size={HeadingSize.M}>{title}</Heading>
        <div>
          {!isCreating && (
            <FormControl
              name="merchantId"
              render={(props) => (
                <Input
                  label={isCreating ? '' : 'Merchant ID: '}
                  labelClassName={classes.labelWidth}
                  value={merchant.merchantId}
                  placeholder="Merchant ID"
                  type={InputType.Text}
                  disabled={isEditing}
                  readOnly={readOnly}
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
          )}
          <FormControl
            name="name"
            render={(props) => (
              <Input
                label="Name:"
                labelClassName={classes.labelWidth}
                value={merchant.name}
                type={InputType.Text}
                readOnly={readOnly}
                size={InputSize.S}
                color={isEditing ? InputColor.Black : InputColor.Gray}
                {...props}
              />
            )}
          />
          <FormControl
            name="description"
            render={(props) => (
              <Input
                label="Descriptor:"
                labelClassName={classes.labelWidth}
                value={merchant.description}
                type={InputType.Text}
                readOnly={readOnly}
                size={InputSize.S}
                color={isEditing ? InputColor.Black : InputColor.Gray}
                {...props}
              />
            )}
          />
          <Switch
            enabled={status}
            setEnabled={onChangeStatusHandler}
            label="Status:"
            labelClassName={classes.labelWidth}
            leftText="INACTIVE"
            rightText="ACTIVE"
            size={SwitchSize.S}
            disabled={readOnly}
            className={classes.switch}
          />
          {!isCreating && (
            <FormControl
              name="createdDate"
              render={(props) => (
                <Input
                  label="Created date:"
                  labelClassName={classes.labelWidth}
                  value={
                    merchant.createdDate ? dateParse(merchant.createdDate) : '-'
                  }
                  placeholder="Created date"
                  type={InputType.Text}
                  readOnly={readOnly}
                  disabled={isEditing}
                  size={InputSize.S}
                  {...props}
                />
              )}
            />
          )}
          <FormControlSelect
            name="source"
            defaultValue={merchant.source}
            label="Source:"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            options={merchantSources}
            color={isEditing ? SelectColor.Black : SelectColor.Gray}
          />
          <FormControlSelect
            name="type"
            defaultValue={merchant.type}
            label="Type:"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            options={merchantTypes}
            color={isEditing ? SelectColor.Black : SelectColor.Gray}
          />
          <FormControlSelect
            name="merchantNetwork"
            label="Merchant Network:"
            labelClassName={classes.labelWidth}
            defaultValue={merchant.merchantNetwork}
            readOnly={readOnly}
            options={merchantNetworks}
            color={isEditing ? SelectColor.Black : SelectColor.Gray}
          />
          <FormControlSelect
            name="acceptedCards"
            label="Accepted Cards:"
            labelClassName={classes.labelWidth}
            defaultValue={defaultMerchantAcceptedCards}
            readOnly={readOnly}
            options={merchantAcceptedCards}
            isMulti
            color={isEditing ? SelectColor.Black : SelectColor.Gray}
          />
          <FormControlSelect
            name="qualifiedIssuer"
            defaultValue={merchant.qualifiedIssuer}
            label="Qualified Issuer(s):"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            disabled={isEditing}
            options={issuersOptions}
            isMulti
            color={isEditing ? SelectColor.Black : SelectColor.Gray}
          />
          <FormControlSelect
            name="category"
            defaultValue={merchant.category}
            label="Category:"
            labelClassName={classes.labelWidth}
            readOnly={readOnly}
            options={categoriesOptions}
            disabled={loadingMerchantCategories}
            color={isEditing ? SelectColor.Black : SelectColor.Gray}
          />
          <FormControl
            name="websiteURL"
            render={(props) => (
              <Input
                label="Website URL:"
                labelClassName={classes.labelWidth}
                value={merchant.websiteURL}
                type={InputType.Text}
                readOnly={readOnly}
                size={InputSize.S}
                color={isEditing ? InputColor.Black : InputColor.Gray}
                {...props}
              />
            )}
          />
          <FormControl
            name="imgUrl"
            render={(props) => (
              <InputImage
                label="Image URL:"
                labelClassName={classes.labelWidth}
                labelPosition={LabelPosition.Left}
                value={merchant.imgUrl}
                type={InputType.Text}
                readOnly={readOnly}
                size={InputSize.S}
                color={isEditing ? InputColor.Black : InputColor.Gray}
                {...props}
              />
            )}
          />
          <FormControl
            name="bannerImgUrl"
            render={(props) => (
              <InputImage
                label="Banner Image URL:"
                labelClassName={classes.labelWidth}
                labelPosition={LabelPosition.Left}
                value={merchant.bannerImgUrl}
                type={InputType.Text}
                readOnly={readOnly}
                size={InputSize.S}
                color={isEditing ? InputColor.Black : InputColor.Gray}
                {...props}
              />
            )}
          />
          <FormControl
            name="fraudWarningAmountInCents"
            className={classes.formControl}
            render={(props) => (
              <Input
                label="Max Allowable Transaction Amount In Cents for Notification"
                labelClassName={classes.labelWidth}
                value={merchant.fraudWarningAmountInCents}
                type={InputType.Number}
                minNumber={0}
                size={InputSize.S}
                readOnly={readOnly}
                color={isEditing ? InputColor.Black : InputColor.Gray}
                {...props}
              />
            )}
          />
          <Checkbox
            label="Manual Review"
            setSelected={() => onChangeCheckboxStateHandler(setReviewMatches)}
            selected={reviewMatches}
            className={classes.checkbox}
            kind={isEditing ? CheckboxKind.Primary : CheckboxKind.Secondary}
            size={isCreating ? CheckboxSize.XS : CheckboxSize.S}
          />
          <div className={classes.associatedNamesContainer}>
            <div className={classes.associatedNames}>
              <Body
                size={BodySize.S}
                className={classes.labelText}
                color={BodyColor.Gray}
              >
                Matching Regular Expressions
              </Body>
              {!readOnly && (
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  className={classes.addNameButton}
                  onClick={onClickAddAssociatedNameHandler}
                >
                  <div className={classes.addIcon}>
                    <img src={addIcon} alt="add" />
                  </div>
                </Button>
              )}
            </div>
            {!isCreating &&
              associatedNames.length === 0 &&
              !addNewAssociatedName && (
                <Body
                  size={BodySize.XS}
                  color={BodyColor.Gray}
                  className={classes.nameItemText}
                >
                  No names created
                </Body>
              )}
            {associatedNames.map((name, nameIndex) => (
              <div
                key={`opt ${nameIndex.toString()}`}
                className={classNames(classes.nameItem, {
                  [classes.lastItem]:
                    nameIndex === associatedNames.length - 1 &&
                    addNewAssociatedName,
                })}
              >
                <Body
                  size={BodySize.XS}
                  color={isEditing ? BodyColor.Black : BodyColor.Gray}
                  className={classes.nameItemText}
                >
                  {name}
                </Body>
                {!readOnly && (
                  <button
                    type="button"
                    className={classes.removeNameIcon}
                    onClick={() => onDeleteAssociatedNameHandler(nameIndex)}
                  >
                    <img src={closeIcon} alt="remove" />
                  </button>
                )}
              </div>
            ))}
            {addNewAssociatedName && (
              <>
                <Input
                  name="associatedName"
                  value={newAssociatedNameValue}
                  onChange={(event) =>
                    setNewAssociatedNameValue(event.target.value)
                  }
                  placeholder="New associated name"
                  type={InputType.Text}
                  size={InputSize.S}
                  className={classes.associatedNameInput}
                />
                <div className={classes.addAssociatedNameActions}>
                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    onClick={onCancelAddAssociatedNameHandler}
                    kind={ButtonKind.Secondary}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    className={classes.submitButton}
                    onClick={onAddNewAssociatedNameHandler}
                    disabled={newAssociatedNameValue === ''}
                  >
                    Add
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className={classes.associatedNamesContainer}>
            <div className={classes.associatedNames}>
              <Body
                size={BodySize.S}
                className={classes.labelText}
                color={BodyColor.Gray}
              >
                Excluded Regular Expressions
              </Body>
              {!readOnly && (
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  className={classes.addNameButton}
                  onClick={onClickAddExcludedNameHandler}
                >
                  <div className={classes.addIcon}>
                    <img src={addIcon} alt="add" />
                  </div>
                </Button>
              )}
            </div>
            {!isCreating && excludedNames.length === 0 && !addNewExcludedName && (
              <Body
                size={BodySize.XS}
                color={BodyColor.Gray}
                className={classes.nameItemText}
              >
                No names created
              </Body>
            )}
            {excludedNames.map((name, nameIndex) => (
              <div
                key={`opt ${nameIndex.toString()}`}
                className={classNames(classes.nameItem, {
                  [classes.lastItem]:
                    nameIndex === excludedNames.length - 1 &&
                    addNewExcludedName,
                })}
              >
                <Body
                  size={BodySize.XS}
                  color={isEditing ? BodyColor.Black : BodyColor.Gray}
                  className={classes.nameItemText}
                >
                  {name}
                </Body>
                {!readOnly && (
                  <button
                    type="button"
                    className={classes.removeNameIcon}
                    onClick={() => onDeleteExcludedNameHandler(nameIndex)}
                  >
                    <img src={closeIcon} alt="remove" />
                  </button>
                )}
              </div>
            ))}
            {addNewExcludedName && (
              <>
                <Input
                  name="excludedName"
                  value={newExcludedNameValue}
                  onChange={(event) =>
                    setNewExcludedNameValue(event.target.value)
                  }
                  placeholder="New excluded name"
                  type={InputType.Text}
                  size={InputSize.S}
                  className={classes.associatedNameInput}
                />
                <div className={classes.addAssociatedNameActions}>
                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    onClick={onCancelAddExcludedNameHandler}
                    kind={ButtonKind.Secondary}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    className={classes.submitButton}
                    onClick={onAddNewExcludedNameHandler}
                    disabled={newExcludedNameValue === ''}
                  >
                    Add
                  </Button>
                </div>
              </>
            )}
          </div>
          <Checkbox
            label="Real Time Match"
            setSelected={() => onChangeCheckboxStateHandler(setRealTimeMatch)}
            selected={realTimeMatch}
            className={classes.checkbox}
            kind={isEditing ? CheckboxKind.Primary : CheckboxKind.Secondary}
            size={isCreating ? CheckboxSize.XS : CheckboxSize.S}
          />
          {isCreating && (
            <div className={classes.divider}>
              <Body size={BodySize.XS} color={BodyColor.Gray}>
                Offers and locations can be added once the merchant is created.
              </Body>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                className={classes.addButtons}
                disabled
              >
                <div className={classes.buttonsContent}>
                  <div className={classes.buttonAddIcon}>
                    <img src={addIcon} alt="add" />
                  </div>
                  Add offer
                </div>
              </Button>
              <Button
                type={ButtonType.Default}
                size={ButtonSize.S}
                className={classes.addButtons}
                disabled
              >
                <div className={classes.buttonsContent}>
                  <div className={classes.buttonAddIcon}>
                    <img src={addIcon} alt="add" />
                  </div>
                  Add locations
                </div>
              </Button>
            </div>
          )}
          <div className={classes.actionButtons}>
            <div className={classes.locationsAndOffers}>
              {!isCreating && (
                <>
                  <button
                    type="button"
                    className={classes.icon}
                    onClick={onSeeMerchantLocationsHandler}
                  >
                    <img src={mapIcon} alt="Locations" />
                  </button>
                  <button
                    type="button"
                    className={classes.icon}
                    onClick={onSeeMerchantOffersHandler}
                  >
                    <img src={offerIcon} alt="Offers" />
                  </button>
                </>
              )}
            </div>
            {readOnly && (
              <div>
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  kind={ButtonKind.Secondary}
                  onClick={onCancel}
                  className={classes.back}
                >
                  Back
                </Button>
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              </div>
            )}
            {(isEditing || isCreating) && (
              <div>
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  onClick={onCancel}
                  kind={ButtonKind.Secondary}
                >
                  Cancel
                </Button>
                <Button
                  type={ButtonType.Submit}
                  size={ButtonSize.S}
                  className={classes.submitButton}
                  loading={loadingCreate || loadingEdit}
                >
                  {isCreating ? 'Create' : 'Save'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

MerchantForm.propTypes = {
  title: PropTypes.string.isRequired,
  merchant: merchantPropTypes,
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
};

MerchantForm.defaultProps = {
  merchant: {},
  isCreating: false,
  onCancel: () => {},
};

export default MerchantForm;
