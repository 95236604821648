import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import failIcon from 'assets/icons/color/boolean-false.svg';
import selectExportDataState from 'state/selectors/exportData';
import Button, { Type as ButtonType } from 'components/Common/Button';
import { closeExportBanner, exportData } from 'state/actions/exportData';
import getIdToken from 'utils/getIdToken';

import classes from './ExportFailed.module.scss';

const ExportFailed = () => {
  const dispatch = useDispatch();

  const { model, filters } = useSelector(selectExportDataState, shallowEqual);

  const onCloseButton = () => dispatch(closeExportBanner());

  const onTryAgainButton = async () => {
    const idToken = await getIdToken();

    const exportInfo = {
      idToken,
      filters,
      model,
    };

    dispatch(exportData(exportInfo));
  };

  return (
    <div className={classes.exportFailedContainer}>
      <div className={classes.exportFailed}>
        <img
          src={failIcon}
          className={classes.exportFailedIcon}
          alt="export failed"
        />
        <div className={classes.exportText}>
          <span className={classes.principalText}>
            {model}s export has failed
          </span>
        </div>
      </div>
      <div>
        <Button
          type={ButtonType.Default}
          className={classes.closeButton}
          onClick={onCloseButton}
          kind="minimal"
        >
          Close
        </Button>
        <Button
          type={ButtonType.Default}
          className={classes.tryAgainButton}
          onClick={onTryAgainButton}
          kind="minimal"
        >
          Try again
        </Button>
      </div>
    </div>
  );
};

export default ExportFailed;
