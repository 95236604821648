import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Link from 'components/Typography/Link';

import { shallowEqual, useSelector } from 'react-redux';
import { selectSidebarExpandedState } from 'state/selectors/layout';
import classes from './Item.module.scss';

const Item = ({ title, logo, to, selected }) => {
  const sidebarExpanded = useSelector(selectSidebarExpandedState, shallowEqual);

  return (
    <Link to={to} underline={false} contents>
      <div
        className={classNames(classes.item, { [classes.selected]: selected })}
        data-tip={!sidebarExpanded ? title : ''}
        data-for="sidebarTip"
        data-testid="item-wrapper"
      >
        <img src={logo} alt="icon" className={classes.icon} />
        <Body
          size={BodySize.S}
          color={BodyColor.Gray}
          className={classes.title}
        >
          {title}
        </Body>
      </div>
    </Link>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Item;
