import { RoleLabels } from 'enums/users/roles.enum';

/**
 * Get the corresponding role label which uniquely represents a role.
 * @param {String} roleId an enabled role (in RoleLabels keys).
 * @returns {String} the corresponding role label for this role.
 */
export function getRoleLabelForId(roleId) {
  return RoleLabels[roleId];
}

/**
 * Get the corresponding role ID for a role label.
 * @param {String} roleLabel the label for an enabled role (in RoleLabels values).
 * @returns {String} the corresponding role ID for this role.
 */
export function getRoleIdForLabel(roleLabel) {
  return Object.entries(RoleLabels).find(
    ([, value]) => value === roleLabel
  )?.[0];
}

/**
 * Formatted RoleLabels for use in a <FormControlSelect>
 */
export const RoleOptionsForFormControlSelect = Object.freeze(
  Object.entries(RoleLabels).map(([value, label]) => ({
    label,
    value,
  }))
);
