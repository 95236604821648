import client from 'services/kardAPI';

const createMerchant = async (body) => {
  try {
    const merchant = await client.post(`/rewards/portal/merchant`, body);
    return merchant.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createMerchant;
