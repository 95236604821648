import client from 'services/kardAPI';

const fetchAudienceById = async (id) => {
  try {
    const { data } = await client.get(`/audience/portal/audience/${id}`);

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchAudienceById;
