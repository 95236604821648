import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Status.module.scss';

export const Types = Object.freeze({
  active: 'Active',
  inactive: 'Inactive',
  closed: 'Closed',
});

const Status = ({ kind, className }) => (
  /**
   * dev notes: i'm using nested span because first span is using pseudo-class ::before
   * which displays a dot (for style proposes only) and needed to move label to the right
   */
  <span className={classNames(classes.status, classes[kind], className)}>
    <span title={Types[kind]} className={classes.label}>
      {Types[kind]}
    </span>
  </span>
);

Status.propTypes = {
  kind: PropTypes.oneOf(Object.keys(Types)),
  className: PropTypes.string,
};

Status.defaultProps = {
  kind: 'active',
  className: '',
};

export default Status;
