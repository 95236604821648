import React from 'react';

import informationIcon from 'assets/icons/color/information.svg';
import FilterTypes from 'enums/reports/filterTypes.enum';
import Groups from 'enums/users/groups.enum';
import dateParse from 'utils/common/dateParse';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'ID',
        accessor: 'audienceId',
        columnName: 'id',
        sortable: false,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Name',
        accessor: 'name',
        columnName: 'name',
        sortable: false,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.String,
          options: ['ACTIVE', 'INACTIVE', 'DRAFT', 'IN_PROGRESS'],
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Size',
        accessor: 'audienceSize',
        columnName: 'audienceSize',
        sortable: true,
        filterable: false,
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Create Date',
        accessor: 'createdDate',
        columnName: 'createdDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'More info',
        accessor: 'moreInfo',
        columnName: 'moreInfo',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={informationIcon} alt="More information" />,
        allowedRoles: Groups.Administrators,
      },
    ],
  },
];

export default columns;
