const getReportsFilters = ({
  resetPagination = false,
  pageIndex = 0,
  pageLimit = 10,
  setPagination,
  transactionId = null,
  transactionTimeStamp = '',
  setTransactionTimeStamp = () => {},
  selected = [],
  selectedFilters = [],
  setSelectedFilters = () => {},
  columnName = '',
  dates = {},
  range = {},
  sortValue = false,
  sortBy = {},
  setSortBy = () => {},
  isDateFilter = false,
  setDateFilter = null,
}) => {
  const filtersQuery = new URLSearchParams();

  if (transactionId) {
    filtersQuery.append('transactionId', transactionId);
  }

  let newSelectedValue = selected;
  if (dates.startDate) {
    newSelectedValue = dates;
  }

  if (range.start) {
    newSelectedValue = range;
  }

  // This is true if the filter is being applied from the transaction date column in the table
  const isTransactionTimeStampColumnFilter =
    columnName === 'transactionTimeStamp' &&
    Object.keys(newSelectedValue).length === 0;

  const filters = {
    transactionTimeStamp: { ...transactionTimeStamp, isDate: true },
    ...selectedFilters,
    ...(!isTransactionTimeStampColumnFilter &&
      columnName && {
        [columnName]: newSelectedValue,
      }),
  };

  Object.entries(filters).forEach(([key, value]) => {
    const selectedValues = [];

    if (value.isDate) {
      if (
        (key !== columnName && value.startDate) ||
        (key === columnName && dates.startDate && dates.endDate) ||
        (key === 'transactionTimeStamp' && value.startDate)
      ) {
        const start = value.startDate.format();
        const end = value.endDate.format();

        filtersQuery.append(`${key}`, true);
        filtersQuery.append(`${key}Start`, start);
        filtersQuery.append(`${key}End`, end);
      }
    } else if (value.isRange) {
      if (
        key !== columnName ||
        (key === columnName && range.start && range.end)
      ) {
        filtersQuery.append(`${key}`, true);
        filtersQuery.append(`${key}Start`, value.start);
        filtersQuery.append(`${key}End`, value.end);
      }
    } else if (value) {
      value.forEach((filterValue) => {
        let { text } = filterValue;
        if (filterValue.columnName === 'receivedPaymentStatus') {
          text = text.replace(/\s/g, '_');
        } else if (text === 'TRUE' || text === 'FALSE') {
          text = text.toLowerCase();
        }
        selectedValues.push(text);
      });
      if (selectedValues.length > 0) {
        const transformedValues = selectedValues.join(',');
        filtersQuery.append(`${key}`, transformedValues);
      }
    }
  });

  if (sortValue.ascending || sortValue.descending) {
    filtersQuery.append('sortBy', sortValue.columnName);
    const order = sortValue.ascending ? 'asc' : 'desc';
    filtersQuery.append('sortOrder', order);
    setSortBy({
      ascending: sortValue.ascending,
      descending: sortValue.descending,
      columnName,
    });
  } else if (
    (!sortValue.ascending || !sortValue.descending) &&
    sortValue.columnName === sortBy.columnName
  ) {
    setSortBy({
      ascending: false,
      descending: false,
      columnName: null,
    });
  } else if (sortBy.ascending || sortBy.descending) {
    filtersQuery.append('sortBy', sortBy.columnName);
    const order = sortBy.ascending ? 'asc' : 'desc';
    filtersQuery.append('sortOrder', order);
  }

  if (isDateFilter) {
    if (setDateFilter) {
      setDateFilter({ value: dates.value, unit: dates.unit });
    }
    setTransactionTimeStamp({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
  } else {
    setSelectedFilters((prevState) => {
      const newState = { ...prevState };
      if (dates && dates.startDate && dates.endDate) {
        newState[columnName] = { ...dates, isDate: true };
      } else if (range && range.start && range.end) {
        newState[columnName] = { ...range, isRange: true };
      } else if (selected.length > 0) {
        newState[columnName] = selected;
      } else {
        delete newState[columnName];
      }
      return newState;
    });
  }

  if (resetPagination) {
    filtersQuery.append('page', 0);
    filtersQuery.append('limit', pageLimit);
    setPagination({
      page: 0,
      limit: pageLimit,
    });
  } else {
    filtersQuery.append('page', pageIndex);
    filtersQuery.append('limit', pageLimit);
    setPagination({
      page: pageIndex,
      limit: pageLimit,
    });
  }

  return `?${filtersQuery.toString()}`;
};

export default getReportsFilters;
