import client from 'services/kardAPI';

const uploadLocations = async (file) => {
  try {
    await client.put(`/rewards/portal/merchant/locations`, file);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default uploadLocations;
