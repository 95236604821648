import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  source: yup.string().required().nullable(),
  locationType: yup.string().required(),
  street: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  city: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  state: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  zipCode: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  latitude: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  longitude: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  monday: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  tuesday: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  wednesday: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  thursday: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  friday: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  saturday: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  sunday: yup.string().when('locationType', {
    is: (locationType) => locationType === 'INSTORE',
    then: yup.string().required(),
  }),
  googleId: yup.string(),
  phone: yup.string(),
});

export default validationSchema;
