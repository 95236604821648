import React from 'react';
import PropTypes from 'prop-types';

import kardLogo from 'assets/logos/white.svg';
import classes from './LoginLayout.module.scss';

const LoginLayout = ({ children }) => (
  <main className={classes.main}>
    <div className={classes.left}>
      <img src={kardLogo} alt="Kard Financial Logo" />
    </div>
    <div className={classes.right}>
      <div className={classes.form}>{children}</div>
    </div>
  </main>
);

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
