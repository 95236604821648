import React from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/Typography/ErrorMessage';

import classes from './FormControl.module.scss';

const FormControl = ({
  name,
  validations,
  render,
  className,
  noErrorMessage,
}) => {
  const { register, errors } = useFormContext();
  const error = errors[name];

  return (
    <div
      className={classNames(classes.formControl, className, {
        [classes.formControlWithError]: error,
      })}
    >
      {render({
        name,
        register: register(validations),
        error: !!error?.message,
      })}
      {!noErrorMessage && error && (
        <ErrorMessage className={classes.formControlErrorMessage}>
          {error.message}
        </ErrorMessage>
      )}
    </div>
  );
};

FormControl.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  render: PropTypes.any.isRequired,
  validations: PropTypes.shape({}),
  className: PropTypes.string,
  noErrorMessage: PropTypes.bool,
};

FormControl.defaultProps = {
  validations: {},
  className: '',
  noErrorMessage: false,
};

export default FormControl;
