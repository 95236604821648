import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';

const emailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email(Validations.InvalidEmail)
    .required(Validations.RequiredEmail),
});

export default emailValidationSchema;
