/* eslint-disable no-await-in-loop */
import {
  fetchMerchantByIdInit,
  fetchMerchantByIdSuccess,
  fetchMerchantByIdFail,
  fetchMerchantsFromCacheInit,
  fetchMerchantsFromCacheFail,
  fetchMerchantsFromCacheSuccess,
  fetchMerchantsInit,
  fetchMerchantsSuccess,
  fetchMerchantsFail,
  createMerchantInit,
  createMerchantSuccess,
  createMerchantFail,
  editMerchantInit,
  editMerchantSuccess,
  editMerchantFail,
  clearCreateMerchantData,
  clearMerchantsErrorsData,
  fetchMerchantCategoriesInit,
  fetchMerchantCategoriesSuccess,
  fetchMerchantCategoriesFail,
  fetchMerchantIssuersInit,
  fetchMerchantIssuersSuccess,
  fetchMerchantIssuersFail,
} from 'state/actionCreators/merchants';

import fetchMerchantByIdService from 'services/merchants/fetchMerchantById';
import fetchMerchantsService from 'services/merchants/fetchMerchants';
import editMerchantService from 'services/merchants/editMerchant';
import createMerchantService from 'services/merchants/createMerchant';
import fetchMerchantCategoriesService from 'services/merchants/fetchMerchantCategories';
import fetchMerchantIssuersService from 'services/merchants/fetchMerchantIssuers';

const HOURS_SINCE_LAST_UPDATE = 12;
const MERCHANTS_PER_PAGE = 500;

export const fetchMerchantById = (merchantId) => async (dispatch) => {
  dispatch(fetchMerchantByIdInit());

  try {
    const merchant = await fetchMerchantByIdService(merchantId);
    return dispatch(fetchMerchantByIdSuccess({ merchant }));
  } catch (error) {
    return dispatch(fetchMerchantByIdFail({ error: error.message }));
  }
};

export const fetchMerchants = (filters = '') => async (dispatch) => {
  dispatch(fetchMerchantsInit());

  try {
    const merchants = await fetchMerchantsService(filters);
    return dispatch(fetchMerchantsSuccess({ merchants }));
  } catch (error) {
    return dispatch(fetchMerchantsFail({ error: error.message }));
  }
};

export const fetchMerchantsFromCache = () => async (dispatch, getState) => {
  dispatch(fetchMerchantsFromCacheInit());
  const { cachedMerchants, lastUpdated } = getState().merchants;

  try {
    const data = {
      merchants: [],
      lastUpdated: null,
    };

    // fetch merchants if nothing is cache OR lastUpdated is older than now - X hours
    const now = new Date();
    const timeXHoursAgo = new Date(
      now.getTime() - HOURS_SINCE_LAST_UPDATE * 3600000
    );
    if (
      !lastUpdated ||
      !(lastUpdated instanceof Date) ||
      lastUpdated.getTime() <= timeXHoursAgo.getTime()
    ) {
      // obtain merchants, one page at a time
      let numberOfReturnedMerchants = 0;
      let page = 0;
      do {
        const { results } = await fetchMerchantsService(
          `?limit=${MERCHANTS_PER_PAGE}&page=${page}`
        );
        numberOfReturnedMerchants = results.length;
        page += 1;

        // add results to data to cache
        data.merchants.push(
          ...results.map(({ _id, name, source }) => ({
            _id,
            name,
            source,
          }))
        );
      } while (numberOfReturnedMerchants === MERCHANTS_PER_PAGE);

      // update lastUpdated
      data.lastUpdated = new Date();
    } else {
      data.merchants = cachedMerchants;
      data.lastUpdated = lastUpdated;
    }

    return dispatch(fetchMerchantsFromCacheSuccess(data));
  } catch (error) {
    return dispatch(fetchMerchantsFromCacheFail({ error: error.message }));
  }
};

export const createMerchant = (body) => async (dispatch) => {
  dispatch(createMerchantInit());

  try {
    const merchant = await createMerchantService(body);
    return dispatch(createMerchantSuccess({ merchant }));
  } catch (error) {
    return dispatch(createMerchantFail({ error: error.message }));
  }
};

export const clearCreateMerchant = () => async (dispatch) => {
  dispatch(clearCreateMerchantData());
};

export const editMerchant = (id, body) => async (dispatch) => {
  dispatch(editMerchantInit());

  try {
    await editMerchantService(id, body);
    return dispatch(editMerchantSuccess());
  } catch (error) {
    return dispatch(editMerchantFail({ error: error.message }));
  }
};

export const clearMerchantsErrors = () => async (dispatch) => {
  dispatch(clearMerchantsErrorsData());
};

export const fetchMerchantCategories = () => async (dispatch) => {
  dispatch(fetchMerchantCategoriesInit());

  try {
    const categories = await fetchMerchantCategoriesService();
    return dispatch(fetchMerchantCategoriesSuccess({ categories }));
  } catch (error) {
    return dispatch(fetchMerchantCategoriesFail({ error: error.message }));
  }
};

export const fetchMerchantIssuers = () => async (dispatch) => {
  dispatch(fetchMerchantIssuersInit());

  try {
    const issuers = await fetchMerchantIssuersService();
    return dispatch(fetchMerchantIssuersSuccess({ issuers }));
  } catch (error) {
    return dispatch(fetchMerchantIssuersFail({ error: error.message }));
  }
};
