import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import msg from 'utils/commonMessages';
import Table from 'components/Common/Table';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import RangeDatePicker from 'components/Common/RangeDatePicker';
import Form from 'components/Common/Form';
import FormControlSelect from 'components/Common/FormControlSelect';
import MerchantsSelect from 'components/Pages/MerchantsSelect';
import ConfirmationMessage from 'components/Common/ConfirmationMessage';
import Modal from 'components/Common/Modal';
import TransactionData from 'components/Pages/TransactionData';
import {
  selectEditPendingTransactionState,
  selectFetchPendingTransactionsState,
  selectReprocessIncomingTransactionState,
  selectReprocessPendingTransactionState,
} from 'state/selectors/pendingTransactions';
import {
  clearReprocessIncomingTransactionStateData,
  editPendingTransaction,
  fetchPendingTransactions,
  reprocessPendingTransaction,
  reprocessIncomingTransaction,
} from 'state/actions/pendingTransactions';
import commonToast from 'components/Common/commonToast';
import { selectFetchMerchantsFromCacheState } from 'state/selectors/merchants';
import { fetchMerchantsFromCache } from 'state/actions/merchants';
import { selectFetchIssuersState } from 'state/selectors/issuers';
import { fetchIssuers } from 'state/actions/issuers';
import {
  clearLocationsByMerchantId,
  fetchLocationsByMerchantId,
} from 'state/actions/locations';
import { selectFetchLocationsByMerchantIdState } from 'state/selectors/locations';
import { columns } from 'utils/pendingTransactions/columns';
import {
  ALL_ISSUERS,
  generateGetPendingTransactionsQueryString,
} from 'utils/filters/pendingTransactionsFilters';
import {
  ReviewStatusOptions,
  ReviewStatusValues,
} from 'utils/pendingTransactions/reviewStatus';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType';
import Columns from 'enums/table/columns.enum';

import PendingTransactionReprocess from 'components/Pages/PendingTransactionsReprocess';
import {
  TransactionStatusOptions,
  TRANSACTION_STATUSES,
} from 'utils/pendingTransactions/transactionStatus';
import classes from './PendingTransactions.module.scss';

/** If you need to reset a record, set to this - otherwise React gets caught in a loop of comparing empty objects. */
const EMPTY = {};

/**
 * This array is used to hide columns as needed.
 * Provide as many columns ids as you want to hide.
 */
const PendingTransactions = () => {
  const dispatch = useDispatch();

  const {
    result: pendingTransactions,
    count: pendingTransactionCount,
    loading,
    error,
  } = useSelector(selectFetchPendingTransactionsState, shallowEqual);

  const { merchants } = useSelector(
    selectFetchMerchantsFromCacheState,
    shallowEqual
  );

  const { issuers } = useSelector(selectFetchIssuersState, shallowEqual);

  const issuerOptions = [
    {
      label: 'ALL',
      value: ALL_ISSUERS,
    },
    ...issuers.map(({ issuerName, issuerId }) => ({
      label: issuerName,
      value: issuerId,
    })),
  ];

  const { locations, loading: loadingLocations } = useSelector(
    selectFetchLocationsByMerchantIdState,
    shallowEqual
  );

  const {
    success: successEditTransaction,
    error: errorEditTransaction,
  } = useSelector(selectEditPendingTransactionState, shallowEqual);

  const {
    loading: reprocessTransactionLoading,
    success: reprocessTransactionSuccess,
    error: reprocessTransactionError,
  } = useSelector(selectReprocessIncomingTransactionState, shallowEqual);

  const {
    success: reprocessPendingTransactionSuccess,
    reprocessPendingTxns,
  } = useSelector(selectReprocessPendingTransactionState, shallowEqual);

  const [reviewStatus, setReviewStatus] = useState('OPEN');
  const [transactionStatus, setTransactionStatus] = useState(
    TRANSACTION_STATUSES.ALL
  );
  const [selectedIssuer, setSelectedIssuer] = useState(ALL_ISSUERS);
  const [startDate, setStartDate] = useState(moment('2023-01-01'));
  const [endDate, setEndDate] = useState(moment());
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [selectedMerchant, setSelectedMerchant] = useState(EMPTY);
  const [selectedMerchantRow, setSelectedMerchantRow] = useState(null);
  const [merchantOptions, setMerchantOptions] = useState(EMPTY);
  const [locationOptions, setLocationOptions] = useState(EMPTY);
  const [selectedLocation, setSelectedLocation] = useState(EMPTY);
  const [submitError, setSubmitError] = useState(false);
  const [isApprovedStatus, setIsApprovedStatus] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [columnsToHide, setColumnsToHide] = useState([]);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const numberOfPages = Math.ceil(
    parseInt(pendingTransactionCount, 10) / parseInt(limit, 10)
  );

  useEffect(() => {
    dispatch(fetchMerchantsFromCache());
    dispatch(fetchIssuers());
  }, [dispatch]);

  // this will call itself in the following useEffect block if any filters change.
  const fetchPage = useCallback(
    (somePage) => {
      const filters = generateGetPendingTransactionsQueryString({
        page: somePage,
        limit,
        startDate,
        endDate,
        reviewStatus,
        transactionStatus,
        issuer: selectedIssuer,
      });
      dispatch(fetchPendingTransactions(filters));
      setPage(somePage);
    },
    [
      dispatch,
      endDate,
      limit,
      reviewStatus,
      selectedIssuer,
      startDate,
      transactionStatus,
    ]
  );

  useEffect(
    () => {
      // clear all merchant and location options and selections
      setMerchantOptions(EMPTY);
      setLocationOptions(EMPTY);
      setSelectedMerchant(EMPTY);
      setSelectedLocation(EMPTY);
      setSelectedMerchantRow(null);

      // reset pagination
      fetchPage(0);
    },
    // this gets triggered when the fetchPage callback updates, and fetchPage is dependent on all the filters.
    [fetchPage]
  );

  // upon successful transaction review, refresh the data but stay on the same page
  useEffect(() => {
    if (successEditTransaction) {
      onCloseModalHandler();
      fetchPage(page);

      // clear all merchant and location options and selections
      setMerchantOptions(EMPTY);
      setLocationOptions(EMPTY);
      setSelectedMerchant(EMPTY);
      setSelectedLocation(EMPTY);
      setSelectedMerchantRow(null);
    }
  }, [onCloseModalHandler, successEditTransaction, fetchPage, page]);

  useEffect(() => {
    if (pendingTransactions.length > 0 && merchants.length > 0) {
      const newMerchantOptions = {};
      pendingTransactions.forEach((transaction, transactionIndex) => {
        const { matchedMerchantIds } = transaction;
        const optionsList = [];
        const matchedOptions = [];
        matchedMerchantIds.forEach((merchantId) => {
          const merchantData = merchants.find(
            (merchant) => merchant._id === merchantId
          );
          if (merchantData) {
            matchedOptions.push({
              label: merchantData.name,
              value: merchantData,
            });
          }
        });
        optionsList.push(
          {
            label: 'Matched merchants',
            options: matchedOptions,
          },
          {
            label: 'Other',
            options: [{ label: 'All Other Merchants..', value: 'other' }],
          }
        );
        newMerchantOptions[transactionIndex] = optionsList;
      });

      setMerchantOptions((prevState) => ({
        ...prevState,
        ...newMerchantOptions,
      }));
    }
  }, [pendingTransactions, merchants]);

  useEffect(() => {
    if (locations.length > 0 && selectedMerchantRow !== null) {
      const options = locations.map((location) => ({
        label: !location.address
          ? 'No address'
          : `${location.address?.street} - ${location.address?.city} - ${location.address?.state}`,
        value: location,
      }));
      setLocationOptions((prevState) => ({
        ...prevState,
        [selectedMerchantRow]: options,
      }));
      setSelectedMerchantRow(null);
      dispatch(clearLocationsByMerchantId());
    } else if (locations.length === 0 && selectedMerchantRow !== null) {
      setLocationOptions((prevState) => ({
        ...prevState,
        [selectedMerchantRow]: [],
      }));
    }
  }, [locations, selectedMerchantRow, dispatch]);

  useEffect(() => {
    if (reprocessTransactionError || reprocessTransactionSuccess) {
      onCloseModalHandler();
      dispatch(clearReprocessIncomingTransactionStateData());
    }
  }, [
    reprocessTransactionError,
    reprocessTransactionSuccess,
    dispatch,
    onCloseModalHandler,
  ]);

  useEffect(() => {
    const hideAcceptAndRejectButtons =
      reviewStatus === ReviewStatusValues.Accepted ||
      reviewStatus === ReviewStatusValues.Rejected;

    const hiddenColumns = hideAcceptAndRejectButtons
      ? ['accept', 'reject']
      : [];

    setColumnsToHide(hiddenColumns);
  }, [reviewStatus]);

  const onChangeSelectedMerchantHandler = useCallback(
    (index, newValue) => {
      setSubmitError(false);
      if (newValue === 'other') {
        setSelectedMerchantRow(index);
        onOpenModalHandler(ModalType.ALL_MERCHANTS_SELECT);
      } else {
        setSelectedMerchant((prevState) => ({
          ...prevState,
          [index]: newValue,
        }));

        if (newValue.source === 'LOCAL') {
          setSelectedMerchantRow(index);
          dispatch(fetchLocationsByMerchantId(newValue._id));
          setSelectedLocation((prevState) => ({ ...prevState, [index]: null }));
        } else {
          setSelectedLocation((prevState) => ({
            ...prevState,
            [index]: 'N/A',
          }));
        }
      }
    },
    [dispatch, onOpenModalHandler]
  );

  const onSelectOtherMerchantHandler = useCallback(
    (merchant) => {
      setSubmitError(false);
      const selectedMerchantInfo = {
        name: merchant.name,
        _id: merchant._id,
        source: merchant.source,
      };
      setMerchantOptions((prevState) => {
        const optionsList = [];
        const row = selectedMerchantRow || 0;
        const previousOptions = [...prevState[row]];
        const matchedOptions = previousOptions[0].options;
        const otherOptions = previousOptions[1].options;

        // remove 'All Other Merchants'
        otherOptions.pop();

        otherOptions.push(
          {
            label: selectedMerchantInfo.name,
            value: selectedMerchantInfo,
          },
          { label: 'All Other Merchants..', value: 'other' }
        );

        optionsList.push(
          {
            label: 'Matched merchants',
            options: matchedOptions,
          },
          {
            label: 'Other',
            options: otherOptions,
          }
        );
        return { ...prevState, [selectedMerchantRow]: optionsList };
      });

      onCloseModalHandler();

      onChangeSelectedMerchantHandler(
        selectedMerchantRow || 0,
        selectedMerchantInfo
      );
    },
    [onCloseModalHandler, onChangeSelectedMerchantHandler, selectedMerchantRow]
  );

  const onChangeSelectedLocationHandler = useCallback((index, newValue) => {
    setSubmitError(false);
    setSelectedLocation((prevState) => ({
      ...prevState,
      [index]: newValue,
    }));
  }, []);

  const onChangeDateRange = ({ startDate: start, endDate: end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  const onChangeManualPaginationHandler = ({ pageSize, pageIndex }) => {
    setSelectedMerchant({});
    setSelectedLocation({});
    setLimit(pageSize);
    fetchPage(pageIndex);
  };

  const onEditTransactionHandler = useCallback(
    (transactions) => {
      const { transaction, isApproved, index } = modal;

      const body = {
        reviewStatus: isApproved ? 'APPROVED' : 'REJECTED',
        pendingTransactionId: transaction._id,
        pendingTransactionIds:
          transactions &&
          transactions.length > 0 &&
          transactions.length !== undefined
            ? transactions
            : [transaction._id],
      };

      if (isApproved) {
        body.matchedMerchantId = selectedMerchant[index]._id;
        body.source = selectedMerchant[index].source;
        if (selectedMerchant[index].source === 'LOCAL') {
          body.locationId = selectedLocation[index]._id;
        }
      }
      dispatch(editPendingTransaction(body));
    },
    [modal, dispatch, selectedLocation, selectedMerchant]
  );

  const viewNewData = useCallback(
    async (transaction, isApproved, index) => {
      const body = {
        pendingTransactionId: transaction._id,
      };
      body.source = isApproved ? selectedMerchant[index].source : 'LOCAL';
      dispatch(reprocessPendingTransaction(body));
      onOpenModalHandler(
        isApproved
          ? ModalType.ACCEPT_TRANSACTION
          : ModalType.REJECT_TRANSACTION,
        {
          transaction,
          isApproved,
          index,
        }
      );
      setIsApprovedStatus(isApproved);
    },
    [selectedMerchant, onOpenModalHandler, dispatch]
  );

  const onClickCellButtonHandler = useCallback(
    (columnName, rowIndex) => {
      if (columnName === Columns.ReprocessTransaction) {
        onOpenModalHandler(ModalType.REPROCESS_TRANSACTION, {
          transaction: pendingTransactions[rowIndex],
        });
      }
      if (columnName === Columns.MoreInfo) {
        onOpenModalHandler(ModalType.TRANSACTION_DATA, {
          transaction: pendingTransactions[rowIndex],
        });
      }
      if (columnName === Columns.Accept) {
        if (
          !selectedMerchant[rowIndex] ||
          (selectedMerchant[rowIndex].source === 'LOCAL' &&
            !selectedLocation[rowIndex])
        ) {
          setSubmitError(true);
        } else {
          viewNewData(pendingTransactions[rowIndex], true, rowIndex);
        }
      }
      if (columnName === Columns.Reject) {
        viewNewData(pendingTransactions[rowIndex], false, rowIndex);
      }
    },
    [
      onOpenModalHandler,
      pendingTransactions,
      selectedLocation,
      selectedMerchant,
      viewNewData,
    ]
  );

  const onRenderSelectHandler = useCallback(
    (columnName, index) => {
      if (columnName === Columns.CorrectMerchant) {
        return (
          <Form className={classes.form} onSubmit={() => {}}>
            <FormControlSelect
              name="correctMerchant"
              onChangeManual={(newValue) => {
                onChangeSelectedMerchantHandler(index, newValue);
              }}
              defaultValue={selectedMerchant[index] || null}
              value={selectedMerchant[index] || null}
              placeholder="Correct merchant"
              options={merchantOptions[index] || []}
              disabled={!merchantOptions[index]}
              className={classes.formControlSelect}
              maxMenuHeight={150}
            />
          </Form>
        );
      }
      if (columnName === Columns.CorrectLocation) {
        let placeholder = 'Correct location';
        let defaultValue = selectedLocation[index] || null;
        let disabled =
          (loadingLocations && selectedMerchantRow === index) ||
          !selectedMerchant[index] ||
          selectedMerchant[index] === 'other';
        let options = locationOptions[index] || [];
        if (defaultValue === 'N/A') {
          defaultValue = null;
          placeholder = 'N/A';
          disabled = true;
          options = [];
        }
        return (
          <Form className={classes.form} onSubmit={() => {}}>
            {loadingLocations && selectedMerchantRow === index ? (
              <div className={classes.alignSpinner}>
                <Spinner color={SpinnerColor.Black} size={SpinnerSize.M} />
              </div>
            ) : (
              <FormControlSelect
                name="correctLocation"
                placeholder={placeholder}
                onChangeManual={(newValue) => {
                  onChangeSelectedLocationHandler(index, newValue);
                }}
                defaultValue={defaultValue}
                options={options}
                disabled={disabled}
                className={classes.formControlSelect}
                maxMenuHeight={346}
              />
            )}
          </Form>
        );
      }
      return null;
    },
    [
      merchantOptions,
      locationOptions,
      selectedMerchant,
      loadingLocations,
      selectedLocation,
      selectedMerchantRow,
      onChangeSelectedLocationHandler,
      onChangeSelectedMerchantHandler,
    ]
  );

  const onReprocessTransaction = useCallback(() => {
    const {
      transaction: { _id: incomingTransactionId },
    } = modal;

    const body = {
      incomingTransactionId,
      realTimeMatchStatus: 'PENDING',
    };

    dispatch(reprocessIncomingTransaction(incomingTransactionId, body));
  }, [dispatch, modal]);

  return (
    <>
      {submitError && (
        <Toast
          id="submit error"
          text="You must select a merchant and location before accepting the transaction"
          type={ToastType.Error}
        />
      )}
      {error && (
        <Toast
          id="fetch pending transactions error"
          text={error}
          type={ToastType.Error}
        />
      )}
      {reprocessTransactionError && (
        <Toast
          id="reprocess incoming transaction error"
          text={reprocessTransactionError}
          type={ToastType.Error}
        />
      )}

      {(successEditTransaction || errorEditTransaction) &&
        commonToast(
          successEditTransaction,
          errorEditTransaction,
          isApprovedStatus
            ? msg.SuccessEditTransactionMsg
            : msg.RejectEditTransactionMsg,
          msg.ErrorEditTransactionMsg
        )}

      <Modal
        isOpen={modal.type === ModalType.ALL_MERCHANTS_SELECT}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <MerchantsSelect
          onCancel={onCloseModalHandler}
          onSubmit={onSelectOtherMerchantHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ACCEPT_TRANSACTION}
        onClose={onCloseModalHandler}
        className={classes.confirmationModal}
      >
        {reprocessPendingTransactionSuccess &&
          reprocessPendingTxns?.length > 1 && (
            <PendingTransactionReprocess
              isApproved
              onCancel={onCloseModalHandler}
              onSubmit={onEditTransactionHandler}
            />
          )}
        {reprocessPendingTransactionSuccess &&
          reprocessPendingTxns?.length === 1 && (
            <ConfirmationMessage
              message="Are you sure you want to approve this transaction?"
              onAccept={onEditTransactionHandler}
              onCancel={onCloseModalHandler}
            />
          )}
        {!reprocessPendingTransactionSuccess && (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        )}
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.REJECT_TRANSACTION}
        onClose={onCloseModalHandler}
        className={classes.confirmationModal}
      >
        {reprocessPendingTransactionSuccess &&
          reprocessPendingTxns?.length > 1 && (
            <PendingTransactionReprocess
              isApproved={false}
              onCancel={onCloseModalHandler}
              onSubmit={onEditTransactionHandler}
            />
          )}
        {reprocessPendingTransactionSuccess &&
          reprocessPendingTxns?.length === 1 && (
            <ConfirmationMessage
              message="Are you sure you want to reject this transaction?"
              onAccept={onEditTransactionHandler}
              onCancel={onCloseModalHandler}
            />
          )}
        {!reprocessPendingTransactionSuccess && (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        )}
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.TRANSACTION_DATA}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <TransactionData
          onCancel={onCloseModalHandler}
          transaction={modal.transaction}
          merchants={merchants}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.REPROCESS_TRANSACTION}
        onClose={onCloseModalHandler}
        className={classes.confirmationModal}
      >
        <ConfirmationMessage
          message="Are you sure you want to reprocess this transaction through the matching algorithm?"
          onAccept={onReprocessTransaction}
          onCancel={onCloseModalHandler}
          loading={reprocessTransactionLoading}
        />
      </Modal>
      <div className={classes.heading}>
        <Form className={classes.form} onSubmit={() => {}}>
          <FormControlSelect
            name="reviewStatus"
            defaultValue={reviewStatus}
            onChangeManual={setReviewStatus}
            label="Review status"
            options={ReviewStatusOptions}
            className={classes.reviewStatusSelect}
          />
          <FormControlSelect
            name="transactionStatus"
            defaultValue={transactionStatus}
            onChangeManual={setTransactionStatus}
            label="Transaction status"
            options={TransactionStatusOptions}
            className={classes.reviewStatusSelect}
          />
          <FormControlSelect
            name="issuerId"
            defaultValue={selectedIssuer}
            onChangeManual={setSelectedIssuer}
            label="Issuer"
            options={issuerOptions}
            className={classes.reviewStatusSelect}
          />
          <RangeDatePicker
            label="Start date and end date: "
            startDate={startDate}
            endDate={endDate}
            onChangeDates={onChangeDateRange}
            focusedInput={focusedInput}
            onChangeFocus={setFocusedInput}
            className={classes.datePicker}
          />
        </Form>
      </div>
      <div className={classes.table}>
        {loading ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={columns}
            data={pendingTransactions}
            pageSize={limit}
            itemsPerPage={limit}
            currentPage={page}
            manualPagination
            fetchData={onChangeManualPaginationHandler}
            onRenderSelect={onRenderSelectHandler}
            onClickCellButton={onClickCellButtonHandler}
            countTotal={numberOfPages}
            newPagination
            columnsToHide={columnsToHide}
          />
        )}
      </div>
    </>
  );
};

export default PendingTransactions;
