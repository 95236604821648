import React from 'react';

import informationIcon from 'assets/icons/color/information.svg';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Offer ID',
        accessor: 'offerId',
        columnName: 'offerId',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Offer Name',
        accessor: 'name',
        columnName: 'name',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Offer Type',
        accessor: 'offerType',
        columnName: 'offerType',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        columnName: 'startDate',
        Cell: ({ value }) => {
          if (value) {
            return new Date(value).toDateString();
          }
          return '-';
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Expiration Date',
        accessor: 'expirationDate',
        columnName: 'expirationDate',
        Cell: ({ value }) => {
          if (value) {
            return new Date(value).toDateString();
          }
          return '-';
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Offer Status',
        accessor: 'status',
        columnName: 'status',
        sortable: false,
        filterable: false,
        isToggle: true,
      },
      {
        Header: 'More info',
        accessor: 'moreInfo',
        columnName: 'moreInfo',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={informationIcon} alt="More information" />,
      },
    ],
  },
];

export default columns;
