const getOffersFilters = ({
  merchantId = null,
  resetPagination,
  pageIndex = 0,
  pageLimit = 10,
  setPagination,
  offerId = null,
  selected = [],
  selectedFilters = [],
  setSelectedFilters = () => {},
  columnName = '',
  dates = {},
  sortValue = false,
  sortBy = {},
  setSortBy = () => {},
  range = {},
}) => {
  const filtersQuery = new URLSearchParams();

  if (offerId) {
    filtersQuery.append('offerId', offerId);
  }

  if (merchantId) {
    filtersQuery.append('merchantId', merchantId);
  }

  let newSelectedValue = selected;
  if (dates.startDate) {
    newSelectedValue = dates;
  }

  if (range.start || range.end) {
    newSelectedValue = range;
  }

  Object.entries({
    ...selectedFilters,
    [columnName]: newSelectedValue,
  }).forEach(([key, value]) => {
    const selectedValues = [];
    if (value.isDate) {
      if (
        (key !== columnName && value.startDate) ||
        (key === columnName && dates.startDate && dates.endDate)
      ) {
        const start = value.startDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        const end = value.endDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

        filtersQuery.append(`${key}`, true);
        filtersQuery.append(`${key}Start`, start);
        filtersQuery.append(`${key}End`, end);
      }
    } else if (value.isRange) {
      if (
        key !== columnName ||
        (key === columnName && (range.start || range.end))
      ) {
        filtersQuery.append(`${key}`, true);
        filtersQuery.append(`${key}Start`, value.start);
        filtersQuery.append(`${key}End`, value.end);
      }
    } else if (value) {
      value.forEach((filterValue) => {
        const { text } = filterValue;
        selectedValues.push(text);
      });
      if (selectedValues.length > 0) {
        selectedValues.forEach((val) => filtersQuery.append(key, val));
      }
    }
  });
  if (sortValue.ascending || sortValue.descending) {
    const order = sortValue.ascending ? '1' : '-1';
    filtersQuery.append(`${sortValue.columnName}Sort`, order);
    setSortBy({
      ascending: sortValue.ascending,
      descending: sortValue.descending,
      columnName,
    });
  } else if (
    (!sortValue.ascending || !sortValue.descending) &&
    sortValue.columnName === sortBy.columnName
  ) {
    setSortBy({
      ascending: false,
      descending: false,
      columnName: null,
    });
  } else if (sortBy.ascending || sortBy.descending) {
    const order = sortBy.ascending ? '1' : '-1';
    filtersQuery.append(`${sortBy.columnName}Sort`, order);
  }

  setSelectedFilters((prevState) => {
    const newState = { ...prevState };
    if (dates && dates.startDate && dates.endDate) {
      newState[columnName] = { ...dates, isDate: true };
    } else if (range && (range.start || range.end)) {
      newState[columnName] = { ...range, isRange: true };
    } else if (selected.length > 0) {
      newState[columnName] = selected;
    } else {
      delete newState[columnName];
    }
    return newState;
  });

  if (resetPagination) {
    filtersQuery.append('page', 0);
    filtersQuery.append('limit', pageLimit);
    setPagination({
      page: 0,
      limit: pageLimit,
    });
  } else {
    filtersQuery.append('page', pageIndex);
    filtersQuery.append('limit', pageLimit);
    setPagination({
      page: pageIndex,
      limit: pageLimit,
    });
  }

  return `?${filtersQuery.toString()}`;
};

export default getOffersFilters;
