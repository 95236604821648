import { Auth } from 'aws-amplify';

import amplifyErrorMessage from 'utils/amplifyErrorMessage';
import AuthChallenge from 'enums/amplify/authChallenge.enum';
import getCognitoUserData from 'utils/users/getCognitoUserData';

const signIn = async (username, password) => {
  try {
    const cognitoUser = await Auth.signIn(username, password);

    if (cognitoUser.challengeName === AuthChallenge.NewPasswordRequired) {
      return null;
    }

    return getCognitoUserData(cognitoUser, username);
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export default signIn;
