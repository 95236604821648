import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import OfferForm, { OfferFormMode } from 'components/Pages/OfferForm';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Table from 'components/Common/Table';
import columns from 'utils/offers/merchantOffersColumns';
import offersIcon from 'assets/icons/color/offer.svg';
import searchIcon from 'assets/icons/search.svg';
import addIcon from 'assets/icons/close.svg';
import useModal from 'hooks/useModal';
import Modal from 'components/Common/Modal';
import ModalType from 'enums/modal/modalType';
import Columns from 'enums/table/columns.enum';

import { selectFetchOffersByMerchantIdState } from 'state/selectors/offers';
import { fetchOffersByMerchantId } from 'state/actions/offers';
import StatusValue from 'enums/status/statusValue.enum';
import Status from 'enums/status/status.enum';
import classes from './OffersData.module.scss';

const OffersData = ({ merchantName, merchantId, onCancel }) => {
  const dispatch = useDispatch();

  const { offers, success, loading } = useSelector(
    selectFetchOffersByMerchantIdState,
    shallowEqual
  );

  const [selectedOffers, setSelectedOffers] = useState([]);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    dispatch(fetchOffersByMerchantId(merchantId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      const transformedData = offers.map((offer) => ({
        ...offer,
        offerId: offer._id,
        merchantName: offer.name,
        status:
          offer.status === StatusValue.Active ? Status.ACTIVE : Status.INACTIVE,
      }));
      setSelectedOffers(transformedData);
    }
  }, [success, offers]);

  const onSearchHandler = useCallback(
    (text) => {
      if (text !== '') {
        const filteredLocations = offers.filter((offer) =>
          offer.offerId.includes(text.toLocaleLowerCase())
        );
        setSelectedOffers(filteredLocations);
      } else {
        setSelectedOffers(offers);
      }
    },
    [offers]
  );

  const onClickCellButtonHandler = useCallback(
    (columnName, rowIndex) => {
      if (columnName === Columns.MoreInfo) {
        onOpenModalHandler(ModalType.OFFER_INFO, {
          offer: {
            ...selectedOffers[rowIndex],
            _id: selectedOffers[rowIndex].offerId,
          },
        });
      }
    },
    [onOpenModalHandler, selectedOffers]
  );

  const onAddOfferHandler = useCallback(() => {
    onOpenModalHandler(ModalType.ADD_OFFER);
  }, [onOpenModalHandler]);

  return (
    <>
      <Modal
        isOpen={modal.type === ModalType.OFFER_INFO}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <OfferForm onCancel={onCloseModalHandler} offer={modal.offer} />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ADD_OFFER}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <OfferForm
          initialMode={OfferFormMode.NEW}
          subtitle={merchantName}
          onCancel={onCloseModalHandler}
          merchant={{ merchantName, merchantId }}
        />
      </Modal>
      <div className={classes.container}>
        <Heading size={HeadingSize.M} className={classes.heading}>
          {merchantName}
        </Heading>
        <div className={classes.location}>
          <img src={offersIcon} alt="offers" className={classes.icon} />
          <Body
            size={BodySize.S}
            color={BodyColor.Gray}
            className={classes.locationText}
          >
            Offers
          </Body>
        </div>
        <div className={classes.tableHeading}>
          <div className={classes.panelSearch}>
            <Input
              name="search"
              placeholder="Search by Offer ID"
              type={InputType.Text}
              icon={searchIcon}
              size={InputSize.XS}
              className={classes.search}
              onChange={(e) => onSearchHandler(e.target.value)}
            />
          </div>
          <div className={classes.actions}>
            <Button
              type={ButtonType.Submit}
              size={ButtonSize.S}
              className={classes.addButton}
              onClick={onAddOfferHandler}
            >
              <div className={classes.addIcon}>
                <img src={addIcon} alt="add" />
              </div>
            </Button>
          </div>
        </div>
        {loading ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={columns}
            data={selectedOffers}
            pagination={false}
            className={classes.table}
            onClickCellButton={onClickCellButtonHandler}
          />
        )}
        <div className={classes.actionButtons}>
          <Button
            type={ButtonType.Default}
            size={ButtonSize.S}
            className={classes.button}
            onClick={onCancel}
            kind={ButtonKind.Secondary}
          >
            Go back
          </Button>
        </div>
      </div>
    </>
  );
};

OffersData.propTypes = {
  onCancel: PropTypes.func.isRequired,
  merchantName: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
};

export default OffersData;
