import {
  fetchPendingTransactionsInit,
  fetchPendingTransactionsSuccess,
  fetchPendingTransactionsFail,
  editPendingTransactionInit,
  editPendingTransactionSuccess,
  editPendingTransactionFail,
  reprocessIncomingTransactionInit,
  reprocessIncomingTransactionSuccess,
  reprocessIncomingTransactionFail,
  clearReprocessIncomingTransactionValues,
  reprocessPendingTransactionInit,
  reprocessPendingTransactionSuccess,
  reprocessPendingTransactionFail,
} from 'state/actionCreators/pendingTransactions';

import fetchPendingTransactionsService from 'services/pendingTransactions/fetchPendingTransactions';
import editPendingTransactionService from 'services/pendingTransactions/editPendingTransaction';
import reprocessIncomingTransactionService from 'services/pendingTransactions/reprocessIncomingTransaction';
import reprocessPendingTransactionService from 'services/pendingTransactions/reprocessPendingTransactions';

export const fetchPendingTransactions = (filters = '') => async (dispatch) => {
  dispatch(fetchPendingTransactionsInit());

  try {
    const data = await fetchPendingTransactionsService(filters);
    return dispatch(fetchPendingTransactionsSuccess(data));
  } catch (error) {
    return dispatch(fetchPendingTransactionsFail({ error: error.message }));
  }
};

export const editPendingTransaction = ({
  pendingTransactionId,
  ...rest
}) => async (dispatch) => {
  dispatch(editPendingTransactionInit());

  try {
    await editPendingTransactionService(pendingTransactionId, rest);
    return dispatch(editPendingTransactionSuccess());
  } catch (error) {
    return dispatch(editPendingTransactionFail({ error: error.message }));
  }
};

export const reprocessIncomingTransaction = (id, body) => async (dispatch) => {
  dispatch(reprocessIncomingTransactionInit());

  try {
    await reprocessIncomingTransactionService(id, body);
    return dispatch(reprocessIncomingTransactionSuccess());
  } catch (error) {
    return dispatch(reprocessIncomingTransactionFail({ error: error.message }));
  }
};

export const reprocessPendingTransaction = ({ pendingTransactionId }) => async (
  dispatch
) => {
  dispatch(reprocessPendingTransactionInit());

  try {
    const data = await reprocessPendingTransactionService(pendingTransactionId);
    return dispatch(reprocessPendingTransactionSuccess(data));
  } catch (error) {
    return dispatch(reprocessPendingTransactionFail({ error: error.message }));
  }
};

export const clearReprocessIncomingTransactionStateData = () => async (
  dispatch
) => dispatch(clearReprocessIncomingTransactionValues());
