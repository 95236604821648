import client from 'services/kardAPI';

const editLocation = async (id, body) => {
  try {
    await client.put(`/rewards/portal/merchant/location/${id}`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default editLocation;
