import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Form from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Checkbox, { Kind as CheckboxKind } from 'components/Common/Checkbox';
import Switch, { Size as SwitchSize } from 'components/Common/Switch';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Status from 'enums/status/status.enum';
import Types from 'enums/type/type.enum';
import { editLocation, createLocation } from 'state/actions/locations';
import {
  selectEditLocationState,
  selectCreateLocationState,
} from 'state/selectors/locations';
import closeIcon from 'assets/icons/color/close-gray.svg';
import addIcon from 'assets/icons/close.svg';
import { clearLocationSuccessProp } from 'state/actionCreators/locations';
import ReactTooltip from 'react-tooltip';
import validationSchema from './LocationForm.schema';
import { addressFields, informationFields } from './formFields';

import classes from './LocationForm.module.scss';

const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const LocationForm = ({
  title,
  location,
  onCancel,
  isCreating,
  setBodyOffer,
  showCancelButton,
}) => {
  const dispatch = useDispatch();

  const { loading: loadingEditLocation, error } = useSelector(
    selectEditLocationState,
    shallowEqual
  );

  const {
    success: successCreateLocation,
    loading: loadingCreateLocation,
    error: createLocationError,
  } = useSelector(selectCreateLocationState, shallowEqual);

  const [isEditing, setIsEditing] = useState(false);
  const [permanentlyClosed, setPermanentlyClosed] = useState(false);
  const [statusSwitch, setStatusSwitch] = useState(
    location.status === Status.ACTIVE
  );
  const [statusValue, setStatusValue] = useState(
    location.status?.toUpperCase()
  );
  const [presenterMids, setPresenterMids] = useState(
    location.presenterMids || []
  );
  const [addNewPresenterMid, setAddNewPresenterMid] = useState(false);
  const [newPresenterMidValue, setNewPresenterMidValue] = useState('');

  const onEditHandler = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onDeletePresenterMidHandler = useCallback((index) => {
    setPresenterMids((prevState) => {
      const previous = [...prevState];
      previous.splice(index, 1);
      return previous;
    });
  }, []);

  const onClickAddPresenterMidHandler = useCallback(() => {
    setAddNewPresenterMid(true);
  }, []);

  const onCancelAddPresenterMidHandler = useCallback(() => {
    setNewPresenterMidValue('');
    setAddNewPresenterMid(false);
  }, []);

  const onAddNewPresenterMidHandler = useCallback(() => {
    setPresenterMids((prevState) => [...prevState, newPresenterMidValue]);
    onCancelAddPresenterMidHandler();
  }, [newPresenterMidValue, onCancelAddPresenterMidHandler]);

  const onChangeCheckboxStateHandler = useCallback(
    (setState) => {
      if (isEditing || isCreating) {
        setState((prevState) => !prevState);
      }
    },
    [isEditing, isCreating]
  );

  const onChangeStatusSwitchHandler = () => {
    if (isEditing || isCreating) {
      setStatusSwitch((prevState) => !prevState);
    }

    const { closed, inactive, active } = Status;
    const locationStatus = location.status.toUpperCase();

    switch (statusValue) {
      case closed:
      case inactive:
        setStatusValue(active);
        break;
      case active:
        setStatusValue(locationStatus === active ? inactive : locationStatus);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (successCreateLocation) {
      dispatch(clearLocationSuccessProp());
      onCancel();
    }
  }, [successCreateLocation, onCancel, dispatch, setBodyOffer]);

  const onSubmitHandler = useCallback(
    (values) => {
      const body = {
        ...location,
        name: values.name,
        address: {
          street: values.street ?? '',
          city: values.city ?? '',
          state: values.state ?? '',
          zipCode: values.zipCode ?? '',
        },
        source: values.source,
        locationType: values.locationType,
        phone: location.phone ? location.phone : values.phone,
        geoLocation: {
          latitude: Number(values.latitude) ?? '',
          longitude: Number(values.longitude) ?? '',
        },
        permanentlyClosed,
        status: statusValue,
        googleId: values.googleId ?? '',
        operationHours: {
          MONDAY: values.monday ?? '',
          TUESDAY: values.tuesday ?? '',
          WEDNESDAY: values.wednesday ?? '',
          THURSDAY: values.thursday ?? '',
          FRIDAY: values.friday ?? '',
          SATURDAY: values.saturday ?? '',
          SUNDAY: values.sunday ?? '',
        },
        ...(presenterMids.length === 0 ? {} : { presenterMids }),
        merchantId: location.merchant
          ? location.merchant._id
          : location.merchantId,
      };

      delete body.category;
      delete body.locationId;
      delete body.__v;
      delete body._id;
      delete body.createdDate;
      delete body.lastModified;
      delete body.merchant;
      delete body.offers;
      delete body.locationName;
      delete body.merchantName;
      delete body.city;
      delete body.state;
      delete body.street;
      delete body.zipCode;
      delete body.latitude;
      delete body.longitude;

      if (body.locationType === Types.ONLINE) {
        delete body.address;
        delete body.geoLocation;
        delete body.operationHours;
      }
      if (isCreating) {
        setBodyOffer(() => {
          dispatch(createLocation(body));
          return '';
        });
      } else {
        dispatch(editLocation(location.locationId, body));
      }
    },
    [
      location,
      permanentlyClosed,
      presenterMids,
      statusValue,
      isCreating,
      dispatch,
      setBodyOffer,
    ]
  );

  return (
    <>
      {(error || createLocationError) && (
        <Toast
          id={error ? 'error edit location' : 'error creating location'}
          text={error || createLocationError}
          type={ToastType.Error}
        />
      )}
      {successCreateLocation && (
        <Toast
          id="success-creating-location"
          text="Location created successfully!"
          type={ToastType.Success}
        />
      )}

      <Form
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
        className={classes.form}
      >
        <Heading size={HeadingSize.M} className={classes.heading}>
          {title}
        </Heading>
        <div>
          {informationFields.map(
            ({
              name,
              renderProps: { isEditable, ...renderProps },
              isCreate,
              isEdit,
            }) => (
              <Fragment key={name}>
                {isEditing || !isCreating ? (
                  <>
                    {isEdit && (
                      <FormControl
                        key={name}
                        name={name}
                        render={(props) => (
                          <Input
                            readOnly={!isEditing}
                            disabled={!isEditable && isEditing}
                            color={
                              isEditable && isEditing
                                ? InputColor.Black
                                : InputColor.Gray
                            }
                            size={InputSize.S}
                            value={location[name]}
                            {...renderProps}
                            {...props}
                          />
                        )}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {isCreate && (
                      <>
                        <FormControl
                          key={name}
                          name={name}
                          render={(props) => (
                            <Input
                              readOnly={!isCreating}
                              disabled={!isEditable && isCreating}
                              color={
                                isEditable && isCreating
                                  ? InputColor.Black
                                  : InputColor.Gray
                              }
                              size={InputSize.S}
                              value={location[name]}
                              {...renderProps}
                              {...props}
                            />
                          )}
                        />
                      </>
                    )}
                  </>
                )}
              </Fragment>
            )
          )}
          {isEditing && !isCreating && (
            <Switch
              enabled={statusSwitch}
              setEnabled={onChangeStatusSwitchHandler}
              label="Status: "
              labelClassName={classes.labelWidth}
              leftText={
                location.status === Status.closed ||
                location.status === Status.CLOSED
                  ? 'Closed'
                  : 'Inactive'
              }
              rightText="Active"
              size={SwitchSize.S}
              disabled={!isEditing}
              className={classes.switch}
            />
          )}
          {!isEditing && !isCreating && (
            <FormControl
              name="status"
              render={(props) => (
                <Input
                  label="Status:"
                  labelClassName={classes.labelWidth}
                  value={location.status?.toUpperCase()}
                  type={InputType.Text}
                  size={InputSize.S}
                  readOnly={!isEditing}
                  disabled={isEditing}
                  color={statusSwitch ? InputColor.Green : InputColor.Red}
                  {...props}
                />
              )}
            />
          )}
          {addressFields.map(({ name, renderProps, isCreate, ...rest }) => (
            <Fragment key={name}>
              {!isCreating ? (
                <>
                  {renderProps ? (
                    <FormControl
                      name={name}
                      render={(props) => (
                        <Input
                          readOnly={!isEditing}
                          color={isEditing ? InputColor.Black : InputColor.Gray}
                          size={InputSize.S}
                          value={location[name]}
                          {...renderProps}
                          {...props}
                        />
                      )}
                    />
                  ) : (
                    <FormControlSelect
                      name={name}
                      readOnly={!isEditing}
                      color={isEditing ? SelectColor.Black : SelectColor.Gray}
                      defaultValue={location[name]}
                      {...rest}
                    />
                  )}
                </>
              ) : (
                <>
                  {isCreate && (
                    <>
                      {renderProps ? (
                        <FormControl
                          name={name}
                          render={(props) => (
                            <Input
                              readOnly={!isEditing}
                              color={
                                isEditing ? InputColor.Black : InputColor.Gray
                              }
                              size={InputSize.S}
                              value={location[name]}
                              {...renderProps}
                              {...props}
                            />
                          )}
                        />
                      ) : (
                        <FormControlSelect
                          name={name}
                          readOnly={!isEditing}
                          disabled={isCreate}
                          color={
                            isEditing ? SelectColor.Black : SelectColor.Gray
                          }
                          defaultValue={location[name]}
                          {...rest}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Fragment>
          ))}
          {!isCreating && (
            <Checkbox
              label="Permanently closed"
              setSelected={() =>
                onChangeCheckboxStateHandler(setPermanentlyClosed)
              }
              selected={permanentlyClosed}
              className={classes.checkbox}
              kind={isEditing ? CheckboxKind.Primary : CheckboxKind.Secondary}
            />
          )}
          {!isCreating && (
            <Body
              size={BodySize.S}
              className={classes.labelText}
              color={BodyColor.Gray}
            >
              BUSSINESS HOURS
            </Body>
          )}
          {!isCreating &&
            WEEK_DAYS.map((day) => (
              <FormControl
                name={day.toLowerCase()}
                key={day}
                render={(props) => (
                  <Input
                    label={`${day}: `}
                    value={location.operationHours?.[day.toUpperCase()]}
                    placeholder={day}
                    type={InputType.Text}
                    readOnly={!isEditing}
                    size={InputSize.S}
                    labelClassName={classes.days}
                    color={isEditing ? InputColor.Black : InputColor.Gray}
                    {...props}
                  />
                )}
              />
            ))}
          {!isCreating && (
            <div className={classes.presenterMidsContainer}>
              <div className={classes.presenterMids}>
                <Body
                  size={BodySize.S}
                  className={classes.labelText}
                  color={BodyColor.Gray}
                >
                  Presenter MIDs
                </Body>
                {isEditing && (
                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    className={classes.addPresenterButton}
                    onClick={onClickAddPresenterMidHandler}
                  >
                    <div className={classes.addIcon}>
                      <img src={addIcon} alt="add" />
                    </div>
                  </Button>
                )}
              </div>
              {presenterMids.length === 0 && !addNewPresenterMid && (
                <Body
                  size={BodySize.XS}
                  color={BodyColor.Gray}
                  className={classes.presenterMidText}
                >
                  No names created
                </Body>
              )}
              {presenterMids.map((mid, midIndex) => (
                <div
                  key={`opt ${midIndex.toString()}`}
                  className={classNames(classes.prensenterMidItem, {
                    [classes.lastItem]:
                      midIndex === presenterMids.length - 1 &&
                      addNewPresenterMid,
                  })}
                >
                  <Body
                    size={BodySize.XS}
                    color={isEditing ? BodyColor.Black : BodyColor.Gray}
                    className={classes.presenterMidText}
                  >
                    {mid}
                  </Body>
                  {isEditing && (
                    <button
                      type="button"
                      className={classes.removePresenterMidIcon}
                      onClick={() => onDeletePresenterMidHandler(midIndex)}
                    >
                      <img src={closeIcon} alt="remove" />
                    </button>
                  )}
                </div>
              ))}
              {addNewPresenterMid && (
                <>
                  <Input
                    name="presenterMids"
                    value={newPresenterMidValue}
                    onChange={(event) =>
                      setNewPresenterMidValue(event.target.value)
                    }
                    placeholder="New presenter MID"
                    type={InputType.Text}
                    size={InputSize.S}
                    className={classes.presenterMidsInput}
                  />
                  <div className={classes.addPresenterMidActions}>
                    <Button
                      type={ButtonType.Default}
                      size={ButtonSize.S}
                      onClick={onCancelAddPresenterMidHandler}
                      kind={ButtonKind.Secondary}
                    >
                      Cancel
                    </Button>
                    <Button
                      type={ButtonType.Default}
                      size={ButtonSize.S}
                      className={classes.submitButton}
                      onClick={onAddNewPresenterMidHandler}
                      disabled={newPresenterMidValue === ''}
                    >
                      Add
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
          <div className={classes.actionButtons}>
            {!isEditing && !isCreating ? (
              <div>
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  kind={ButtonKind.Secondary}
                  onClick={onCancel}
                  className={classes.back}
                >
                  Back
                </Button>
                <Button
                  type={ButtonType.Default}
                  size={ButtonSize.S}
                  onClick={onEditHandler}
                >
                  Edit
                </Button>
              </div>
            ) : (
              <>
                {showCancelButton && (
                  <Button
                    type={ButtonType.Default}
                    size={ButtonSize.S}
                    onClick={onCancel}
                    kind={ButtonKind.Secondary}
                  >
                    Cancel
                  </Button>
                )}
                <span
                  data-tip="Cannot save a closed location"
                  data-for="noSaveMessageTooltip"
                >
                  <Button
                    type={ButtonType.Submit}
                    size={ButtonSize.S}
                    className={classes.submitButton}
                    loading={loadingEditLocation || loadingCreateLocation}
                    disabled={statusValue === Status.closed}
                  >
                    Save
                  </Button>
                  {statusValue === Status.closed ? (
                    <ReactTooltip id="noSaveMessageTooltip" />
                  ) : null}
                </span>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

LocationForm.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
  setBodyOffer: PropTypes.func,
  showCancelButton: PropTypes.bool,
};

LocationForm.defaultProps = {
  location: {},
  isCreating: false,
  onCancel: () => {},
  setBodyOffer: () => {},
  showCancelButton: true,
};

export default LocationForm;
