import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ErrorMessage from 'components/Typography/ErrorMessage';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, { Type as InputType } from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import { forgotPassword } from 'state/actions/auth';
import { selectForgotPasswordState } from 'state/selectors/auth';
import emailIcon from 'assets/icons/mail-at.svg';
import Body, { Size as BodySize } from 'components/Typography/Body';

import emailValidationSchema from './ForgotPasswordFormSchama.schema';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector(
    selectForgotPasswordState,
    shallowEqual
  );

  const onSubmitHandler = useCallback(
    ({ email }) => {
      dispatch(forgotPassword(email));
    },
    [dispatch]
  );

  return (
    <>
      <Heading size={HeadingSize.XL}>Forgot password</Heading>
      <Form
        validationSchema={emailValidationSchema}
        validationMode={ValidationMode.OnChange}
        onSubmit={onSubmitHandler}
      >
        <>
          <Body size={BodySize.S}>
            A code will be sent to this email in order to reset the password.
          </Body>
          <FormControl
            name="email"
            render={(props) => (
              <Input
                placeholder="Email Address"
                type={InputType.Email}
                icon={emailIcon}
                {...props}
              />
            )}
          />
        </>
        <Button
          type={ButtonType.Submit}
          size={ButtonSize.L}
          loading={loading}
          isFullWidth
        >
          Change password
        </Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
