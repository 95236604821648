/* eslint-disable react/prop-types */
import React from 'react';

import trueIcon from 'assets/icons/color/boolean-true.svg';
import falseIcon from 'assets/icons/color/boolean-false.svg';
import informationIcon from 'assets/icons/color/information.svg';
import toDollarFormat from 'utils/toDollarFormat';
import centsToDollars from 'utils/centsToDollars';
import toFormatTransactionId from 'utils/formatToolTip';
import dateParse from 'utils/common/dateParse';
// import reprocessIcon from 'assets/icons/color/reprocess.png';

export const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Txn ID',
        accessor: 'transactionId',
        columnName: 'transactionId',
        sortable: false,
        filterable: false,
        Cell: ({ row }) => (
          <span
            style={{
              width: '100%',
              marginTop: '0px',
              marginBottom: '0px',
            }}
            data-tip={toFormatTransactionId(row)}
            data-for="tooltiptable"
            data-html
          >
            {row.values.transactionId}
          </span>
        ),
      },
      {
        Header: 'Merchant Id',
        accessor: 'merchantId',
        columnName: 'merchantId',
        isVisible: false,
      },
      {
        Header: 'Merchant Name',
        accessor: 'merchantName',
        columnName: 'merchantName',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Merchant Address State',
        accessor: 'merchantAddrState',
        columnName: 'merchantAddrState',
      },
      {
        Header: 'Merchant Zip code',
        accessor: 'merchantAddrStreet',
        columnName: 'merchantAddrStreet',
        isVisible: false,
      },
      {
        Header: 'Merchant Zip code',
        accessor: 'merchantAddrZipcode',
        columnName: 'merchantAddrZipcode',
        isVisible: false,
      },
      {
        Header: 'Txn Amount',
        accessor: 'amount',
        columnName: 'amount',
        sortable: false,
        filterable: false,
        Cell: ({ value }) => (
          <span
            style={{
              width: '100%',
              marginTop: '0px',
              marginBottom: '0px',
            }}
            data-tip={toDollarFormat(centsToDollars(value))}
            data-for="tooltiptable"
            data-html
          >
            {toDollarFormat(centsToDollars(value))}
          </span>
        ),
      },
      {
        Header: 'City',
        accessor: 'merchantAddrCity',
        columnName: 'merchantAddrCity',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Description',
        accessor: 'description',
        columnName: 'description',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Txn Date',
        accessor: 'transactionDate',
        columnName: 'transactionDate',
        sortable: false,
        filterable: false,
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
      },
      {
        Header: 'Correct Merchant',
        accessor: 'correctMerchant',
        columnName: 'correctMerchant',
        sortable: false,
        filterable: false,
        isSelect: true,
      },
      {
        Header: 'Correct Location',
        accessor: 'correctLocation',
        columnName: 'correctLocation',
        sortable: false,
        filterable: false,
        isSelect: true,
      },
      {
        Header: '',
        accessor: 'accept',
        columnName: 'accept',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={trueIcon} alt="accept" />,
      },
      {
        Header: '',
        accessor: 'reject',
        columnName: 'reject',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={falseIcon} alt="reject" />,
      },
      {
        Header: '',
        accessor: 'moreInfo',
        columnName: 'moreInfo',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={informationIcon} alt="More information" />,
      },
      // Removed momentarily
      // {
      //   Header: '',
      //   accessor: 'reprocessTransaction',
      //   columnName: 'reprocessTransaction',
      //   sortable: false,
      //   filterable: false,
      //   isButton: true,
      //   Cell: () => (
      //     <img
      //       style={{ width: '30px' }}
      //       src={reprocessIcon}
      //       alt="Reprocess Transaction"
      //     />
      //   ),
      // },
    ],
  },
];

export const reprocessColumns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Txn ID',
        accessor: 'transactionId',
        columnName: 'transactionId',
        sortable: false,
        filterable: false,
        Cell: ({ row }) => (
          <span
            style={{
              width: '100%',
              marginTop: '0px',
              marginBottom: '0px',
            }}
            data-tip={toFormatTransactionId(row)}
            data-for="tooltiptable"
            data-html
          >
            {row.values.transactionId}
          </span>
        ),
      },
      {
        Header: 'Merchant Id',
        accessor: 'merchantId',
        columnName: 'merchantId',
        isVisible: false,
      },
      {
        Header: 'Merchant Name',
        accessor: 'merchantName',
        columnName: 'merchantName',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Merchant Address State',
        accessor: 'merchantAddrState',
        columnName: 'merchantAddrState',
      },
      {
        Header: 'Merchant Zip code',
        accessor: 'merchantAddrStreet',
        columnName: 'merchantAddrStreet',
        isVisible: false,
      },
      {
        Header: 'Merchant Zip code',
        accessor: 'merchantAddrZipcode',
        columnName: 'merchantAddrZipcode',
        isVisible: false,
      },
      {
        Header: 'Txn Amount',
        accessor: 'amount',
        columnName: 'amount',
        sortable: false,
        filterable: false,
        Cell: ({ value }) => (
          <span
            style={{
              width: '100%',
              marginTop: '0px',
              marginBottom: '0px',
            }}
            data-tip={toDollarFormat(centsToDollars(value))}
            data-for="tooltiptable"
            data-html
          >
            {toDollarFormat(centsToDollars(value))}
          </span>
        ),
      },
      {
        Header: 'City',
        accessor: 'merchantAddrCity',
        columnName: 'merchantAddrCity',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Description',
        accessor: 'description',
        columnName: 'description',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Txn Date',
        accessor: 'transactionDate',
        columnName: 'transactionDate',
        sortable: false,
        filterable: false,
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
      },
    ],
  },
];
