import client from 'services/kardAPI';

const reprocessIncomingTransaction = async (id, body) => {
  try {
    await client.put(`/transactions/portal/incomingtransaction/${id}`, body);
  } catch (error) {
    throw new Error(error.message);
  }
};

export default reprocessIncomingTransaction;
