const { default: Roles } = require('./roles.enum');

const Groups = Object.freeze({
  Everyone: Object.freeze([
    Roles.Issuer,
    Roles.Admin,
    Roles.Reporter,
    Roles.KardReporter,
    Roles.KardAdmin,
  ]),
  Administrators: Object.freeze([Roles.Admin, Roles.KardAdmin]),
  Internal: Object.freeze([
    Roles.Admin,
    Roles.Reporter,
    Roles.KardAdmin,
    Roles.KardReporter,
  ]),
  Reporters: Object.freeze([Roles.Issuer, Roles.Reporter, Roles.KardReporter]),
});

export default Groups;
