const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
        columnName: 'name',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Google ID',
        accessor: 'googleId',
        columnName: 'googleId',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Location Type',
        accessor: 'locationType',
        columnName: 'locationType',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Street',
        accessor: 'street',
        columnName: 'street',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'City',
        accessor: 'city',
        columnName: 'city',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'State',
        accessor: 'state',
        columnName: 'state',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Zip Code',
        accessor: 'zipCode',
        columnName: 'zipCode',
        sortable: false,
        filterable: false,
      },
    ],
  },
];

export default columns;
