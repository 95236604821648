import Status from 'enums/status/status.enum';
import queryParamsToObject from 'utils/queryParamsToObject';

/**
 *
 * @param {String} queryParams // query params filters from the URL
 * @returns {Boolean}
 */
const isAnyStatusActive = (queryParams) => {
  const { status } = queryParamsToObject(queryParams);

  if (!status) {
    return true;
  }

  if (Array.isArray(status)) {
    return status.includes(Status.active);
  }

  return status === Status.active;
};

export default isAnyStatusActive;
