/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';

import Select from 'components/Common/Select';
import Body, { Color as BodyColor } from 'components/Typography/Body';

import ErrorMessage from 'components/Typography/ErrorMessage';
import classes from './FormControlSelect.module.scss';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
});

export const LabelPosition = Object.freeze({
  Top: 'top',
  Left: 'left',
});

export const Color = Object.freeze({
  Black: 'black',
  Gray: 'gray',
});

const SELECT_ALL = { label: 'Select All', value: 'all' };

const FormControlSelect = ({
  name,
  label,
  labelClassName,
  placeholder,
  className,
  options: selectOptions,
  value: manualValue,
  defaultValue,
  disabled,
  readOnly,
  size,
  onChangeManual,
  isMulti,
  allowSelectAll,
  labelPosition,
  color,
  noErrorMessage,
  ...props
}) => {
  const { errors, control, setValue: setFormValue } = useFormContext();
  const error = errors[name];

  useEffect(() => {
    if (defaultValue) {
      setFormValue(name, defaultValue);
    }
  }, [setFormValue, name, defaultValue]);

  const borderColor = useMemo(
    () => (error ? '1px solid red' : '1px solid #e8e8e8'),
    [error]
  );

  const backgroundColor = useMemo(() => (disabled ? '#e8e8e8' : 'white'), [
    disabled,
  ]);

  const options = useMemo(
    () =>
      isMulti && allowSelectAll
        ? [SELECT_ALL, ...selectOptions]
        : selectOptions,
    [isMulti, allowSelectAll, selectOptions]
  );

  const getValue = useCallback(
    (value) => {
      if (value) {
        if (options.length > 0 && options[0]?.options) {
          if (isMulti) {
            const allOptions = [];
            options.forEach((op) => {
              const newOptions =
                op.options.filter((c) => value?.includes(c.value)) || [];
              allOptions.concat(newOptions);
            });
            return allOptions;
          }
          let selectedOption = null;
          options.forEach((op) => {
            const selected = op.options.find((c) => c.value === value) || null;
            if (selected) {
              selectedOption = selected;
            }
          });
          return selectedOption;
        }
        if (isMulti) {
          return options.filter((c) => value?.includes(c.value)) || [];
        }
        return options.find((c) => c.value === value) || null;
      }
      return null;
    },
    [isMulti, options]
  );

  return (
    <div
      className={classNames(
        className,
        classes[labelPosition],
        classes.container,
        {
          [classes.error]: !!error,
          [classes.disabled]: disabled,
        }
      )}
    >
      {label && (
        <Body
          size={size}
          className={classNames(labelClassName, classes.labelText, {
            [classes.noMargin]: labelPosition === LabelPosition.Top,
          })}
          color={BodyColor.Gray}
        >
          {label}
        </Body>
      )}
      <div
        className={classNames(classes.select, {
          [classes.selectWithLeftLabel]:
            label && labelPosition === LabelPosition.Left,
        })}
      >
        <Controller
          name={name}
          defaultValue={defaultValue}
          control={control}
          render={({ field, onChange, value, ref }) => {
            const actualValue = manualValue || value;

            return (
              <Select
                {...field}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    border: state.isFocused ? '1px solid #0583dc' : borderColor,
                    minHeight: '48px',
                    boxShadow: '0px 12px 23px rgba(55, 125, 255, 0.06)',
                    fontSize: '0.875rem',
                    '&:hover': {
                      border: state.isFocused
                        ? '1px solid #0583dc'
                        : borderColor,
                    },
                    backgroundColor:
                      state.isDisabled && readOnly ? backgroundColor : 'white',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color:
                      color === Color.Gray || disabled ? '#7b88a8' : 'black',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color:
                      color === Color.Gray || disabled ? '#7b88a8' : 'black',
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: '0.875rem',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: 'max-content',
                    minWidth: '100%',
                  }),
                  multiValueRemove: (base, state) =>
                    state.data.isFixed ? { ...base, display: 'none' } : base,
                }}
                inputRef={ref}
                options={options}
                value={getValue(actualValue)}
                defaultValue={
                  options.filter((c) =>
                    isMulti
                      ? defaultValue?.includes(c.value)
                      : c.value === defaultValue
                  ) || null
                }
                onChange={(val) => {
                  let result;
                  if (isMulti) {
                    let selected;
                    // if this is a "select all operation"
                    if (
                      allowSelectAll &&
                      val.some((option) => option === SELECT_ALL)
                    ) {
                      // then it is all the options, less the synthetic "select all" option at index 0
                      selected = options.slice(1);
                    } else {
                      // otherwise it is just the values we have already selected
                      selected = val;
                    }
                    // get the values for the selected items
                    result = selected.map((option) => option.value);
                  } else {
                    // get the value for the selected item
                    result = val?.value;
                  }
                  onChangeManual?.(result);
                  onChange(result);
                }}
                placeholder={placeholder}
                isDisabled={disabled || readOnly}
                isMulti={isMulti}
                className={className}
                {...props}
              />
            );
          }}
        />
        {!noErrorMessage && error && (
          <ErrorMessage>{error.message}</ErrorMessage>
        )}
      </div>
    </div>
  );
};

FormControlSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(Size)),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  readOnly: PropTypes.bool,
  isMulti: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  onChangeManual: PropTypes.func,
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  labelClassName: PropTypes.string,
  color: PropTypes.oneOf(Object.values(Color)),
  noErrorMessage: PropTypes.bool,
};

FormControlSelect.defaultProps = {
  label: null,
  placeholder: null,
  className: '',
  disabled: false,
  size: Size.S,
  defaultValue: null,
  readOnly: false,
  isMulti: false,
  allowSelectAll: true,
  onChangeManual: null,
  labelPosition: LabelPosition.Left,
  value: null,
  color: Color.Gray,
  labelClassName: '',
  noErrorMessage: false,
};

export default FormControlSelect;
