import { Auth } from 'aws-amplify';

import amplifyErrorMessage from 'utils/amplifyErrorMessage';
import AuthChallenge from 'enums/amplify/authChallenge.enum';
import getCognitoUserData from 'utils/users/getCognitoUserData';

const completeNewPassword = async (username, oldPassword, newPassword) => {
  try {
    const user = await Auth.signIn(username, oldPassword);

    if (user.challengeName !== AuthChallenge.NewPasswordRequired) {
      return getCognitoUserData(user, username);
    }

    const newUser = await Auth.completeNewPassword(user, newPassword, {
      email: username,
    });

    return getCognitoUserData(newUser, username);
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export default completeNewPassword;
