/* eslint-disable react/prop-types */
import React from 'react';

import informationIcon from 'assets/icons/color/information.svg';
import trueIcon from 'assets/icons/color/boolean-true.svg';
import falseIcon from 'assets/icons/color/boolean-false.svg';
import FilterTypes from 'enums/reports/filterTypes.enum';
import Groups from 'enums/users/groups.enum';
import dateParse from 'utils/common/dateParse';
import Status from 'components/Common/Status';
import { merchantNetwork } from 'utils/common/merchantNetwork';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Offer ID',
        accessor: 'offerId',
        columnName: 'offerId',
        sortable: false,
        filterable: false,
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Offer Name',
        accessor: 'name',
        columnName: 'name',
        sortable: false,
        filterable: true,
        filter: {
          type: FilterTypes.Search,
          options: [],
        },
        allowedRoles: Groups.Everyone,
      },
      // TODO: can we remove the merchant object from the API?
      // {
      //   Header: 'Merchant Name',
      //   accessor: 'merchantName',
      //   columnName: 'merchantName',
      //   sortable: true,
      //   filterable: false,
      //   isButton: true,
      //   allowedRoles: Groups.Everyone,
      // },
      {
        Header: 'Offer Type',
        accessor: 'offerType',
        columnName: 'offerType',
        sortable: false,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.String,
          options: ['INSTORE', 'ONLINE'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Merchant Network',
        accessor: 'merchantNetwork',
        columnName: 'merchantNetwork',
        sortable: true,
        filterable: true,
        multiValue: false,
        searchable: true,
        filter: {
          type: FilterTypes.String,
          options: merchantNetwork,
        },
        allowedRoles: Groups.Administrators,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        columnName: 'startDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Expiration Date',
        accessor: 'expirationDate',
        columnName: 'expirationDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Date,
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Offer Status',
        accessor: 'status',
        columnName: 'status',
        sortable: true,
        filterable: true,
        multiValue: true,
        showSwitchToggle: false,
        Cell: ({ value }) => <Status kind={value} />,
        filter: {
          type: FilterTypes.String,
          options: ['ACTIVE', 'INACTIVE', 'CLOSED'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Commission Type',
        accessor: 'commissionType',
        columnName: 'commissionType',
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.String,
          options: ['FLAT', 'PERCENT'],
        },
        allowedRoles: Groups.Reporters,
      },
      {
        Header: 'Total Commission',
        accessor: 'totalCommission',
        columnName: 'totalCommission',
        sortable: true,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Number,
        },
        allowedRoles: Groups.Reporters,
      },
      {
        Header: 'Is Targeted',
        accessor: 'isTargeted',
        columnName: 'isTargeted',
        Cell: ({ value }) => {
          if (value) return <img src={trueIcon} alt="valid" />;
          return <img src={falseIcon} alt="invalid" />;
        },
        sortable: false,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.Boolean,
          options: ['TRUE', 'FALSE'],
        },
        allowedRoles: Groups.Everyone,
      },
      {
        Header: 'Creation Date',
        accessor: 'createdDate',
        columnName: 'createdDate',
        Cell: ({ value }) => {
          if (value) {
            return dateParse(value);
          }
          return '-';
        },
        sortable: true,
        filterable: false,
        allowedRoles: Groups.Internal,
      },
      {
        Header: 'More info',
        accessor: 'moreInfo',
        columnName: 'moreInfo',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={informationIcon} alt="More information" />,
        allowedRoles: Groups.Administrators,
      },
    ],
  },
];

export default columns;
