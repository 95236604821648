import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SideBar from 'components/Navigation/SideBar';
import Header from 'components/Navigation/Header';
import ExportBanner from 'components/Common/ExportBanner';

import classes from './NavigationLayout.module.scss';

const NavigationLayout = ({ children, fullscreen }) => (
  <div className={classes.container}>
    {!fullscreen && <SideBar />}
    <div
      className={classNames(classes.layout, {
        [classes.routeIsFullscreen]: fullscreen,
      })}
    >
      {!fullscreen && <Header />}
      <ExportBanner />
      <main className={classes.content}>{children}</main>
    </div>
  </div>
);

NavigationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
};

NavigationLayout.defaultProps = {
  fullscreen: false,
};

export default NavigationLayout;
