import { DEFAULT_SORT_BY } from 'utils/audiences/values';

const DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

const setSelectedData = ({
  dates,
  setSelectedFilters,
  selected,
  columnName,
}) => {
  setSelectedFilters((prevState) => {
    const newState = { ...prevState };

    if (dates && dates.startDate && dates.endDate) {
      newState[columnName] = { ...dates, isDate: true };

      return newState;
    }

    if (selected.length > 0) {
      newState[columnName] = selected;

      return newState;
    }

    delete newState[columnName];

    return newState;
  });
};

const setSortFilter = ({ filtersQuery, value }) => {
  if (value.ascending || value.descending) {
    const order = value.ascending ? '1' : '-1';

    filtersQuery.append(`${value.columnName}Sort`, order);
  }
};

const setSortingFilters = ({
  sortValue,
  columnName,
  sortBy,
  setSortBy,
  filtersQuery,
}) => {
  if (sortValue.ascending || sortValue.descending) {
    setSortFilter({ filtersQuery, value: sortValue });

    setSortBy({
      ascending: sortValue.ascending,
      descending: sortValue.descending,
      columnName,
    });

    return;
  }

  if (sortValue.columnName === sortBy.columnName) {
    setSortFilter({
      filtersQuery,
      value: DEFAULT_SORT_BY,
    });

    setSortBy(DEFAULT_SORT_BY);

    return;
  }

  setSortFilter({ filtersQuery, value: sortBy });
};

const getAudiencesFilters = ({
  resetPagination,
  pageIndex = 0,
  pageLimit = 10,
  setPagination,
  audienceId = null,
  selected = [],
  selectedFilters = [],
  setSelectedFilters = () => {},
  columnName = '',
  dates = {},
  sortValue = false,
  sortBy = {},
  setSortBy = () => {},
}) => {
  const filtersQuery = new URLSearchParams();

  if (audienceId) {
    filtersQuery.append('audienceId', audienceId);
  }

  let newSelectedValue = selected;
  if (dates.startDate) {
    newSelectedValue = dates;
  }

  Object.entries({
    ...selectedFilters,
    [columnName]: newSelectedValue,
  }).forEach((filter) => {
    const [firstFilter, secondFilter] = filter;

    if (secondFilter.isDate) {
      if (
        (firstFilter !== columnName && secondFilter.startDate) ||
        (firstFilter === columnName && dates.startDate && dates.endDate)
      ) {
        const start = secondFilter.startDate.format(DATE_FORMAT);
        const end = secondFilter.endDate.format(DATE_FORMAT);

        filtersQuery.append(firstFilter, true);
        filtersQuery.append(`${firstFilter}Start`, start);
        filtersQuery.append(`${firstFilter}End`, end);
      }
    } else if (secondFilter) {
      const selectedValues = secondFilter.map(({ text }) => text);

      if (selectedValues.length > 0) {
        const transformedValues = selectedValues.join(',');
        filtersQuery.append(firstFilter, transformedValues);
      }
    }
  });

  setSortingFilters({ columnName, filtersQuery, setSortBy, sortBy, sortValue });

  setSelectedData({ columnName, dates, selected, setSelectedFilters });

  const newPageIndex = resetPagination ? 0 : pageIndex;

  filtersQuery.append('page', newPageIndex);
  filtersQuery.append('limit', pageLimit);

  setPagination({
    page: newPageIndex,
    limit: pageLimit,
  });

  return `?${filtersQuery.toString()}`;
};

export default getAudiencesFilters;
