import { useState } from 'react';

const useMultipleStepFormProgress = (position) => {
  const [currentStep, setCurrentStep] = useState(position);

  const goForward = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const goBack = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  return [currentStep, goForward, goBack];
};

export default useMultipleStepFormProgress;
