import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import cloudIcon from 'assets/icons/cloud.png';
import selectExportDataState from 'state/selectors/exportData';
import Button, { Type as ButtonType } from 'components/Common/Button';

import { cancelExport } from 'state/actions/exportData';
import classes from './ExportInProgress.module.scss';

const ExportInProgress = () => {
  const dispatch = useDispatch();

  const { model } = useSelector(selectExportDataState, shallowEqual);

  const onCancelButton = () => {
    dispatch(cancelExport());
  };

  return (
    <div className={classes.exportInProgressContainer}>
      <div className={classes.exportInProgress}>
        <img
          src={cloudIcon}
          className={classes.exportInProgressIcon}
          alt="export in progress"
        />
        <div className={classes.exportText}>
          <span className={classes.principalText}>
            {model}s export in progress
          </span>
          <span>It could take some time...</span>
        </div>
      </div>
      <Button
        type={ButtonType.Default}
        className={classes.cancelButton}
        onClick={onCancelButton}
        kind="minimal"
      >
        Cancel
      </Button>
    </div>
  );
};

export default ExportInProgress;
