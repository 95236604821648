import client from 'services/kardAPI';

const fetchMerchantCategories = async () => {
  try {
    const categoriesData = await client.get(`/categories/portal/categories`);
    const categories = categoriesData.data.map(
      (category) => category.primaryCategory
    );
    return categories;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchMerchantCategories;
