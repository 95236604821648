import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';

const validationSchema = yup.object().shape({
  issuer: yup.string().min(2).required(Validations.RequiredIssuer),
  transactionId: yup
    .string()
    .min(3)
    .required(Validations.RequiredTransactionId),
  auditCode: yup
    .number()
    .oneOf([3005, 3006, 8001])
    .transform((val) => (Number.isNaN(val) ? -1 : val))
    .required(Validations.RequiredAuditCode),
  merchantName: yup
    .string()
    .transform((val) => (val === '' ? undefined : val))
    .required(Validations.RequiredMerchantName),
  preferredContactEmail: yup
    .string()
    .email(Validations.PreferredContactEmailInvalid)
    .required(Validations.PreferredContactEmail),
  auditDescription: yup
    .string()
    .transform((val) => (val === '' ? undefined : val)),
  referringPartnerUserId: yup
    .string()
    .transform((val) => (val === '' ? undefined : val)),

  transactionAmountInCents: yup
    .number()
    .transform((val) => (Number.isNaN(val) ? undefined : val)),
});

export default validationSchema;
