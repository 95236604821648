import { createSelector } from '@reduxjs/toolkit';

const selectPendingTransactionsState = (state) => state.pendingTransactions;

export const selectFetchPendingTransactionsState = createSelector(
  selectPendingTransactionsState,
  ({
    fetchPendingTransactionsLoading,
    fetchPendingTransactionsSuccess,
    fetchPendingTransactionsError,
    pendingTransactions,
    countTotal,
  }) => ({
    loading: fetchPendingTransactionsLoading,
    success: fetchPendingTransactionsSuccess,
    error: fetchPendingTransactionsError,
    result: pendingTransactions,
    count: countTotal,
  })
);

export const selectEditPendingTransactionState = createSelector(
  selectPendingTransactionsState,
  ({
    editPendingTransactionLoading,
    editPendingTransactionSuccess,
    editPendingTransactionError,
  }) => ({
    loading: editPendingTransactionLoading,
    success: editPendingTransactionSuccess,
    error: editPendingTransactionError,
  })
);

export const selectReprocessIncomingTransactionState = createSelector(
  selectPendingTransactionsState,
  ({
    reprocessIncomingTransactionLoading,
    reprocessIncomingTransactionSuccess,
    reprocessIncomingTransactionError,
  }) => ({
    loading: reprocessIncomingTransactionLoading,
    success: reprocessIncomingTransactionSuccess,
    error: reprocessIncomingTransactionError,
  })
);

export const selectReprocessPendingTransactionState = createSelector(
  selectPendingTransactionsState,
  ({
    reprocessPendingTransactionLoading,
    reprocessPendingTransactionSuccess,
    reprocessPendingTransactionFail,
    reprocessPendingTxns,
  }) => ({
    loading: reprocessPendingTransactionLoading,
    success: reprocessPendingTransactionSuccess,
    error: reprocessPendingTransactionFail,
    reprocessPendingTxns,
  })
);
