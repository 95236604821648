import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import LoginRoute from 'components/Navigation/LoginRoute';
import AuthenticatedRoute from 'components/Navigation/AuthenticatedRoute';
import { resumeSession } from 'state/actions/auth';
import {
  selectAuthenticatedState,
  selectUserAttributesState,
} from 'state/selectors/auth';
import Roles from 'enums/users/roles.enum';
import NavigationLayout from 'components/Layouts/NavigationLayout';
import { routes, getAuthenticatedRoutes, getLoginRoutes } from './paths';

const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resumeSession());
  }, [dispatch]);

  const { isAuthenticated } = useSelector(
    selectAuthenticatedState,
    shallowEqual
  );

  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual
  );

  const userHasKardAdminRole = useMemo(
    () => userRoles?.includes(Roles.KardAdmin),
    [userRoles]
  );

  const loginRoutes = getLoginRoutes(isAuthenticated);

  const authenticatedRoutes = getAuthenticatedRoutes({
    isAuthenticated,
    userHasKardAdminRole,
  });

  // don't display the header or sidebar when the user is not authenticated
  const isFullscreen = useMemo(() => !isAuthenticated, [isAuthenticated]);

  return (
    <BrowserRouter>
      <NavigationLayout fullscreen={isFullscreen}>
        <Switch>
          {loginRoutes.map(({ component, condition, redirectTo, path }) => (
            <LoginRoute
              key={path}
              component={component}
              condition={condition}
              redirectTo={redirectTo}
              path={path}
            />
          ))}
          {routes.map(({ component, path }) => (
            <Route component={component} path={path} key={path} />
          ))}
          {authenticatedRoutes.map(
            ({ component, condition, redirectTo, path }) => (
              <AuthenticatedRoute
                key={path}
                component={component}
                condition={condition}
                redirectTo={redirectTo}
                path={path}
              />
            )
          )}
        </Switch>
      </NavigationLayout>
    </BrowserRouter>
  );
};

export default Router;
