import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import addIcon from 'assets/icons/close.svg';
import { selectCreateOfferState } from 'state/selectors/offers';
import { clearOffersErrors } from 'state/actions/offers';

import classes from './MerchantOffersAndLocations.module.scss';
import OfferForm, { OfferFormMode } from '../OfferForm';
import LocationsUpload from '../LocationsUpload';

const MerchantOffersAndLocations = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [showOffersFlow, setShowOffersFlow] = useState(false);
  const [showLocationsFlow, setShowLocationsFlow] = useState(false);

  const { success: successCreateOffer } = useSelector(
    selectCreateOfferState,
    shallowEqual
  );

  useEffect(() => {
    if (successCreateOffer) {
      setShowOffersFlow(false);
      dispatch(clearOffersErrors());
    }
  }, [successCreateOffer, dispatch]);

  const onClickAddOffersHandler = useCallback(() => {
    setShowOffersFlow(true);
  }, []);

  const onCancelAddOffersHandler = useCallback(() => {
    setShowOffersFlow(false);
  }, []);

  const onClickAddLocationsHandler = useCallback(() => {
    setShowLocationsFlow(true);
  }, []);

  const onCancelAddLocationsHandler = useCallback(() => {
    setShowLocationsFlow(false);
  }, []);

  return (
    <>
      {!showLocationsFlow && !showOffersFlow && (
        <>
          <Heading size={HeadingSize.M} className={classes.heading}>
            Add Offers and Locations to the Merchant
          </Heading>
          <Body
            size={BodySize.S}
            className={classes.subtitle}
            color={BodyColor.Gray}
          >
            The merchant was successfully created, add new offers and locations,
            this is not required.
          </Body>
          <div className={classes.addButtonsContainer}>
            <Button
              type={ButtonType.Default}
              size={ButtonSize.S}
              onClick={onClickAddOffersHandler}
              className={classes.addButtons}
            >
              <div className={classes.buttonsContent}>
                <div className={classes.addIcon}>
                  <img src={addIcon} alt="add" />
                </div>
                Add offer
              </div>
            </Button>
            <Button
              type={ButtonType.Default}
              size={ButtonSize.S}
              onClick={onClickAddLocationsHandler}
              className={classes.addButtons}
            >
              <div className={classes.buttonsContent}>
                <div className={classes.addIcon}>
                  <img src={addIcon} alt="add" />
                </div>
                Add locations
              </div>
            </Button>
          </div>
          <div className={classes.actionButtons}>
            <Button
              type={ButtonType.Default}
              size={ButtonSize.S}
              onClick={onCancel}
              kind={ButtonKind.Secondary}
            >
              Close
            </Button>
          </div>
        </>
      )}
      {showOffersFlow && (
        <OfferForm
          onCancel={onCancelAddOffersHandler}
          initialMode={OfferFormMode.NEWMERCHANT}
        />
      )}
      {showLocationsFlow && (
        <LocationsUpload
          title="Add New Merchant Location"
          onCancel={onCancelAddLocationsHandler}
          isCreateMerchantFlow
        />
      )}
    </>
  );
};

MerchantOffersAndLocations.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default MerchantOffersAndLocations;
