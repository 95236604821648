import React from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';

import classes from './ConfirmationMessage.module.scss';

const ConfirmationMessage = ({
  title,
  message,
  onAccept,
  onCancel,
  loading,
}) => (
  <div className={classes.container}>
    {title && <Heading size={HeadingSize.M}>{title}</Heading>}
    <Body size={BodySize.S} className={classes.body}>
      {message}
    </Body>
    <div className={classes.buttons}>
      <Button
        type={ButtonType.Submit}
        size={ButtonSize.S}
        className={classes.button}
        onClick={onCancel}
        kind={ButtonKind.Secondary}
      >
        Cancel
      </Button>
      <Button
        type={ButtonType.Submit}
        size={ButtonSize.S}
        className={classes.button}
        onClick={onAccept}
        loading={loading}
      >
        Accept
      </Button>
    </div>
  </div>
);

ConfirmationMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ConfirmationMessage.defaultProps = {
  title: null,
  loading: false,
};

export default ConfirmationMessage;
