/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, { Type as InputType } from 'components/Common/Input';
import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import ErrorMessage from 'components/Typography/ErrorMessage';
import { completeNewPassword } from 'state/actions/auth';
import { selectChangePasswordState } from 'state/selectors/auth';
import lockIcon from 'assets/icons/lock.svg';
import showPasswordIcon from 'assets/icons/eye-solid.svg';
import hidePasswordIcon from 'assets/icons/eye-slash-solid.svg';

import validationSchema from './PasswordChangeRequiredForm.schema';

import classes from './PasswordChangeRequired.module.scss';

const PasswordChangeRequiredForm = () => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector(
    selectChangePasswordState,
    shallowEqual
  );

  const [showPassword, setShowPassword] = useState(false);

  const onSubmitHandler = useCallback(
    ({ password }) => {
      dispatch(completeNewPassword(password));
    },
    [dispatch]
  );

  const onChangeShowPassword = useCallback(
    () => setShowPassword((prevState) => !prevState),
    []
  );

  return (
    <>
      <Heading size={HeadingSize.XL}>Change Password</Heading>
      <Form
        validationMode={ValidationMode.OnChange}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        <label className={classes.label}>New password</label>
        <div className={classes.passwordContainer}>
          <FormControl
            name="password"
            render={(props) => (
              <Input
                type={showPassword ? InputType.Text : InputType.Password}
                icon={lockIcon}
                className={classes.newPasswordInput}
                {...props}
              />
            )}
          />
          <img
            src={showPassword ? hidePasswordIcon : showPasswordIcon}
            alt="hide or show password"
            className={classes.hideShowPassword}
            onClick={onChangeShowPassword}
          />
        </div>
        <Button
          type={ButtonType.Submit}
          size={ButtonSize.L}
          loading={loading}
          isFullWidth
        >
          Change Password
        </Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </>
  );
};

export default PasswordChangeRequiredForm;
