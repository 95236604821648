import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Dropdown from 'components/Common/Dropdown';
import Avatar from 'components/Common/Avatar';
import { logout } from 'state/actions/auth';
import { selectUserAttributesState } from 'state/selectors/auth';
import Path from 'enums/path.enum';
import arrowDown from 'assets/icons/arrows/arrow-down.svg';

import { fetchUserProfile } from 'state/actions/users';
import { selectFetchUserProfileState } from 'state/selectors/users';

import Modal from 'components/Common/Modal';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType';
import UserForm from 'components/Pages/UserForm';
import classes from './Header.module.scss';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { fullName } = useSelector(selectUserAttributesState, shallowEqual);

  const { userProfile } = useSelector(
    selectFetchUserProfileState,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const onClickLogoutHandler = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const onClickEditProfileHandler = useCallback(() => {
    onOpenModalHandler(ModalType.USER_PROFILE);
  }, [onOpenModalHandler]);

  const onClickHelpHandler = useCallback(() => {
    history.push(Path.Help);
  }, [history]);

  const dropdownItems = useMemo(
    () => [
      { name: 'Profile', onClick: onClickEditProfileHandler },
      { name: 'Help', onClick: onClickHelpHandler },
      { name: 'Log Out', onClick: onClickLogoutHandler },
    ],
    [onClickEditProfileHandler, onClickHelpHandler, onClickLogoutHandler]
  );

  return (
    <>
      <Modal
        isOpen={modal.type === ModalType.USER_PROFILE}
        onClose={onCloseModalHandler}
        className={classes.userProfile}
      >
        <UserForm
          title="User profile"
          user={userProfile}
          isProfile
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <header className={classes.header}>
        <Dropdown items={dropdownItems}>
          <div className={classes.container}>
            <Avatar
              className={classes.avatar}
              name={fullName || 'Admin User'}
            />
            <img src={arrowDown} alt="Arrow down" />
          </div>
        </Dropdown>
      </header>
    </>
  );
};

Header.propTypes = {};

export default Header;
