/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchOffersInit,
  fetchOffersSuccess,
  fetchOffersFail,
  createOfferInit,
  createOfferSuccess,
  createOfferFail,
  editOfferInit,
  editOfferSuccess,
  editOfferFail,
  clearOffersErrorsData,
  fetchOffersByMerchantIdInit,
  fetchOffersByMerchantIdSuccess,
  fetchOffersByMerchantIdFail,
  fetchOfferByIdInit,
  fetchOfferByIdSuccess,
  fetchOfferByIdFail,
  clearOffersSuccessProp,
} from 'state/actionCreators/offers';

export const initialState = {
  fetchOffersLoading: false,
  fetchOffersSuccess: false,
  fetchOffersError: null,
  fetchOffersByMerchantIdLoading: false,
  fetchOffersByMerchantIdSuccess: false,
  fetchOffersByMerchantIdError: null,
  fetchOfferByIdLoading: false,
  fetchOfferByIdSuccess: false,
  fetchOfferByIdError: null,
  createOfferLoading: false,
  createOfferSuccess: false,
  createOfferError: null,
  editOfferLoading: false,
  editOfferSuccess: false,
  editOfferError: null,
  msgOfferStatus: null,
  offersByMerchant: [],
  offers: [],
  offer: null,
  createdOffer: null,
};

const persistConfig = {
  key: 'offers',
  storage,
  blacklist: [
    'fetchOffersLoading',
    'fetchOffersSuccess',
    'fetchOffersError',
    'fetchOffersByMerchantIdLoading',
    'fetchOffersByMerchantIdSuccess',
    'fetchOffersByMerchantIdError',
    'fetchOfferByIdLoading',
    'fetchOfferByIdSuccess',
    'fetchOfferByIdError',
    'createOfferLoading',
    'createOfferSuccess',
    'createOfferError',
    'editOfferLoading',
    'editOfferSuccess',
    'editOfferError',
    'offers',
    'offersByMerchant',
    'offer',
    'createdOffer',
    'msgOfferStatus',
  ],
};

const offersReducer = persistReducer(
  persistConfig,
  createReducer(initialState, {
    [fetchOffersInit]: (state) => {
      state.fetchOffersLoading = true;
      state.fetchOffersSuccess = false;
      state.fetchOffersError = null;
      state.offers = [];
      state.offer = null;
      state.fetchOfferByIdError = null;
    },
    [fetchOffersSuccess]: (state, action) => {
      const { offers } = action.payload;
      state.fetchOffersLoading = false;
      state.fetchOffersError = null;
      state.fetchOffersSuccess = true;
      state.offers = offers;
    },
    [fetchOffersFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchOffersLoading = false;
      state.fetchOffersError = error;
      state.fetchOffersSuccess = false;
    },
    [fetchOffersByMerchantIdInit]: (state) => {
      state.fetchOffersByMerchantIdLoading = true;
      state.fetchOffersByMerchantIdSuccess = false;
      state.fetchOffersByMerchantIdError = null;
      state.offersByMerchant = [];
    },
    [fetchOffersByMerchantIdSuccess]: (state, action) => {
      const { offers } = action.payload;
      state.fetchOffersByMerchantIdLoading = false;
      state.fetchOffersByMerchantIdError = null;
      state.fetchOffersByMerchantIdSuccess = true;
      state.offersByMerchant = offers;
    },
    [fetchOffersByMerchantIdFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchOffersByMerchantIdLoading = false;
      state.fetchOffersByMerchantIdError = error;
      state.fetchOffersByMerchantIdSuccess = false;
    },
    [fetchOfferByIdInit]: (state) => {
      state.fetchOfferByIdLoading = true;
      state.fetchOfferByIdSuccess = false;
      state.fetchOfferByIdError = null;
      state.offer = null;
    },
    [fetchOfferByIdSuccess]: (state, action) => {
      const { offer } = action.payload;
      state.fetchOfferByIdLoading = false;
      state.fetchOfferByIdError = null;
      state.fetchOfferByIdSuccess = true;
      state.offer = offer;
    },
    [fetchOfferByIdFail]: (state, action) => {
      const { error } = action.payload;
      state.fetchOfferByIdLoading = false;
      state.fetchOfferByIdError = error;
      state.fetchOfferByIdSuccess = false;
      state.offer = null;
    },
    [createOfferInit]: (state) => {
      state.createOfferLoading = true;
      state.createOfferError = null;
      state.createOfferSuccess = false;
      state.createdOffer = null;
    },
    [createOfferSuccess]: (state, action) => {
      const { offer } = action.payload;
      state.createOfferLoading = false;
      state.createOfferError = null;
      state.createOfferSuccess = true;
      state.createdOffer = offer;
    },
    [createOfferFail]: (state, action) => {
      const { error } = action.payload;
      state.createOfferLoading = false;
      state.createOfferError = error;
      state.createOfferSuccess = false;
      state.createdOffer = null;
    },
    [editOfferInit]: (state) => {
      state.editOfferLoading = true;
      state.editOfferError = null;
      state.editOfferSuccess = false;
    },
    [editOfferSuccess]: (state) => {
      state.editOfferLoading = false;
      state.editOfferError = null;
      state.editOfferSuccess = true;
    },
    [editOfferFail]: (state, action) => {
      const { error } = action.payload;
      state.editOfferLoading = false;
      state.editOfferError = true;
      state.editOfferSuccess = false;
      state.msgOfferStatus = error;
    },
    [clearOffersErrorsData]: (state) => {
      state.createOfferLoading = false;
      state.createOfferError = null;
      state.editOfferLoading = false;
      state.editOfferError = null;
      state.editOfferSuccess = false;
      state.msgOfferStatus = null;
      state.fetchOfferByIdError = null;
    },
    [clearOffersSuccessProp]: (state) => {
      state.createOfferSuccess = false;
    },
  })
);

export default offersReducer;
