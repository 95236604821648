import StatusValue from 'enums/status/statusValue.enum';
import { getRoleIdForLabel } from 'utils/users/roles';

const getUsersFilters = (filters) =>
  Object.entries(filters)
    .reduce((search, [field, value]) => {
      if (!value) {
        return search;
      }
      let [apiField, apiValue] = [field, value];
      if (value === StatusValue.Active) {
        apiValue = 'enabled';
      }
      if (value === StatusValue.Inactive) {
        apiValue = 'disabled';
      }
      if (field === 'roles') {
        apiField = 'role';
        apiValue = getRoleIdForLabel(value);
      }
      search.append(apiField, apiValue);
      return search;
    }, new URLSearchParams())
    .toString();

export default getUsersFilters;
