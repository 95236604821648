import { Auth } from 'aws-amplify';

import amplifyErrorMessage from 'utils/amplifyErrorMessage';

const forgotPassword = async (username) => {
  try {
    await Auth.forgotPassword(username);
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export default forgotPassword;
