import React, { forwardRef, useMemo, useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';

import classes from './DatePicker.module.scss';

const DatePicker = ({
  date,
  onChange,
  placeholder,
  error,
  color,
  disabled,
  readOnly,
  defaultValue,
  showTimeSelect,
  onBlur,
}) => {
  // eslint-disable-next-line react/prop-types
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={classNames(classes.button, {
        [classes.error]: error,
        [classes[color]]: !!value,
        [classes.disabled]: disabled,
      })}
      type="button"
      onClick={onClick}
      ref={ref}
      disabled={disabled || readOnly}
    >
      {value || placeholder}
    </button>
  ));

  const dateValue = useCallback(() => {
    if (date) {
      return new Date(date);
    }
    if (defaultValue) {
      return new Date(defaultValue);
    }
    return null;
  }, [date, defaultValue]);

  const dateFormat = useMemo(
    () => (showTimeSelect ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy'),
    [showTimeSelect]
  );

  return (
    <ReactDatePicker
      selected={dateValue()}
      onChange={onChange}
      onBlur={onBlur}
      customInput={<CustomInput />}
      showTimeSelect={showTimeSelect}
      timeFormat="HH:mm"
      timeIntervals={15}
      dateFormat={dateFormat}
    />
  );
};

DatePicker.propTypes = {
  date: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  date: null,
  placeholder: 'MM/DD/YYYY',
  error: false,
  disabled: false,
  readOnly: false,
  defaultValue: null,
  showTimeSelect: false,
};

export default DatePicker;
