const categoryList = [
  'Arts & Entertainment',
  'Baby, Kids & Toys',
  'Books & Digital Media',
  'Clothing, Shoes & Accessories',
  'Computers, Electronics & Software',
  'Convenience',
  'Department Stores',
  'Food & Beverage',
  'Gas',
  'Health & Beauty',
  'Home & Garden',
  'Miscellaneous',
  'Occasions & Gifts',
  'Pets',
  'Sports & Outdoors',
  'Supplies & Services',
  'Travel',
];

export default categoryList;
