import {
  fetchReportsInit,
  fetchReportsSuccess,
  fetchReportsFail,
  clearReportsData,
} from 'state/actionCreators/reports';

import fetchReportsService from 'services/reports/fetchReports';

export const fetchReports = (filters = '') => async (dispatch) => {
  dispatch(fetchReportsInit());

  try {
    const reports = await fetchReportsService(filters);
    return dispatch(fetchReportsSuccess({ reports }));
  } catch (error) {
    return dispatch(fetchReportsFail({ error: error.message }));
  }
};

export const clearFetchReportsData = () => async (dispatch) => {
  dispatch(clearReportsData());
};
