import Path from 'enums/path.enum';

import Home from 'pages/Home';
import Login from 'pages/Login';
import Offers from 'pages/Offers';
import Support from 'pages/Support';
import Audience from 'pages/Audience';
import Locations from 'pages/Locations';
import Merchants from 'pages/Merchants';
import ReportsPage from 'pages/Reports';
import PortalUsers from 'pages/PortalUsers';
import ForgotPassword from 'pages/ForgotPassword';
import PendingTransactions from 'pages/PendingTransactions';
import TransactionsSupport from 'pages/TransactionsSupport';
import QueryPlayground from 'refactored/pages/QueryPlayground';

export const routes = [
  {
    component: Support,
    path: Path.Support,
  },
  ...(process.env.NODE_ENV === 'development'
    ? [
        {
          component: QueryPlayground,
          path: '/playground',
        },
      ]
    : []),
];

export const getAuthenticatedRoutes = ({
  isAuthenticated,
  userHasKardAdminRole,
}) => [
  {
    component: Merchants,
    path: Path.Merchants,
    condition: isAuthenticated,
    redirectTo: Path.Login,
  },
  {
    component: Offers,
    path: `${Path.Offers}/:merchantId?`,
    condition: isAuthenticated,
    redirectTo: Path.Login,
  },
  {
    component: Locations,
    path: Path.Locations,
    condition: isAuthenticated,
    redirectTo: Path.Login,
  },
  {
    component: TransactionsSupport,
    path: Path.TransactionsSupport,
    condition: isAuthenticated,
    redirectTo: Path.Login,
  },
  {
    component: Audience,
    path: Path.Audience,
    condition: userHasKardAdminRole,
    redirectTo: Path.Login,
  },
  {
    component: ReportsPage,
    path: Path.Reports,
    condition: isAuthenticated,
    redirectTo: Path.Login,
  },
  {
    component: PendingTransactions,
    condition: userHasKardAdminRole,
    redirectTo: Path.Home,
    path: Path.PendingTransactions,
  },
  {
    component: PortalUsers,
    condition: userHasKardAdminRole,
    redirectTo: Path.Home,
    path: Path.PortalUsers,
  },
  {
    component: Home,
    path: Path.Home,
    condition: isAuthenticated,
    redirectTo: Path.Login,
  },
];

export const getLoginRoutes = (isAuthenticated) => [
  {
    component: ForgotPassword,
    condition: !isAuthenticated,
    redirectTo: Path.Home,
    path: Path.ForgotPassword,
  },
  {
    component: Login,
    condition: !isAuthenticated,
    redirectTo: Path.Home,
    path: Path.Login,
  },
];
