import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Table from 'components/Common/Table';
import Modal from 'components/Common/Modal';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import searchIcon from 'assets/icons/search.svg';
import addIcon from 'assets/icons/close.svg';
import ModalType from 'enums/modal/modalType';
import Columns from 'enums/table/columns.enum';
import getAuditFilters from 'utils/filters/auditFilters';
import { AuditStatus } from 'enums/status/auditStatus';
import allAuditsColumns from 'utils/transactionsSupport/columns';
import useModal from 'hooks/useModal';
import {
  clearAuditsErrors,
  fetchAuditById,
  fetchAudits,
} from 'state/actions/audits';
import {
  selectCreateAuditState,
  selectEditAuditState,
  selectFetchAuditByIdState,
  selectFetchAuditsState,
} from 'state/selectors/audits';
import AuditForm from 'components/Pages/AuditForm';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import { selectUserAttributesState } from 'state/selectors/auth';

import classes from './TransactionsSupport.module.scss';

const filterByCreatedDateDesc = '?createdDateSort=-1';
const filterByNewAndInProgressStatus = '&status=NEW&status=IN_PROGRESS';

const defaultFilters = (isAdmin) => {
  if (isAdmin)
    return {
      status: [
        { columnName: 'status', text: 'NEW', type: 'string' },
        { columnName: 'status', text: 'IN PROGRESS', type: 'string' },
      ],
    };
  return {};
};

const TransactionsSupport = () => {
  const dispatch = useDispatch();
  const [isDefaultFilter, setIsDefaultFilter] = useState(true);
  const [filter, setFilter] = useState(filterByCreatedDateDesc);
  const { isAdmin } = useSelector(selectUserAttributesState, shallowEqual);

  const { auditsData, loading: loadingFetchAudits } = useSelector(
    selectFetchAuditsState,
    shallowEqual
  );

  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual
  );

  const columns = useMemo(
    () => [
      {
        Header: 'header',
        columns: getAllowedColumns(allAuditsColumns[0].columns, userRoles),
      },
    ],
    [userRoles]
  );

  const {
    audit: auditById,
    loading: loadingFetchAuditById,
    error: errorFetchAuditById,
  } = useSelector(selectFetchAuditByIdState, shallowEqual);

  const {
    error: errorCreatingAudit,
    success: successCreatingAudit,
  } = useSelector(selectCreateAuditState, shallowEqual);

  const {
    error: errorEditingAudit,
    success: successEditingAudit,
  } = useSelector(selectEditAuditState, shallowEqual);

  const [data, setData] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState(
    defaultFilters(isAdmin)
  );

  const [selectedFiltersGeneral, setSelectedFiltersGeneral] = useState({});

  const [generalCount, setGeneralCount] = useState();

  const [sortBy, setSortBy] = useState({
    ascending: false,
    descending: true,
    columnName: 'createdDate',
  });

  const [searchText, setSearchText] = useState('');

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 100,
  });

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const { countTotal, audits } = auditsData;

  const auditFilter =
    isAdmin && isDefaultFilter
      ? `${filterByCreatedDateDesc}${filterByNewAndInProgressStatus}`
      : filter;

  useEffect(() => {
    dispatch(fetchAudits(auditFilter));
  }, [dispatch, auditFilter]);

  useEffect(() => {
    if (successCreatingAudit || successEditingAudit) {
      if (!searchText) {
        dispatch(fetchAudits(auditFilter));
      }

      if (searchText) {
        dispatch(fetchAuditById(searchText));
      }

      dispatch(clearAuditsErrors());
      onCloseModalHandler();
    }
  }, [
    dispatch,
    selectedFiltersGeneral,
    searchText,
    onCloseModalHandler,
    successCreatingAudit,
    successEditingAudit,
    auditFilter,
  ]);

  useEffect(() => {
    if (audits.length > 0 && data.length === 0 && searchText === '') {
      const transformedData = audits.map((audit) => ({
        ...audit,
        auditId: audit._id,
        status: AuditStatus[audit.status],
      }));
      setData(transformedData);
      setGeneralCount(
        Math.ceil(parseInt(countTotal, 10) / parseInt(pagination.limit, 10))
      );
    } else if (audits.length === 0 && data.length > 0) {
      setData([]);
    }
  }, [audits, data, searchText, countTotal, pagination]);

  useEffect(() => {
    if (auditById && searchText !== '') {
      const transformedData = [
        {
          ...auditById,
          auditId: auditById._id,
          status: AuditStatus[auditById.status],
        },
      ];
      setData(transformedData);
    }
  }, [auditById, searchText]);

  useEffect(() => {
    if (errorFetchAuditById) {
      dispatch(clearAuditsErrors());
    }
  }, [errorFetchAuditById, dispatch]);

  useEffect(() => {
    if (errorCreatingAudit || errorEditingAudit) {
      dispatch(clearAuditsErrors());
    }
  }, [errorCreatingAudit, errorEditingAudit, dispatch]);

  const onClickCellButtonHandler = useCallback(
    (columnName, rowIndex) => {
      if (columnName === Columns.MoreInfo) {
        onOpenModalHandler(ModalType.TRANSACTIONS_SUPPORT_INFO, {
          audit: data[rowIndex],
        });
      }
    },
    [onOpenModalHandler, data]
  );

  const onSearchByIdHandler = useCallback(() => {
    dispatch(fetchAuditById(searchText));
  }, [searchText, dispatch]);

  const onSubmitFilterHandler = useCallback(
    ({
      resetPagination = false,
      selected,
      auditId,
      columnName,
      dates,
      sortValue,
      pageIndex,
      pageSize,
    }) => {
      const actualID = auditId === '' ? auditId : searchText;
      const actualPage =
        pageIndex || pageIndex === 0 ? pageIndex : pagination.page;
      const filters = getAuditFilters({
        resetPagination,
        pageIndex: actualPage,
        pageLimit: pageSize || pagination.limit,
        setPagination,
        auditId: actualID,
        selected,
        selectedFilters,
        setSelectedFilters,
        setIsDefaultFilter,
        columnName,
        dates,
        sortValue,
        sortBy,
        setSortBy,
      });
      setData([]);
      const generalFilter = filters.replace('IN+PROGRESS', 'IN_PROGRESS');
      setFilter(generalFilter);
      setSelectedFiltersGeneral(generalFilter);
      dispatch(fetchAudits(generalFilter));
    },
    [selectedFilters, sortBy, pagination, searchText, dispatch]
  );

  const onChangeSearchTextHandler = useCallback(
    (text) => {
      setSearchText(text);
      if (text === '') {
        onSubmitFilterHandler({ auditId: '' });
      }
    },
    [onSubmitFilterHandler]
  );

  const onAddAuditHandler = useCallback(() => {
    onOpenModalHandler(ModalType.ADD_AUDIT);
  }, [onOpenModalHandler]);

  return (
    <>
      {errorFetchAuditById && (
        <Toast
          id="fetch audit by id"
          text="There is no audit that matches that transaction Id"
          type={ToastType.Error}
        />
      )}
      {(errorEditingAudit || errorCreatingAudit) && (
        <Toast
          id={
            errorCreatingAudit ? 'error creating audit' : 'error editing audit'
          }
          text={errorEditingAudit || errorCreatingAudit}
          type={ToastType.Error}
        />
      )}
      {successCreatingAudit && (
        <Toast
          id="success creating audit"
          text="Our team has received your request and will provide a status update within 2 business days - thank you!"
          type={ToastType.Success}
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.TRANSACTIONS_SUPPORT_INFO}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <AuditForm
          title="Audit information"
          onCancel={onCloseModalHandler}
          audit={modal.audit}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ADD_AUDIT}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <AuditForm
          title="Add New Audit"
          isCreating
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <div className={classes.table}>
        <div className={classes.tableHeading}>
          <div className={classes.searchWidth}>
            <Input
              name="search"
              onChange={(event) =>
                onChangeSearchTextHandler(event.target.value)
              }
              placeholder="Search By Transaction ID"
              type={InputType.Text}
              icon={searchIcon}
              size={InputSize.S}
              className={classes.search}
              onKeyPress={onSearchByIdHandler}
            />
          </div>
          <div className={classes.actions}>
            <Button
              type={ButtonType.Submit}
              size={ButtonSize.S}
              className={classes.button}
              onClick={onAddAuditHandler}
            >
              <div className={classes.addIcon}>
                <img src={addIcon} alt="add" />
              </div>
            </Button>
          </div>
        </div>
        {loadingFetchAudits || loadingFetchAuditById ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={columns}
            data={data}
            selectedFilters={selectedFilters}
            sortBy={sortBy}
            onSubmitFilter={onSubmitFilterHandler}
            onClickCellButton={onClickCellButtonHandler}
            itemsPerPage={pagination.limit}
            currentPage={pagination.page}
            manualPagination
            fetchData={onSubmitFilterHandler}
            bodyClassName={classes.tableBody}
            countTotal={generalCount}
            newPagination
          />
        )}
      </div>
    </>
  );
};

export default TransactionsSupport;
