import React from 'react';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Dropdown.module.scss';

const Dropdown = ({ items, children }) => (
  <Menu as="div" className={classes.menu}>
    <Menu.Button className={classes.button}>{children}</Menu.Button>
    <Menu.Items className={classes.dropdown}>
      {items.map(({ name, onClick: onClickItemHandler }) => (
        <Menu.Item key={name} data-testid="menuItem">
          {({ active }) => (
            <button
              type="button"
              className={classNames(classes.item, {
                [classes.itemActive]: active,
              })}
              onClick={onClickItemHandler}
            >
              {name}
            </button>
          )}
        </Menu.Item>
      ))}
    </Menu.Items>
  </Menu>
);

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  children: PropTypes.node.isRequired,
};

export default Dropdown;
