import Status from 'enums/status/status.enum';
import CognitoUserAttributes from 'enums/users/cognitoAttributes.enum';

/**
 * Format Cognito portal user data, as passed from the API, into usable fields/values.
 * @param {Object} user the Cognito user data.
 * @returns {Object} a record of user properties for display/editing.
 */
function getPortalUserData(user) {
  const cognitoUserAttributes = Object.fromEntries(
    Object.entries(CognitoUserAttributes).map(([key, attributeName]) => [
      key,
      user.Attributes.find(({ Name }) => Name === attributeName)?.Value,
    ])
  );
  return {
    ...cognitoUserAttributes,
    status: user.Enabled ? Status.ACTIVE : Status.INACTIVE,
    roles:
      cognitoUserAttributes.roles
        ?.split(',')
        .map((role) => role.toLowerCase()) ?? [],
    username: user.Username,
  };
}

export default getPortalUserData;
