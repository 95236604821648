import React, { useCallback } from 'react';
import { DateRangePicker } from 'react-dates';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import classNames from 'classnames';

import Body, { Color as BodyColor } from 'components/Typography/Body';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import './RangeDatePicker.scss';
import classes from './RangeDatePicker.module.scss';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
});

export const LabelPosition = Object.freeze({
  Top: 'top',
  Left: 'left',
});

const START_TIME = Object.freeze({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});

const END_TIME = Object.freeze({
  hour: 23,
  minute: 59,
  second: 59,
  millisecond: 999,
});

const RangeDatePicker = ({
  startDate,
  endDate,
  onChangeDates,
  focusedInput,
  onChangeFocus,
  className,
  label,
  labelClassName,
  labelPosition,
  error,
  size,
  disabled,
  ...rest
}) => {
  const onChangeDatesHandler = useCallback(
    ({ startDate: start, endDate: end }) => {
      onChangeDates({
        startDate: start?.set(START_TIME),
        endDate: end?.set(END_TIME),
      });
    },
    [onChangeDates]
  );

  const onChangeFocussHandler = useCallback(
    (focused) => {
      onChangeFocus(focused);
    },
    [onChangeFocus]
  );

  const windowOrietation = window.matchMedia('(max-width: 768px)').matches
    ? 'vertical'
    : 'horizontal';

  return (
    <div className={classNames(classes.container)}>
      <div
        className={classNames(
          className,
          classes.content,
          classes[labelPosition]
        )}
      >
        {label && (
          <Body
            size={size}
            color={BodyColor.Gray}
            className={classNames(labelClassName, classes.label)}
          >
            {label}
          </Body>
        )}
        <DateRangePicker
          startDate={startDate}
          startDateId="start_date_id"
          endDate={endDate}
          endDateId="end_date_id"
          onDatesChange={onChangeDatesHandler}
          focusedInput={focusedInput}
          onFocusChange={onChangeFocussHandler}
          daySize={35}
          small
          noBorder
          orientation={windowOrietation}
          isOutsideRange={() => false}
          disabled={disabled}
          {...rest}
        />
      </div>
      {error && (
        <Body size={size} color={BodyColor.Red} className={classes.label}>
          Please select both dates
        </Body>
      )}
    </div>
  );
};

RangeDatePicker.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  onChangeDates: PropTypes.func.isRequired,
  focusedInput: PropTypes.oneOf(['startDate', 'endDate']),
  onChangeFocus: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Size)),
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
};

RangeDatePicker.defaultProps = {
  startDate: null,
  endDate: null,
  focusedInput: null,
  className: '',
  label: null,
  size: Size.S,
  labelPosition: LabelPosition.Left,
  error: false,
  disabled: false,
  labelClassName: '',
};

export default RangeDatePicker;
