import client from 'services/kardAPI';

const createAudit = async (body) => {
  try {
    await client.post(`/transactions/portal/audit`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createAudit;
