import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import PropTypes from 'prop-types';

export const Size = Object.freeze({
  L: 12,
  M: 7,
});

export const Color = Object.freeze({
  White: '#FFF',
  Black: '#000',
});

const Spinner = ({ color, size, className }) => (
  <div className={className}>
    <ClipLoader color={color} size={size} />
  </div>
);

Spinner.propTypes = {
  color: PropTypes.oneOf(Object.values(Color)),
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
};

Spinner.defaultProps = {
  color: Color.White,
  size: Size.M,
  className: '',
};

export default Spinner;
