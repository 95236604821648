/* eslint-disable react/prop-types */
import React from 'react';

import FilterTypes from 'enums/reports/filterTypes.enum';
import informationIcon from 'assets/icons/color/information.svg';
import { RoleLabels } from 'enums/users/roles.enum';
import Status from 'components/Common/Status';
import { getRoleLabelForId } from '../roles';

const columns = [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Email',
        accessor: 'email',
        columnName: 'email',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        sortable: false,
        filterable: true,
        multiValue: false,
        Cell: ({ value }) => <Status kind={value} />,
        filter: {
          type: FilterTypes.String,
          options: ['ACTIVE', 'INACTIVE'],
        },
        isToggle: true,
      },
      {
        Header: 'Roles',
        accessor: 'roles',
        columnName: 'roles',
        sortable: false,
        filterable: true,
        multiValue: false,
        filter: {
          type: FilterTypes.String,
          options: Object.values(RoleLabels),
        },
        Cell: ({ value }) => value.map(getRoleLabelForId).join(' + '),
      },
      {
        Header: 'More info',
        accessor: 'moreInfo',
        columnName: 'moreInfo',
        sortable: false,
        filterable: false,
        isButton: true,
        Cell: () => <img src={informationIcon} alt="More information" />,
      },
    ],
  },
];

export default columns;
