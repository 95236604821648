import Validations from 'enums/schemaValidations/validations.enum';
import * as yup from 'yup';

const ruleBuilderSchema = yup.object().shape({
  behavior: yup.string().nullable().required(Validations.RequiredBehavior),
  merchant: yup.string().nullable().required(Validations.RequiredMerchant),
  behaviorRange: yup
    .string()
    .nullable()
    .required(Validations.RequiredPurchaseRange),
  behaviorValue: yup
    .number()
    .min(0)
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(Validations.RequiredPurchaseCountValue),
  timescale: yup.string().nullable().required(Validations.RequiredTimescale),
  period: yup.string().nullable().required(Validations.RequiredPeriod),
  timescaleValue: yup
    .number()
    .min(0)
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(Validations.RequiredTimescaleValue)
    .when('period', {
      is: (timescale) => timescale === 'days',
      then: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .max(730)
        .min(7),
    })
    .when('period', {
      is: (timescale) => timescale === 'months',
      then: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .max(24)
        .min(1),
    })
    .when('period', {
      is: (timescale) => timescale === 'years',
      then: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .max(2)
        .min(1),
    }),
});

export default ruleBuilderSchema;
