/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const selectPortalUsersState = (state) => state.users;

export const selectFetchPortalUsersState = createSelector(
  selectPortalUsersState,
  ({
    fetchPortalUsersLoading,
    fetchPortalUsersSuccess,
    fetchPortalUsersError,
    portalUsers: { results, countTotal },
  }) => ({
    loading: fetchPortalUsersLoading,
    success: fetchPortalUsersSuccess,
    error: fetchPortalUsersError,
    portalUsersData: {
      portalUsers: results || [],
      countTotal: countTotal || 0,
    },
  })
);

export const selectEditPortalUserState = createSelector(
  selectPortalUsersState,
  ({ editPortalUserLoading, editPortalUserSuccess, editPortalUserError }) => ({
    loading: editPortalUserLoading,
    success: editPortalUserSuccess,
    error: editPortalUserError,
  })
);

export const selectCreatePortalUserState = createSelector(
  selectPortalUsersState,
  ({
    createPortalUserLoading,
    createPortalUserSuccess,
    createPortalUserError,
  }) => ({
    loading: createPortalUserLoading,
    success: createPortalUserSuccess,
    error: createPortalUserError,
  })
);

export const selectFetchUserProfileState = createSelector(
  selectPortalUsersState,
  ({
    fetchUserProfileLoading,
    fetchUserProfileSuccess,
    fetchUserProfileError,
    userProfile,
  }) => ({
    loading: fetchUserProfileLoading,
    success: fetchUserProfileSuccess,
    error: fetchUserProfileError,
    userProfile,
  })
);
