import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Link.module.scss';

const Link = ({ to, children, underline, contents }) => (
  <RouterLink
    className={classNames(classes.link, {
      [classes.underline]: underline,
      [classes.contents]: contents,
    })}
    to={to}
  >
    {children}
  </RouterLink>
);

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  underline: PropTypes.bool,
  contents: PropTypes.bool,
};

Link.defaultProps = {
  underline: true,
  contents: false,
};

export default Link;
