import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Button, {
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Common/Button';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Table from 'components/Common/Table';
import Modal from 'components/Common/Modal';
import ConfirmationMessage from 'components/Common/ConfirmationMessage';
import OfferForm, { OfferFormMode } from 'components/Pages/OfferForm';
// import MerchantData from 'components/Pages/MerchantData';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import {
  clearOffersErrors,
  clearOffersSuccess,
  editOffer,
  fetchOfferById,
  fetchOffers,
} from 'state/actions/offers';
import {
  selectCreateOfferState,
  selectEditOfferState,
  selectFetchOfferByIdState,
  selectFetchOffersState,
} from 'state/selectors/offers';
import searchIcon from 'assets/icons/search.svg';
import addIcon from 'assets/icons/close.svg';
import exportIcon from 'assets/icons/export.png';
import TableConfig from 'enums/table/table.config';
import ModalType from 'enums/modal/modalType';
import Columns from 'enums/table/columns.enum';
import StatusValue from 'enums/status/statusValue.enum';
import Status from 'enums/status/status.enum';
import getOffersFilters from 'utils/filters/offersFilters';
import { selectUserAttributesState } from 'state/selectors/auth';
import allOffersColumns from 'utils/offers/columns';
import useModal from 'hooks/useModal';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import Types from 'enums/type/type.enum';
import CommissionType from 'enums/offers/commissionTypes.enum';
import LocationForm from 'components/Pages/LocationForm';
import { selectFetchMerchantByIdState } from 'state/selectors/merchants';
import { fetchMerchantById } from 'state/actions/merchants';
import { clearLocationFailProp } from 'state/actionCreators/locations';
import getIdToken from 'utils/getIdToken';
import { exportData } from 'state/actions/exportData';
import selectExportDataState from 'state/selectors/exportData';
import getParsedFilters from 'utils/getParsedFilters';
import isAnyStatusActive from 'utils/isAnyStatusActive';

import classes from './Offers.module.scss';

const FILTER_ACTIVE_STATUS = Object.freeze({
  status: [
    {
      columnName: 'status',
      text: 'ACTIVE',
      type: 'string',
    },
  ],
});

const DEFAULT_SORT_BY = Object.freeze({
  ascending: false,
  descending: true,
  columnName: 'createdDate',
});

const FILTER_BY_CREATED_DATE_DESC_ACTIVE_STATUS = `?status=ACTIVE&createdDateSort=-1&page=${TableConfig.PAGE}&limit=${TableConfig.LIMIT}`;

const noCloseOnClickOutside = () => null;

const getTotalCommission = ({ commissionType, totalCommission }) =>
  commissionType === CommissionType.Percent
    ? `${totalCommission ?? '-'}%`
    : `$${totalCommission ?? '-'}`;

const Offers = () => {
  const dispatch = useDispatch();
  const { merchantId } = useParams();

  const { offersData, loading: loadingFetchOffers } = useSelector(
    selectFetchOffersState,
    shallowEqual
  );

  const { loading: exportInProgress } = useSelector(
    selectExportDataState,
    shallowEqual
  );

  const { merchant } = useSelector(selectFetchMerchantByIdState, shallowEqual);
  const [bodyOffer, setBodyOffer] = useState({});
  const [fetchMerchantCalled, setFetchMerchantCalled] = useState(false);

  const { roles: userRoles, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual
  );

  const columns = useMemo(
    () => [
      {
        Header: 'header',
        columns: getAllowedColumns(allOffersColumns[0].columns, userRoles),
      },
    ],
    [userRoles]
  );

  const {
    offer: offerById,
    loading: loadingFetchOfferById,
    error: errorFetchOfferById,
  } = useSelector(selectFetchOfferByIdState, shallowEqual);

  const {
    success: successEdit,
    loading: loadingEdit,
    error: errorEdit,
  } = useSelector(selectEditOfferState, shallowEqual);

  const {
    success: successCreate,
    error: errorCreate,
    createdOffer: newOffer,
  } = useSelector(selectCreateOfferState, shallowEqual);

  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(
    !merchantId ? FILTER_ACTIVE_STATUS : {}
  );
  const [selectedFiltersGeneral, setSelectedFiltersGeneral] = useState(
    !merchantId
      ? FILTER_BY_CREATED_DATE_DESC_ACTIVE_STATUS
      : `?merchantId=${merchantId}`
  );
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);

  const [searchText, setSearchText] = useState('');

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 100,
  });

  const [generalCount, setGeneralCount] = useState();

  const [clickedMoreInfo, setClickedMoreInfo] = useState(false);

  const [isStatusActive, setIsStatusActive] = useState(true);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const { countTotal, offers } = offersData;

  const [loadingLocationModal, setLoadingLocationModal] = useState(false);

  useEffect(() => {
    dispatch(fetchOffers(selectedFiltersGeneral));
  }, [dispatch, selectedFiltersGeneral]);

  useEffect(() => {
    if (successEdit || successCreate) {
      dispatch(fetchOffers(selectedFiltersGeneral));

      // if creating a not-cloned online offer, show loading until the location modal opens.
      if (
        successCreate &&
        bodyOffer.offerType === Types.ONLINE &&
        bodyOffer.merchantLocationIds === undefined
      ) {
        setLoadingLocationModal(true);
      }

      dispatch(clearOffersErrors());
      onCloseModalHandler();
    }
  }, [
    successEdit,
    successCreate,
    onCloseModalHandler,
    dispatch,
    selectedFiltersGeneral,
    searchText,
    bodyOffer,
  ]);

  useEffect(() => {
    if (errorCreate || errorEdit || errorFetchOfferById) {
      dispatch(clearOffersErrors());
      if (modal.type === ModalType.OFFER_STATUS) {
        onCloseModalHandler();
      }
    }
  }, [
    errorCreate,
    errorEdit,
    modal,
    onCloseModalHandler,
    dispatch,
    errorFetchOfferById,
  ]);

  useEffect(() => {
    if (offers.length > 0 && data.length === 0) {
      const transformedData = offers.map((offer) => ({
        ...offer,
        offerId: offer._id,
        status: Status[offer.status],
        merchantId: offer.merchant?._id,
        merchantName: offer.merchant?.name,
        totalCommission: getTotalCommission(offer),
      }));
      setData(transformedData);
      setGeneralCount(
        Math.ceil(parseInt(countTotal, 10) / parseInt(pagination.limit, 10))
      );
    } else if (offers.length === 0 && data.length > 0) {
      setData([]);
    }
  }, [offers, data, searchText, countTotal, pagination]);

  useEffect(() => {
    // if we have just made an online offer which was not cloned
    // (as we already have a location for cloned offers) and has loaded merchant
    if (
      successCreate &&
      bodyOffer.merchantLocationIds === undefined &&
      bodyOffer.offerType === Types.ONLINE &&
      merchant
    ) {
      setLoadingLocationModal(false);
      onOpenModalHandler(ModalType.LOCATION_INFO, {
        location: {
          merchantName: merchant.name,
          locationType: bodyOffer.offerType,
          name: bodyOffer.name,
          merchantId: bodyOffer.merchantId,
          networkData: [
            {
              network: merchant.merchantNetwork,
            },
          ],
          status: bodyOffer.status,
          qualifiedIssuer: merchant.qualifiedIssuer,
          websiteURL: merchant.websiteURL,
          source: merchant.source,
          offerId: newOffer._id,
          isValidated: true,
        },
      });
      dispatch(clearOffersSuccess());
      setBodyOffer({});
    }
  }, [
    successCreate,
    newOffer,
    bodyOffer,
    dispatch,
    onOpenModalHandler,
    merchant,
  ]);

  useEffect(() => {
    if (!fetchMerchantCalled && bodyOffer?.merchantId) {
      dispatch(fetchMerchantById(bodyOffer.merchantId));
      setFetchMerchantCalled(true);
    }
  }, [bodyOffer, dispatch, fetchMerchantCalled]);

  useEffect(() => {
    if (clickedMoreInfo && !errorFetchOfferById && offerById) {
      onOpenModalHandler(ModalType.OFFER_INFO, {
        offer: {
          ...offerById,
          merchantId: clickedMoreInfo.merchant?._id,
          merchantName: clickedMoreInfo.merchant?.name,
        },
      });
      setClickedMoreInfo(false);
    }
  }, [errorFetchOfferById, offerById, onOpenModalHandler, clickedMoreInfo]);

  useEffect(() => {
    const anyStatusActive = isAnyStatusActive(selectedFiltersGeneral);

    setIsStatusActive(anyStatusActive);
  }, [selectedFiltersGeneral]);

  const onCloseLocationHandler = useCallback(() => {
    setBodyOffer({});
    onCloseModalHandler();
    dispatch(clearLocationFailProp());
  }, [onCloseModalHandler, dispatch]);

  const onClickCellButtonHandler = useCallback(
    (columnName, rowIndex) => {
      if (columnName === Columns.MoreInfo) {
        setClickedMoreInfo(data[rowIndex]);
        const { offerId } = data[rowIndex];
        dispatch(fetchOfferById(offerId));
      }
      // if (columnName === Columns.MerchantName) {
      //   onOpenModalHandler(ModalType.OFFER_MERCHANT_DATA, {
      //     merchantId: data[rowIndex].merchantId,
      //   });
      // }
    },
    [data, dispatch]
  );

  const onSubmitFilterHandler = useCallback(
    ({
      resetPagination = false,
      selected,
      offerId,
      columnName,
      dates,
      sortValue,
      pageIndex,
      pageSize,
      range,
    }) => {
      const mId = merchantId || '';
      const actualID = offerId === '' ? offerId : searchText;
      const actualPage =
        pageIndex || pageIndex === 0 ? pageIndex : pagination.page;
      const filters = getOffersFilters({
        resetPagination,
        pageIndex: actualPage,
        pageLimit: pageSize || pagination.limit,
        setPagination,
        offerId: actualID,
        selected,
        selectedFilters,
        setSelectedFilters,
        columnName,
        dates,
        sortValue,
        sortBy,
        setSortBy,
        range,
        merchantId: mId,
      });
      setData([]);
      setSelectedFiltersGeneral(filters);
      dispatch(fetchOffers(filters));
    },
    [selectedFilters, sortBy, pagination, searchText, merchantId, dispatch]
  );

  const onChangeSearchTextHandler = useCallback((text) => {
    setSearchText(text);
    if (text === '') {
      setSortBy(DEFAULT_SORT_BY);
      setSelectedFilters(FILTER_ACTIVE_STATUS);
      setSelectedFiltersGeneral(FILTER_BY_CREATED_DATE_DESC_ACTIVE_STATUS);
    }
  }, []);

  const onChangeSwitchHandler = useCallback(
    (columnName, index, status) => {
      if (columnName === 'status') {
        const offer =
          status === 'active'
            ? { ...data[index], expirationDate: new Date() }
            : data[index];
        onOpenModalHandler(ModalType.OFFER_STATUS, {
          status,
          offer,
        });
      }
    },
    [onOpenModalHandler, data]
  );

  const onChangeOfferStatusHandler = useCallback(() => {
    const { offer, status } = modal;
    const newStatus =
      status === Status.INACTIVE ? StatusValue.Active : StatusValue.Inactive;
    const body = {
      ...offer,
      status: newStatus,
    };

    delete body.merchantName;
    delete body.__v;
    delete body._id;
    delete body.createdDate;
    delete body.lastModified;
    delete body.merchant;
    delete body.offerId;

    if (!offer.minTransactionAmount) {
      delete body.minTransactionAmount;
    }

    if (!offer.maxTransactionAmount) {
      delete body.maxTransactionAmount;
    }

    if (!offer.terms) {
      delete body.terms;
    }

    dispatch(editOffer(offer.offerId, body));
  }, [modal, dispatch]);

  const onAddOfferHandler = useCallback(() => {
    onOpenModalHandler(ModalType.ADD_OFFER);
  }, [onOpenModalHandler]);

  const fetchOfferByIdErrorMessage = clickedMoreInfo
    ? 'Error fetching the offer, please try again'
    : 'There is no offer that matches that ID';

  const onExportDataHandler = async () => {
    const idToken = await getIdToken();
    let filters = getParsedFilters(selectedFiltersGeneral);

    if (searchText !== '') {
      filters = {
        ...filters,
        _id: searchText,
      };
    }

    const exportInfo = {
      idToken,
      filters,
      model: 'Offer',
    };

    dispatch(exportData(exportInfo));
  };

  const noActiveOfferById =
    searchText && data.length > 0 && data[0].status !== Status.ACTIVE;
  const noDataToShow = data.length === 0;

  const getExportOffersTooltip = () => {
    if (loadingFetchOffers) {
      return '';
    }

    if (exportInProgress) {
      return 'There is another export in progress. Try again after!';
    }

    if (!isStatusActive) {
      return 'There are no active offers to export';
    }

    if (noActiveOfferById) {
      return 'Cannot export inactive/closed offers';
    }

    if (noDataToShow) {
      return 'There is no data to export';
    }

    return '';
  };

  const disableExportButton =
    !isStatusActive ||
    loadingFetchOffers ||
    exportInProgress ||
    noDataToShow ||
    noActiveOfferById;

  return (
    <>
      {errorFetchOfferById && (
        <Toast
          id="fetch offer by id"
          text={fetchOfferByIdErrorMessage}
          type={ToastType.Error}
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.OFFER_STATUS}
        onClose={onCloseModalHandler}
        className={classes.confirmationModalWidth}
      >
        <ConfirmationMessage
          message="Are you sure you want to change the offer status?"
          onAccept={onChangeOfferStatusHandler}
          onCancel={onCloseModalHandler}
          loading={loadingEdit}
        />
      </Modal>
      <Modal
        isOpen={
          modal.type === ModalType.ADD_OFFER ||
          modal.type === ModalType.OFFER_INFO
        }
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <OfferForm
          initialMode={
            modal.type === ModalType.ADD_OFFER
              ? OfferFormMode.NEW
              : OfferFormMode.VIEW
          }
          bodyOffer={bodyOffer}
          setBodyOffer={setBodyOffer}
          onCancel={onCloseModalHandler}
          offer={modal.offer}
        />
      </Modal>
      {/* <Modal
        isOpen={modal.type === ModalType.OFFER_MERCHANT_DATA}
        onClose={onCloseModalHandler}
        className={classes.merchantDataWidth}
      >
        <MerchantData
          onCancel={onCloseModalHandler}
          merchantId={modal.merchantId}
        />
      </Modal> */}
      <Modal
        isOpen={modal.type === ModalType.LOCATION_INFO}
        onClose={noCloseOnClickOutside}
        className={classes.modalWidth}
      >
        <LocationForm
          title="Location Information"
          onCancel={onCloseLocationHandler}
          location={modal.location}
          setBodyOffer={setBodyOffer}
          isCreating
          showCancelButton={false}
        />
      </Modal>
      <div className={classes.table}>
        <div className={classes.tableHeading}>
          <div className={classes.searchWidth}>
            <Input
              name="search"
              onChange={(event) =>
                onChangeSearchTextHandler(event.target.value)
              }
              placeholder="Search By Offer ID"
              type={InputType.Text}
              icon={searchIcon}
              size={InputSize.S}
              className={classes.search}
              onKeyPress={() =>
                onSubmitFilterHandler({ resetPagination: true })
              }
            />
          </div>
          {isAdmin && (
            <div className={classes.actions}>
              <Button
                type={ButtonType.Submit}
                size={ButtonSize.S}
                className={classes.button}
                onClick={onAddOfferHandler}
              >
                <div className={classes.addIcon}>
                  <img src={addIcon} alt="add" />
                </div>
              </Button>
            </div>
          )}
          {!isAdmin && (
            <span data-tip={getExportOffersTooltip()} data-for="exportOffers">
              <Button
                type={ButtonType.Default}
                className={classes.exportButton}
                onClick={onExportDataHandler}
                kind="minimal"
                disabled={disableExportButton}
              >
                <span className={classes.exportOffers}>
                  <img
                    src={exportIcon}
                    className={classes.exportIcon}
                    alt="export offers"
                  />
                  Export
                </span>
              </Button>
            </span>
          )}
        </div>
        {loadingFetchOffers || loadingFetchOfferById || loadingLocationModal ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <Table
            columns={columns}
            data={data}
            selectedFilters={selectedFilters}
            sortBy={sortBy}
            onSubmitFilter={onSubmitFilterHandler}
            onChangeSwitch={onChangeSwitchHandler}
            onClickCellButton={onClickCellButtonHandler}
            itemsPerPage={pagination.limit}
            currentPage={pagination.page}
            manualPagination
            fetchData={onSubmitFilterHandler}
            bodyClassName={classes.tableBody}
            countTotal={generalCount}
            newPagination
          />
        )}
      </div>
      <ReactTooltip id="exportOffers" />
    </>
  );
};

export default Offers;
