import Groups from 'enums/users/groups.enum';

const fields = [
  {
    text: 'Reward Type',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Valid Reward',
    default: true,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Under Review',
    default: true,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Status',
    default: true,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Transaction ID',
    default: true,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Transaction Date',
    default: true,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Created Date',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Received Payment',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Paid To Issuer',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'ReferringPartner User ID',
    default: true,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Card BIN',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Card Last 4',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Matched Merchant Name',
    default: false,
    allowedRoles: Groups.Internal,
  },
  {
    text: 'First Offer',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Merchant Network',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Offer ID',
    default: true,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Transaction Amount (in cents)',
    default: true,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Received Commission',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Issuer Commission',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Authorization Date',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Settled Date',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Issuer ID',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Txn Merchant Name',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Merchant City',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Merchant Street Address',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Transaction Description',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Merchant Latitude',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Merchant Longitude',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Merchant State',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Merchant MCC',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Merchant Country',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Merchant Zip Code',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Provided Merchant ID',
    default: false,
    allowedRoles: Groups.Everyone,
  },
  {
    text: 'Offer Total Commission',
    default: false,
    allowedRoles: Groups.Administrators,
  },
  {
    text: 'Offer Commission Type',
    default: false,
    allowedRoles: Groups.Administrators,
  },
];

export default fields;
